const DesignDevIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={98.878}
    height={90.638}
    viewBox="0 0 98.878 90.638"
  >
    <path
      d="M98.878,91.638H16.48A16.486,16.486,0,0,1,0,75.158V10.5A12.782,12.782,0,0,1,12.36,1,12.346,12.346,0,0,1,24.7,10.525l.021,6.954H98.878Zm-8.24-65.918H24.719V71.038a9.36,9.36,0,0,0-7.918-4.12,8.523,8.523,0,0,0-8.561,8.24,8.243,8.243,0,0,0,8.24,8.24H90.638ZM82.4,75.158H32.959v-41.2H82.4ZM49.439,38.079H37.079V71.038h41.2V38.079H53.559v12.36H78.278v4.12h-8.24v12.36h-4.12V54.559H53.559v12.36h-4.12Z"
      transform="translate(0 -1)"
      fill="#ff884a"
      fillRule="evenodd"
    />
  </svg>
);

export default DesignDevIcon;
