const MediaBuyingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={90.638}
    height={90.638}
    viewBox="0 0 90.638 90.638"
  >
    <path
      d="M79.946,74.6a18.925,18.925,0,1,0-5.34,5.34L85.3,90.638l5.34-5.34ZM64.2,75.532A11.33,11.33,0,1,1,75.532,64.2,11.344,11.344,0,0,1,64.2,75.532ZM45.734,83.085H18.883L28.7,71.755H38.88A26.452,26.452,0,0,0,45.734,83.085ZM37.766,64.2H0V0H83.085V45.734a26.559,26.559,0,0,0-7.553-5.382V7.553H7.553v49.1H38.88A26.27,26.27,0,0,0,37.766,64.2ZM22.659,49.1H15.106V26.436h7.553Zm11.33,0H26.436V15.106h7.553Zm11.33,0H37.766V33.989h7.553Z"
      fill="#ff884a"
    />
  </svg>
);

export default MediaBuyingIcon;
