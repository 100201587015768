const DigitalMarketingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={90.638}
    height={90.638}
    viewBox="0 0 90.638 90.638"
  >
    <path
      d="M63.9,21.394l5.181-5.181a11.33,11.33,0,1,1,5.34,5.34l-5.163,5.163a30.191,30.191,0,0,1,0,37.207l5.163,5.163a11.33,11.33,0,1,1-5.34,5.34L63.9,69.244a30.188,30.188,0,0,1-37.044-.125l-5.306,5.306a11.33,11.33,0,1,1-5.34-5.34l5.325-5.325a30.185,30.185,0,0,1,0-36.882l-5.325-5.325a11.33,11.33,0,1,1,5.34-5.34l5.306,5.306A30.188,30.188,0,0,1,63.9,21.394ZM46.029,32.018a1.369,1.369,0,1,1,2.6-.865L56.69,51.384a1.372,1.372,0,1,1-2.6.869,19.156,19.156,0,0,0-11.126.3l3.969,6.167a1.1,1.1,0,0,1-.563,1.658l-2.7.9-.566.094a1.808,1.808,0,0,1-1.348-.608l-5.45-6.016c-2.968.937-6.33-.144-7.451-2.938a5.422,5.422,0,0,1-.389-2.013A5.815,5.815,0,0,1,32,44.583c12.1-5.941,14.034-12.565,14.034-12.565Zm8.592,1.3A9.145,9.145,0,0,1,59.47,45.289l2.088.887A11.423,11.423,0,0,0,55.5,31.221l-.88,2.092Zm-1.507,3.573a5.266,5.266,0,0,1,2.795,6.892l2.036.865a7.49,7.49,0,0,0-3.969-9.8Z"
      fill="#ff884a"
      fillRule="evenodd"
    />
  </svg>
);

export default DigitalMarketingIcon;
