const HeroImage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={731.502}
    height={624.428}
    viewBox="0 0 731.502 624.428"
  >
    <defs>
      <linearGradient
        id="a"
        x1={0.099}
        y1={0.082}
        x2={0.899}
        y2={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#1db6cf" />
        <stop offset={1} stopColor="#1c3e5e" />
      </linearGradient>
      <radialGradient
        id="b"
        cx={0.5}
        cy={0.5}
        r={0.66}
        gradientTransform="matrix(-0.634, 0, 0, 1, 0.817, 0)"
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} />
        <stop offset={1} stopColor="#02b3c4" />
      </radialGradient>
      <radialGradient
        id="c"
        cx={0.475}
        cy={0.493}
        r={0.529}
        gradientTransform="matrix(-1.271, -0.04, -0.04, 0.787, 1.085, 0.071)"
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} />
        <stop offset={0.11} stopColor="#000a0b" />
        <stop offset={0.29} stopColor="#002528" />
        <stop offset={0.53} stopColor="#015058" />
        <stop offset={0.82} stopColor="#028b99" />
        <stop offset={1} stopColor="#02b3c4" />
      </radialGradient>
      <linearGradient
        id="d"
        x1={0.888}
        y1={0.559}
        x2={0.381}
        y2={-1.043}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#6c00a9" />
        <stop offset={1} stopColor="#4700b3" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={1.183}
        y1={-0.056}
        x2={0.265}
        y2={-0.056}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#420b6e" />
        <stop offset={0.23} stopColor="#4e1790" />
        <stop offset={0.7} stopColor="#7123b8" />
        <stop offset={1} stopColor="#9c1aac" />
      </linearGradient>
      <linearGradient
        id="f"
        x1={0.519}
        y1={0.95}
        x2={0.519}
        y2={-0.051}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#58fffe" />
        <stop offset={0.27} stopColor="#48daf1" />
        <stop offset={0.58} stopColor="#39b6e5" />
        <stop offset={0.84} stopColor="#2fa0de" />
        <stop offset={1} stopColor="#2c98db" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={0.893}
        y1={-0.466}
        x2={0.893}
        y2={-1.419}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#0043b3" />
        <stop offset={0.22} stopColor="#004bb8" />
        <stop offset={0.55} stopColor="#0061c7" />
        <stop offset={0.97} stopColor="#0085de" />
        <stop offset={1} stopColor="#0088e0" />
      </linearGradient>
      <linearGradient
        id="h"
        x1={-0.228}
        y1={1.744}
        x2={-0.228}
        y2={2.744}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#00349a" />
        <stop offset={0.25} stopColor="#003ca1" />
        <stop offset={0.63} stopColor="#0052b3" />
        <stop offset={1} stopColor="#006dc9" />
      </linearGradient>
      <linearGradient id="i" x1={-0.456} y1={2.281} x2={-0.456} y2={3.282} xlinkHref="#f" />
      <radialGradient
        id="j"
        cx={0.509}
        cy={0.489}
        r={0.468}
        gradientTransform="matrix(1.07, 0, 0, -1.074, -0.201, 1.045)"
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#02689b" />
        <stop offset={1} />
      </radialGradient>
      <linearGradient id="k" x1={1} y1={0.499} x2={0} y2={0.499} gradientUnits="objectBoundingBox">
        <stop offset={0} stopColor="#0020a9" />
        <stop offset={1} stopColor="#0063b3" />
      </linearGradient>
    </defs>
    <path
      d="M344.59,48.485,326,195.009a16.724,16.724,0,0,1-16.536,14.634L79.638,210.6a16.724,16.724,0,0,1-16.6-19.17L83.108,54.819A14.864,14.864,0,0,1,97.135,42.15L329.183,31.7A14.864,14.864,0,0,1,344.59,48.485Z"
      transform="translate(255.463 32.364)"
      fill="url(#a)"
    />
    <path
      d="M80.008,129.5h68.255a6.878,6.878,0,0,0,6.836-6.021l7.881-62.715A6.857,6.857,0,0,0,155.852,53L87.22,56.015a6.878,6.878,0,0,0-6.543,6.021l-7.5,59.747a6.878,6.878,0,0,0,6.836,7.714Z"
      transform="translate(415.686 55.607)"
      fill="#4ea7d4"
    />
    <path
      d="M324.858,63.736,62.645,73.561l3.094-20.905A12.083,12.083,0,0,1,77.112,42.391L314.74,31.667a12.062,12.062,0,0,1,12.543,13.547Z"
      transform="translate(273.25 32.332)"
      fill="#1db6cf"
    />
    <path
      d="M176.362,43.352,185.226,38l6.9,6.711L190.39,55.623,181.714,59.8l-6.794-7.442Zm6.272,10.014c1.861.293,3.679-1.463,4.076-3.93a4.181,4.181,0,0,0-2.634-5.017c-1.861-.293-3.679,1.463-4.077,3.93a4.181,4.181,0,0,0,2.55,5.017Z"
      transform="translate(173.79 39.253)"
      fill="#58fffe"
    />
    <g transform="translate(0)">
      <g transform="translate(0 20.798)">
        <path
          d="M496.875,27.73,478.29,174.254a16.724,16.724,0,0,1-16.536,14.634l-229.831.941a16.724,16.724,0,0,1-16.62-19.17L235.477,34A14.843,14.843,0,0,1,249.5,21.458L481.551,11.006A14.863,14.863,0,0,1,496.874,27.73Z"
          transform="translate(-215.123 -10.995)"
          fill="url(#b)"
          style={{ mixBlendMode: 'screen', isolation: 'isolate' }}
        />
        <path
          d="M228.739,184.033l229.831-.941a16.724,16.724,0,0,0,16.536-14.634l18.564-146.5a15.051,15.051,0,0,0-.376-5.665,14.634,14.634,0,0,1,2.091,9.616L476.779,172.472a16.724,16.724,0,0,1-16.515,14.634l-229.831.941a16.724,16.724,0,0,1-16.181-12.23A16.724,16.724,0,0,0,228.739,184.033Z"
          transform="translate(-211.814 -5.22)"
          fill="#58fffe"
        />
      </g>
      <g transform="translate(27.784)">
        <path
          d="M328.022,28.127l17.1-.481a5.5,5.5,0,0,0,5.352-4.808l1.9-15.616a5.5,5.5,0,0,0-5.77-6.167l-17.1.481a5.519,5.519,0,0,0-5.352,4.808l-1.9,15.721a5.477,5.477,0,0,0,5.77,6.062Z"
          transform="translate(-322.22 -1.046)"
          fill="#c16a3c"
        />
        <path
          d="M328.263,27.517l16.473-.481a5.289,5.289,0,0,0,5.122-4.683L351.7,7.009a5.331,5.331,0,0,0-5.456-5.958l-16.452.46a5.352,5.352,0,0,0-5.143,4.7l-1.84,15.344a5.352,5.352,0,0,0,5.456,5.958Z"
          transform="translate(-322.691 -1.042)"
          fill="#ff884a"
        />
        <path
          d="M327.954,18.888a1.861,1.861,0,0,0,1.338-.544L341.835,5.99a1.9,1.9,0,0,0,0-2.7,1.923,1.923,0,0,0-2.718,0L326.574,15.627a1.923,1.923,0,0,0,0,2.718,1.944,1.944,0,0,0,1.38.544Z"
          transform="translate(-320.124 0.792)"
          fill="#fff"
        />
        <path
          d="M339.091,18.814a1.9,1.9,0,0,0,1.4-3.2L329.705,3.929a1.908,1.908,0,0,0-2.8,2.592l10.745,11.686a1.944,1.944,0,0,0,1.442.606Z"
          transform="translate(-319.534 1.431)"
          fill="#fff"
        />
      </g>
      <path
        d="M376.9,162.8v-1.672l-143.054-.272,9.428-64.91-1.672-.23-9.721,66.834h.983Z"
        transform="translate(-130.059 102.198)"
        fill="#58fffe"
      />
      <path
        d="M229.449,130.243a6.062,6.062,0,0,0,4.557,7.17c2.927.335,5.623-2.341,6.042-5.958s-1.631-6.836-4.557-7.17S229.867,126.626,229.449,130.243Z"
        transform="translate(12.102 133.317)"
        fill="#58fffe"
      />
      <path
        d="M294.039,99.333a6.063,6.063,0,0,0,4.557,7.17c2.927.334,5.624-2.341,6.042-5.958s-1.631-6.836-4.557-7.17S294.52,95.716,294.039,99.333Z"
        transform="translate(-187.514 99.61)"
        fill="#58fffe"
      />
    </g>
    <path
      d="M83.7,308.093a20.3,20.3,0,0,0,5.435,1.15l281.571,18.689a19.525,19.525,0,0,0,20.717-17.309l2.091-18.083L84.62,273.85l-3.115,22.034a19.546,19.546,0,0,0,2.2,12.209Z"
      transform="translate(168.115 296.451)"
      fill="#1db6cf"
    />
    <path
      d="M213.881,297.775a1.84,1.84,0,0,0,1.213-.46,1.861,1.861,0,0,0,.188-2.613l-5.289-6.083,6.857-6.272a1.861,1.861,0,1,0-2.488-2.76l-9.554,8.613,7.672,8.843a1.777,1.777,0,0,0,1.4.732Z"
      transform="translate(85.89 302.107)"
      fill="#fff"
    />
    <path
      d="M111.529,303.006a1.8,1.8,0,0,0,1.108-.355L122.943,295l-8.258-9.888a1.86,1.86,0,0,0-2.613-.251,1.881,1.881,0,0,0-.23,2.613l5.749,6.878-7.17,5.31a1.861,1.861,0,0,0-.376,2.592,1.8,1.8,0,0,0,1.484.753Z"
      transform="translate(379.357 307.997)"
      fill="#fff"
    />
    <g transform="translate(62.407 48.364)">
      <ellipse
        cx={38.089}
        cy={47.204}
        rx={38.089}
        ry={47.204}
        transform="matrix(-0.994, -0.112, 0.112, -0.994, 101.274, 102.368)"
        fill="#58fffe"
        opacity={0.5}
      />
      <path
        d="M279.24,58.1c-1.359,12.041,5.414,22.7,15.156,23.811s18.71-7.777,20.069-19.839-5.414-22.7-15.135-23.811S280.578,46.035,279.24,58.1Z"
        transform="translate(-228.851 -8.93)"
        fill="#58fffe"
      />
      <path
        d="M251.5,62.781c-.4,3.617,1.631,6.836,4.557,7.15a6.042,6.042,0,0,0,6.042-5.959c.418-3.616-1.631-6.835-4.557-7.149S251.922,59.144,251.5,62.781Z"
        transform="translate(-118.644 11.389)"
        fill="#58fffe"
      />
      <path
        d="M314.985,66.783c-.4,3.638,1.631,6.836,4.557,7.17s5.644-2.279,6.063-5.958a6.062,6.062,0,0,0-4.557-7.17C318.1,60.49,315.4,63.166,314.985,66.783Z"
        transform="translate(-314.936 15.749)"
        fill="#58fffe"
      />
      <path
        d="M315,49.883c-.4,3.638,1.631,6.836,4.557,7.17s5.644-2.341,6.042-5.958a6.063,6.063,0,0,0-4.557-7.17C318.11,43.591,315.413,46.2,315,49.883Z"
        transform="translate(-314.945 -2.681)"
        fill="#eb7f47"
      />
      <path
        d="M262.589,38.263a6.063,6.063,0,0,0,4.557,7.17c2.927.334,5.624-2.341,6.042-5.958s-1.631-6.836-4.557-7.17S262.986,34.646,262.589,38.263Z"
        transform="translate(-152.724 -15.352)"
        fill="#fcba98"
      />
      <path
        d="M293.96,59.556l7.024-6.836a1.505,1.505,0,1,0-2.091-2.091l-4.662,4.536-8.362-10.1a1.515,1.515,0,1,0-2.341,1.923Z"
        transform="translate(-224.464 -2.006)"
        fill="#fff"
      />
      <path
        d="M266.647,64.13H309.4v-.564H266.856l-9.951-7.756-.355.439Z"
        transform="translate(-176.538 10.311)"
        fill="#58fffe"
      />
      <path
        d="M267.989,47.072l9.031-11.5h20.424V35.01H276.727L267.55,46.738Z"
        transform="translate(-187.58 -12.371)"
        fill="#58fffe"
      />
      <path
        d="M335.437,50.482l.293-.5L330.8,47.2H296.01v.564h34.64Z"
        transform="translate(-285.361 0.922)"
        fill="#58fffe"
      />
      <path
        d="M291.15,71.622H332.6l8.425-13.609-.481-.293-8.258,13.337H291.15Z"
        transform="translate(-280.501 12.394)"
        fill="#58fffe"
      />
    </g>
    <g transform="translate(520.864 126.987)">
      <path
        d="M92.071,68.94a6.4,6.4,0,1,0,12.543.669c.209-4.181-2.425-7.651-5.9-7.819a6.961,6.961,0,0,0-6.648,7.15Z"
        transform="translate(-86.03 -61.79)"
        fill="#58fffe"
      />
      <path
        d="M101.973,69.826c6.146.314,10.829,6.522,10.453,13.86L90.12,83.5C90.5,76.161,95.806,69.492,101.973,69.826Z"
        transform="translate(-90.12 -53.039)"
        fill="#58fffe"
      />
    </g>
    <path
      d="M56.52,256.64c1.526-42.312,29.706-75.656,62.966-74.443a48.425,48.425,0,0,1,5.247.481,55.557,55.557,0,0,1,5.6,0h0c16.369.606,31.023,8.55,41.81,21.135a71.419,71.419,0,0,1,7,9.365,84.854,84.854,0,0,1,12.125,39.49l28.536,1.442a15.693,15.693,0,0,1,.9-1.3l-2.8,26.09-.418-.92-27.908-1.4h0a83.432,83.432,0,0,1-13.567,31.8,72.725,72.725,0,0,1-8.153,9.637,57.928,57.928,0,0,1-42.96,17.749h0a39.468,39.468,0,0,1-5.247-.46,44.472,44.472,0,0,1-5.54.125h-.5C80.54,333.926,55.057,298.889,56.52,256.64Zm64.1,63.949h0Z"
      transform="translate(392.849 196.467)"
      fill="#197de5"
      fillRule="evenodd"
      opacity={0.88}
    />
    <g transform="translate(430.134 368.253)">
      <path
        d="M146.245,178.538c35.246,1.275,62.423,36.542,60.98,78.917a92.083,92.083,0,0,1-1.568,13.944,83.62,83.62,0,0,1-13.818,32.988c-12.313,17.372-30.814,28.138-51.113,27.407-35.225-1.275-62.527-36.6-61-78.917S111.02,177.263,146.245,178.538Zm-8.655,138.1c26.842.878,49.482-25.839,50.716-59.747s-19.442-62.381-46.179-63.343S92.77,219.262,91.6,253.3,110.978,315.655,137.59,316.638Z"
        transform="translate(-72.364 -175.779)"
        fill="#6fe0f6"
      />
      <g transform="translate(16.759 2.759)">
        <path
          d="M158.831,308.817h-1.192c-26.717-.962-47.371-29.267-46.159-63.322s23.895-60.834,50.507-59.872h1.192c-26.194-.125-48.207,26.361-49.42,59.851S132.72,307.061,158.831,308.817Z"
          transform="translate(-111.43 -170.801)"
          fill="#7cfff6"
        />
        <path
          d="M129.179,304.285A84.018,84.018,0,0,0,143.018,271.3a94.841,94.841,0,0,0,1.547-13.944c1.526-41.81-25.086-76.826-59.789-78.833h1.192c35.225,1.275,62.4,36.542,60.98,78.917a92.084,92.084,0,0,1-1.568,13.944,83.622,83.622,0,0,1-13.735,32.988c-12.313,17.372-30.814,28.138-51.113,27.407h-.878C99.284,331.88,117.179,321.218,129.179,304.285Z"
          transform="translate(-28.825 -178.52)"
          fill="#7cfff6"
        />
      </g>
      <path
        d="M88.766,245.46c-1.212,34.013,19.442,62.36,46.159,63.322s49.336-25.818,50.549-59.726-19.442-62.381-46.159-63.343S89.937,211.426,88.766,245.46Z"
        transform="translate(-69.532 -167.944)"
        fill="url(#c)"
        style={{ mixBlendMode: 'screen', isolation: 'isolate' }}
      />
      <path
        d="M80.44,258.44l22.264,3.366a83.622,83.622,0,0,0,13.818-32.988l-10.7-11.268Z"
        transform="translate(16.771 -133.198)"
        fill="#197de5"
        fillRule="evenodd"
        opacity={0.5}
      />
      <path
        d="M149.58,178.346c33.239,1.213,58.952,36.479,57.426,78.812S177.3,332.814,144.04,331.6,85.088,295.122,86.614,252.81,116.236,177.155,149.58,178.346Zm-5.059,140.4c27.657,1.066,51.155-26.738,52.388-61.963S176.861,192.29,149.1,191.2s-51.113,26.759-52.388,61.963S116.843,317.721,144.521,318.745Z"
        transform="translate(-86.549 -175.984)"
        fill="#197de5"
      />
      <g transform="translate(7.662 2.383)">
        <path
          d="M163.871,311.994h-1.108c-27.657-1-49.064-30.354-47.789-65.579s24.731-62.883,52.388-61.963h1.108c-27.177-.23-50.026,27.177-51.28,61.963S136.757,310.28,163.871,311.994Z"
          transform="translate(-114.92 -171.699)"
          fill="#4dd1ff"
        />
        <path
          d="M148.367,257.069c1.505-41.81-23.623-76.805-56.318-78.729h1.087c33.26,1.192,58.973,36.479,57.447,78.791S120.877,332.787,87.617,331.6H86.509C119.267,331.993,146.862,298.879,148.367,257.069Z"
          transform="translate(-37.704 -178.34)"
          fill="#4dd1ff"
        />
      </g>
      <path
        d="M78.13,223.69c0,6.564,3.449,11.937,7.735,12s7.777-5.205,7.8-11.77-3.449-11.937-7.735-12S78.151,217.126,78.13,223.69Z"
        transform="translate(44.46 -139.338)"
        fill="#7cfff6"
      />
      <path
        d="M54.7,234.653l56.653,2.864,1.338-22.7L56.038,211.95Z"
        transform="translate(74.412 -139.305)"
        fill="#7cfff6"
      />
      <path
        d="M78.15,224.28c0,6.564,3.428,11.937,7.735,12s7.777-5.205,7.8-11.77-3.449-11.937-7.735-12S78.171,217.716,78.15,224.28Z"
        transform="translate(44.398 -138.694)"
        fill="#6fcef6"
      />
      <path
        d="M54.72,235.243l56.674,2.864,1.338-22.7L56.058,212.54Z"
        transform="translate(74.329 -138.661)"
        fill="#6fcef6"
      />
      <path
        d="M21.745,209.833l112.114-.293-5.059,53.1L19.362,249.26C12.233,248.9,6.986,239.79,7.634,228.9S14.616,209.477,21.745,209.833Z"
        transform="translate(151.749 -141.933)"
        fill="url(#d)"
      />
      <path
        d="M21.631,209.843l112.114-.293h0L23.282,211.1c-7.108-.355-13.4,8.174-14.048,19.066-.439,7.526,1.923,14.195,5.791,17.728-4.808-2.989-7.944-10.453-7.442-18.982C8.21,218.037,14.5,209.487,21.631,209.843Z"
        transform="translate(151.967 -141.922)"
        fill="#65b1db"
        opacity={0.5}
      />
      <path
        d="M.111,235.177c-.857,14.634,6.272,26.9,15.783,27.386s18.02-11,18.815-25.63-6.272-26.9-15.783-27.386S.989,220.544.111,235.177Z"
        transform="translate(266.599 -141.94)"
        fill="#55008d"
      />
      <g transform="translate(164.371 67.9)">
        <path
          d="M32.575,216.963a.69.69,0,0,0-.564-.523C31.927,217.088,32.366,216.942,32.575,216.963Z"
          transform="translate(37.612 -202.308)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M38.339,216.514s-.188-.146,0-.188a1.045,1.045,0,0,0-1.129,0C37.565,216.389,37.816,216.389,38.339,216.514Z"
          transform="translate(20.956 -202.613)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M37.833,217.267c-.125-.272-.4-.376-.523-.627C37.352,217.2,37.519,217.2,37.833,217.267Z"
          transform="translate(21.253 -202.09)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M37.887,216.853c0-.1.125,0,.209,0s-.4-.251-.46-.523c-.125,0,0,.23,0,.209v.585a1.777,1.777,0,0,1,.627.314c.1,0,.146-.23.146-.481S38.012,217.1,37.887,216.853Z"
          transform="translate(20.112 -202.428)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M38.773,216.406c0-.376-.334-.146-.481-.23s0-.544-.293,0C38.334,216.427,38.5,216.26,38.773,216.406Z"
          transform="translate(18.87 -202.881)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M39.773,216.221c0-.125-.523-.188-.773-.251A.941.941,0,0,0,39.773,216.221Z"
          transform="translate(15.78 -202.821)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M40.084,216.38c-.146.167-.188,0-.355,0C39.645,216.735,40.126,216.777,40.084,216.38Z"
          transform="translate(13.964 -202.374)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M40.528,217.215c0-.167-.188,0-.418-.125C40.027,217.445,40.486,217.3,40.528,217.215Z"
          transform="translate(12.725 -201.599)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M35.271,217.814v-.23c-.125-.188-.314,0-.481,0C34.978,217.625,35.062,217.793,35.271,217.814Z"
          transform="translate(29.083 -201.152)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M36.032,216.4c0-.146,0-.188-.209-.167s0,.125,0,.146S35.99,216.442,36.032,216.4Z"
          transform="translate(26.357 -202.54)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M36.167,216.144c0-.1-.188,0-.376,0,0,.251.146.23.314.23S36.125,216.165,36.167,216.144Z"
          transform="translate(26.076 -202.681)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M47.635,219.971a9.5,9.5,0,0,0-1.024-.711.36.36,0,0,1-.251.314c0,.376.523,0,.314.5C47.05,219.887,47.092,220.054,47.635,219.971Z"
          transform="translate(-7.468 -199.233)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M38.248,219.273c0-.4-.376,0-.544-.251s.272-.188.4-.125c0-.585-.585.251-.523-.4a.983.983,0,0,1-.439,0c0,.272.209.125.314.167s.167.272,0,.585A1.149,1.149,0,0,1,38.248,219.273Z"
          transform="translate(21.193 -200.062)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M39.768,217.363c-.146-.209,0-.188,0-.46s0,0-.209,0S39.5,217.426,39.768,217.363Z"
          transform="translate(14.823 -201.935)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M30.872,216.153c-.188-.355-.669-.523-.606.293C30.621,216.592,30.516,216.028,30.872,216.153Z"
          transform="translate(42.952 -202.941)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M33.992,214.912c0-.564-1.045-.418-1.171-.23s-.23-.627-.293-.983a4.473,4.473,0,0,0-.669,1.422c.209.293,1.338.167,1.066-.272C33.3,214.912,33.7,215.08,33.992,214.912Z"
          transform="translate(36.487 -205.296)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M41.332,218.1c0-.209-.376,0-.418,0A.49.49,0,0,1,41.332,218.1Z"
          transform="translate(10.228 -200.601)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M42.161,219.094c-.167,0-.272-.251-.46-.314C41.68,219.24,41.973,219.24,42.161,219.094Z"
          transform="translate(7.748 -199.756)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M20.346,232.841v-.481c0-.167-.606-.23-.585.125S20.116,232.757,20.346,232.841Z"
          transform="translate(75.429 -185.093)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M45.8,216.523c0-.5-.146-.962-.544-1a2.383,2.383,0,0,0,0,1.129.543.543,0,0,1,.544-.125Z"
          transform="translate(-3.189 -203.311)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M20.476,217.256c0-.523-.606-.773-.585,0Z"
          transform="translate(75.027 -201.953)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M21.816,217.1a.962.962,0,0,0-.815-.376C20.98,217.306,21.649,216.972,21.816,217.1Z"
          transform="translate(71.367 -202.003)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M22.172,216.126c.564-.272-.753-.69-.585,0C21.88,216.273,22.11,215.645,22.172,216.126Z"
          transform="translate(69.672 -203.061)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M22.942,216.121c0-.418-.983-.648-.941,0C22.315,215.849,22.357,216.163,22.942,216.121Z"
          transform="translate(68.15 -203.097)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M25.355,215.624c0-.167-.23-.125-.209-.334-.23.46-.314.355-.606.46C24.686,216.21,25.23,215.792,25.355,215.624Z"
          transform="translate(60.427 -203.562)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M23.915,216.077c-.1-.376-.732-.46-1.045-.4C23.1,215.973,23.643,215.91,23.915,216.077Z"
          transform="translate(65.358 -203.158)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M5.606,228.725a.523.523,0,0,0-.46-.355c0,.293-.146.335-.146.648h.334C5.188,228.746,5.251,228.663,5.606,228.725Z"
          transform="translate(121.024 -189.298)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M13.147,228.4c.125-.627-.544-.251-.794-.376C12.353,228.48,12.833,228.334,13.147,228.4Z"
          transform="translate(98.097 -189.68)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M35.277,221.324a.481.481,0,0,0-.439-.334c0,.293-.167.334-.146.627h.334C34.88,221.345,34.943,221.262,35.277,221.324Z"
          transform="translate(29.286 -197.346)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M42.813,221.006c.125-.606-.544-.23-.815-.376C41.935,221.09,42.416,220.964,42.813,221.006Z"
          transform="translate(6.468 -197.739)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M37.781,215.318c-.669-.334-1.652.314-2.091.836C36.129,216.572,36.965,215.443,37.781,215.318Z"
          transform="translate(24.692 -203.632)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M36.406,211.736c0-.941-.69.544-.69-.376-.146,0-.1.418-.146.648a7.374,7.374,0,0,1,.836-.272Z"
          transform="translate(26.317 -207.848)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M36.505,214.418c0-.355-.418,0-.585-.188a1.3,1.3,0,0,1,0,.794C36.15,214.941,36.213,214.5,36.505,214.418Z"
          transform="translate(25.486 -204.718)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M36.815,214.057c-.1-.418-.439-.836-.585-.355A.585.585,0,0,0,36.815,214.057Z"
          transform="translate(24.528 -205.528)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M38.221,214.6c-.314-.4-1.087-.92-1.4-.418.209.355.439,0,.209.669C37.238,214.47,37.74,214.533,38.221,214.6Z"
          transform="translate(21.89 -205)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M40.18,214.652c.251-.857-2.091-.857-1.756.23.125,0,.1-.293.125-.481C38.925,214.756,39.72,214.15,40.18,214.652Z"
          transform="translate(16.628 -204.931)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M42.205,213.649c0-.376-.585,0-.815-.209C41.495,213.858,41.85,213.816,42.205,213.649Z"
          transform="translate(8.351 -205.58)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M46.1,213.289a.547.547,0,0,0-.69-.544C45.452,213.142,45.828,213.142,46.1,213.289Z"
          transform="translate(-3.947 -206.348)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M50.346,212.711c-.125-.606-1,0-1.526,0,0,.439.669-.1.439.523C49.593,212.564,49.782,212.857,50.346,212.711Z"
          transform="translate(-15.322 -206.669)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M36.925,211.86v-.648c0-.209-.272,1.108-.732.606-.376.146.23.272.209.5C36.507,212.153,36.591,211.8,36.925,211.86Z"
          transform="translate(24.753 -208.034)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M38.83,214.608c-.125-.69-.773,0-1.066.1a.878.878,0,0,1,0,.5c-.439-.418-.334.334-.711.439s0-.334-.23-.334-.523.1-.5.606c.627-.23.544-.167,1.171,0v-.5a.585.585,0,0,0,.606-.125c0,.418-.188.564,0,.815s-.544-.1-.376.481l.711-.46c-.5-.23-.188-.544,0-1.129-.23.293-.293,0-.585,0C37.931,214.4,38.453,214.608,38.83,214.608Z"
          transform="translate(22.326 -204.636)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M37.39,211.18c-.314.188-.376.334-.711.125a.45.45,0,0,1-.272.481C36.24,211.849,37.348,211.724,37.39,211.18Z"
          transform="translate(23.62 -208.044)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M38.466,214.807c-.334-.606-.648.293-.732.606-.125-.1,0-.376-.334-.334C37.609,216.187,38.048,214.891,38.466,214.807Z"
          transform="translate(20.432 -204.313)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M35.355,220.858c0-.481-.355.293-.355-.188V221Z"
          transform="translate(28.56 -197.695)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M35.684,222.061c0-.209-.23-.418-.314-.188A.313.313,0,0,0,35.684,222.061Z"
          transform="translate(27.458 -196.494)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M35.735,220.93V220.6c0-.1-.146.564-.376.314-.209,0,.125.146.1.251S35.547,220.888,35.735,220.93Z"
          transform="translate(27.574 -197.788)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M35.983,220.6c-.146,0-.188.167-.355,0s0,.209-.146.251S35.983,220.874,35.983,220.6Z"
          transform="translate(26.97 -197.816)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M49.649,218.363a.475.475,0,0,0-.439-.523c-.293.355,0,.648,0,1.15C49.816,219.052,49.21,218.425,49.649,218.363Z"
          transform="translate(-15.168 -200.781)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M53.535,218.109c0-.376-.585-.1-.815-.209C52.8,218.423,53.18,217.963,53.535,218.109Z"
          transform="translate(-26.664 -200.716)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M53.652,214.156c0-.564-.92-.251-.836.293C53.254,214.532,53.254,214.156,53.652,214.156Z"
          transform="translate(-26.969 -205.125)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M56.106,218.388c.355-.23,0-.669-.439-.523C55.5,218.492,56.169,217.949,56.106,218.388Z"
          transform="translate(-35.486 -200.786)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M59.809,218.05c.272-.314-.836-.167-1.15-.23C58.931,218.322,59.14,217.9,59.809,218.05Z"
          transform="translate(-45.398 -200.803)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M61.916,221.275c-.188-.92-.815.167-1.3,0C60.808,221.526,61.686,221.463,61.916,221.275Z"
          transform="translate(-51.56 -197.443)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M46.426,219.256c0-.523-.606-.773-.585,0Z"
          transform="translate(-5.172 -199.772)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M47.709,219.067a.941.941,0,0,0-.794-.376C46.789,219.338,47.5,218.941,47.709,219.067Z"
          transform="translate(-8.671 -199.855)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M48.121,218.1c.544-.272-.773-.69-.585,0C47.807,218.305,48.037,217.615,48.121,218.1Z"
          transform="translate(-10.504 -200.912)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M57.173,218.676s-.753.125-.9-.146C56.086,218.823,57.048,218.781,57.173,218.676Z"
          transform="translate(-37.682 -200.029)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M42.121,220.217c.125-.376-.878.209-1.3,0C41.159,220.824,41.766,220.238,42.121,220.217Z"
          transform="translate(9.606 -198.33)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M45.256,220.2c0-.941-.711.543-.69-.376-.167,0-.125.4-.146.648A5.417,5.417,0,0,1,45.256,220.2Z"
          transform="translate(-1.034 -198.622)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M45.785,220.35V219.7c0-.209-.272,1.108-.732.606-.376.125.23.272.209.5C45.367,220.517,45.451,220.183,45.785,220.35Z"
          transform="translate(-2.629 -198.776)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M46.247,219.62c-.314.188-.376.335-.711.125,0,.272,0,.418-.251.46S46.205,220.164,46.247,219.62Z"
          transform="translate(-3.76 -198.84)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M46.931,213.807c-.669-.335-1.672.334-2.091.857C45.279,215.061,46.094,213.932,46.931,213.807Z"
          transform="translate(-3.586 -205.278)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M45.556,210.185c0-.92-.711.543-.69-.355-.167,0-.125.4-.146.648A6.89,6.89,0,0,1,45.556,210.185Z"
          transform="translate(-1.961 -209.516)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M45.665,212.908c0-.334-.418,0-.585-.188a1.359,1.359,0,0,1,0,.815C45.331,213.431,45.394,213.013,45.665,212.908Z"
          transform="translate(-2.823 -206.365)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M45.954,212.543c0-.418-.439-.815-.564-.355A.5.5,0,0,0,45.954,212.543Z"
          transform="translate(-3.76 -207.17)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M47.38,213.075c-.293-.376-1.087-.9-1.38-.4.188.334.418,0,.209.669C46.46,212.971,46.941,213.033,47.38,213.075Z"
          transform="translate(-6.46 -206.636)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M49.348,213.157c.23-.857-2.091-.878-1.777.209.146,0,0-.272.146-.481C48.157,213.261,48.868,212.634,49.348,213.157Z"
          transform="translate(-11.669 -206.572)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M51.365,212.109c0-.355-.564,0-.815-.209C50.655,212.381,51.01,212.339,51.365,212.109Z"
          transform="translate(-19.958 -207.259)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M55.239,211.768a.518.518,0,0,0-.669-.523C54.612,211.643,54.988,211.622,55.239,211.768Z"
          transform="translate(-32.235 -207.984)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M59.526,211.2c-.125-.606-1,0-1.526,0,0,.418.669-.125.46.5C58.815,211.075,59,211.347,59.526,211.2Z"
          transform="translate(-43.693 -208.316)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M46.082,210.349v-.669c-.272,0-.272,1.108-.732.606-.376.146.251.293.209.5C45.664,210.642,45.748,210.349,46.082,210.349Z"
          transform="translate(-3.553 -209.68)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M47.888,213.16c-.125-.69-.753,0-1.066,0a.9.9,0,0,1,.1.5c-.46-.418-.334.355-.732.439s0-.314-.209-.335-.544.125-.5.627c.606-.23.544-.188,1.171,0v-.481a.606.606,0,0,0,.585-.125c0,.418-.167.564,0,.815s-.523-.125-.376.46l.732-.439c-.5-.251-.209-.564,0-1.15-.251.293-.293,0-.585,0C47.01,212.825,47.511,213.16,47.888,213.16Z"
          transform="translate(-5.881 -206.219)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M46.546,209.68c-.314.188-.376.334-.711,0,0,.293,0,.418-.251.481S46.525,210.224,46.546,209.68Z"
          transform="translate(-4.686 -209.68)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M47.626,213.309c-.314-.606-.627.272-.732.606-.1-.125-.1-.4-.334-.355C46.706,214.688,47.208,213.392,47.626,213.309Z"
          transform="translate(-7.877 -205.95)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M41.3,212.11c-.293-.146-.753.146-.983.4C40.55,212.7,40.926,212.173,41.3,212.11Z"
          transform="translate(11.491 -207.072)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M41.762,211.783c0-.314-.355,0-.481,0a.648.648,0,0,1,0,.23c-.209-.209-.167.146-.334.188s0-.146-.1-.146-.251,0-.23.293c.272-.125.251,0,.544,0v-.23a.293.293,0,0,0,.272,0c0,.188,0,.251,0,.376s-.251,0-.167.209l.334-.209c-.251,0-.1-.251,0-.523,0,.125-.125,0-.272,0S41.595,211.783,41.762,211.783Z"
          transform="translate(10.426 -207.539)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M41.591,211.878c-.146-.272-.293.125-.334.272s0-.167-.146-.146C41.194,212.506,41.4,211.92,41.591,211.878Z"
          transform="translate(9.551 -207.384)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M32.912,217.724c.209,0,.69.272.585-.293-.69.146-.773-.773-.418-1.15,0-.167-.251-.188-.209-.5C32.494,216.24,32.912,216.972,32.912,217.724Z"
          transform="translate(35.184 -203.028)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M35.631,216.034c0,.5-.4.983-.167,1.129s0-.69.4-1.108c-.188,0-.209-.146-.209-.334-.732.334-.983-.272-1.881-.1.1.293.4.439.585.188-.293.627.4.5.773,1.024v-.815Z"
          transform="translate(30.626 -203.24)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M36.493,212.18c.167.836-.4.544-.627.773C36.116,213.518,37.308,212.305,36.493,212.18Z"
          transform="translate(25.332 -206.954)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M37.891,216.988c.146,0,1,0,.209-.188a.46.46,0,0,1-.209.188Z"
          transform="translate(19.439 -201.916)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M38.11,216.92a1.317,1.317,0,0,0,.836.251c-.146-.251,0-.376.167-.585a.418.418,0,0,0-.564.251s0-.146,0-.376a.272.272,0,0,1-.418,0C37.921,216.544,38.34,216.606,38.11,216.92Z"
          transform="translate(18.384 -202.286)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M45.981,217.976a3.345,3.345,0,0,0-2.091-1.108v-.293c0-.1-.23.188-.146.46-.314,0-.334-.146-.5,0a.293.293,0,0,1-.125-.314,10.808,10.808,0,0,1-3.742-.5c.209.313-.251.146-.167.585A.356.356,0,0,0,39,216.68c0-.167.272,0,.23-.376-.355.167-.5,0-.669.564s.418-.439.355.209c2.091.1,3.345,0,5.791.146.209,0,.146.209.209.293s.334,0,.355-.167a.376.376,0,0,1,.209.4C45.542,217.851,45.919,217.851,45.981,217.976Z"
          transform="translate(10.554 -202.548)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M39.586,217.147c0-.209-.251,0-.314-.23s.1-.1.167-.146c-.314-.523-.9-.167-1.4-.314.146.439-1.087.544-1.192,0-.167,0,0,.188-.209.209s-.188-.314-.376-.376a.418.418,0,0,1,0,.293c-.23-.125-.355.355-.564.481,0-.209-.23-.146-.376,0,.23.167,0,.272-.272.209.314.209.4.46.732.481v-.23a.69.69,0,0,1,.711-.188.355.355,0,0,0,0-.355c.167.251.669,0,.753.418a.543.543,0,0,1,.376-.125c0-.314-.167,0-.314,0a.46.46,0,0,1-.1-.23,20.084,20.084,0,0,1,2.383.1Z"
          transform="translate(24.224 -202.472)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M35.3,218.247c0-.209.418,0,.5-.355s-.544.209-.648.125S35.279,218.247,35.3,218.247Z"
          transform="translate(27.842 -200.854)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M45.679,219.858c.669.293,1.024.167,1.735.439a.324.324,0,0,0-.334-.355,1.192,1.192,0,0,0,.146-.481c-.69.481-1.192.146-1.756-.251C45.616,219.356,46.432,219.942,45.679,219.858Z"
          transform="translate(-5.387 -199.287)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M47.422,219.392c.23-.4-.481.146-.376.627a.544.544,0,0,1,.46-.125C47.59,219.663,47.067,219.475,47.422,219.392Z"
          transform="translate(-8.761 -199.239)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M13.192,228.369c-.209-.335-.669-.523-.606.293C12.941,228.808,12.837,228.223,13.192,228.369Z"
          transform="translate(97.592 -189.611)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M46.535,213.791c-.355-.334-.544-.334-1.024-.711a.338.338,0,0,1-.251.314c0,.376.523,0,.314.5C45.95,213.707,45.992,213.874,46.535,213.791Z"
          transform="translate(-4.069 -205.972)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M37.662,210.673c-.209-.355-.669-.523-.606.293C37.411,211.091,37.307,210.527,37.662,210.673Z"
          transform="translate(21.967 -208.917)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M47.486,219.845c0-.941-.69.544-.669-.355-.167,0-.125.4-.167.627A7.374,7.374,0,0,1,47.486,219.845Z"
          transform="translate(-7.926 -198.982)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M35.368,220c.23-.857-2.091-.857-1.777.23.146,0,.1-.293.146-.481C34.177,220.106,34.888,219.5,35.368,220Z"
          transform="translate(31.536 -199.097)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M37.385,219c0-.376-.564,0-.815-.209C36.675,219.229,37.03,219.229,37.385,219Z"
          transform="translate(23.248 -199.745)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M48.005,219.98v-.648c0-.209-.272,1.108-.732.606-.376.146.23.272.209.5C47.587,220.273,47.671,219.917,48.005,219.98Z"
          transform="translate(-9.49 -199.179)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M48.492,219.3c-.314.188-.376.335-.711.125,0,.272,0,.418-.272.481S48.45,219.844,48.492,219.3Z"
          transform="translate(-10.646 -199.189)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M44.579,213.678a15.029,15.029,0,0,0,1.735.439.335.335,0,0,0-.334-.355,1.191,1.191,0,0,0,.146-.481c-.69.481-1.192.146-1.756-.251C44.516,213.176,45.332,213.762,44.579,213.678Z"
          transform="translate(-1.987 -206.027)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M46.337,213.212c.23-.4-.481.146-.376.627a.544.544,0,0,1,.46-.125C46.546,213.483,46.024,213.3,46.337,213.212Z"
          transform="translate(-5.418 -205.979)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M48.62,217.771v-.481c-.209.732-1.4,0-1.338,1.045.23.1.23-.209.251-.46C47.951,217.771,48.349,217.854,48.62,217.771Z"
          transform="translate(-10.377 -201.381)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M44.3,214c-.209,0-.23.209-.439.167.167,0,.314.46.564.314v.4c0,.188.293-.125.5,0s0,.209-.23.188.23,0,.334.418c.167,0,0-.439,0-.5a.463.463,0,0,1,.376-.272c.188,0-.167,0-.125-.293C44.679,214.293,44.47,214.4,44.3,214Z"
          transform="translate(-0.08 -204.969)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M37.681,219.209h0s.125,0,.125-.209-.251.209-.4.272S37.722,219.063,37.681,219.209Z"
          transform="translate(21.134 -199.579)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M41.389,218.518c0-.146,0-.334-.314-.314.188.376-.293-.146-.481,0s-.125-.335,0-.293-1.066,0-1.422.355c0-.481-.4.146-.481-.314,0,0,0,.251-.1.293-.251-.272-.564,0-.773,0s0,.606-.167.293c-.1.293.293.188.334-.125a.836.836,0,0,0,.585-.125.251.251,0,0,1,0,.314c.314,0,.334-.188.585,0,0-.794.753,0,1.066-.523C40.616,218.455,40.95,218.329,41.389,218.518Z"
          transform="translate(17.028 -200.706)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M41.511,216.058c0-.125-.167-.167-.314-.167s0-.188,0-.376-.439-.188-.627-.334c.125.46-1.108.23-1.233.836a.23.23,0,0,0-.209,0,.544.544,0,0,1-.5.334c-.272.523-.878.606-1.066,1.066.146-.125.46,0,.376,0,.92.272,2.592-.669,3.261.355-.167,0-.314-.4-.69-.272.272.23.314.564.669.794,0,.209-.334-.125-.272.209s.188,0,.376,0c-.209-.523.544-.418.711-.334s-.146-.1,0-.314-.355.167-.481,0c.251-.5.627-.167,1.024-.251-.4,0-.732-.836-1.192-1.108-.209,0-.5,0-.523-.335-.125,0,0,.251-.272.209s0-.4-.376-.314a.439.439,0,0,1,0-.314c.167-.314.69.146.606-.251.125,0,0,.146.146.146s-.23,0-.167.209.251,0,.23-.209C41.156,215.7,41.177,216.079,41.511,216.058Z"
          transform="translate(16.028 -203.682)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M38.272,218.509c0-.167-.146,0-.272,0v.376C38.272,218.718,38.125,218.509,38.272,218.509Z"
          transform="translate(19.372 -200.133)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M39.711,218.33a1.82,1.82,0,0,0-.711.125S39.585,218.6,39.711,218.33Z"
          transform="translate(15.842 -200.247)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M38.96,216.881c0,.648.627,0,.92-.1a1.464,1.464,0,0,0-.92.1Z"
          transform="translate(15.757 -201.976)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M17.875,227.4c-.46,0-.376.69-.732.773S18.064,228.069,17.875,227.4Z"
          transform="translate(83.481 -190.356)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M13.325,220.4c0-.418-.334.293-.585.125a.648.648,0,0,0,.564.523C13.2,220.755,12.928,220.525,13.325,220.4Z"
          transform="translate(97.125 -198.136)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M42.274,216.242c0,.209.23,0,.188.5,0-.334.523-.167.523-.648.376.167.627.523.523,1.3a1.385,1.385,0,0,0,2.091-.46c.293-1.213,0-2.091-.523-2.3s-1.819,1.024-2.425.46c-.251.125-.962.125-1.3.418v.585c0,.376.732,0,.69.418a.376.376,0,0,0,.23-.272Z"
          transform="translate(4.917 -204.305)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M58.186,213.2a.4.4,0,0,0-.376.23C58.249,213.514,58.165,213.326,58.186,213.2Z"
          transform="translate(-41.956 -205.842)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M58.382,215.44h-.146a.427.427,0,0,0,0,.481A4.054,4.054,0,0,0,58.382,215.44Z"
          transform="translate(-42.883 -203.399)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M58.19,215.51c-.209,0-.272.146-.46.209C58.127,215.719,58.127,215.656,58.19,215.51Z"
          transform="translate(-41.792 -203.322)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M58.234,215.776h0a.893.893,0,0,1-.376.167c-.188.021.146,0,.146,0a2.615,2.615,0,0,0,.4,0c0-.1.146-.167.251-.251a.585.585,0,0,0-.334,0C58.025,215.63,58.422,215.734,58.234,215.776Z"
          transform="translate(-42.4 -203.15)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M58.646,215.77c-.272,0-.1.125-.167.188s-.4,0,0,.125S58.52,215.9,58.646,215.77Z"
          transform="translate(-43.439 -203.039)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M58.537,216.22a3.235,3.235,0,0,0-.167.314.335.335,0,0,0,.167-.314Z"
          transform="translate(-43.477 -202.548)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M58.094,216.52c.1,0,0,0,0,.167S58.45,216.52,58.094,216.52Z"
          transform="translate(-42.617 -202.221)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M57.65,216.72c-.125,0,0,0,0,.167C57.8,216.929,57.692,216.741,57.65,216.72Z"
          transform="translate(-41.043 -202.003)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M57.392,214.45h-.146c-.125,0,0,.125,0,.209S57.392,214.555,57.392,214.45Z"
          transform="translate(-39.865 -204.478)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M58.273,214.81h-.021S58.294,214.831,58.273,214.81Z"
          transform="translate(-42.753 -204.086)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M58.19,214.83V215c0,.167.167,0,.188-.125Z"
          transform="translate(-42.942 -204.064)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M26.821,229.73c-.46.272-.439.4-.941.753.167,0,.355,0,.376.23.5,0,0-.439.669-.23C26.674,230.294,26.9,230.169,26.821,229.73Z"
          transform="translate(56.055 -187.815)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M56.831,215.51c-.272,0,0,.167-.188.23s-.125-.125,0-.167c-.4,0,.167.251-.293.209a.251.251,0,0,1,0,.188c.188,0,0,0,.125-.146s.188,0,.4,0a.355.355,0,0,1-.042-.314Z"
          transform="translate(-37.59 -203.322)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M58.086,216.44h-.314S58.127,216.565,58.086,216.44Z"
          transform="translate(-41.604 -202.308)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M58.374,212.45c-.251,0-.376.251.188.251C58.666,212.555,58.269,212.6,58.374,212.45Z"
          transform="translate(-43.084 -206.659)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M60.277,213.07a.261.261,0,0,0-.167.481c.146.063-.46,0-.711,0a4.472,4.472,0,0,0,.983.334c.209,0,.167-.564-.167-.481A.22.22,0,0,0,60.277,213.07Z"
          transform="translate(-47.6 -205.983)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M57.065,217.1c-.146,0,0,.146,0,.188Z"
          transform="translate(-39.141 -201.588)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M56.635,217.47a1.3,1.3,0,0,0-.23.188C56.676,217.679,56.656,217.574,56.635,217.47Z"
          transform="translate(-37.477 -201.185)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M59.211,218.86c-.355,0-.669,0-.711.188a2.612,2.612,0,0,0,.794,0,.188.188,0,0,1-.084-.188Z"
          transform="translate(-44.506 -199.669)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M53.182,211.39c-.669,0,.376.314-.272.272h.46A2.089,2.089,0,0,1,53.182,211.39Z"
          transform="translate(-26.896 -207.815)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M52.533,215.67c-.251,0-.418.1-.376.272S52.428,215.775,52.533,215.67Z"
          transform="translate(-24.47 -203.148)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M52.579,217.12c-.439,0,0,.439-.1.648.314,0,0-.293.376-.167C52.454,217.434,52.663,217.371,52.579,217.12Z"
          transform="translate(-25.269 -201.567)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M53.3,211.609a1.528,1.528,0,0,0-.46,0c0,.125.773.146.418.334,0,.167.209,0,.355,0S53.3,211.755,53.3,211.609Z"
          transform="translate(-27.01 -207.595)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M53.1,211.73c.125.146.23.167,0,.314a.4.4,0,0,1,.334.125.439.439,0,0,0-.334-.439Z"
          transform="translate(-27.358 -207.444)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M54.868,214.55c-.146,0-.23,0-.251.167s.23,0,.439.1a.46.46,0,0,0,0-.167C54.868,214.717,54.827,214.7,54.868,214.55Z"
          transform="translate(-32.157 -204.369)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M55.362,217.69c-.439,0-.188.23-.272.335C55.4,218.045,55.32,217.836,55.362,217.69Z"
          transform="translate(-33.445 -200.945)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M9.816,213.3c0-.1.376,0,.627,0,.167-.585-1.212-1.087-.4-1.589.46,0,0,.481.334.564s-.146-.376.314-.711a7.977,7.977,0,0,1-1.233-.188c-.334.753,0,.669-.23,1.631.753-.209.23.544.564.523-.836,0-.732.272,0,.543-.23.23-.523-.188-.836,0,.418.335-.46,1,.5.962a.19.19,0,0,0,.167.188c-.23.209-.878-.251-.857.209.732-.251.523.251.773.439,1.3-.376.523-1.652,1.15-2.216a5.689,5.689,0,0,0-.878-.355Z"
          transform="translate(108.055 -207.826)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M58.659,214.75c-.251.272.188.732.544.962C59.516,215.5,58.722,215.105,58.659,214.75Z"
          transform="translate(-44.665 -204.151)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M61.7,214.55c-.669,0,.355.335-.272.293h.439A1.4,1.4,0,0,1,61.7,214.55Z"
          transform="translate(-53.206 -204.369)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M59.5,214.81c-.23,0,0,.188-.146.251a1.84,1.84,0,0,1,.585,0C59.873,214.977,59.559,214.935,59.5,214.81Z"
          transform="translate(-46.924 -204.086)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M60.406,214.93c-.293,0-.585.146-.272.23S60.406,215.076,60.406,214.93Z"
          transform="translate(-48.754 -203.955)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M59.975,215.2c-.272.1-.648.418-.314.585s0-.188.481,0C59.871,215.618,59.933,215.409,59.975,215.2Z"
          transform="translate(-47.508 -203.66)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M59.993,215.888c-.606-.146-.669.878.125.773h-.334C60.035,216.389,59.617,216.138,59.993,215.888Z"
          transform="translate(-47.483 -202.926)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M60.343,217.13c-.251,0,0,.251-.167.355C60.469,217.444,60.448,217.3,60.343,217.13Z"
          transform="translate(-49.13 -201.556)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M61.053,218.84c-.251,0-.418,0-.376.272S60.948,218.945,61.053,218.84Z"
          transform="translate(-50.801 -199.691)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M35.1,231.16c-.773,0,0,.773-.167,1.171.544,0-.125-.523.69-.314C34.849,231.724,35.247,231.6,35.1,231.16Z"
          transform="translate(28.815 -186.256)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M61.834,214.778a1.675,1.675,0,0,0-.46,0c0,.125.773.167.418.355,0,.146.188-.1.355,0S61.792,214.925,61.834,214.778Z"
          transform="translate(-53.401 -204.138)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M59.014,215c-.481,0,0,.334,0,.46a.962.962,0,0,1,.355,0c-.293.167.23.167.314.334s-.251,0-.251,0,0,.23.439.23c-.167-.272-.125-.23,0-.5h-.355a.188.188,0,0,0,0-.272c.272,0,.376.1.564,0s0,.23.334.188L60.1,215.1c-.167.209-.4,0-.794,0,.188.125,0,.125,0,.251C58.846,215.355,59.014,215.146,59.014,215Z"
          transform="translate(-46.253 -203.878)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M61.62,214.89c.125.146.23.188,0,.314.209,0,.293,0,.334.125a.439.439,0,0,0-.334-.439Z"
          transform="translate(-53.689 -203.998)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M59.236,215.46c-.439.125.188.293.4.355s-.272,0-.251.125C60.177,215.9,59.278,215.648,59.236,215.46Z"
          transform="translate(-46.142 -203.377)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M53.5,214.78c-.125.146.1.376.293.5S53.545,214.968,53.5,214.78Z"
          transform="translate(-28.535 -204.118)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M55.106,214.68c-.334,0,.188.167-.146.146h.23Z"
          transform="translate(-33.002 -204.227)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M53.906,214.81c-.125,0,0,.1,0,.125a.815.815,0,0,1,.314,0S53.906,214.873,53.906,214.81Z"
          transform="translate(-29.71 -204.086)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M54.388,214.87c-.146,0-.293,0-.125.125S54.388,214.933,54.388,214.87Z"
          transform="translate(-30.59 -204.02)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M54.182,215c-.125,0-.334.23-.146.314s0,0,.23,0A.4.4,0,0,1,54.182,215Z"
          transform="translate(-29.986 -203.878)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M54.243,215.36c-.314,0-.334.46,0,.4h-.167C54.013,215.757,54.055,215.465,54.243,215.36Z"
          transform="translate(-30.048 -203.486)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M55.142,214.79h-.23s.4,0,.209.188.1,0,.188,0S55.142,214.874,55.142,214.79Z"
          transform="translate(-33.037 -204.107)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M53.691,214.975c-.251,0,0,.167,0,.23h.167c-.146.1.125,0,.167.188s-.125,0-.125,0h.23c.23,0,0,0,0-.251h-.167v-.125c0-.125.209,0,.293,0s0,.1.167,0l-.146-.167c-.1.1-.209,0-.418,0,.1,0,0,0,0,.125S53.671,215.164,53.691,214.975Z"
          transform="translate(-29.35 -204.042)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M55,214.86s.125,0,0,.146l.272.146S55.272,214.86,55,214.86Z"
          transform="translate(-33.167 -204.031)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M53.814,215.15c-.23,0,0,.146.209.167s-.146,0-.125,0C54.295,215.359,53.835,215.255,53.814,215.15Z"
          transform="translate(-29.305 -203.715)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M52.473,212.56c-.272,0,0,.251-.167.335C52.6,212.874,52.578,212.727,52.473,212.56Z"
          transform="translate(-24.808 -206.539)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M53.183,214.27c-.251,0-.418,0-.376.272S53.078,214.375,53.183,214.27Z"
          transform="translate(-26.479 -204.674)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M53.176,215.71c-.418,0,0,.439,0,.669.293,0,0-.293.376-.188C53.071,216.044,53.28,215.961,53.176,215.71Z"
          transform="translate(-27.245 -203.104)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M16.884,212c-.314,0-.669,0-.669.293.439.251.815,0,1.442.125C17.762,212,16.968,212.314,16.884,212Z"
          transform="translate(85.517 -207.15)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M17.114,214.82c-.481,0-.146.439-.293.606C17.615,215.405,17.009,215.071,17.114,214.82Z"
          transform="translate(84.682 -204.075)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M22.211,214.615c-.732-.1-.355.69.334.669C22.776,214.97,22.211,214.908,22.211,214.615Z"
          transform="translate(68.88 -204.309)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M40.964,229.16a.541.541,0,0,0-.523.794C40.818,229.787,40.734,229.39,40.964,229.16Z"
          transform="translate(11.683 -188.437)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M41.057,229.81c-.69,0,0,.815-.585,1.15C41.12,230.855,41.1,229.977,41.057,229.81Z"
          transform="translate(11.402 -187.728)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M40.441,231c-.732,0-.627.481-.753.836.523.125.439-.585.878-.188C41.11,231.5,40.315,231.146,40.441,231Z"
          transform="translate(13.356 -186.43)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M28.6,212.262c.4.209.4,0,.836,0-.167-.188,0-.5-.564-.5.1,0,.188.146.167.272S28.6,211.99,28.6,212.262Z"
          transform="translate(47.858 -207.412)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M29,211.91c-.627.188.606.606-.125.9C29.9,212.788,29.563,212.1,29,211.91Z"
          transform="translate(47.314 -207.248)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M54.307,217c-.481,0-.209.251,0,.251S54.14,217.125,54.307,217Z"
          transform="translate(-30.258 -201.697)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M27.295,229.28c-.669,0-.983.439,0,.46Z"
          transform="translate(54.034 -188.306)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M27.382,230.1a.627.627,0,0,0-.5.606C27.653,230.832,27.131,230.288,27.382,230.1Z"
          transform="translate(53.508 -187.412)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M28.6,230.634c-.334-.46-.9.523,0,.439C28.916,230.885,28,230.655,28.6,230.634Z"
          transform="translate(49.633 -186.963)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M55.543,217.15c-.272,0,.125.376,0,.564C55.94,217.589,55.543,217.317,55.543,217.15Z"
          transform="translate(-34.546 -201.534)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M52.869,218.88c-.251.272.188.732.544.962C53.726,219.633,52.932,219.235,52.869,218.88Z"
          transform="translate(-26.771 -199.647)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M55.912,218.68c-.669,0,.355.334-.272.293h.439a1.4,1.4,0,0,1-.167-.293Z"
          transform="translate(-35.312 -199.865)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M53.716,219c-.23,0,0,.167-.146.23a1.672,1.672,0,0,1,.564,0C54.093,219,53.779,219,53.716,219Z"
          transform="translate(-29.04 -199.516)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M54.576,219.06c-.293,0-.585.146-.272.23S54.576,219.185,54.576,219.06Z"
          transform="translate(-30.736 -199.451)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M22.6,229.42c-.5.209-1.192.773-.564,1.045.439-.125.1-.334.857,0A.778.778,0,0,1,22.6,229.42Z"
          transform="translate(68.634 -188.153)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M22.616,230.662c-1.087-.251-1.192,1.589.23,1.4a1.347,1.347,0,0,1-.606-.146C22.7,231.582,21.947,231,22.616,230.662Z"
          transform="translate(68.684 -186.824)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M56.045,218.913a1.068,1.068,0,0,0-.46,0c0,.1.773.146.418.335,0,.167.188,0,.355,0S55.982,219.122,56.045,218.913Z"
          transform="translate(-35.508 -199.639)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M53.234,219.12c-.481,0,0,.335,0,.46a.961.961,0,0,1,.355,0c-.293.188.23.167.314.334s-.251,0-.251,0,0,.23.418.23c-.146-.272-.1-.23,0-.5h-.355v-.272c.272,0,.376.1.564,0s0,.23.334.188l-.314-.334c-.167.209-.4,0-.794,0,.188.125,0,.125,0,.251C53.066,219.5,53.234,219.287,53.234,219.12Z"
          transform="translate(-28.369 -199.386)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M55.84,219c.125.125.23.167,0,.293.209,0,.293,0,.334.146A.418.418,0,0,0,55.84,219Z"
          transform="translate(-35.825 -199.516)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M9.856,210.15c-.773.209.355.5.753.606-.146,0-.481,0-.439.23C11.549,210.9,9.856,210.464,9.856,210.15Z"
          transform="translate(106.322 -209.167)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M59.882,218.62c-.23.272.209.732.564.962C60.739,219.414,59.966,219,59.882,218.62Z"
          transform="translate(-48.481 -199.931)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M62.911,218.43c-.648,0,.376.314-.251.272a.989.989,0,0,1,.439.1Z"
          transform="translate(-57.008 -200.138)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M60.736,218.69c-.251,0,0,.167-.146.23a1.675,1.675,0,0,1,.564,0C61.092,218.857,60.8,218.815,60.736,218.69Z"
          transform="translate(-50.736 -199.854)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M61.6,218.8c-.293,0-.606.146-.272.23S61.6,218.925,61.6,218.8Z"
          transform="translate(-52.439 -199.734)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M35.345,229c-.5.23-1.192.794-.564,1.066.439-.125,0-.334.857-.125A.718.718,0,0,1,35.345,229Z"
          transform="translate(29.26 -188.611)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M35.356,230.2c-1.087-.251-1.192,1.568.23,1.38a1.538,1.538,0,0,1-.606-.125C35.44,231.122,34.6,230.516,35.356,230.2Z"
          transform="translate(29.311 -187.326)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M24.621,212.71c-.481,0-.146.439-.293.606C24.851,213.316,24.809,212.982,24.621,212.71Z"
          transform="translate(61.474 -206.376)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M63.1,218.649a1.671,1.671,0,0,0-.481,0c0,.125.794.146.418.334,0,.167.209,0,.355,0S63.035,218.8,63.1,218.649Z"
          transform="translate(-57.236 -199.918)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M60.253,218.87c-.5,0,0,.314,0,.46a.919.919,0,0,1,.355,0c-.314.167.23.146.293.314s-.23,0-.23,0,0,.23.418.251c-.146-.272-.125-.251,0-.5h-.334v-.251c.293,0,.4.1.585,0s0,.209.314.167L61.361,219c-.188.188-.4,0-.815,0,.209,0,0,.125,0,.251C60.086,219.142,60.232,219.016,60.253,218.87Z"
          transform="translate(-50.064 -199.658)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M62.84,218.77c.125.146.23.167,0,.314a.4.4,0,0,1,.334.125.439.439,0,0,0-.334-.439Z"
          transform="translate(-57.459 -199.767)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M33.985,229.44c-.794.209.334.5.753.606-.146,0-.5,0-.46.23C35.72,230.193,34.068,229.754,33.985,229.44Z"
          transform="translate(31.749 -188.131)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M61.1,216.62c-.125.125,0,.335.251.439S61.126,216.766,61.1,216.62Z"
          transform="translate(-51.982 -202.112)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M64.616,217.57c-.23,0,0,.167-.146.251a1.4,1.4,0,0,1,.585,0C64.993,217.737,64.679,217.7,64.616,217.57Z"
          transform="translate(-62.748 -201.076)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M65.475,217.68c-.293,0-.585.167-.251.23S65.475,217.805,65.475,217.68Z"
          transform="translate(-64.443 -200.956)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M65.1,218c-.272,0-.648.418-.314.564s0-.167.481,0A.464.464,0,0,1,65.1,218Z"
          transform="translate(-63.331 -200.607)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M65.747,217.1c-.585-.125-.648.878.125.773h-.334C65.81,217.618,65.392,217.284,65.747,217.1Z"
          transform="translate(-65.3 -201.605)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M31.664,210.68c-.46,0-.146.439-.293.627C31.915,211.349,31.873,210.973,31.664,210.68Z"
          transform="translate(39.694 -208.589)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M32.989,213.78c-.46,0-.773.167-.711.481C32.8,214.24,32.8,213.968,32.989,213.78Z"
          transform="translate(36.633 -205.209)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M61.292,216.73c-.23,0,0,.146,0,.209h.167c-.146,0,0,0,.125.167h-.1s0,.1.188.125,0-.125,0-.23H61.5v-.125c0-.125.167,0,.251,0s0,.1.146,0l-.125-.146H61.4c-.188,0,0,0,0,.1S61.292,216.793,61.292,216.73Z"
          transform="translate(-52.734 -201.992)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M61.369,216.94c-.209,0,0,.146.188.167s-.125,0,0,0C61.808,217.065,61.39,217.065,61.369,216.94Z"
          transform="translate(-52.664 -201.763)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M59.167,213.782s.188-.293-.188-.272c0,.293-.564.293-.836.125s-.125.1-.355,0C58.143,213.929,58.624,213.761,59.167,213.782Z"
          transform="translate(-42.937 -205.504)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M58.433,214.1c.355,0,.669.23.794.125s-.481,0-.773-.23,0,.1-.251,0c.23.335-.209.418-.1.815.209,0,.314-.167.146-.251.439.146.355-.146.732-.293h-.564Z"
          transform="translate(-43.582 -205.067)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M60.8,214.82c.606,0,.376.188.544.293S60.942,214.465,60.8,214.82Z"
          transform="translate(-51.38 -204.179)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M57.905,215.985s0-.418-.125-.1Z"
          transform="translate(-41.612 -203.045)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M58.284,216.24a.376.376,0,0,0,.188-.334.548.548,0,0,1-.4-.1c-.146-.1,0,.209.167.251s0,0-.272,0,0,.146,0,.167S58.054,216.24,58.284,216.24Z"
          transform="translate(-42.326 -203.028)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M59.009,216a1.338,1.338,0,0,0-.836.878h-.209c-.084,0,.146.1.334,0s-.1.125,0,.209,0,0-.209,0a2.8,2.8,0,0,1-.439,1.589c.23,0,0,.1.418.1h0s0-.125-.272-.125c.1.167,0,.23.376.314s-.293-.188.167-.146a10.608,10.608,0,0,1,.209-2.488h.209c.063,0,0-.146,0-.167a.334.334,0,0,1,.293,0S58.842,216.1,59.009,216Z"
          transform="translate(-42.486 -202.788)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M58.287,214.53c-.146,0,0,.1-.167.125a.21.21,0,0,1-.1,0c-.376.125-.125.376-.23.585a.262.262,0,0,1,0,.523c-.334.021.146,0,.146,0s-.23,0-.272.146h.209c-.1,0,.23.167.334.251s-.125,0,0,.167.188,0,.146.125.334-.146.355-.314h-.146c-.146,0-.188-.23-.125-.314a.4.4,0,0,0-.272,0c.188,0,0-.272.314-.293,0,0-.1-.1,0-.167s0,0,0,.125h-.167A6.939,6.939,0,0,1,58.287,214.53Z"
          transform="translate(-42.161 -204.391)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M57.249,214.842c-.146,0,0-.188-.23-.23s.125.23,0,.293A1.16,1.16,0,0,1,57.249,214.842Z"
          transform="translate(-39.2 -204.306)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M26.546,230.367c.418-.5.272-.794.606-1.317-.251,0-.439,0-.439.23a2.09,2.09,0,0,0-.606-.146c.585.585.125.941-.4,1.359C25.835,230.367,26.546,229.782,26.546,230.367Z"
          transform="translate(56.184 -188.557)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M26.106,230.293c-.5-.209.167.4.794.355a.314.314,0,0,1-.146-.376C26.461,230.188,26.189,230.565,26.106,230.293Z"
          transform="translate(55.976 -187.249)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M60.842,218.79c-.23.146-.23.23-.5.418,0,0,.188,0,.209.125s0-.251.355-.125C60.779,219.041,60.9,219.02,60.842,218.79Z"
          transform="translate(-49.963 -199.745)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M62.374,215.15c-.251,0-.376.272.188.272C62.666,215.275,62.269,215.3,62.374,215.15Z"
          transform="translate(-55.446 -203.715)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M9.312,210.56c-.439.481.376,1.317,1.024,1.735C10.838,211.94,9.458,211.208,9.312,210.56Z"
          transform="translate(107.43 -208.72)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M26.2,230c-1.212,0,.669.585-.5.5,0,.125.523.125.836.167A3.24,3.24,0,0,1,26.2,230Z"
          transform="translate(56.821 -187.521)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M22.222,230.43c-.46,0-.146.314-.272.439a3.407,3.407,0,0,1,1.024,0C22.87,230.723,22.326,230.66,22.222,230.43Z"
          transform="translate(68.222 -187.052)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M23.77,230.63c-.523,0-1.066.293-.46.418S23.77,230.86,23.77,230.63Z"
          transform="translate(65.127 -186.834)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M23.085,231.13c-.5.209-1.192.773-.564,1.045.439-.125.1-.334.857,0C22.9,231.883,23,231.506,23.085,231.13Z"
          transform="translate(67.15 -186.288)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M56.117,214.006c-.585-.125-.648.878.125.773h-.334C56.18,214.549,55.762,214.215,56.117,214.006Z"
          transform="translate(-35.538 -204.975)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M56.473,215.27c-.272,0,0,.23-.167.335C56.6,215.584,56.578,215.416,56.473,215.27Z"
          transform="translate(-37.17 -203.584)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M26.507,230.367a2.78,2.78,0,0,0-.857,0c0,.209,1.422.293.753.606.167.314.376-.167.648-.125C26.842,230.7,26.319,230.618,26.507,230.367Z"
          transform="translate(56.411 -187.156)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M21.3,230.75c-.878,0,0,.606.1.836a1.839,1.839,0,0,1,.627,0c-.544.335.418.293.544.606s-.418,0-.439.146.125.418.773.439c-.272-.5-.209-.439.125-.92h-.606a.4.4,0,0,0-.146-.481c.523,0,.711.188,1.045,0s-.167.4.585.314l-.544-.585c-.334.376-.732.1-1.463,0,.355.209,0,.23,0,.46C21,231.44,21.276,231.043,21.3,230.75Z"
          transform="translate(69.461 -186.703)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M26,230.58c.23.251.418.314.125.564.376,0,.544.1.606.23A.773.773,0,0,0,26,230.58Z"
          transform="translate(55.998 -186.888)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M10.348,211.85c-.773.209.355.523.753.627-.146,0-.481,0-.439.23C12.083,212.623,10.452,212.164,10.348,211.85Z"
          transform="translate(104.764 -207.314)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M60.72,219.142c.23-.272.146-.439.334-.732-.146,0-.23,0-.251.146a.647.647,0,0,0-.334,0c.334.314,0,.5-.209.732C60.365,219.142,60.783,218.828,60.72,219.142Z"
          transform="translate(-49.946 -200.16)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M33.976,229.032c-.5-.209.167.4.794.355a.376.376,0,0,1-.167-.4C34.352,228.885,34.059,229.262,33.976,229.032Z"
          transform="translate(31.654 -188.643)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M28.909,230.3h-.627c.92.209-.146,1.087,1.317,1.108.1-.167-.293-.188-.606-.23C28.867,230.823,28.993,230.53,28.909,230.3Z"
          transform="translate(48.344 -187.194)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M59.479,218.761s.146.1,0,.188.334,0,.251-.209.125,0,.272,0,0-.125,0-.209.125,0,.125,0l.293-.125c.293-.125-.293,0-.355,0s-.188,0-.167-.167,0,0-.23,0S59.751,218.7,59.479,218.761Z"
          transform="translate(-47.701 -200.428)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M56.394,215.65h-.146c-.146,0,.146,0,.188.167S56.269,215.63,56.394,215.65Z"
          transform="translate(-36.861 -203.17)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M57.187,215.7s-.23,0-.23.1v.23c0,.084-.23,0-.209,0s0,.46.23.627c-.334,0,0,.167-.23.188h.188a1.691,1.691,0,0,1,0,.335c0,.1.418,0,.188,0,.23,0,.146-.125,0-.146s0-.167,0-.272,0,0,.209,0-.125-.167,0-.251c-.564,0,0-.335-.355-.481C57.124,216.055,57.04,215.867,57.187,215.7Z"
          transform="translate(-38.949 -203.115)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M58.5,216.02a.1.1,0,0,0-.125.125H58.1c-.146,0-.146.167-.251.251.334,0,.146.481.564.564h0s.23.125.23.23c.376.146.418.4.732.5v-.146c.209-.4-.439-1.15.293-1.4,0,0-.272.125-.188.293s.4-.125.564-.272,0,.146.146.125,0,0,0-.146c-.376,0-.293-.251-.23-.334s0,0-.209,0,0,.146,0,.209a.945.945,0,0,0-.146-.46c0,.167-.606.293-.815.46s0,.23-.23.209.167,0,.146.146-.293,0-.251.125,0,0-.209,0,.125-.293-.167-.272h0s0,.1.146,0,0,0-.146,0S58.5,216.145,58.5,216.02Z"
          transform="translate(-44.106 -203.268)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M56.537,215.85v.1c0,.1.125,0,.251,0S56.558,215.954,56.537,215.85Z"
          transform="translate(-37.924 -202.952)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M56.769,216.3a.439.439,0,0,0,0,.314S56.957,216.342,56.769,216.3Z"
          transform="translate(-38.385 -202.461)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M57.818,216.619c.46,0,0-.272,0-.4S57.693,216.535,57.818,216.619Z"
          transform="translate(-41.692 -202.571)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M59.52,218.656h.355c-.23,0-.1-.23-.46-.251.146-.146.376-.251.92-.167.523-.272.167-.732-.272-.941a2.423,2.423,0,0,0-1.631.125c-.146.125.69.836.272,1.066a.753.753,0,0,0,.272.585,1.463,1.463,0,0,1,.418,0c0-.1,0-.314.314-.272a.4.4,0,0,0-.188-.146Z"
          transform="translate(-45.631 -201.451)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M33.121,211.81a.5.5,0,0,0-.481.293C33.2,212.208,33.1,211.978,33.121,211.81Z"
          transform="translate(35.728 -207.358)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M33.381,214.71h-.188a.543.543,0,0,0,0,.627A2.293,2.293,0,0,1,33.381,214.71Z"
          transform="translate(34.527 -204.195)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M33.125,214.79c-.251,0-.355.209-.585.272C33.063,215.062,33.042,214.978,33.125,214.79Z"
          transform="translate(35.932 -204.107)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M33.176,215.247v-.125c0-.125-.23.209-.481.23s.209,0,.188,0h.523a.962.962,0,0,1,.314-.334.669.669,0,0,0-.439-.1C32.925,215.1,33.406,215.1,33.176,215.247Z"
          transform="translate(35.171 -203.979)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M33.732,215.13c-.334,0-.125.167-.209.251s-.5,0,0,.167C33.732,215.4,33.585,215.3,33.732,215.13Z"
          transform="translate(33.779 -203.737)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M33.59,215.71a2.126,2.126,0,0,0-.23.418.481.481,0,0,0,.23-.418Z"
          transform="translate(33.754 -203.104)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M33,216.111c.146,0,0,0,0,.188C33.234,216.361,33.359,216.09,33,216.111Z"
          transform="translate(34.862 -202.669)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M32.43,216.35c-.146,0,0,.1,0,.23C32.639,216.643,32.514,216.392,32.43,216.35Z"
          transform="translate(36.878 -202.406)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M32.1,213.43h-.188c-.167,0,0,.188,0,.272S32.1,213.555,32.1,213.43Z"
          transform="translate(38.419 -205.591)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M33.209,213.89c-.125,0-.167,0-.167.1a.209.209,0,0,1,.146,0C33.376,214.12,33.251,213.911,33.209,213.89Z"
          transform="translate(34.72 -205.089)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M33.14,213.936v.188c.251,0,.23,0,.23-.146S33.161,213.957,33.14,213.936Z"
          transform="translate(34.434 -205.072)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M30.969,220c-.314.188-.314.293-.669.544.125,0,.251,0,.272.146.355,0,0-.293.481-.167C30.885,220.209,31.032,220.209,30.969,220Z"
          transform="translate(42.688 -198.426)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M31.512,214.8c-.355,0,0,.209-.23.272s-.167-.146-.125-.209c-.523,0,.209.334-.376.272a.23.23,0,0,1,0,.23c.23,0,.1,0,.167-.167s.23,0,.523,0a.4.4,0,0,1,.042-.4Z"
          transform="translate(41.225 -204.097)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M32.987,216h-.4c-.23,0,0,0,0,.1S33.049,216.146,32.987,216Z"
          transform="translate(36.176 -202.788)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M33.346,210.84c-.314,0-.481.334.251.334C33.806,210.986,33.2,211.028,33.346,210.84Z"
          transform="translate(34.29 -208.415)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M35.844,211.64a.35.35,0,0,0-.251.627c.167.084-.564.1-.9.125a6.412,6.412,0,0,0,1.275.439c.272-.1.188-.732-.209-.606C35.823,212.016,35.99,211.849,35.844,211.64Z"
          transform="translate(28.426 -207.543)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M31.7,216.85c-.188,0,0,.188,0,.23A.75.75,0,0,1,31.7,216.85Z"
          transform="translate(39.277 -201.861)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M31.139,217.33a1.217,1.217,0,0,0-.314.251C31.181,217.6,31.16,217.435,31.139,217.33Z"
          transform="translate(41.494 -201.338)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M34.471,219.12c-.46,0-.878,0-.941.251a3.576,3.576,0,0,0,1.045,0,.209.209,0,0,1-.1-.251Z"
          transform="translate(32.413 -199.386)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M38.025,229.23c-.857,0,.481.418-.355.355h.585A2.3,2.3,0,0,1,38.025,229.23Z"
          transform="translate(20.078 -188.36)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M25.837,215c-.314,0-.544.1-.5.334C25.711,215.355,25.711,215.146,25.837,215Z"
          transform="translate(58.294 -203.878)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M25.877,216.87c-.544,0,0,.564-.125.857.4,0,0-.376.481-.23C25.73,217.288,26,217.142,25.877,216.87Z"
          transform="translate(57.271 -201.839)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M38.236,229.509a2.681,2.681,0,0,0-.606,0c0,.167,1,.209.544.439.125.209.251-.125.46,0C38.487,229.76,38.174,229.7,38.236,229.509Z"
          transform="translate(19.784 -188.075)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M37.92,229.67c.167.167.293.209,0,.4.272,0,.4,0,.439.167A.544.544,0,0,0,37.92,229.67Z"
          transform="translate(19.452 -187.881)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M28.8,213.55a.376.376,0,0,0-.334.23c.272,0,.314.1.585.125s0-.125,0-.209S28.734,213.738,28.8,213.55Z"
          transform="translate(48.414 -205.46)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M29.485,217.619c-.544-.1-.23.293-.355.418C29.548,218.079,29.444,217.807,29.485,217.619Z"
          transform="translate(46.701 -201.041)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M33.712,213.81c-.314.355.251.941.732,1.233C34.758,214.793,33.712,214.207,33.712,213.81Z"
          transform="translate(32.293 -205.176)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M37.665,213.56c-.857,0,.46.418-.355.355,0,.1.355,0,.585.125A2.361,2.361,0,0,1,37.665,213.56Z"
          transform="translate(21.191 -205.449)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M34.828,213.9c-.314,0-.1.209-.188.293a2.091,2.091,0,0,1,.732,0C35.309,214.109,34.912,214.109,34.828,213.9Z"
          transform="translate(29.296 -205.078)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M35.935,214c-.376,0-.753.188-.334.293S35.935,214.251,35.935,214Z"
          transform="translate(27.102 -204.969)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M35.473,214.39c-.355.146-.836.564-.4.753s0-.23.606,0A.973.973,0,0,1,35.473,214.39Z"
          transform="translate(28.442 -204.544)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M35.5,215.28c-.773-.188-.836,1.129.167.983h-.439C35.671,215.928,35.148,215.51,35.5,215.28Z"
          transform="translate(28.412 -203.594)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M35.9,216.89c-.334,0,0,.314-.209.439C36.069,217.287,36.049,217.12,35.9,216.89Z"
          transform="translate(26.466 -201.817)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M36.842,219.09c-.334,0-.564.125-.5.355S36.7,219.236,36.842,219.09Z"
          transform="translate(24.293 -199.418)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M36.832,221c-.544,0,0,.564,0,.857.376,0,0-.376.46-.23C36.685,221.334,36.957,221.251,36.832,221Z"
          transform="translate(23.3 -197.335)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M37.876,213.853a1.925,1.925,0,0,0-.606,0c0,.167,1,.209.544.439.125.23.251-.1.46,0C38.127,214.187,37.814,214.041,37.876,213.853Z"
          transform="translate(20.896 -205.156)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M34.209,214.12c-.627,0,0,.439,0,.606h.46c-.376.23.314.209.4.439s-.314,0-.314.1,0,.293.544.314c-.188-.355-.146-.314.1-.648h-.46c0-.146,0-.251-.1-.334.376,0,.523.125.753,0s-.125.272.418.23l-.4-.439c-.23.272-.5,0-1.024,0,.251.146,0,.167,0,.334C34,214.6,34.209,214.329,34.209,214.12Z"
          transform="translate(30.145 -204.838)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M37.56,214c.167.188.293.23,0,.4.272,0,.376,0,.439.167A.544.544,0,0,0,37.56,214Z"
          transform="translate(20.565 -204.969)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M34.47,214.73c-.544.167.251.376.544.439-.1,0-.355,0-.314.188C35.7,215.294,34.554,214.981,34.47,214.73Z"
          transform="translate(30.302 -204.173)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M27.082,213.85c-.146.188.146.5.376.648S27.145,214.164,27.082,213.85Z"
          transform="translate(53.034 -205.133)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M29.128,213.72c-.439,0,.251.23-.188.188h.314a1.379,1.379,0,0,1-.125-.188Z"
          transform="translate(47.33 -205.274)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M27.584,213.9c-.167,0,0,0,0,.146a1.148,1.148,0,0,1,.376,0S27.626,213.963,27.584,213.9Z"
          transform="translate(51.612 -205.078)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M28.279,214c-.209,0-.4.1-.188.146S28.279,214,28.279,214Z"
          transform="translate(50.27 -204.969)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M27.981,214.15c-.188,0-.439.293-.209.4s0-.125.314,0A.309.309,0,0,1,27.981,214.15Z"
          transform="translate(51.132 -204.805)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M28.034,214.616c-.4-.1-.439.585,0,.5H27.8C27.7,215.117,27.783,214.72,28.034,214.616Z"
          transform="translate(51.1 -204.309)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M29.2,213.878a.774.774,0,0,0-.314,0s.523,0,.272.23.146,0,.251,0S29.2,213.962,29.2,213.878Z"
          transform="translate(47.255 -205.119)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M27.329,214c-.314,0,0,.23,0,.314h.23c-.188.125.167,0,.209.23s-.167,0-.167,0,0,.146.293.146v-.335h-.23v-.167c0-.042.272,0,.4,0s0,.146.209.125l-.188-.23c-.125.146-.272,0-.544,0,.125,0,0,0,0,.167S27.329,214.146,27.329,214Z"
          transform="translate(51.972 -204.969)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M29.105,214a.12.12,0,0,1,0,.209c-.146.1.209,0,.23,0A.314.314,0,0,0,29.105,214Z"
          transform="translate(46.977 -204.969)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M27.48,214.33c-.293,0,.125.188.272.23s-.188,0-.167,0C28.107,214.6,27.5,214.455,27.48,214.33Z"
          transform="translate(52.051 -204.609)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M37.126,230.74c-.334,0,0,.314-.209.439C37.4,231.137,37.376,230.949,37.126,230.74Z"
          transform="translate(22.608 -186.714)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M26.677,213.19c-.334,0-.544.1-.5.355C26.531,213.524,26.531,213.315,26.677,213.19Z"
          transform="translate(55.697 -205.852)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M26.716,215.06c-.544,0,0,.564-.1.836.376,0,0-.376.481-.23C26.569,215.457,26.841,215.374,26.716,215.06Z"
          transform="translate(54.655 -203.813)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M31.988,221.38c-.23,0-.481,0-.481.23s.585,0,1.045,0C32.616,221.359,32.051,221.631,31.988,221.38Z"
          transform="translate(38.658 -196.921)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M41.049,219.54a.382.382,0,0,0-.376.564C40.756,220.251,40.882,219.707,41.049,219.54Z"
          transform="translate(11.097 -198.928)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M41.089,220c-.481,0,0,.564-.4.815C41.131,220.753,41.131,220.146,41.089,220Z"
          transform="translate(10.91 -198.426)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M40.65,220.83c-.523,0-.46.355-.523.606.355,0,.293-.418.606-.146C41.152,221.185,40.566,220.976,40.65,220.83Z"
          transform="translate(12.311 -197.521)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M40.313,221.545c.293.167.293,0,.606,0-.125-.125,0-.335-.4-.355,0,0,.125.125,0,.209S40.292,221.42,40.313,221.545Z"
          transform="translate(11.896 -197.128)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M40.6,221.32c-.439.167.439.46,0,.648C41.251,221.947,41.021,221.466,40.6,221.32Z"
          transform="translate(11.459 -196.986)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M28.108,216.73c-.585,0-.251.293,0,.314S27.9,216.855,28.108,216.73Z"
          transform="translate(50.817 -201.992)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M31.311,219.63c-.481,0-.711.293,0,.334Z"
          transform="translate(41.259 -198.829)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M31.355,220.21a.439.439,0,0,0-.355.439C31.523,220.733,31.209,220.356,31.355,220.21Z"
          transform="translate(40.922 -198.197)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M32.252,220.582c-.23-.314-.648.4,0,.334C32.419,220.77,31.813,220.6,32.252,220.582Z"
          transform="translate(38.102 -197.879)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M29.707,216.91c-.334,0,.167.5,0,.732C30.229,217.474,29.707,217.1,29.707,216.91Z"
          transform="translate(45.288 -201.796)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M26.249,219.15c-.314.355.251.941.711,1.233C27.4,220.132,26.354,219.61,26.249,219.15Z"
          transform="translate(55.331 -199.353)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M30.214,218.89c-.836,0,.481.418-.334.376a1.721,1.721,0,0,1,.585.1A3.639,3.639,0,0,1,30.214,218.89Z"
          transform="translate(44.153 -199.636)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M27.348,219.23c-.314,0,0,.23-.188.314a2.091,2.091,0,0,1,.732,0C27.808,219.439,27.432,219.4,27.348,219.23Z"
          transform="translate(52.413 -199.266)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M28.462,219.38c-.376,0-.773.188-.334.293S28.462,219.526,28.462,219.38Z"
          transform="translate(50.212 -199.102)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M27.972,219.73c-.355.146-.857.544-.4.753.314-.1,0-.251.606,0C27.846,220.294,27.909,219.981,27.972,219.73Z"
          transform="translate(51.643 -198.72)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M27.973,220.608c-.773-.167-.836,1.129.167,1a.989.989,0,0,1-.439-.1C28.036,221.277,27.492,220.859,27.973,220.608Z"
          transform="translate(51.683 -197.779)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M30.416,219.179a2.674,2.674,0,0,0-.606,0c0,.146,1,.209.544.439.1.209.251-.125.46,0C30.688,219.43,30.416,219.367,30.416,219.179Z"
          transform="translate(43.951 -199.34)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M26.769,219.46c-.627,0,0,.418,0,.585a1.526,1.526,0,0,1,.46,0c-.4.23.314.209.4.418s-.314,0-.314,0,0,.314.544.314c-.209-.355-.146-.314,0-.648h-.439a.251.251,0,0,0-.1-.335c.376,0,.5.146.753,0s-.125.293.418.23l-.4-.418c-.23.272-.5,0-1.045,0,.272.146,0,.167,0,.314C26.56,219.941,26.769,219.669,26.769,219.46Z"
          transform="translate(53.222 -199.015)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M30.08,219.34c.167.167.293.209,0,.4.251,0,.376,0,.439.167A.564.564,0,0,0,30.08,219.34Z"
          transform="translate(43.681 -199.146)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M26.991,220.07c-.544.146.251.376.544.439,0,0-.355,0-.334.167C28.245,220.614,27.054,220.3,26.991,220.07Z"
          transform="translate(53.418 -198.35)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M35.326,218.82c-.314.355.251.941.711,1.233C36.434,219.8,35.431,219.2,35.326,218.82Z"
          transform="translate(27.293 -199.713)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M39.224,218.56c-.857,0,.481.418-.334.376h.564q-.124-.182-.23-.376Z"
          transform="translate(16.329 -199.996)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M36.418,218.9c-.314,0,0,.23-.188.314a2.089,2.089,0,0,1,.732,0C36.878,219.109,36.5,219.109,36.418,218.9Z"
          transform="translate(24.382 -199.625)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M37.522,219c-.376,0-.773.209-.334.314S37.522,219.251,37.522,219Z"
          transform="translate(22.212 -199.516)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M37.042,219.4c-.355.146-.857.543-.4.732s0-.23.606,0C36.916,219.923,36.979,219.651,37.042,219.4Z"
          transform="translate(23.612 -199.08)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M37.043,220.278c-.773-.167-.836,1.129.167,1a.988.988,0,0,1-.439-.1C37.106,220.947,36.562,220.508,37.043,220.278Z"
          transform="translate(23.652 -198.139)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M39.445,218.849a2.487,2.487,0,0,0-.585,0c0,.146,1,.209.523.439.125.209.272-.125.481-.1C39.7,219.183,39.383,219.037,39.445,218.849Z"
          transform="translate(15.982 -199.7)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M35.789,219.13c-.627,0,0,.418,0,.585a1.528,1.528,0,0,1,.46,0c-.4.23.293.209.376.418s-.293,0-.314.1.1.314.564.314c-.209-.355-.146-.314,0-.648h-.439a.251.251,0,0,0,0-.334c.376,0,.5.125.753,0s-.125.293.418.23l-.4-.418c-.23.272-.5,0-1.045,0,.272.146,0,.167,0,.314C35.58,219.611,35.768,219.339,35.789,219.13Z"
          transform="translate(25.241 -199.375)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M39.159,219c.167.188.293.23,0,.418.251,0,.376,0,.418.167A.544.544,0,0,0,39.159,219Z"
          transform="translate(15.642 -199.516)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M36.074,219.74c-.564.146.251.355.544.439,0,0-.355,0-.334.167C37.308,220.284,36.137,219.97,36.074,219.74Z"
          transform="translate(25.374 -198.709)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M36.859,216.22c-.146.167.125.439.334.564S36.859,216.429,36.859,216.22Z"
          transform="translate(22.875 -202.548)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M41.438,217.46c-.314,0-.1.209-.188.314a1.714,1.714,0,0,1,.732,0C41.9,217.669,41.522,217.606,41.438,217.46Z"
          transform="translate(8.868 -201.196)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M42.522,217.6c-.376,0-.773.209-.334.293S42.522,217.767,42.522,217.6Z"
          transform="translate(6.759 -201.043)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M42.062,218c-.355.167-.857.564-.4.753s0-.23.606,0A.63.63,0,0,1,42.062,218Z"
          transform="translate(8.098 -200.607)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M42.893,216.85c-.773-.188-.836,1.129.167.983h-.439C42.956,217.5,42.412,217.184,42.893,216.85Z"
          transform="translate(5.573 -201.882)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M42.516,220.45c-.334,0,0,.314-.209.439C42.684,220.868,42.642,220.659,42.516,220.45Z"
          transform="translate(6.033 -197.935)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M37.121,216.37c-.272,0,0,.188,0,.272h.209c-.188,0,.146,0,.167.188s-.125,0-.125,0,0,.125.251.146v-.314h-.209a.125.125,0,0,0,0-.146h.334c.1,0,0,.125.188.1l-.167-.188c0,.125-.251,0-.481,0,.125,0,0,0,0,.146S37.121,216.454,37.121,216.37Z"
          transform="translate(21.798 -202.384)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M37.245,216.65c-.272,0,0,.167.251.188s-.167,0-.167,0C37.851,216.9,37.266,216.755,37.245,216.65Z"
          transform="translate(21.883 -202.079)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M34.408,212.545c0-.125.251-.355-.251-.335,0,.4-.732.376-1.087.167s-.167.125-.46,0C33.07,212.754,33.655,212.524,34.408,212.545Z"
          transform="translate(34.441 -206.922)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M33.453,212.9c.439,0,.857.293,1.024.167s-.648,0-1.024-.293c0,.125-.125.125-.293,0,.272.439-.293.523-.146,1.045.251,0,.4-.209.188-.314.564.188.46-.209.941-.376h-.732a1.443,1.443,0,0,1,.042-.23Z"
          transform="translate(33.619 -206.31)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M36.452,213.914c.773,0,.5.251.69.376C37.664,214.249,36.6,213.455,36.452,213.914Z"
          transform="translate(23.596 -205.218)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M32.777,215.408s0-.543-.167-.125a.355.355,0,0,1,.167.125Z"
          transform="translate(36.134 -203.743)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M33.107,215.788c.125-.125.272-.23.251-.46a.755.755,0,0,1-.523-.125c-.188-.125,0,.293.209.335h-.355v.23C32.689,215.808,32.71,215.641,33.107,215.788Z"
          transform="translate(35.386 -203.683)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M34.205,215.48a1.735,1.735,0,0,0-1.045,1.129h-.272c-.1,0,.167.146.418.125s-.146.167,0,.272a.4.4,0,0,1-.272,0,3.742,3.742,0,0,1-.564,2.091c.293,0,.125.146.523.125v.125c0,.125,0-.167-.334-.146.125.188,0,.272.481.376s-.376-.23.209-.167c.146-1.192.125-1.861.272-3.219,0-.1.188,0,.272,0s0-.188-.146-.209a.418.418,0,0,1,.376-.1S34,215.5,34.205,215.48Z"
          transform="translate(34.999 -203.355)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M33.346,213.53c-.209,0-.125.125-.23.167s0,0-.125-.1c-.481.146-.167.481-.314.753a.335.335,0,0,1,0,.669s.167,0,.188.125-.314,0-.376.188h.23c-.146.1.293.209.418.334-.188,0-.146.125,0,.209s.23,0,.167.167.46-.209.481-.4-.125,0-.209,0a.293.293,0,0,1-.167-.4.459.459,0,0,0-.334,0c.23,0,0-.376.4-.4,0,0-.125-.125,0-.209s0,0,0,.167h-.209a6.419,6.419,0,0,1,.084-1.275Z"
          transform="translate(35.377 -205.481)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M31.942,213.926c-.188,0,0-.23-.314-.293s.167.314,0,.376S31.88,213.947,31.942,213.926Z"
          transform="translate(39.248 -205.376)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M30.806,220.4c.293-.355.188-.564.439-.941-.188,0-.314,0-.314.167a1.171,1.171,0,0,0-.439,0c.418.4,0,.648-.293.941C30.346,220.422,30.89,219.983,30.806,220.4Z"
          transform="translate(42.704 -199.015)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M30.464,220.364c-.355-.167.125.272.564.23a.23.23,0,0,1-.1-.272C30.714,220.281,30.526,220.552,30.464,220.364Z"
          transform="translate(42.629 -198.079)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M36.579,219c-.334.188-.314.293-.669.544.125,0,.251,0,.272.146.334,0,0-.293.46-.146C36.454,219.4,36.642,219.376,36.579,219Z"
          transform="translate(25.371 -199.516)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M38.528,214.33c-.314,0-.481.355.251.355C38.9,214.5,38.381,214.518,38.528,214.33Z"
          transform="translate(18.3 -204.609)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M26.595,220.36c-.314.355.272.941.732,1.254C27.724,221.343,26.7,220.841,26.595,220.36Z"
          transform="translate(54.253 -198.033)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M30.535,220.11c-.857,0,.481.418-.355.376a1.654,1.654,0,0,1,.585.1,2.361,2.361,0,0,1-.23-.481Z"
          transform="translate(43.226 -198.306)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M27.709,220.45c-.334,0,0,.23-.209.314a1.84,1.84,0,0,1,.753,0C28.169,220.659,27.772,220.6,27.709,220.45Z"
          transform="translate(51.341 -197.935)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M28.813,220.59c-.376,0-.773.209-.355.314S28.813,220.757,28.813,220.59Z"
          transform="translate(49.151 -197.782)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M28.319,220.94c-.355.167-.857.564-.418.753s0-.23.627,0C28.194,221.484,28.257,221.212,28.319,220.94Z"
          transform="translate(50.585 -197.401)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M30.523,212.87c-.773-.188-.857,1.129.146.983h-.418C30.691,213.518,30.147,213.162,30.523,212.87Z"
          transform="translate(43.844 -206.222)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M30.912,214.48c-.334,0-.1.314-.209.439C31.079,214.877,31.058,214.689,30.912,214.48Z"
          transform="translate(41.888 -204.446)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M30.725,220.4a1.715,1.715,0,0,0-.585,0c0,.146,1,.188.523.439.125.209.272-.125.46-.1C30.976,220.654,30.663,220.591,30.725,220.4Z"
          transform="translate(42.952 -198.014)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M27.109,220.68c-.627,0,0,.418,0,.585a1.529,1.529,0,0,1,.46,0c-.4.23.293.188.376.418s-.293,0-.314,0,0,.293.564.314c-.209-.355-.167-.314,0-.648h-.439a.272.272,0,0,0-.1-.334c.376,0,.5.125.753,0s-.125.293.418.23l-.4-.418c-.23.272-.523,0-1.045,0,.251.146,0,.146,0,.314C26.879,221.161,27.088,220.889,27.109,220.68Z"
          transform="translate(52.172 -197.684)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M30.39,220.55c.167.188.314.23.1.4.251,0,.376,0,.418.167A.564.564,0,0,0,30.39,220.55Z"
          transform="translate(42.64 -197.826)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M27.344,221.29c-.564.146.251.355.544.439,0,0-.355,0-.334.167C28.578,221.834,27.407,221.52,27.344,221.29Z"
          transform="translate(52.354 -197.019)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M36.4,219.5c.293-.376.188-.585.439-.962-.188,0-.334,0-.334.167a1.087,1.087,0,0,0-.439,0c.418.4.1.648-.272.941C35.915,219.5,36.4,219.083,36.4,219.5Z"
          transform="translate(25.428 -200.018)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M36.075,219.444c-.376-.167.1.272.564.23a.23.23,0,0,1-.125-.272C36.305,219.361,36.117,219.632,36.075,219.444Z"
          transform="translate(25.31 -199.083)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M32.48,220.35h-.46c.669.146-.1.773.941.794,0-.125-.209-.146-.439-.167S32.543,220.517,32.48,220.35Z"
          transform="translate(37.184 -198.044)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M34.75,218.909c0,.125.188.146.146.251s.418-.146.314-.293h.355c0-.146-.1-.167,0-.272s.167,0,.146.125,0-.125.4-.167h-.46c-.063,0-.251-.1-.23-.209s0,0-.293,0S35.126,218.846,34.75,218.909Z"
          transform="translate(28.329 -200.282)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M30.806,215h-.188c-.188,0,.188.146.251.23S30.639,215,30.806,215Z"
          transform="translate(42.307 -203.878)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M31.921,215c-.146,0-.314,0-.293.146.355,0-.146.167,0,.272s-.314,0-.272,0,0,.606.293.815c-.439,0,.125.23-.293.251h.251a.413.413,0,0,1,0,.418c-.1.1.544,0,.251,0,.272,0,.167-.146-.1-.188a.427.427,0,0,0-.1-.335c-.1-.125.125,0,.293,0s-.167-.188,0-.314c-.732,0,0-.418-.46-.627C31.837,215.544,31.733,215.314,31.921,215Z"
          transform="translate(39.52 -203.878)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M33.483,215.38c-.125,0-.167,0-.167.167s-.167,0-.334,0-.188.23-.314.335c.418,0,.167.627.711.732a.146.146,0,0,0,0,.1.336.336,0,0,1,.314.293c.46.188.523.523.941.648v-.188c.272-.5-.544-1.484.4-1.819,0,0-.355.167-.251.376.209-.125.523-.125.732-.335s-.125.188.167.167,0-.1,0-.209c-.481,0-.355-.314-.293-.418s0,0-.272,0,.146.209,0,.272-.125-.355-.188-.585c0,.209-.794.376-1.045.606s0,.272-.314.272c0,0,.23,0,.167.167s-.355,0-.293.188h-.272c-.293-.1.146-.4-.23-.355h.146c.021,0,0,.125.188.1s0-.146-.188-.125S33.483,215.568,33.483,215.38Z"
          transform="translate(32.982 -203.966)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M31.065,215.23c-.146,0,0,0,0,.146a.668.668,0,0,0,.355,0C31.253,215.335,31.086,215.355,31.065,215.23Z"
          transform="translate(40.857 -203.628)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M31.3,215.82a.585.585,0,0,0,0,.4S31.526,215.862,31.3,215.82Z"
          transform="translate(40.333 -202.984)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M32.68,216.273c.585,0,0-.355,0-.523A.4.4,0,0,0,32.68,216.273Z"
          transform="translate(36.022 -203.061)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M34.856,218.851c.188,0,0-.125.46,0-.314,0-.146-.293-.585-.314.167-.209.481-.314,1.192-.23.648-.355.188-.92-.376-1.213a2.924,2.924,0,0,0-2.091.188c-.209.188.878,1.066.355,1.38.1.125,0,.523.334.732a2.572,2.572,0,0,1,.544,0c.1-.146,0-.4.418-.355A.46.46,0,0,0,34.856,218.851Z"
          transform="translate(30.961 -201.688)"
          fill="#65b1db"
          fillRule="evenodd"
        />
      </g>
      <g transform="translate(19.632 35.894)">
        <path
          d="M123.834,216.83a.711.711,0,0,0-.585.5C124.085,217.415,123.855,217.185,123.834,216.83Z"
          transform="translate(-99.71 -169.877)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M123.347,221s-.167.167-.23,0c-.334.272-.146.648,0,.9q.137-.444.23-.9Z"
          transform="translate(-98.511 -165.33)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.85,221c-.314.146-.4.355-.69.5C122.871,221.376,122.829,221.251,122.85,221Z"
          transform="translate(-96.405 -165.33)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.951,221.612c-.146,0,0,0-.125-.167-.355,0-.251.334-.585.439h.983a1.088,1.088,0,0,1,.334-.544,1.3,1.3,0,0,0-.627,0C122.533,221.4,123.243,221.466,122.951,221.612Z"
          transform="translate(-97.279 -165.001)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M123.544,221.61c-.481,0-.125.272-.209.418s-.711,0,0,.209C123.544,221.986,123.314,221.882,123.544,221.61Z"
          transform="translate(-98.855 -164.664)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M123.151,222.44c-.167,0-.167.439-.188.627C123.276,222.921,123.276,222.691,123.151,222.44Z"
          transform="translate(-98.462 -163.759)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.31,223.027c.23,0,0,.125,0,.272C122.833,223.215,122.791,222.839,122.31,223.027Z"
          transform="translate(-96.555 -163.176)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M121.487,223.15c-.209,0,0,.146-.1.334C121.842,223.505,121.591,223.192,121.487,223.15Z"
          transform="translate(-93.578 -162.985)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.013,218.94h-.272c-.209.1,0,.251,0,.376S122.055,219.065,122.013,218.94Z"
          transform="translate(-94.502 -167.576)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M123.352,219.83c-.167,0-.209,0-.167.188s.167,0,.188,0S123.415,219.872,123.352,219.83Z"
          transform="translate(-99.061 -166.605)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M123.333,219.933c-.125,0,0,.146,0,.293.335,0,.272-.125.272-.272S123.354,219.954,123.333,219.933Z"
          transform="translate(-99.502 -166.542)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.042,227.77c-.355.314-.314.46-.732.878.188,0,.376,0,.439.167.481,0-.167-.418.585-.335C118.021,228.251,118.23,228.251,118.042,227.77Z"
          transform="translate(-81.75 -157.947)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.569,220.64c-.523,0,0,.293-.251.439s-.272-.188-.209-.272c-.711,0,.4.4-.439.439,0,0,.167.188.146.334.334,0,0-.167.146-.272.4.146.314-.146.773,0A.585.585,0,0,1,120.569,220.64Z"
          transform="translate(-89.086 -165.722)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.235,222.67c-.251.125-.23,0-.564,0v.188C121.67,223.046,122.381,222.858,122.235,222.67Z"
          transform="translate(-94.786 -163.508)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M124.465,215.59c-.4.188-.544.585.46.439C125.05,215.736,124.339,215.883,124.465,215.59Z"
          transform="translate(-102.682 -171.229)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M127.272,217.05c-.732,0-.376.857-.1.92,0,.23-.753.272-1.192.355a7.734,7.734,0,0,0,1.881.355c.334-.188,0-1.066-.5-.815C127.376,217.573,127.543,217.3,127.272,217.05Z"
          transform="translate(-109.523 -169.637)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.241,223.68c-.23,0,0,.293.125.335S120.241,223.784,120.241,223.68Z"
          transform="translate(-89.741 -162.407)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.319,224.19c0,.146-.272.251-.335.418C119.507,224.566,119.319,224.357,119.319,224.19Z"
          transform="translate(-86.268 -161.851)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M105.848,205.44h-.606c.125.125-.209.5.251.46S105.764,205.628,105.848,205.44Z"
          transform="translate(-44.031 -182.298)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M123.212,227.27c-.606,0-1.171.209-1.192.544a4.748,4.748,0,0,0,1.422-.188A.356.356,0,0,1,123.212,227.27Z"
          transform="translate(-96.704 -158.492)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.243,207.36c-.669,0-.878.564,0,.46Z"
          transform="translate(-104.024 -180.204)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M124.988,208.2a.627.627,0,0,0-.334.69C125.427,208.911,124.863,208.43,124.988,208.2Z"
          transform="translate(-103.812 -179.288)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.215,208.9c-.439-.4-.753.69,0,.481C126.508,209.209,125.609,209.021,126.215,208.9Z"
          transform="translate(-107.359 -178.625)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.2,209.16c-.523,0-.669.857,0,.753C125.945,209.641,126.321,209.578,126.2,209.16Z"
          transform="translate(-107.277 -178.241)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.131,211.18c-.23,0-.125.188-.4.209.606.125.481.209.648.418C127.05,211.64,126.465,211.264,126.131,211.18Z"
          transform="translate(-107.713 -176.038)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.091,209.83c-.46,0-.439.627-.314.857C126.091,210.478,125.944,210.185,126.091,209.83Z"
          transform="translate(-107.046 -177.511)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M112.633,194.37a.376.376,0,0,0-.376.4h.815a1.193,1.193,0,0,0,0-.272C112.7,194.684,112.591,194.642,112.633,194.37Z"
          transform="translate(-65.931 -194.37)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M112.207,200.16c-.794,0-.23.439-.355.669C112.437,200.766,112.186,200.411,112.207,200.16Z"
          transform="translate(-64.083 -188.056)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M117.416,218.45a.46.46,0,0,0-.376.4h.836a1.3,1.3,0,0,1,0-.272C117.5,218.764,117.4,218.722,117.416,218.45Z"
          transform="translate(-80.728 -168.11)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M116.975,224.24c-.773,0-.209.439-.334.669C117.2,224.846,116.975,224.491,116.975,224.24Z"
          transform="translate(-78.865 -161.796)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M123.853,219.92c-.314.564.669,1.275,1.4,1.589C125.692,221.07,124.145,220.547,123.853,219.92Z"
          transform="translate(-102.3 -166.507)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M129.358,220.27c-1.171.146.794.481-.355.564,0,.125.523,0,.836,0A5.85,5.85,0,0,1,129.358,220.27Z"
          transform="translate(-117.63 -166.126)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.451,220.22c-.46,0,0,.334-.167.481a2.844,2.844,0,0,1,1.024,0C126.141,220.429,125.6,220.429,125.451,220.22Z"
          transform="translate(-106.302 -166.18)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.966,220.53c-.5.125-.983.418-.376.481C126.882,220.906,127.008,220.76,126.966,220.53Z"
          transform="translate(-109.197 -165.842)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.967,220.93c-.439.272-.983.941-.314,1.129.418-.188,0-.355.815-.23C125.967,221.7,125.967,221.306,125.967,220.93Z"
          transform="translate(-106.672 -165.406)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.667,222.15c-1.108,0-.773,1.756.544,1.359,0-.1-.376,0-.627,0C125.96,223.07,125.1,222.568,125.667,222.15Z"
          transform="translate(-105.703 -164.076)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.9,224.59c-.439,0,0,.46-.125.648C126.278,225.113,126.173,224.841,125.9,224.59Z"
          transform="translate(-107.087 -161.415)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.484,227.8c-.439,0-.711.272-.564.585C126.422,228.218,126.233,228.009,126.484,227.8Z"
          transform="translate(-107.816 -157.914)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.864,230.51c-.753.167.188.794.1,1.213.564,0-.23-.5.585-.418C125.78,231.116,126.136,230.928,125.864,230.51Z"
          transform="translate(-107.237 -154.959)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M129.634,220.66a3.178,3.178,0,0,0-.794.146c.125.209,1.422,0,.857.5.23.272.335-.209.606-.209C129.969,220.953,129.613,220.932,129.634,220.66Z"
          transform="translate(-117.823 -165.7)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M124.417,220.564c-.836.167.125.585.293.815a2.088,2.088,0,0,1,.606-.146c-.46.4.481.23.669.523s-.4,0-.4.209.23.4.857.314c-.376-.439-.314-.4,0-.92h-.606a.4.4,0,0,0-.251-.439,1.7,1.7,0,0,0,1-.167c.293-.167,0,.418.648.23l-.669-.5c-.23.418-.669.209-1.422.167.4.167,0,.251,0,.481C124.292,221.274,124.48,220.856,124.417,220.564Z"
          transform="translate(-104.808 -165.897)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M129.1,221c.272.23.481.251.251.544.355,0,.544,0,.627.146a.857.857,0,0,0-.878-.69Z"
          transform="translate(-118.041 -165.33)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M124.7,221.33c-.711.335.46.46.878.5-.125.1-.46.125-.376.314C126.668,221.832,124.974,221.644,124.7,221.33Z"
          transform="translate(-104.195 -164.97)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M117.783,218.76c-.606,0,.4.251-.188.314h.418a1.192,1.192,0,0,1-.23-.314Z"
          transform="translate(-81.993 -167.772)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M116.523,218.9c-.272,0-.5.23-.188.251S116.544,219.109,116.523,218.9Z"
          transform="translate(-77.619 -167.62)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M117.915,219.012a.961.961,0,0,0-.418,0c0,.1.732,0,.439.251.125.146.167,0,.314-.125S117.894,219.012,117.915,219.012Z"
          transform="translate(-82.084 -167.523)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M117.64,219.122c.146.125.251.125.125.272h.334A.439.439,0,0,0,117.64,219.122Z"
          transform="translate(-82.206 -167.381)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.109,230.06c-.293.1-.627.188-.585.418.481.188.815,0,1.442,0C119.966,229.935,119.276,230.415,119.109,230.06Z"
          transform="translate(-85.912 -155.45)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.641,232.93c-.46,0,0,.46-.146.669C119.268,233.453,118.6,233.223,118.641,232.93Z"
          transform="translate(-84.628 -152.32)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M123.619,233.47c-.711,0-.167.753.481.627C124.2,233.742,123.619,233.763,123.619,233.47Z"
          transform="translate(-100.017 -151.731)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.552,235.29c-.314-.251-.815.167-.564.418C118.8,235.792,118.009,235.29,118.552,235.29Z"
          transform="translate(-83.244 -149.83)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M117.795,237.6c-.418-.167,0,.69-.125.941C118.276,238.27,117.711,238.082,117.795,237.6Z"
          transform="translate(-81.984 -147.249)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M113.7,238.75c-1.108.251.355.606.1,1C114.18,239.586,113.992,238.9,113.7,238.75Z"
          transform="translate(-69.004 -145.973)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.843,227.42c-.669,0-.878.585,0,.481Z"
          transform="translate(-84.245 -158.328)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.59,228.27a.585.585,0,0,0-.334.669C119.029,228.96,118.465,228.5,118.59,228.27Z"
          transform="translate(-84.034 -157.402)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.815,228.967c-.439-.4-.753.669,0,.46C120.108,229.134,119.209,229.134,119.815,228.967Z"
          transform="translate(-87.58 -156.739)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M117.124,235.61c-.146,0,.251.585,0,.732C117.542,236.425,117.291,235.715,117.124,235.61Z"
          transform="translate(-80.31 -149.397)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M117.5,223.38c-.5,0,.376.648.293,1C118.373,224.049,117.558,223.673,117.5,223.38Z"
          transform="translate(-81.476 -162.734)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M117.418,226.21c-1.171.146.794.481-.355.564,0,.125.523,0,.836,0a3.912,3.912,0,0,1-.481-.564Z"
          transform="translate(-80.729 -159.648)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M117.715,226.6a3.181,3.181,0,0,0-.815.146c.146.209,1.442,0,.878.5.23.272.314-.209.606-.209C118.175,226.893,117.694,226.872,117.715,226.6Z"
          transform="translate(-80.943 -159.223)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M117.16,226.89c.293.23.481.251.272.544.355,0,.544,0,.627.146a.878.878,0,0,0-.9-.69Z"
          transform="translate(-81.161 -158.907)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M124.363,227.26c-.314.564.648,1.275,1.38,1.589C126.182,228.41,124.656,227.887,124.363,227.26Z"
          transform="translate(-103.855 -158.503)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M129.861,227.6c-1.171.167.773.5-.355.585,0,.125.523,0,.836,0A3.137,3.137,0,0,1,129.861,227.6Z"
          transform="translate(-119.178 -158.132)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.941,227.56c-.439,0,0,.335-.146.46a2.779,2.779,0,0,1,1.024,0C126.652,227.748,126.108,227.769,125.941,227.56Z"
          transform="translate(-107.858 -158.176)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M127.457,227.87c-.5.125-.962.418-.355.481C127.394,228.246,127.519,228.142,127.457,227.87Z"
          transform="translate(-110.754 -157.838)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.457,228.26c-.418.293-.962.962-.293,1.15.4-.188,0-.355.794-.251C126.478,229.054,126.478,228.657,126.457,228.26Z"
          transform="translate(-108.228 -157.412)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.166,229.49c-1.108,0-.774,1.756.564,1.359H126.1C126.479,230.41,125.685,229.908,126.166,229.49Z"
          transform="translate(-107.267 -156.071)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.42,231.93c-.46,0,0,.46-.146.648C126.776,232.453,126.671,232.181,126.42,231.93Z"
          transform="translate(-108.651 -153.41)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.984,235.14c-.439.1-.711.272-.564.585C126.9,235.642,126.838,235.349,126.984,235.14Z"
          transform="translate(-109.361 -149.91)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.354,237.85c-.732.167.188.794.125,1.212.544,0-.251-.523.585-.418C126.291,238.456,126.626,238.268,126.354,237.85Z"
          transform="translate(-108.794 -146.954)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M130.165,228a3.172,3.172,0,0,0-.815.146c.146.209,1.442,0,.878.5.23.272.314-.23.585-.23C130.583,228.293,130.144,228.272,130.165,228Z"
          transform="translate(-119.399 -157.696)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M124.971,227.883c-.836.188.1.606.293.836a1.694,1.694,0,0,1,.606-.146c-.46.4.481.23.669.523s-.418,0-.4.209.23.4.857.314c-.4-.439-.314-.4-.1-.92h-.606a.4.4,0,0,0-.251-.46,2,2,0,0,0,1.024-.146c.293-.146,0,.418.627.23l-.669-.5c-.23.418-.669.209-1.422.167.4.167,0,.23,0,.46C124.825,228.614,125.034,228.2,124.971,227.883Z"
          transform="translate(-106.407 -157.892)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M129.57,228.29c.293.23.481.251.272.543.355,0,.523,0,.627.146a.9.9,0,0,0-.9-.69Z"
          transform="translate(-119.515 -157.38)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.19,228.67c-.711.314.439.46.857.5-.125.1-.46.125-.376.293C127.05,229.172,125.357,228.984,125.19,228.67Z"
          transform="translate(-105.622 -156.965)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M127.49,223.92c-.146.251.314.585.648.732C128.347,224.443,127.636,224.213,127.49,223.92Z"
          transform="translate(-112.814 -162.145)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M127.762,224.19c-.4,0,0,.272.125.376a.732.732,0,0,1,.272,0c-.209.188.23,0,.314.251s-.188,0-.188,0,0,.188.4.146c-.167-.209-.146-.188,0-.418h-.272a.188.188,0,0,0-.125-.209h.46c.125,0,0,.209.314.125l-.314-.251c0,.209-.314,0-.669,0,.188,0,0,.1,0,.209C127.7,224.524,127.783,224.336,127.762,224.19Z"
          transform="translate(-113.965 -161.851)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M127.871,224.57c-.314.146.23.209.418.23s-.209,0-.188.125C128.749,224.8,127.871,224.7,127.871,224.57Z"
          transform="translate(-113.635 -161.436)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.557,217.855c-.125-.167.209-.564-.46-.418.272.523-.878.69-1.4.46-.209,0-.188.209-.606.209C123.822,218.419,124.6,217.959,125.557,217.855Z"
          transform="translate(-101.056 -169.25)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M123.807,218.527c.606-.1,1.275.209,1.442,0-.188-.293-.878,0-1.463-.167,0,.146-.125.167-.376.209.523.523-.209.794.146,1.463.334,0,.5-.355.146-.46.836.146.564-.376,1.171-.732h-1.024C123.828,218.778,123.828,218.653,123.807,218.527Z"
          transform="translate(-101.418 -168.209)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M127.736,220.681c1-.23.732.23,1.066.4C129.471,220.807,127.757,219.991,127.736,220.681Z"
          transform="translate(-114.169 -165.972)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.341,221.877c0-.125,0-.773-.251-.146A.5.5,0,0,1,122.341,221.877Z"
          transform="translate(-95.75 -164.827)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.883,222.36c.125-.209.293-.4.167-.69-.272.146-.481,0-.753,0s0,.4.418.418h-.481a.167.167,0,0,1,0,.335C122.444,222.548,122.444,222.214,122.883,222.36Z"
          transform="translate(-96.71 -164.599)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M123.205,221.94a2.216,2.216,0,0,0-1.066,1.8h-.355c-.125,0,.272.167.627,0,0,.23-.167.251.146.376a.593.593,0,0,1-.355.125,5.477,5.477,0,0,1,0,2.989c.355-.209.23.167.753,0,0,0-.125,0-.125.167s0-.209-.5-.125c.251.251.125.376.794.439s-.606-.251.209-.293c-.188-1.693-.418-2.613-.69-4.557,0-.125.23-.1.355-.188-.23,0,0-.251-.293-.251a.648.648,0,0,1,.481-.209C123.184,222.3,122.829,222.065,123.205,221.94Z"
          transform="translate(-96.049 -164.305)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.864,219.26c-.293,0,0,.209-.272.272l-.188-.125c-.606.314,0,.711-.167,1.129.523-.167.815.794.272.92,0,.146.251,0,.293.146s-.376.188-.439.334a.878.878,0,0,1,.376,0c-.125.188.5.23.711.376-.272,0-.167.209,0,.314.188-.209.334,0,.293.188.23-.251.544-.355.5-.627s-.125,0-.272.1a.481.481,0,0,1-.335-.544.69.69,0,0,0-.46,0c.272-.167-.167-.523.4-.627,0,0-.209-.167-.209-.293-.376,0,0,.125,0,.251h-.272a10.663,10.663,0,0,1-.23-1.819Z"
          transform="translate(-97.736 -167.227)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M121.623,219.524c-.272,0-.209-.314-.523-.334,0,.167.335.376.251.481S121.539,219.566,121.623,219.524Z"
          transform="translate(-92.962 -167.303)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.152,228.472c.272-.564,0-.836.272-1.422-.251,0-.4.146-.376.314a1.754,1.754,0,0,0-.627,0c.711.481.355.92,0,1.4C117.525,228.576,118.131,227.865,118.152,228.472Z"
          transform="translate(-82.069 -158.732)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M117.462,228.427c-.544-.146.251.355.836.23a.376.376,0,0,1-.23-.355C117.754,228.26,117.608,228.678,117.462,228.427Z"
          transform="translate(-81.651 -157.37)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M111.8,200.33c-.4.188-.544.585.46.439C112.39,200.476,111.679,200.623,111.8,200.33Z"
          transform="translate(-63.556 -187.871)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.972,227.63c-.376.314-.334.46-.732.878.188,0,.376,0,.418.167.5,0-.146-.418.585-.314C125.951,228.09,126.16,228.048,125.972,227.63Z"
          transform="translate(-106.237 -158.1)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M130.338,221.55c-.4.188-.544.585.46.439C130.882,221.675,130.234,221.843,130.338,221.55Z"
          transform="translate(-120.827 -164.73)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M117.491,228c-1.171.146.774.481-.355.585,0,.125.523,0,.815,0a5.017,5.017,0,0,1-.46-.585Z"
          transform="translate(-80.928 -157.696)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.66,217.75c-1.087,0-.753,1.756.564,1.359,0-.1-.376,0-.627,0C119.974,218.67,119.1,218.273,119.66,217.75Z"
          transform="translate(-87.195 -168.874)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.921,220.19c-.46,0,0,.46-.125.648C120.3,220.713,120.192,220.441,119.921,220.19Z"
          transform="translate(-88.584 -166.213)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M117.785,228.38a2.467,2.467,0,0,0-.815.146c.125.209,1.442,0,.878.5.23.272.314-.23.585-.23C118.2,228.673,117.743,228.652,117.785,228.38Z"
          transform="translate(-81.139 -157.282)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M117.22,228.67c.293.23.481.251.272.544.334,0,.523,0,.627.146a.9.9,0,0,0-.9-.69Z"
          transform="translate(-81.347 -156.966)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.072,228.321c.272-.564,0-.836.272-1.4-.251,0-.418.146-.4.293a1.756,1.756,0,0,0-.606,0c.69.481.355.9,0,1.4C125.445,228.425,126.051,227.714,126.072,228.321Z"
          transform="translate(-106.546 -158.874)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.379,228.286c-.544-.125.272.376.857.23a.355.355,0,0,1-.251-.355C125.692,228.139,125.525,228.558,125.379,228.286Z"
          transform="translate(-106.146 -157.522)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.076,228.72c-.188,0-.188,0-.209.1h-.4c.941,0,0,1.108,1.526.92,0-.209-.314-.167-.627-.146A8.667,8.667,0,0,1,120.076,228.72Z"
          transform="translate(-88.928 -156.911)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M123.89,227.423c0,.167.293.167.272.334,0-.146.523-.293.334-.481h.5c.23,0-.188-.188,0-.376s.251,0,.251.146c.355,0,0-.188.481-.314,0-.125-.544,0-.627,0s-.355-.125-.4-.272,0,.146-.355.146C124.2,227.089,124.371,227.235,123.89,227.423Z"
          transform="translate(-102.901 -159.419)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.776,220.85h-.293c-.293,0,.293.167.4.272S119.567,220.83,119.776,220.85Z"
          transform="translate(-87.686 -165.494)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.685,221.1c-.167,0-.418.125-.355.272.46-.188-.125.251.125.376s-.4.146-.355,0,.209.836.669,1.087c-.606.125.23.293-.334.4h.376c-.293.23.23.418.146.585s.753-.1.4,0c.4,0,.188-.251-.209-.251a.406.406,0,0,0-.23-.439c-.23-.146.146-.1.355-.125s-.272-.23,0-.46c-.983.146,0-.585-.836-.753C120.727,221.769,120.518,221.455,120.685,221.1Z"
          transform="translate(-90.812 -165.221)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M123.075,222.221c-.167,0-.209.146-.167.272h-.481c-.23,0-.188.376-.314.543.544-.146.439.836,1.213.857a.209.209,0,0,0,0,.188.585.585,0,0,1,.523.334c.69.167.9.627,1.484.732-.167-.1,0-.376,0-.314.209-.753-1.233-1.944,0-2.592.146.125-.439.293-.23.564.251-.23.669-.293.878-.606s0,.272.314.188,0-.146,0-.293c-.606.23-.606-.376-.544-.523s-.1.125-.355.1.272.251,0,.376c-.669-.146-.314-.46-.46-.773,0,.314-.962.669-1.233,1.066,0,.146.146.376-.334.439,0,0,.334,0,.314.188s-.5,0-.355.334-.188,0-.355,0,0-.564-.439-.439c0-.1.188,0,.188-.146s0,.188.293.1,0-.209-.293-.146C122.678,222.534,123.138,222.472,123.075,222.221Z"
          transform="translate(-99.891 -165.024)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.978,221.32c-.209,0,.146.1,0,.209a.836.836,0,0,0,.481,0C120.27,221.425,120.04,221.508,119.978,221.32Z"
          transform="translate(-89.31 -164.981)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.019,222.18a.857.857,0,0,0,.251.544S120.312,222.18,120.019,222.18Z"
          transform="translate(-89.351 -164.043)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.082,223.021c.815-.1,0-.5-.251-.711C121.852,222.561,121.831,222.854,122.082,223.021Z"
          transform="translate(-95.302 -163.901)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M111.543,204.025c0,.376.9.23,1.066.481S112.358,203.753,111.543,204.025Z"
          transform="translate(-64.004 -183.893)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M121.382,201.413c-.523,0,.4.209.251.439a.627.627,0,0,0,.564-.5C121.842,201.454,121.591,201.705,121.382,201.413Z"
          transform="translate(-93.788 -186.758)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M124.94,227.068c.272,0,0-.188.585-.209-.418,0-.272-.376-.9-.334.188-.314.564-.544,1.568-.544.753-.627,0-1.359-.92-1.631-1.547,0-2.613.23-2.78.69s1.547,1.3.92,1.84c.209.188.314.732.732.962a2.657,2.657,0,0,1,.732,0c0-.209-.167-.564.439-.585a.627.627,0,0,0-.376-.188Z"
          transform="translate(-100.732 -161.676)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M123.585,237.474a.439.439,0,0,0,.334.272C123.982,237.391,123.752,237.474,123.585,237.474Z"
          transform="translate(-100.464 -147.373)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.58,237.48s.1,0,0,.1a.586.586,0,0,0,.606,0C121,237.522,120.852,237.543,120.58,237.48Z"
          transform="translate(-91.438 -147.358)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.7,237.14c0,.146.251.188.335.335C120.972,237.161,120.888,237.161,120.7,237.14Z"
          transform="translate(-91.537 -147.729)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.658,237.494h-.125c-.125,0,.23.125.293.272v-.439a.94.94,0,0,1-.355-.167s0,.146,0,.272S120.575,237.369,120.658,237.494Z"
          transform="translate(-90.847 -147.707)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.24,237.55c0,.209.188,0,.293.125s0,.293.146,0C120.512,237.529,120.428,237.634,120.24,237.55Z"
          transform="translate(-90.22 -147.282)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.67,237.572h.418A.543.543,0,0,0,119.67,237.572Z"
          transform="translate(-88.438 -147.303)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.4,237.423c0-.1,0,0,.209,0S119.355,237.193,119.4,237.423Z"
          transform="translate(-87.433 -147.531)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.218,236.93c0,.1,0,0,.23,0S119.239,236.888,119.218,236.93Z"
          transform="translate(-86.92 -147.978)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.141,236.83v.125c0,.1.167,0,.251,0S122.266,236.83,122.141,236.83Z"
          transform="translate(-95.926 -148.067)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M121.62,237.555a.1.1,0,0,0,.125,0h0S121.641,237.534,121.62,237.555Z"
          transform="translate(-94.172 -147.286)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M121.552,237.715h.209c.209,0,0-.125-.188-.125S121.573,237.694,121.552,237.715Z"
          transform="translate(-93.999 -147.238)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M107.19,210.687c.4.314.564.293,1.087.648,0-.146,0-.293.209-.334,0-.4-.523.1-.376-.481C107.775,210.729,107.713,210.562,107.19,210.687Z"
          transform="translate(-50.746 -176.758)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.75,236.07c0,.23.209,0,.314.125s-.146.1-.209,0c0,.314.293-.167.314.209a.376.376,0,0,1,.23,0c0-.167-.125,0-.188,0s0-.146,0-.334A.627.627,0,0,1,120.75,236.07Z"
          transform="translate(-92.005 -148.896)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.613,237v.251c0,.146,0,0,.125,0S119.76,237,119.613,237Z"
          transform="translate(-88.026 -147.881)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M124.472,238.012c.125.188.4.251.314-.188C124.6,237.762,124.682,238.075,124.472,238.012Z"
          transform="translate(-103.191 -146.991)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M123.11,238.983c0,.334.585.209.648,0s.167.355.23.544a2.864,2.864,0,0,0,.272-.815c-.125-.146-.732,0-.564.188C123.486,238.962,123.3,238.878,123.11,238.983Z"
          transform="translate(-99.801 -146.08)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.889,236.39c0,.125.209,0,.23,0A.6.6,0,0,1,118.889,236.39Z"
          transform="translate(-85.838 -148.547)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.449,235.871a.874.874,0,0,0,.272.167C118.825,236.059,118.553,235.85,118.449,235.871Z"
          transform="translate(-84.541 -149.114)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M116.311,237.435c0,.272.125.523.355.543s0-.523,0-.648a.251.251,0,0,1-.355.1Z"
          transform="translate(-78.097 -147.521)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.56,234.008c0,.523.355-.335.4.188v-.376A3,3,0,0,1,126.56,234.008Z"
          transform="translate(-109.71 -151.349)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M121.31,232.75a.335.335,0,0,0,.4.272C121.665,232.792,121.456,232.813,121.31,232.75Z"
          transform="translate(-93.485 -152.516)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.193,233c0,.335.544,0,.836,0s-.355,0-.272-.251C119.612,233.043,119.507,232.9,119.193,233Z"
          transform="translate(-87.459 -152.516)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.273,234.064v.355c0,.125,0-.627.376-.355.188,0-.146-.146-.146-.272S126.461,234.085,126.273,234.064Z"
          transform="translate(-108.859 -151.405)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.056,234.226c.146-.1.167-.188.376,0s0-.251,0-.272S126.035,233.933,126.056,234.226Z"
          transform="translate(-108.224 -151.212)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.4,234.988a.272.272,0,0,0,.251.167V234.8h-.188C122.609,234.967,122.588,235.009,122.4,234.988Z"
          transform="translate(-96.708 -150.28)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.3,234.827c0,.335.314.1.46.167C118.739,234.743,118.488,234.827,118.3,234.827Z"
          transform="translate(-84.246 -150.266)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M132.991,199.867c-.125,0,0-.293-.146-.481-.732,0-1.171,1.066-1.923.5,0-.376.606,0,.669-.334-.314-.314-.46.167-.941-.146v.983c.983.167.857-.125,2.091,0-.376-.564.627-.251.544-.5,0,.669.481.544.711,0,.334.167-.146.439.146.648.376-.355,1.338.251,1.129-.481a.167.167,0,0,0,.23-.167c.293.146-.188.711.376.648-.418-.544.251-.439.439-.669-.669-.962-2.09-.209-2.948-.627a3.1,3.1,0,0,0-.376.627Z"
          transform="translate(-127.619 -189.059)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M121.1,238.438c.376.146.878-.251,1.129-.544C121.936,237.665,121.539,238.334,121.1,238.438Z"
          transform="translate(-93.568 -146.958)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M121.3,240.308c0,.523.355-.335.4.167v-.355Z"
          transform="translate(-93.454 -144.479)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M121.3,238.89c0,.188.23,0,.335,0a.69.69,0,0,1,0-.46C121.467,238.576,121.446,238.827,121.3,238.89Z"
          transform="translate(-93.392 -146.322)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M121.1,239c0,.23.272.439.314.167A.272.272,0,0,0,121.1,239Z"
          transform="translate(-92.753 -145.704)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.6,238.727c.209.209.648.481.794.188s-.251,0-.167-.355C121.211,238.769,120.939,238.748,120.6,238.727Z"
          transform="translate(-91.734 -146.18)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.644,238.648c0,.481,1.213.418.962-.188v.272C120.605,238.836,119.895,238.92,119.644,238.648Z"
          transform="translate(-88.934 -146.289)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.218,238.914c0,.188.314,0,.439,0S118.386,238.81,118.218,238.914Z"
          transform="translate(-83.997 -145.844)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M116.011,239.05c0,.188.167.314.4.251S116.157,239.113,116.011,239.05Z"
          transform="translate(-77.169 -145.646)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M103.93,217.791c.188.585.983,0,1.505,0,0-.439-.627.167-.481-.481C104.683,217.874,104.474,217.6,103.93,217.791Z"
          transform="translate(-40.88 -169.354)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M121.013,240.354v.376c0,.125,0-.627.355-.376.188,0-.146-.146-.146-.272S121.2,240.4,121.013,240.354Z"
          transform="translate(-92.582 -144.545)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.616,239.225c.125.376.418,0,.585-.1a.46.46,0,0,1,0-.272c.272.23.167-.188.355-.272s0,.188.146.188.272,0,.23-.355c-.314.146-.272.1-.627,0v.272a.314.314,0,0,0-.314,0v-.439c0-.125.293,0,.167-.293l-.355.293c.293.1.146.293,0,.627,0-.167.146,0,.314,0C121.139,239.309,120.825,239.2,120.616,239.225Z"
          transform="translate(-92.269 -146.845)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.752,240.532c.167-.125.188-.209.376,0s0-.23.125-.272S120.752,240.218,120.752,240.532Z"
          transform="translate(-91.882 -144.347)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.41,238.6c.23.335.334-.188.376-.355s0,.209.188.167C120.807,237.808,120.64,238.54,120.41,238.6Z"
          transform="translate(-90.871 -146.599)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.11,234.172a.793.793,0,0,0,.564-.293C122.8,233.712,122.34,234.172,122.11,234.172Z"
          transform="translate(-96.146 -151.325)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.216,235.188c0,.272.188-.167.209,0s0-.125,0-.188Z"
          transform="translate(-96.105 -150.062)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.22,234.411c0,.1.125,0,.167,0a1.027,1.027,0,0,1,0-.251S122.3,234.39,122.22,234.411Z"
          transform="translate(-96.068 -150.978)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.11,234.45c0,.125.125.23.167,0A.188.188,0,0,0,122.11,234.45Z"
          transform="translate(-95.728 -150.683)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M121.839,234.357c0,.1.335.251.418.1s-.125,0,0-.188S121.944,234.378,121.839,234.357Z"
          transform="translate(-95.164 -150.924)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M121.362,234.23c0,.251.627.23.5,0v.146C121.864,234.523,121.508,234.376,121.362,234.23Z"
          transform="translate(-93.767 -150.902)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.04,235.187v.188c0,.063,0-.334.188-.188s0,0,0-.146S122.124,235.208,122.04,235.187Z"
          transform="translate(-95.616 -150.061)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.108,234.622c0,.188.209,0,.293,0a.293.293,0,0,1,0-.146c.146,0,0-.1.188-.146h0s.146,0,0-.188-.146,0-.314,0v.125a.209.209,0,0,0-.167,0c0-.125,0-.167,0-.251s.146,0,0-.125l-.188.125c.146,0,0,.167,0,.335s0,0,.167,0S122.213,234.622,122.108,234.622Z"
          transform="translate(-95.726 -151.273)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M121.941,235.261s0-.1.188,0,0-.125,0-.146S121.921,235.094,121.941,235.261Z"
          transform="translate(-95.308 -149.947)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M121.81,234.261c.125.167.188-.1.188-.188h0C122,233.969,121.935,234.219,121.81,234.261Z"
          transform="translate(-94.821 -151.101)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.149,233.294c0,.188.334,0,.46,0S125.337,233.19,125.149,233.294Z"
          transform="translate(-105.435 -151.973)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.941,233.43c0,.188.167.314.4.272S123.087,233.493,122.941,233.43Z"
          transform="translate(-98.586 -151.775)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.853,233.682c.1.334.544,0,.836,0s-.355,0-.272-.272C121.272,233.745,121.146,233.577,120.853,233.682Z"
          transform="translate(-92.59 -151.796)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M131.222,205.725c0,.23.209.523.481.481s0-.648,0-1.15C131.055,204.951,131.661,205.62,131.222,205.725Z"
          transform="translate(-124.324 -182.729)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M127.52,205.358c0,.376.585,0,.815.167C128.189,204.814,127.875,205.4,127.52,205.358Z"
          transform="translate(-113.095 -182.592)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M127.014,209.453c0,.585.92.188.794-.334C127.37,209.014,127.391,209.474,127.014,209.453Z"
          transform="translate(-111.524 -178.304)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M105.83,222c.188.481.794.439,1.066.335A1.381,1.381,0,0,0,105.83,222Z"
          transform="translate(-46.313 -164.239)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M104.78,222.052c.146.544,1.024,0,1.526.314C106.076,221.822,104.989,221.927,104.78,222.052Z"
          transform="translate(-43.528 -164.292)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M103.59,221.491c.125.564.732.439,1.192.481,0-.418-.815-.272-.376-.648C104.217,220.906,103.925,221.554,103.59,221.491Z"
          transform="translate(-39.516 -165.131)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M130.345,215.409c.209-.334,0-.314-.146-.669-.209.167-.627,0-.564.5a.481.481,0,0,1,.335-.167C130.094,215.284,130.115,215.451,130.345,215.409Z"
          transform="translate(-119.6 -172.156)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M129.17,215.39c.355.46.69-.544,1.15,0C130.132,214.6,129.337,214.93,129.17,215.39Z"
          transform="translate(-118.529 -171.97)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.38,234.119c0,.376.314.125.314,0S119.547,234.244,119.38,234.119Z"
          transform="translate(-87.437 -151.063)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M107.985,211.22c0,.544.669.732.564,0Z"
          transform="translate(-52.482 -175.995)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M106.808,211.3a.815.815,0,0,0,.815.335C107.686,211.009,107.059,211.427,106.808,211.3Z"
          transform="translate(-49.089 -175.93)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M106.259,212.251c-.523.314.815.648.585,0C106.531,212.042,106.385,212.732,106.259,212.251Z"
          transform="translate(-46.931 -174.915)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.8,235.3c0,.23.46-.125.69,0C119.281,234.949,119.072,235.284,118.8,235.3Z"
          transform="translate(-86.021 -149.907)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M116.79,233.435c.376.146.9-.251,1.129-.564C117.626,232.662,117.229,233.331,116.79,233.435Z"
          transform="translate(-80.248 -152.428)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M116.99,235.277c.1.523.355-.314.4.188v-.355A1.675,1.675,0,0,1,116.99,235.277Z"
          transform="translate(-80.134 -149.942)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M116.99,233.815c0,.209.23,0,.314.1a.878.878,0,0,1,0-.46C117.136,233.523,117.136,233.753,116.99,233.815Z"
          transform="translate(-80.051 -151.742)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M116.78,233.943c0,.209.272.439.334.167A.314.314,0,0,0,116.78,233.943Z"
          transform="translate(-79.423 -151.221)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M108.14,207.674c.335.376,1.171.857,1.4.334-.23-.355-.418,0-.272-.669C109.1,207.737,108.621,207.7,108.14,207.674Z"
          transform="translate(-53.787 -180.226)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M106.436,207.525c-.146.857,2.091.732,1.714-.355-.125,0,0,.314,0,.5C107.586,207.337,107,208.006,106.436,207.525Z"
          transform="translate(-48.864 -180.411)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M116.7,235.344v.355c0,.1,0-.627.355-.355.209,0-.146-.146-.125-.272S116.887,235.365,116.7,235.344Z"
          transform="translate(-79.257 -150.009)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M116.3,234.172c.1.376.4,0,.564-.1a.46.46,0,0,1,0-.272c.272.209.167-.209.376-.272s0,.188.125.188.293,0,.23-.376c-.314.167-.272.125-.627,0v.272a.293.293,0,0,0-.314.1v-.46c0-.146.293,0,.188-.272l-.376.272c.293.125.146.314.1.648,0-.188.167,0,.314,0C116.784,234.255,116.492,234.151,116.3,234.172Z"
          transform="translate(-78.925 -152.265)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M116.462,235.51c.167-.1.188-.188.376,0s0-.23.125-.272S116.462,235.218,116.462,235.51Z"
          transform="translate(-78.624 -149.82)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M134.32,200.852c.376.585.585-.335.648-.669.125.1.146.376.376.334C135.01,199.41,134.717,200.748,134.32,200.852Z"
          transform="translate(-134.32 -188.142)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M116.05,238.945c.4.167.9-.251,1.129-.544C116.886,238.192,116.489,238.841,116.05,238.945Z"
          transform="translate(-77.961 -146.398)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M116.25,240.818c0,.523.334-.335.376.167v-.355Z"
          transform="translate(-77.826 -143.923)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M116.25,239.4c0,.188.23,0,.314,0a.857.857,0,0,1,0-.46C116.4,239.065,116.4,239.337,116.25,239.4Z"
          transform="translate(-77.764 -145.766)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M116.04,239.463c0,.23.272.439.335.188A.293.293,0,0,0,116.04,239.463Z"
          transform="translate(-77.136 -145.202)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M106.79,217.694c.355.376,1.171.857,1.422.335-.23-.335-.439,0-.272-.669C107.773,217.757,107.271,217.715,106.79,217.694Z"
          transform="translate(-49.635 -169.299)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M105.086,217.545c-.146.857,2.09.732,1.714-.355-.146,0,0,.314,0,.5C106.236,217.357,105.588,218.026,105.086,217.545Z"
          transform="translate(-44.692 -169.484)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M129.03,211.467c0,.355.564,0,.815.146C129.7,211.216,129.364,211.279,129.03,211.467Z"
          transform="translate(-117.762 -175.886)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M115.954,240.871v.355c0,.125,0-.627.376-.376.188,0-.167-.146-.146-.251S116.142,240.892,115.954,240.871Z"
          transform="translate(-76.966 -143.976)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M115.563,239.681c0,.376.4,0,.564,0a.585.585,0,0,1,0-.272c.272.209.167-.209.376-.293s0,.188.125.188.293,0,.23-.355c-.314.146-.272,0-.627,0v.272a.334.334,0,0,0-.314,0v-.439c0-.125.293,0,.188-.272l-.376.272c.293.1.146.293,0,.627,0-.167.167,0,.314,0C115.961,239.764,115.752,239.66,115.563,239.681Z"
          transform="translate(-76.638 -146.235)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M115.766,241.042c.167-.125.188-.209.4,0s0-.23,0-.272A.3.3,0,0,0,115.766,241.042Z"
          transform="translate(-76.443 -143.791)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M106.45,217.457c.376.585.585-.334.648-.669.125.1.146.376.376.334C107.14,215.994,106.847,217.352,106.45,217.457Z"
          transform="translate(-48.187 -170.044)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.692,239.9a.461.461,0,0,0,.5-.251C119.3,239.4,118.88,239.858,118.692,239.9Z"
          transform="translate(-85.516 -145.074)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M117.06,242.57c0,.188.23,0,.335,0a.69.69,0,0,1,0-.46C117.227,242.256,117.206,242.507,117.06,242.57Z"
          transform="translate(-80.288 -142.309)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M116.84,242.633c0,.23.293.439.335.188a.293.293,0,0,0-.335-.188Z"
          transform="translate(-79.608 -141.745)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M116.347,242.428c.188.188.648.46.773.167s-.23,0-.146-.355C116.891,242.449,116.619,242.428,116.347,242.428Z"
          transform="translate(-78.551 -142.167)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M117.244,243.018c0,.481,1.212.4.962-.188v.272C118.205,243.206,117.536,243.185,117.244,243.018Z"
          transform="translate(-81.517 -141.524)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M130.51,217.225c0,.355.585,0,.815.146C131.179,216.932,130.844,217.036,130.51,217.225Z"
          transform="translate(-122.336 -169.624)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.54,217.42c.125.355.335.585.732.5C127.188,217.525,126.812,217.545,126.54,217.42Z"
          transform="translate(-109.983 -169.234)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.592,240.257c0,.167.188,0,.251,0s0,0,0-.125,0,0,.167-.125h0s.125,0,.1-.167-.125,0-.293,0v.125a.126.126,0,0,0-.146,0v-.209c0-.063.146,0,0-.125l-.167.125c.125,0,0,.146,0,.293s0,0,.146,0S118.676,240.257,118.592,240.257Z"
          transform="translate(-85.04 -145.013)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.416,239.976c0,.146.146,0,.167-.167h0A.386.386,0,0,1,118.416,239.976Z"
          transform="translate(-84.319 -144.899)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M123.415,237.433c-.125,0-.4-.125-.314.188a.376.376,0,0,1,.293.627c-.188.23.167,0,.146.272C123.833,238.206,123.477,237.851,123.415,237.433Z"
          transform="translate(-99.144 -147.436)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.36,238.222c0-.272.167-.544,0-.627s0,.4-.146.627.125,0,.125.188c.4-.209.544.125,1.024,0,0-.167-.23-.23-.314,0,0-.355-.251-.251-.481-.523v.439Z"
          transform="translate(-96.939 -147.243)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M121.325,240.1c-.146-.439.167-.314.293-.439C121.43,239.348,120.844,240.059,121.325,240.1Z"
          transform="translate(-93.041 -145.066)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.642,237.14s-.544,0,0,.1a.168.168,0,0,1,0-.1Z"
          transform="translate(-90.517 -147.729)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.6,237.3a.669.669,0,0,0-.46-.1v.335c0,.125.272,0,.293-.167s0,0,0,.209a.146.146,0,0,1,.23,0A.184.184,0,0,1,120.6,237.3Z"
          transform="translate(-89.995 -147.677)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.325,237a1.923,1.923,0,0,0,1.233.544v.167c0,.063.125-.125,0-.272s.188,0,.272,0a.167.167,0,0,1,0,.167,5.684,5.684,0,0,1,2.091.146c-.125-.167.125-.1,0-.334,0,.1-.146,0,0,.209s.251,0,.314-.334-.188.272-.188,0a28,28,0,0,0-3.157.125c-.1,0,0-.1-.125-.146a.224.224,0,0,0-.188,0,.23.23,0,0,1-.125-.209S118.367,237.188,118.325,237Z"
          transform="translate(-87.783 -147.881)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.9,237.612c0,.125.146,0,.188.125.188.272.5,0,.773.125,0-.251.564-.334.648,0s0-.125,0-.125.125.167.209.188,0,0,0-.146.167-.209.272-.314.125,0,.209,0,0-.146.125-.125-.23-.251-.418-.23,0,0,0,.125a.418.418,0,0,1-.376.125.251.251,0,0,0,0,.209c0-.146-.355,0-.418-.209s-.125,0-.209,0h.167a.188.188,0,0,1,0,.125A9.5,9.5,0,0,1,120.9,237.612Z"
          transform="translate(-94.264 -147.762)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.194,236.61c0,.125-.209,0-.251.209s.272-.125.355,0S122.194,236.652,122.194,236.61Z"
          transform="translate(-95.415 -148.307)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M109.446,210.854c-.69-.272-1.045-.1-1.756-.334a.335.335,0,0,0,.355.334,1.087,1.087,0,0,0,0,.5c.627-.523,1.15-.23,1.756.146C109.551,211.356,108.693,210.813,109.446,210.854Z"
          transform="translate(-53.107 -176.758)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M107.069,211.179c-.209.418.46-.188.293-.669a.46.46,0,0,1-.439.167C106.86,210.928,107.382,211.074,107.069,211.179Z"
          transform="translate(-49.078 -176.769)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M116.05,238.854a6.391,6.391,0,0,0,.606.376v-.188c0-.021-.272,0-.209-.272C116.384,238.9,116.343,238.791,116.05,238.854Z"
          transform="translate(-77.438 -145.951)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.463,240.815c.125.188.4.272.314-.167C120.567,240.564,120.651,240.877,120.463,240.815Z"
          transform="translate(-90.798 -143.919)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M133.34,200.732c.69.272,1.589-.439,2.09-1C134.824,199.352,134.051,200.543,133.34,200.732Z"
          transform="translate(-132.357 -188.607)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M107.164,210.684c.167.962.648-.585.711.335.167,0,0-.418,0-.669A3.639,3.639,0,0,1,107.164,210.684Z"
          transform="translate(-50.156 -176.944)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M107.2,208.018c0,.376.418,0,.585.167a1.359,1.359,0,0,1,0-.815C107.472,207.475,107.451,207.914,107.2,208.018Z"
          transform="translate(-50.066 -180.193)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M106.83,208.235c.146.418.523.815.606.335A.544.544,0,0,0,106.83,208.235Z"
          transform="translate(-48.944 -179.26)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M105.92,207.854c.334.355,1.15.836,1.4.314-.23-.334-.439,0-.272-.648C106.882,207.9,106.4,207.875,105.92,207.854Z"
          transform="translate(-46.926 -180.03)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.533,235.848c0,.481,1.192.4.941-.188v.272C123.474,236.036,122.722,236.1,122.533,235.848Z"
          transform="translate(-97.844 -149.343)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M121.148,236.119c0,.209.314,0,.439,0S121.316,235.951,121.148,236.119Z"
          transform="translate(-93.052 -148.923)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M106.558,210.772a1.275,1.275,0,0,0,.167.648c.251,0,.125-1.129.648-.648.355-.167-.272-.272-.272-.5C106.851,210.458,106.809,210.814,106.558,210.772Z"
          transform="translate(-48.421 -177.031)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M106.023,208.664c.188.669.753,0,1.024-.188A1.192,1.192,0,0,1,106.9,208c.481.376.293-.376.669-.5s0,.335.251.335.523-.146.439-.669c-.585.272-.523.23-1.171,0v.481a.564.564,0,0,0-.564.167c0-.418.125-.564-.167-.815s.544,0,.334-.5l-.669.5c.523.209.251.543.167,1.15.209-.314.293,0,.585,0C106.922,208.811,106.378,208.622,106.023,208.664Z"
          transform="translate(-48.094 -181.153)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M106.248,211.165c.293-.209.335-.355.69-.167,0-.272,0-.418.209-.481S106.227,210.621,106.248,211.165Z"
          transform="translate(-47.463 -176.776)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M132.1,201.039c.4.585.606-.335.669-.669.125.1.146.376.376.334C132.811,199.576,132.518,200.914,132.1,201.039Z"
          transform="translate(-127.48 -187.952)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M117.222,238.958c-.376-.146-.564,0-.962-.188,0,.125,0,.188.209.188a.523.523,0,0,0,0,.272c.355-.293.648-.125.983,0C117.284,239.251,116.8,238.937,117.222,238.958Z"
          transform="translate(-78.673 -145.951)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M107.5,217.489c-.188.418.46-.188.293-.669a.46.46,0,0,1-.439.167C107.289,217.2,107.812,217.384,107.5,217.489Z"
          transform="translate(-50.406 -169.888)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M106.043,212.979c0,.146,0,.167.125.167a1.922,1.922,0,0,0,0,.334c.125-.753,1.38,0,1.213-1.171-.251,0-.209.251-.209.5C106.691,212.958,106.314,212.9,106.043,212.979Z"
          transform="translate(-47.257 -174.806)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M117.524,238.923c.125,0,0-.125.23-.1s-.188-.23-.314-.167,0,0,0-.209-.146,0-.272,0h0s-.125,0-.209-.23,0,.251,0,.272,0,.167-.188.167,0,0,0,.167S117.4,238.714,117.524,238.923Z"
          transform="translate(-79.936 -146.627)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.812,236.028h0v.125c0,.125.1-.125.188-.167S120.812,236.028,120.812,236.028Z"
          transform="translate(-91.754 -149)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.823,236.589a.171.171,0,0,0,.188.167h.272c.084,0,0,.188,0,.167s.564,0,.753-.251c0,.272.188,0,.272.167s0-.146,0-.167a2.647,2.647,0,0,1,.418,0c.125,0,0-.334,0-.167,0-.167-.167,0-.167,0a.418.418,0,0,0-.314,0s0,0,0-.167-.188.125-.335,0c0,.439-.4,0-.544.334C120.3,236.589,120.074,236.672,119.823,236.589Z"
          transform="translate(-90.452 -148.662)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.059,238.62a.209.209,0,0,0,.188,0v.209c0,.209.251,0,.355.167,0-.251.606-.167.627-.523h.125a.313.313,0,0,1,.251-.209c.125-.293.439-.355.523-.627h-.209c-.523-.1-1.38.481-1.8,0,0,0,.188.209.376.125-.146-.125-.188-.314-.4-.418s.188,0,.146-.125,0,0-.209,0c.146.272-.272.251-.376.209s0,0,0,.167.188,0,.272,0a3.494,3.494,0,0,1-.544.167c.209,0,.46.46.711.585s.272,0,.293.167,0-.146.146-.125,0,.209.209.167,0,0,0,.167-.4,0-.314.167h0s.146,0,0-.125-.146,0,0,.125S120.247,238.6,120.059,238.62Z"
          transform="translate(-89.893 -147.703)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.5,236.387h.146c.146,0,0-.125,0-.209S120.645,236.366,120.5,236.387Z"
          transform="translate(-90.793 -148.795)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.82,236.334a.941.941,0,0,0,.376,0S119.82,236.188,119.82,236.334Z"
          transform="translate(-88.86 -148.679)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.2,237.227c0-.376-.334,0-.481,0C119.908,237.311,120.1,237.332,120.2,237.227Z"
          transform="translate(-88.655 -147.816)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.252,238.187c0-.125-.146,0-.146-.272,0,.188-.272.1-.251.376-.209,0-.355-.272-.334-.711a.773.773,0,0,0-1.15.335c0,.69,0,1.171.418,1.254s.941-.627,1.3-.335a1.387,1.387,0,0,0,.69-.272c.188-.167,0-.125,0-.335s-.4,0-.4-.209a.167.167,0,0,0-.125.167Z"
          transform="translate(-83.399 -147.396)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M129.086,217.945a.481.481,0,0,0,.439.355C129.588,217.841,129.295,217.945,129.086,217.945Z"
          transform="translate(-117.567 -168.672)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.21,218s.146,0,0,.146a.732.732,0,0,0,.794,0Z"
          transform="translate(-105.935 -168.601)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.37,217.52c0,.188.314.251.418.418C125.725,217.541,125.6,217.541,125.37,217.52Z"
          transform="translate(-106.054 -169.125)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.321,217.979h-.146c-.146,0,.293.146.355.334v-.125a3.09,3.09,0,0,1,0-.439.92.92,0,0,1-.46-.209s0,.188,0,.355S125.2,217.812,125.321,217.979Z"
          transform="translate(-105.168 -169.103)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M124.73,218.062c0,.251.251,0,.355.125s0,.4.209,0C125,217.936,124.9,218.145,124.73,218.062Z"
          transform="translate(-104.222 -168.537)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M124.03,218.059a1.084,1.084,0,0,1,.564.146.627.627,0,0,0-.564-.146Z"
          transform="translate(-102.059 -168.555)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M123.715,217.873c0-.125.125,0,.251,0S123.653,217.6,123.715,217.873Z"
          transform="translate(-100.782 -168.871)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M123.488,217.3h.293C123.823,217.088,123.509,217.234,123.488,217.3Z"
          transform="translate(-100.116 -169.487)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M127.215,217.12v.167c0,.125.209,0,.335,0S127.383,217.12,127.215,217.12Z"
          transform="translate(-111.704 -169.561)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.514,218.074h.167S126.535,217.97,126.514,218.074Z"
          transform="translate(-109.414 -168.571)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.445,218.257h.272c.146,0-.125-.167-.251-.167S126.466,218.257,126.445,218.257Z"
          transform="translate(-109.199 -168.503)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.965,215.255c.293.23.418.209.773.46,0,0,0-.209.167-.23s-.376,0-.272-.355C119.383,215.3,119.341,215.172,118.965,215.255Z"
          transform="translate(-86.813 -171.731)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.412,216.205c0,.293.251,0,.4.146s-.167.146-.272,0c0,.418.4-.188.4.272h.314c.146,0-.167,0-.251-.125s-.125-.167,0-.418A.753.753,0,0,1,125.412,216.205Z"
          transform="translate(-106.639 -170.695)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M123.959,217.334c.125.146,0,.146,0,.334s0,0,.167,0S124.1,217.271,123.959,217.334Z"
          transform="translate(-101.507 -169.335)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M130.231,218.636c.167.251.523.355.418-.23C130.377,218.322,130.5,218.72,130.231,218.636Z"
          transform="translate(-121.095 -168.171)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M128.48,219.905c0,.418.753.272.836,0s.209.439.272.711a3.2,3.2,0,0,0,.355-1.066c-.167-.188-.941,0-.732.251C128.856,219.884,128.71,219.759,128.48,219.905Z"
          transform="translate(-116.71 -166.994)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.989,216.56c0,.125.272,0,.293,0Z"
          transform="translate(-98.572 -170.172)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.47,215.893c.125,0,.209.188.355.23C122.784,215.83,122.6,215.851,122.47,215.893Z"
          transform="translate(-97.028 -170.926)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.779,217.9c0,.334.167.669.46.69a2.091,2.091,0,0,0-.125-.815.439.439,0,0,1-.334.125Z"
          transform="translate(-88.818 -168.852)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M106.41,220.061c.1.669.439-.439.5.23v-.481Z"
          transform="translate(-47.541 -166.627)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.15,211.85a.392.392,0,0,0,.523.355C126.61,211.913,126.338,211.934,126.15,211.85Z"
          transform="translate(-108.569 -175.308)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M123.48,212.185c.146.418.711,0,1.087,0,0-.314-.46.1-.355-.355C124.065,212.185,123.919,212.06,123.48,212.185Z"
          transform="translate(-100.882 -175.33)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M106.033,220.105v.481c0,.167,0-.815.481-.481.251,0-.188-.188-.188-.355C106.3,219.9,106.284,220.147,106.033,220.105Z"
          transform="translate(-46.432 -166.693)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M105.742,220.4c.209-.167.23-.272.481-.125,0-.209,0-.314.146-.355S105.721,220.09,105.742,220.4Z"
          transform="translate(-45.619 -166.51)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M127.56,214.73a.4.4,0,0,0,.334.23v-.46h-.251C127.832,214.688,127.811,214.751,127.56,214.73Z"
          transform="translate(-112.738 -172.418)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.27,214.523c0,.439.4.146.585.23C122.834,214.419,122.5,214.544,122.27,214.523Z"
          transform="translate(-96.64 -172.399)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.89,219.2c.5.188,1.15-.314,1.442-.711C126.956,218.216,126.454,219.053,125.89,219.2Z"
          transform="translate(-108.685 -168.128)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.14,221.6c0,.669.46-.418.5.23v-.46a4.607,4.607,0,0,1-.5.23Z"
          transform="translate(-108.517 -164.926)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.14,219.721c0,.251.293,0,.418.1a1.128,1.128,0,0,1,0-.585C126.328,219.324,126.328,219.637,126.14,219.721Z"
          transform="translate(-108.433 -167.249)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.88,219.865c0,.293.355.564.418.23a.355.355,0,0,0-.418-.23Z"
          transform="translate(-107.63 -166.578)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.23,219.58c.251.272.836.606,1,.23-.167-.23-.314,0-.188-.46C125.92,219.622,125.585,219.6,125.23,219.58Z"
          transform="translate(-106.206 -167.129)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M124,219.46c0,.627,1.547.523,1.233-.23v.334C124.815,219.335,124.376,219.815,124,219.46Z"
          transform="translate(-102.677 -167.26)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.17,219.839c0,.251.418,0,.585.1C122.651,219.651,122.4,219.693,122.17,219.839Z"
          transform="translate(-96.331 -166.719)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.35,220a.375.375,0,0,0,.5.355C119.789,220,119.538,220,119.35,220Z"
          transform="translate(-87.532 -166.42)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M116.66,220.315c.146.439.711,0,1.087,0,0-.314-.46.1-.355-.355C117.2,220.4,117.036,220.19,116.66,220.315Z"
          transform="translate(-79.804 -166.464)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.683,221.679c0,.251,0,.314.125.46s0-.815.46-.46c.251-.125-.188-.209-.188-.355S125.871,221.7,125.683,221.679Z"
          transform="translate(-107.266 -165.005)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.255,220.157c.146.481.523,0,.732-.125a.71.71,0,0,1,0-.335c.355.272.209-.272.481-.355s0,.23.167.23.376,0,.314-.481c-.418.209-.355.167-.836,0v.334a.4.4,0,0,0-.4.125.847.847,0,0,0-.125-.585c-.125-.188.4,0,.23-.355l-.46.355c.355.167.167.4.125.815.146-.209.209,0,.418,0C125.882,220.282,125.4,220.136,125.255,220.157Z"
          transform="translate(-106.983 -167.936)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.47,221.951c.209-.146.251-.251.5-.125a.334.334,0,0,1,.146-.355S125.47,221.554,125.47,221.951Z"
          transform="translate(-106.593 -164.817)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M124.98,219.4c.272.418.418-.23.46-.46s0,.272.272.23C125.461,218.379,125.252,219.34,124.98,219.4Z"
          transform="translate(-105.162 -167.663)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M127.2,213.681c.251.125.585-.146.732-.355C127.743,213.179,127.493,213.618,127.2,213.681Z"
          transform="translate(-112.023 -173.731)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M127.33,214.935c0,.355.23-.209.251.125v-.251a.815.815,0,0,1-.251.125Z"
          transform="translate(-111.944 -172.08)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M127.33,213.994c0,.125.146,0,.209,0a.8.8,0,0,1,0-.314S127.435,213.952,127.33,213.994Z"
          transform="translate(-111.902 -173.312)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M127.19,214c0,.146.188.293.23.125A.23.23,0,0,0,127.19,214Z"
          transform="translate(-111.49 -172.964)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.851,213.95c.125.125.418.314.5,0s-.146,0,0-.23C127.206,213.971,127.06,213.95,126.851,213.95Z"
          transform="translate(-110.733 -173.269)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.219,213.835c0,.314.815.272.648-.125v.167C126.867,214.044,126.428,214,126.219,213.835Z"
          transform="translate(-108.931 -173.279)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M127.108,214.976a.731.731,0,0,0,0,.251s0-.418.251-.251,0-.1,0-.188S127.233,215,127.108,214.976Z"
          transform="translate(-111.345 -172.12)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.927,214.184c0,.251.272,0,.376,0a.4.4,0,0,1,0-.167c.167.125,0-.146.23-.188s0,.125,0,.125.188,0,.146-.251-.188,0-.418,0v.188a.209.209,0,0,0-.209,0v-.314c0-.1.209,0,.125-.188l-.251.188c.209,0,.1.209,0,.439,0-.125.125,0,.23,0S127.053,214.184,126.927,214.184Z"
          transform="translate(-111.206 -173.628)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.993,215.1a.158.158,0,0,1,.251,0c.125.146,0-.167,0-.188A.188.188,0,0,0,126.993,215.1Z"
          transform="translate(-110.959 -171.977)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.73,213.8c.146.23.209-.1.251-.23s0,.125.125.1C126.981,213.274,126.876,213.755,126.73,213.8Z"
          transform="translate(-110.215 -173.492)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M104.6,219.157c0,.272.418,0,.585.125C105.081,218.969,104.851,218.969,104.6,219.157Z"
          transform="translate(-42.031 -167.479)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M128.3,212.72a.392.392,0,0,0,.523.355C128.76,212.783,128.488,212.824,128.3,212.72Z"
          transform="translate(-115.213 -174.359)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.62,213.055c.146.439.711,0,1.066,0,0-.314-.439.125-.335-.355C126.163,213.139,125.892,212.93,125.62,213.055Z"
          transform="translate(-107.474 -174.381)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M117.21,216.39c0,.167.146.376.334.355s0-.46,0-.815C117.085,215.91,117.5,216.307,117.21,216.39Z"
          transform="translate(-80.835 -170.859)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.02,223.29c.125.355.564.314.753.23C118.605,223.332,118.25,223.395,118.02,223.29Z"
          transform="translate(-83.673 -162.832)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M117.27,223.311c.1.376.732,0,1.087.209C118.19,223.186,117.416,223.27,117.27,223.311Z"
          transform="translate(-81.689 -162.854)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M116.42,222.945c0,.418.523.314.857.355,0-.314-.585-.188-.272-.481C116.88,222.527,116.65,223.008,116.42,222.945Z"
          transform="translate(-78.833 -163.449)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M116.581,223.29c.146-.23,0-.23-.125-.46-.125,0-.439,0-.376.355a.313.313,0,0,1,.23-.125S116.413,223.332,116.581,223.29Z"
          transform="translate(-77.467 -163.334)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M115.74,223.272c.251.335.5-.4.836,0C116.43,222.707,115.865,223.042,115.74,223.272Z"
          transform="translate(-76.71 -163.19)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M123.66,213.615c0,.481.418.167.418,0S123.869,213.782,123.66,213.615Z"
          transform="translate(-100.769 -173.436)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.547,215.64c0,.376.481.523.418,0Z"
          transform="translate(-88.064 -171.175)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.709,215.7a.752.752,0,0,0,.606.23C119.357,215.474,118.918,215.788,118.709,215.7Z"
          transform="translate(-85.658 -171.135)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.326,216.364c-.376.23.585.481.4,0C118.515,216.218,118.41,216.72,118.326,216.364Z"
          transform="translate(-84.105 -170.415)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.91,215.087c0,.293.585-.167.9,0C123.621,214.711,123.161,215.087,122.91,215.087Z"
          transform="translate(-98.932 -171.96)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.31,212.718c.5.209,1.15-.314,1.442-.711C121.355,211.736,120.854,212.593,120.31,212.718Z"
          transform="translate(-91.44 -175.194)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.57,215.131c0,.669.439-.439.5.23v-.481Z"
          transform="translate(-91.303 -172.004)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.57,213.24c0,.251.293,0,.418.125a.941.941,0,0,1,0-.585C120.758,212.822,120.758,213.24,120.57,213.24Z"
          transform="translate(-91.219 -174.294)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.31,213.383c.1.293.355.585.418.23A.376.376,0,0,0,120.31,213.383Z"
          transform="translate(-90.416 -173.643)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.66,213.131c.251.251.836.585,1,.23-.167-.251-.314,0-.188-.481C120.35,213.131,119.953,213.131,119.66,213.131Z"
          transform="translate(-88.992 -174.185)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.4,213c0,.627,1.547.523,1.213-.251v.355C119.129,212.875,118.669,213.273,118.4,213Z"
          transform="translate(-85.346 -174.326)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.143,215.2a.6.6,0,0,0,.125.481c.125.167,0-.815.46-.481.251-.1-.188-.188-.188-.355S120.394,215.2,120.143,215.2Z"
          transform="translate(-90.144 -172.075)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.685,213.676c.125.481.523,0,.732-.125a.794.794,0,0,1,0-.355c.355.272.209-.251.481-.355s0,.23.167.23.376-.1.314-.46c-.418.188-.376.146-.836,0v.355a.376.376,0,0,0-.4.125c0-.314,0-.418-.125-.585s.376,0,.23-.355l-.481.355c.376.146.188.4.146.815.146-.23.209,0,.4,0C120.312,213.781,119.935,213.655,119.685,213.676Z"
          transform="translate(-89.769 -174.981)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.892,215.487c.209-.167.23-.272.5-.125,0-.209,0-.314.146-.355S119.871,215.006,119.892,215.487Z"
          transform="translate(-89.37 -171.879)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.42,212.944c.272.418.418-.23.46-.481,0,0,.1.272.251.23C119.9,211.9,119.692,212.86,119.42,212.944Z"
          transform="translate(-87.958 -174.729)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.36,219.856c.481.188,1.129-.314,1.442-.711C120.426,218.894,119.924,219.709,119.36,219.856Z"
          transform="translate(-88.504 -167.405)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.61,222.26c.125.669.46-.418.523.23v-.46a4.606,4.606,0,0,1-.523.23Z"
          transform="translate(-88.357 -164.206)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.61,220.381c0,.251.293,0,.418.1a.941.941,0,0,1,0-.585C119.8,219.984,119.777,220.3,119.61,220.381Z"
          transform="translate(-88.252 -166.529)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.35,220.526c0,.293.376.564.439.23a.376.376,0,0,0-.439-.23Z"
          transform="translate(-87.47 -165.859)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.7,220.24c.251.272.815.606,1,.23-.167-.23-.314,0-.188-.46C119.39,220.282,119.076,220.261,118.7,220.24Z"
          transform="translate(-86.025 -166.409)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M117.477,220.12c0,.627,1.547.523,1.212-.23v.335C118.293,219.994,117.833,220.475,117.477,220.12Z"
          transform="translate(-82.503 -166.54)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.233,222.342v.46c0,.146,0-.815.481-.46.251-.125-.188-.188-.188-.355S119.484,222.363,119.233,222.342Z"
          transform="translate(-87.227 -164.289)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.727,220.807c.125.481.523,0,.732-.125a.522.522,0,0,1,0-.334c.334.272.209-.272.481-.355s0,.23.167.23.355,0,.314-.481c-.418.209-.376.167-.836,0v.334a.4.4,0,0,0-.4.125.847.847,0,0,0-.125-.585c-.125-.188.376,0,.23-.355l-.481.355c.376.167.188.4.125.815.146-.209.209,0,.418,0C119.354,220.953,118.978,220.807,118.727,220.807Z"
          transform="translate(-86.805 -167.227)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.942,222.618c.209-.146.23-.251.481-.125,0-.188,0-.293.146-.355S118.921,222.221,118.942,222.618Z"
          transform="translate(-86.413 -164.105)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.46,220.063c.272.418.418-.23.481-.46s0,.272.251.23C118.962,219.039,118.753,220,118.46,220.063Z"
          transform="translate(-85.012 -166.943)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.772,221.068a.9.9,0,0,0,.648-.334C123.545,220.545,122.96,221.005,122.772,221.068Z"
          transform="translate(-98.271 -165.669)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.67,224.46c0,.251.293,0,.418.125a1.129,1.129,0,0,1,0-.585C120.879,224,120.858,224.376,120.67,224.46Z"
          transform="translate(-91.528 -162.058)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.4,224.614c0,.293.376.585.439.23a.4.4,0,0,0-.439-.23Z"
          transform="translate(-90.715 -161.397)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.75,224.351c.23.251.815.585,1,.23-.167-.251-.314,0-.209-.481C120.44,224.372,120.084,224.351,119.75,224.351Z"
          transform="translate(-89.27 -161.949)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.907,225.131c0,.627,1.547.523,1.212-.251v.355C121.723,224.964,121.263,225.424,120.907,225.131Z"
          transform="translate(-93.104 -161.098)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M116.72,224.585c0,.272.418,0,.585.1C117.243,224.4,116.992,224.459,116.72,224.585Z"
          transform="translate(-79.488 -161.535)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.634,221.469c0,.23.251,0,.334,0a.5.5,0,0,1,0-.167c.146.125,0-.125.209-.167s0,.1,0,.1.167,0,.146-.209-.167,0-.376,0v.188a.188.188,0,0,0-.188,0c0-.125,0-.188,0-.272s.167,0,0-.146l-.23.146c.167,0,0,.188,0,.376s0,0,.188,0S122.759,221.469,122.634,221.469Z"
          transform="translate(-97.652 -165.548)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.36,221.138c.125.188.188-.1.209-.23s0,.125.125.1C122.59,220.7,122.485,221.138,122.36,221.138Z"
          transform="translate(-96.668 -165.468)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M128.887,217.895c-.167,0-.523-.146-.4.23.46-.125.585.544.376.815s.188.125.188.355C129.326,218.9,128.95,218.438,128.887,217.895Z"
          transform="translate(-115.884 -168.747)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M127.458,218.922c0-.355.23-.711,0-.815s0,.5-.188.815c.125,0,.146,0,.167.23.5-.272.711.167,1.317,0,0-.209-.293-.293-.418,0,.167-.481-.314-.355-.606-.711v.585Z"
          transform="translate(-112.992 -168.498)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M126.171,221.355c-.167-.585.23-.418.376-.585C126.317,220.372,125.565,221.355,126.171,221.355Z"
          transform="translate(-108.004 -165.684)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.388,217.51c-.125,0-.711,0-.146.146A.334.334,0,0,1,125.388,217.51Z"
          transform="translate(-104.88 -169.135)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.239,217.73a.9.9,0,0,0-.606-.146c.125.167,0,.272,0,.439a.314.314,0,0,0,.4-.23v.293a.167.167,0,0,1,.293,0C125.406,217.98,125.114,217.96,125.239,217.73Z"
          transform="translate(-104.062 -169.062)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M122.314,217.38a2.383,2.383,0,0,0,1.589.69s0,.125,0,.209.146-.146,0-.355.23.1.335,0,0,0,0,.209a7.023,7.023,0,0,1,2.655.188c-.167-.209.167-.125,0-.418a.188.188,0,0,0,.146,0c0,.146-.188,0-.125.293.23-.125.334,0,.418-.439s-.251.334-.251-.125c-1.526,0-2.362.1-4.181.167-.125,0,0-.146-.167-.209s-.23,0-.23.125a.272.272,0,0,1-.167-.272C122.627,217.4,122.376,217.526,122.314,217.38Z"
          transform="translate(-101.22 -169.277)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.55,218.183c0,.167.188,0,.23.167h0c.251.355.648,0,1,.146-.125-.293.732-.418.815-.1s0-.146.146-.167.167.23.293.272a.313.313,0,0,1,0-.209c.167,0,.23-.272.355-.376s.188,0,.272,0,0-.188.188-.167c-.23-.125-.314-.314-.544-.314s0,0,0,.167a.5.5,0,0,1-.481.167s0,.188,0,.272-.439,0-.544-.272a.544.544,0,0,1-.251.1c0,.209,0,0,.23,0a1.228,1.228,0,0,1,0,.167A15.615,15.615,0,0,1,125.55,218.183Z"
          transform="translate(-109.495 -169.223)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M127.277,216.84c0,.146-.293,0-.334.272s.355-.167.439-.1S127.277,216.882,127.277,216.84Z"
          transform="translate(-110.95 -169.866)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.594,215.37c-.5-.188-.753,0-1.254-.23a.237.237,0,0,0,.251.23,1.357,1.357,0,0,0,0,.355c.46-.376.836-.167,1.254.1C120.657,215.725,120.051,215.349,120.594,215.37Z"
          transform="translate(-88.505 -171.72)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.911,215.59c-.146.314.335-.125.209-.46a.376.376,0,0,1-.314.125C118.7,215.423,119.141,215.527,118.911,215.59Z"
          transform="translate(-85.651 -171.731)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.278,219.745a7.571,7.571,0,0,0,.774.46c0-.1,0-.209.146-.23s-.376,0-.272-.355C119.7,219.787,119.633,219.662,119.278,219.745Z"
          transform="translate(-87.753 -166.835)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.061,222.284c.146.23.5.334.4-.251C125.186,221.971,125.312,222.368,125.061,222.284Z"
          transform="translate(-105.097 -164.21)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.74,212.842c.5.209,1.15-.314,1.442-.711C119.806,211.9,119.3,212.7,118.74,212.842Z"
          transform="translate(-86.588 -175.046)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.99,215.25c0,.669.439-.418.5.23v-.46A3.184,3.184,0,0,1,118.99,215.25Z"
          transform="translate(-86.42 -171.851)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.99,213.425c0,.251.293,0,.418,0a.941.941,0,0,1,0-.585C119.178,213.028,119.178,213.342,118.99,213.425Z"
          transform="translate(-86.336 -174.228)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.73,213.513c.1.293.355.564.418.23a.376.376,0,0,0-.418-.23Z"
          transform="translate(-85.533 -173.501)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.08,213.23c.251.272.836.606,1,.23-.167-.23-.314,0-.188-.46C118.77,213.272,118.414,213.251,118.08,213.23Z"
          transform="translate(-84.109 -174.054)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M127.793,215.851c-.1.627,1.547.523,1.212-.251v.355C128.609,215.725,128.149,216.185,127.793,215.851Z"
          transform="translate(-114.372 -171.218)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.95,216.2c0,.272.4,0,.585.125C126.431,216.033,126.18,216.074,125.95,216.2Z"
          transform="translate(-108.013 -170.669)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.563,215.332c0,.251,0,.314.125.46s0-.815.46-.46c.251-.125-.188-.188-.188-.355S118.814,215.353,118.563,215.332Z"
          transform="translate(-85.261 -171.933)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.175,213.8c.125.5.523,0,.732-.1a.878.878,0,0,1-.1-.355c.355.272.209-.272.481-.355s0,.23.167.23.376-.1.314-.46c-.418.188-.376.146-.836,0v.355a.4.4,0,0,0-.4.1.847.847,0,0,0-.125-.585c-.125-.188.376,0,.23-.355l-.481.355c.376.167.188.4.146.836.146-.23.209,0,.4,0C118.8,213.921,118.426,213.8,118.175,213.8Z"
          transform="translate(-84.957 -174.85)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.312,215.594c.209-.146.23-.251.5-.125,0-.188,0-.293.146-.334S118.291,215.2,118.312,215.594Z"
          transform="translate(-84.487 -171.735)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M117.84,213.053c.293.418.439-.23.481-.46s0,.272.251.23C118.237,212.029,118.133,212.99,117.84,213.053Z"
          transform="translate(-83.096 -174.588)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M120.9,219.86a11.131,11.131,0,0,0-1.254-.23.272.272,0,0,0,.272.23.607.607,0,0,0,0,.355c.439-.376.815-.146,1.233.1C120.988,220.215,120.423,219.839,120.9,219.86Z"
          transform="translate(-89.463 -166.824)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M119.2,220.1c-.125.293.335-.125.23-.481a.376.376,0,0,1-.334.125C119.073,219.913,119.428,220.017,119.2,220.1Z"
          transform="translate(-86.586 -166.835)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M118.215,216.891v.355c0-.544.983,0,.857-.836-.188,0-.146.167-.146.355A5.116,5.116,0,0,1,118.215,216.891Z"
          transform="translate(-84.39 -170.335)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M121.064,219.908c.146,0,.146-.167.293-.146s-.23-.314-.4-.209,0-.146,0-.293-.188.1-.335,0,0-.146.125-.125-.146,0-.251-.293,0,.314,0,.355-.1.209-.23.209,0,0,0,.209C120.793,219.7,120.918,219.615,121.064,219.908Z"
          transform="translate(-90.877 -167.77)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.514,216.042h0v.146c0,.146.167-.146.251-.209S125.514,216.042,125.514,216.042Z"
          transform="translate(-106.344 -170.824)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M124.239,216.744c0,.1,0,.23.251.209-.167-.272.23,0,.334,0s0,.251,0,.23.753,0,.983-.335c0,.335.251-.125.355.209v-.209c0-.021.376-.125.523,0s0-.439,0-.209c0-.23-.209-.125-.23,0a.46.46,0,0,0-.4.1s0,0,0-.209-.209.146-.418,0c.125.564-.523,0-.69.439C124.845,216.744,124.552,216.849,124.239,216.744Z"
          transform="translate(-104.651 -170.272)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M124.608,219.416a.462.462,0,0,0,.23.1c.1,0,0,.125,0,.272s.334.125.481.209c-.125-.334.753-.209.794-.648a.167.167,0,0,0,.146,0,.418.418,0,0,1,.334-.272c.167-.376.585-.481.669-.815,0,.1-.335,0-.272,0-.669-.146-1.756.606-2.32-.1.125,0,.251.272.5.167-.209-.146-.251-.4-.523-.523s.251,0,.188-.167-.125,0-.272,0c.188.355-.355.314-.481.272s0,0,0,.209.251-.146.355,0-.439.146-.711.209c.272,0,.585.585.92.753s.355,0,.4.209,0-.188.167-.146,0,.272.272.209,0,.1,0,.209-.5,0-.418.23,0,0,0-.125.167,0,0-.146-.188,0-.146.146S124.942,219.395,124.608,219.416Z"
          transform="translate(-104.1 -169.076)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M125.1,216.545c0,.125,0,0,.188,0s0-.146,0-.272S125.267,216.524,125.1,216.545Z"
          transform="translate(-105.073 -170.512)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M124.23,216.514a1.192,1.192,0,0,0,.5-.125S124.251,216.3,124.23,216.514Z"
          transform="translate(-102.614 -170.376)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M124.738,217.614c0-.46-.46,0-.648.125C124.341,217.74,124.592,217.761,124.738,217.614Z"
          transform="translate(-102.328 -169.24)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M123.52,218.863c0-.167-.188,0-.188-.355,0,.251-.355.146-.314.5-.272-.125-.46-.355-.46-.92a.983.983,0,0,0-1.463.439c-.1.878.146,1.484.544,1.61s1.192-.815,1.672-.439c.167-.1.648-.125.878-.355s0-.146,0-.418-.523,0-.523-.272A.293.293,0,0,0,123.52,218.863Z"
          transform="translate(-95.57 -168.69)"
          fill="#65b1db"
          fillRule="evenodd"
        />
      </g>
      <g transform="translate(0.441)">
        <path
          d="M89.648,199.67a.538.538,0,0,0-.564.5C89.857,200.234,89.669,199.9,89.648,199.67Z"
          transform="translate(25.101 -152.696)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.177,203.82s-.167.167-.23,0c-.334.251-.146.627,0,.878q.136-.433.23-.878Z"
          transform="translate(26.282 -148.17)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.711,203.83c-.314.125-.418.335-.711.481C88.711,204.185,88.711,204.185,88.711,203.83Z"
          transform="translate(28.337 -148.16)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.8,204.436c-.146,0-.125-.1-.146-.167-.355,0-.251.335-.585.439h1a.982.982,0,0,1,.314-.523,1.044,1.044,0,0,0-.627,0C88.363,204.227,89.094,204.31,88.8,204.436Z"
          transform="translate(27.514 -147.825)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.414,204.45c-.46.1-.125.251-.209.4s-.711,0,0,.23C89.5,204.826,89.268,204.7,89.414,204.45Z"
          transform="translate(25.815 -147.484)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.968,205.27c-.167,0-.146.439-.188.627C89.01,205.751,89.01,205.521,88.968,205.27Z"
          transform="translate(26.428 -146.589)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.14,205.786c.23,0,0,.146,0,.272C88.663,206.079,88.621,205.7,88.14,205.786Z"
          transform="translate(28.239 -146.039)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.291,206c-.209.125,0,.146,0,.355C87.667,206.334,87.4,206,87.291,206Z"
          transform="translate(31.241 -145.793)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.855,201.77h-.293c-.209.125,0,.251,0,.376S87.876,201.937,87.855,201.77Z"
          transform="translate(30.301 -150.406)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.16,202.67c-.146,0-.188,0-.167.167s.167,0,.188,0S89.16,202.691,89.16,202.67Z"
          transform="translate(25.798 -149.425)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.156,202.748c-.146,0,0,.167,0,.314.355,0,.293-.146.272-.272S89.177,202.79,89.156,202.748Z"
          transform="translate(25.32 -149.356)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M83.872,210.6c-.376.314-.335.46-.732.878.167,0,.355,0,.418.167.5,0-.167-.418.585-.314C83.851,211.06,84.06,211,83.872,210.6Z"
          transform="translate(43.064 -140.777)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.378,203.47c-.523,0,0,.293-.23.439s-.293-.167-.23-.272c-.711,0,.4.418-.418.46a.4.4,0,0,1,.125.314c.334,0,0-.167.167-.251s.293-.167.753,0A.585.585,0,0,1,86.378,203.47Z"
          transform="translate(35.729 -148.552)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.065,205.5c-.23.125-.23,0-.564,0v.167C87.5,205.834,88.211,205.688,88.065,205.5Z"
          transform="translate(30.007 -146.338)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.295,198.42c-.4.188-.544.585.46.439C90.88,198.566,90.169,198.713,90.295,198.42Z"
          transform="translate(22.112 -154.059)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.749,232.311c-.627-.293-.878.585-.69.773-.23.146-.815,0-1.275-.188a5.852,5.852,0,0,0,1.442,1.045c.4,0,.69-.9,0-.878C90.5,232.813,90.832,232.645,90.749,232.311Z"
          transform="translate(24.647 -117.165)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.058,206.51c-.23,0,0,.293.146.335S86.058,206.615,86.058,206.51Z"
          transform="translate(35.066 -145.237)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M135.023,237.72c0,.167-.1.314,0,.481C135.378,237.992,135.169,237.824,135.023,237.72Z"
          transform="translate(-116.376 -111.202)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M141.157,219.31h-.627c.146.146-.188.5.272.46S141.074,219.477,141.157,219.31Z"
          transform="translate(-133.924 -131.278)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.051,210.1c-.606.1-1.171.23-1.171.544a4.746,4.746,0,0,0,1.422-.188.355.355,0,0,1-.251-.355Z"
          transform="translate(27.997 -141.322)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.073,190.19c-.669,0-.878.564,0,.46Z"
          transform="translate(20.77 -163.034)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.835,191a.564.564,0,0,0-.355.669C91.274,191.773,90.71,191.293,90.835,191Z"
          transform="translate(20.986 -162.151)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M92.045,191.74c-.439-.418-.753.669,0,.46C92.338,191.949,91.46,191.845,92.045,191.74Z"
          transform="translate(17.435 -161.449)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M92.014,192c-.523,0-.648.836,0,.732C91.784,192.46,92.16,192.4,92.014,192Z"
          transform="translate(17.529 -161.06)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.94,194c-.23,0-.125.188-.4.209.606.125.481.209.669.418C92.755,194.481,92.17,194.1,91.94,194Z"
          transform="translate(17.206 -158.879)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.935,192.66a.585.585,0,0,0-.334.857C91.935,193.371,91.768,192.932,91.935,192.66Z"
          transform="translate(17.754 -160.341)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M78.456,177.2a.46.46,0,0,0-.376.4h.836v-.272C78.54,177.514,78.414,177.472,78.456,177.2Z"
          transform="translate(58.869 -177.2)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M136.617,231.92a.355.355,0,0,0-.1.5c.293-.167.439,0,.753-.272l-.188-.23C136.867,232.087,136.742,232.087,136.617,231.92Z"
          transform="translate(-121.502 -117.527)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M133.081,236.91c-.711.251,0,.481,0,.732C133.646,237.391,133.207,237.1,133.081,236.91Z"
          transform="translate(-109.898 -112.085)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.683,202.75c-.314.564.669,1.275,1.4,1.589C91.522,203.9,89.975,203.4,89.683,202.75Z"
          transform="translate(22.494 -149.337)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M94.03,233.934c-1.129-.334.376.732-.69.355,0,.146.439.251.711.355a2.824,2.824,0,0,1-.021-.711Z"
          transform="translate(11.834 -115.402)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.272,203.05c-.439,0,0,.334-.167.481a2.426,2.426,0,0,1,1.024,0C91.983,203.259,91.418,203.259,91.272,203.05Z"
          transform="translate(18.501 -149.01)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.815,235.291c-.523-.1-1.129,0-.627.272S91.815,235.5,91.815,235.291Z"
          transform="translate(18.961 -113.895)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.791,203.76c-.439.293-.983.962-.293,1.129.4-.188,0-.335.794-.23C91.791,204.533,91.791,204.136,91.791,203.76Z"
          transform="translate(18.128 -148.236)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.5,205c-1.108,0-.773,1.756.544,1.359h-.627C91.811,205.878,90.933,205.4,91.5,205Z"
          transform="translate(19.091 -146.884)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.74,207.42c-.46,0,0,.46-.146.669C92.1,207.943,91.991,207.671,91.74,207.42Z"
          transform="translate(17.719 -144.245)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M92.305,210.63c-.418.1-.69.272-.564.585C92.242,211.132,92.18,210.839,92.305,210.63Z"
          transform="translate(16.987 -140.744)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.684,213.35c-.732.167.188.773.125,1.192.544,0-.251-.5.564-.418C91.621,213.935,91.956,213.768,91.684,213.35Z"
          transform="translate(17.566 -137.778)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M94.214,234.369a2.985,2.985,0,0,0-.794-.209c0,.23,1.213.648.439.774,0,.334.418,0,.669,0C94.4,234.787,94.026,234.578,94.214,234.369Z"
          transform="translate(11.189 -115.084)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.272,203.394c-.857.167,0,.585.272.815a1.547,1.547,0,0,1,.606-.125c-.439.4.481.209.669.5s-.4.1-.376.209.209.418.857.334c-.4-.46-.314-.418,0-.941h-.606a.4.4,0,0,0-.251-.439,1.67,1.67,0,0,0,1-.167c.272-.167,0,.439.648.251-.209-.188-.46-.355-.669-.523-.23.418-.669.209-1.422.188.4.146,0,.23,0,.46C90.125,204.1,90.313,203.686,90.272,203.394Z"
          transform="translate(19.982 -148.727)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M94,234.36c0,.314.251.4-.125.564.334.125.46.251.46.376A.669.669,0,0,0,94,234.36Z"
          transform="translate(10.362 -114.866)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.513,204.16c-.711.334.46.46.878.5-.125,0-.46.125-.376.314C92.374,204.683,90.681,204.474,90.513,204.16Z"
          transform="translate(20.723 -147.8)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M136.955,232.36c-.5.272.5,0,0,.335a.7.7,0,0,0,.4-.125Z"
          transform="translate(-122.342 -117.047)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M135.736,232c-.188.146-.334.376,0,.293a.188.188,0,0,0,0-.293Z"
          transform="translate(-118.134 -117.44)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M136.978,232.551c-.209,0-.209.146-.335.209s.69-.23.564,0,0-.167.209-.23C137.249,232.614,137.04,232.676,136.978,232.551Z"
          transform="translate(-122.031 -116.862)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M136.63,232.751c.188,0,.293,0,.272.209a.334.334,0,0,1,.334,0A.565.565,0,0,0,136.63,232.751Z"
          transform="translate(-121.85 -116.644)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M84.922,212.9c-.293,0-.627.167-.564.4.46.188.794,0,1.422,0C85.779,212.733,85.089,213.234,84.922,212.9Z"
          transform="translate(38.899 -138.269)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M84.471,215.76c-.46,0,0,.481-.146.669C85.1,216.262,84.429,216.053,84.471,215.76Z"
          transform="translate(40.166 -135.15)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.464,216.3c-.732,0-.188.753.46.627C90.029,216.572,89.443,216.593,89.464,216.3Z"
          transform="translate(24.782 -134.561)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M84.385,218.12c-.334-.251-.815.167-.585.418C84.615,218.643,83.842,218.141,84.385,218.12Z"
          transform="translate(41.568 -132.66)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M83.6,220.444c-.418-.188,0,.669,0,.941C84.1,221.092,83.514,220.9,83.6,220.444Z"
          transform="translate(42.837 -130.071)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M79.539,221.58c-1.129.251.334.606,0,1.024C80.062,222.458,79.811,221.726,79.539,221.58Z"
          transform="translate(55.8 -128.803)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M84.673,210.26c-.669,0-.878.564,0,.46Z"
          transform="translate(40.549 -141.148)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M84.432,211.1a.606.606,0,0,0-.355.69C84.871,211.811,84.307,211.33,84.432,211.1Z"
          transform="translate(40.768 -140.232)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.645,211.8c-.439-.4-.753.669,0,.46C85.938,212.027,85.06,211.922,85.645,211.8Z"
          transform="translate(37.215 -139.569)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M82.964,218.45c-.125,0,.251.564,0,.711C83.257,219.286,82.964,218.534,82.964,218.45Z"
          transform="translate(44.536 -132.216)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M133.96,236.49c-.481.125.711.46.836.794C135.13,236.783,134.19,236.72,133.96,236.49Z"
          transform="translate(-113.661 -112.543)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M132.308,238.93c-.962.543.962.167,0,.648a3.568,3.568,0,0,0,.773-.251A5.034,5.034,0,0,1,132.308,238.93Z"
          transform="translate(-108.079 -109.883)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M132.4,239.28c-.4.125-.418.272-.648.4.251.146,1.317-.418,1.066.167.355.146.167-.314.4-.418C132.942,239.385,132.523,239.531,132.4,239.28Z"
          transform="translate(-107.626 -109.501)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M131.74,239.705c.376.1.564,0,.523.4a.794.794,0,0,1,.648-.1A1.066,1.066,0,0,0,131.74,239.705Z"
          transform="translate(-107.302 -109.111)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.2,210.09c-.314.564.669,1.275,1.38,1.589C91.938,211.24,90.5,210.717,90.2,210.09Z"
          transform="translate(20.928 -141.333)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M99.13,240.034c-1.129-.335.376.732-.69.355,0,.125.439.23.711.355a2.823,2.823,0,0,1-.021-.711Z"
          transform="translate(-3.928 -108.75)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M95.529,241.37c-.4-.167-.251.251-.439.334a2.823,2.823,0,0,1,.941.355C96.031,241.83,95.55,241.6,95.529,241.37Z"
          transform="translate(6.195 -107.264)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M96.915,241.35c-.523,0-1.129,0-.627.272S96.81,241.58,96.915,241.35Z"
          transform="translate(3.2 -107.243)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M96.818,242c-.544,0-1.442.439-.962.857.46,0,.23-.272.836.125C96.233,242.627,96.588,242.293,96.818,242Z"
          transform="translate(4.091 -106.535)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.98,212.32c-1.087,0-.753,1.756.564,1.359,0-.1-.376,0-.627,0C92.294,213.24,91.416,212.759,91.98,212.32Z"
          transform="translate(17.541 -138.901)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M98.775,245.01c-.439-.125-.314.376-.544.523C98.754,245.679,98.838,245.408,98.775,245.01Z"
          transform="translate(-3.134 -103.274)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M101.218,247.75c-.439,0-.794,0-.878.293C100.842,248.273,100.967,247.875,101.218,247.75Z"
          transform="translate(-9.967 -100.264)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M92.25,220.68c-.753.188.167.794,0,1.213.544,0-.23-.5.585-.418C92.167,221.349,92.522,221.119,92.25,220.68Z"
          transform="translate(15.955 -129.784)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M99.314,240.438a3.015,3.015,0,0,0-.794-.188c0,.23,1.192.627.439.773,0,.334.418,0,.669,0C99.482,240.856,99.105,240.668,99.314,240.438Z"
          transform="translate(-4.572 -108.443)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.751,210.734c-.836.167.1.585.293.815a1.84,1.84,0,0,1,.606-.146c-.46.418.481.23.669.523s-.4,0-.4.209.23.4.857.314c-.4-.439-.314-.4,0-.92h-.627a.376.376,0,0,0-.251-.439,1.744,1.744,0,0,0,1.024-.167c.293-.167,0,.439.648.23l-.669-.5c-.23.418-.69.209-1.442.188.418.146,0,.23,0,.46C90.626,211.382,90.814,211.026,90.751,210.734Z"
          transform="translate(18.436 -140.722)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M98.929,240.45c.125.293.272.4,0,.564.314.125.439.251.46.376a.669.669,0,0,0-.46-.941Z"
          transform="translate(-5.253 -108.225)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.017,211.5c-.711.335.46.46.878.5-.125,0-.481.125-.4.314C92.878,212,91.184,211.814,91.017,211.5Z"
          transform="translate(19.174 -139.795)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M94.492,237.59c-.293.167,0,.606,0,.878C94.889,238.447,94.429,237.883,94.492,237.59Z"
          transform="translate(9.093 -111.344)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M94.756,237.65c-.376,0-.125.251-.1.376h.272c-.293,0,.125.167.125.314s-.188,0-.23,0,0,.209.251.272,0-.209.23-.376h-.251v-.23c0-.063.272.167.46.125s-.146.146.188.209a3.477,3.477,0,0,0-.125-.334c-.209.125-.314,0-.606-.188,0,.146,0,.1-.125.209S94.756,237.775,94.756,237.65Z"
          transform="translate(7.658 -111.278)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M95.033,238.06c-.376,0,0,.272.209.355s-.23,0-.251,0C95.577,238.6,95.033,238.206,95.033,238.06Z"
          transform="translate(7.381 -110.831)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.366,200.7c-.125-.167.209-.585-.46-.439.272.523-.857.69-1.4.46-.209,0-.188.23-.585.209C89.631,201.248,90.425,200.809,91.366,200.7Z"
          transform="translate(23.759 -152.08)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.637,201.378c.606-.125,1.3.209,1.442,0-.188-.314-.878,0-1.442-.188,0,.146-.146.188-.4.209.523.544-.209.794.146,1.484.355-.125.5-.355.167-.481.815.146.544-.376,1.15-.732H89.679a1.838,1.838,0,0,0-.042-.293Z"
          transform="translate(23.376 -151.039)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M92.3,234.552c1.024.209.5.5.69.753C93.741,235.346,92.758,233.987,92.3,234.552Z"
          transform="translate(14.798 -114.807)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.171,204.707c0-.125,0-.773-.251-.146a.732.732,0,0,1,.251.146Z"
          transform="translate(29.044 -147.657)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.688,205.19c.125-.209.293-.4.188-.69-.293.146-.481,0-.753,0s0,.4.4.418H88.04a.167.167,0,1,1,0,.334C88.249,205.378,88.249,205.043,88.688,205.19Z"
          transform="translate(28.109 -147.429)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.982,204.77a2.216,2.216,0,0,0-1.066,1.819H87.56c-.146,0,.272.167.627,0,0,.23-.167.272.167.376a.724.724,0,0,1-.376.146,5.2,5.2,0,0,1,0,2.968c.355-.188.23.167.753,0,0,0-.125.1,0,.167s-.125-.209-.523-.125c.251.251.146.376.794.46s-.606-.272.23-.293c-.209-1.714-.439-2.634-.711-4.557,0-.146.251-.125.355-.188-.23,0,0-.272-.272-.272a.585.585,0,0,1,.46-.209C88.961,205.125,88.71,204.854,88.982,204.77Z"
          transform="translate(28.798 -147.134)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.686,202.09c-.272,0,0,.209-.251.272a.293.293,0,0,1-.209-.125c-.606.314,0,.732-.167,1.129.523-.146.815.794.272.92,0,.146.251,0,.314.167s-.4.167-.439.314a.774.774,0,0,1,.355,0c-.125.188.5.251.711.376-.272,0-.167.209,0,.314s.334,0,.293.188c.23-.251.544-.355.523-.627s-.146,0-.272,0a.481.481,0,0,1-.355-.543.69.69,0,0,0-.46,0c.293-.146-.167-.5.4-.627,0,0-.209-.146-.209-.293-.376,0,0,.125,0,.251a.523.523,0,0,1-.272.1A10.662,10.662,0,0,1,88.686,202.09Z"
          transform="translate(27.066 -150.057)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.453,202.375c-.272,0-.209-.314-.523-.355,0,.167.334.4.251.5S87.306,202.4,87.453,202.375Z"
          transform="translate(31.832 -150.133)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M83.982,211.291c.272-.564,0-.836.272-1.4-.251,0-.4.125-.376.293a1.756,1.756,0,0,0-.627,0c.711.5.355.92,0,1.4C83.355,211.4,83.961,210.684,83.982,211.291Z"
          transform="translate(42.724 -141.551)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M83.292,211.256c-.544-.125.251.376.836.23a.376.376,0,0,1-.23-.355C83.605,211.109,83.438,211.528,83.292,211.256Z"
          transform="translate(43.143 -140.199)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.792,210.46c-.376.314-.334.46-.732.9.188,0,.376,0,.439.146.481,0-.167-.418.585-.314C91.771,210.92,91.98,210.878,91.792,210.46Z"
          transform="translate(18.566 -140.929)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M95.485,234.58c-.481,0-.857.293.125.543C95.9,234.935,95.193,234.789,95.485,234.58Z"
          transform="translate(6.741 -114.626)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M83.311,210.81c-1.171.167.773.5-.355.585,0,.125.523,0,.836,0a3.136,3.136,0,0,1-.481-.585Z"
          transform="translate(43.876 -140.548)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.507,200.58c-1.108,0-.773,1.756.544,1.359h-.627C85.716,201.458,84.943,201.019,85.507,200.58Z"
          transform="translate(37.603 -151.704)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.741,203c-.439,0,0,.46-.125.669C86.118,203.564,86.013,203.293,85.741,203Z"
          transform="translate(36.219 -149.065)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M83.615,211.21a2.467,2.467,0,0,0-.815.146c.146.209,1.442,0,.878.5.23.272.314-.23.585-.23C84.033,211.5,83.594,211.482,83.615,211.21Z"
          transform="translate(43.655 -140.112)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M83.05,211.5c.293.23.481.251.272.544.355,0,.523,0,.627.146A.9.9,0,0,0,83.05,211.5Z"
          transform="translate(43.447 -139.796)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.9,211.151c.272-.544,0-.815.272-1.4-.251,0-.418.146-.376.293a2.759,2.759,0,0,0-.627,0c.69.481.355.9,0,1.4C91.275,211.255,91.881,210.565,91.9,211.151Z"
          transform="translate(18.247 -141.704)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.212,211.136c-.544-.146.251.355.836.209a.334.334,0,0,1-.23-.355C91.5,211.011,91.337,211.387,91.212,211.136Z"
          transform="translate(18.666 -140.352)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.8,211.56c-.167,0-.188,0-.188.1H85.26c.941,0,.125,1.108,1.547.92,0-.209-.314-.146-.648-.146C85.887,212.083,85.95,211.769,85.8,211.56Z"
          transform="translate(35.969 -139.73)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.72,210.205c0,.146.293.146.272.314s.523-.293.334-.481h.5c.23,0-.188-.188,0-.376s.251,0,.272.146,0-.188.46-.314c0,0-.544,0-.606.1s-.376-.125-.4-.251-.125.125-.376.125C90.034,209.871,90.2,210.017,89.72,210.205Z"
          transform="translate(21.892 -142.18)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.606,203.68h-.293c-.293,0,.293.167.4.272S85.4,203.68,85.606,203.68Z"
          transform="translate(37.107 -148.323)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.515,203.93c-.167,0-.4.125-.355.272.46-.188-.125.272.146.376s-.418.146-.376,0,.209.815.669,1.066c-.606.125.251.293-.334.4h.376c-.293.23.23.418.167.585s.753,0,.376.1c.4,0,.188-.23-.209-.23a.432.432,0,0,0-.23-.46c-.23-.146.146,0,.355,0s-.272-.251,0-.481c-.983.146,0-.585-.836-.753C86.557,204.6,86.348,204.285,86.515,203.93Z"
          transform="translate(33.982 -148.051)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.919,205.116c-.167,0-.188.146-.167.272s-.251,0-.481,0-.167.355-.314.523c.544-.146.439.857,1.213.857a.167.167,0,0,0,0,.188.49.49,0,0,1,.5.355c.69.146.9.627,1.484.711-.167,0,0-.376,0-.293.188-.773-1.254-1.965,0-2.613.146.125-.439.293-.23.564.251-.23.669-.293.9-.606s0,.293.293.188,0-.125,0-.293c-.606.23-.606-.376-.523-.523s-.125.125-.376,0,.272.251,0,.376c-.669-.125-.293-.46-.46-.773,0,.314-.962.69-1.212,1.066,0,.167.125.4-.334.439,0,.1.334,0,.293.209s-.5,0-.334.314a.732.732,0,0,1-.376.1c-.418,0,0-.564-.418-.439,0,0,.167,0,.167-.146s0,.209.293,0,0-.188-.293-.146C88.522,205.451,88.982,205.388,88.919,205.116Z"
          transform="translate(24.888 -147.92)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.848,204.15c-.209,0,.146,0,0,.209a.543.543,0,0,0,.46,0C86.2,204.255,85.973,204.338,85.848,204.15Z"
          transform="translate(35.381 -147.811)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.864,205a1.149,1.149,0,0,0,.251.543S86.282,205,85.864,205Z"
          transform="translate(35.365 -146.884)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.912,205.851c.815,0,0-.5-.251-.711C87.682,205.412,87.682,205.683,87.912,205.851Z"
          transform="translate(29.492 -146.731)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.2,184.232c-.5,0,.4.23.251.46.4-.1.523-.293.564-.5C87.664,184.274,87.413,184.524,87.2,184.232Z"
          transform="translate(31.015 -169.577)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.766,209.9c.272,0,0-.188.606-.209-.439,0-.293-.376-.92-.334.188-.293.564-.523,1.568-.544.773-.627,0-1.338-.9-1.631-1.547,0-2.634.23-2.8.69s1.547,1.3.941,1.861c.209.167.293.711.711.962.376-.1.272,0,.732,0,0-.209-.167-.564.439-.585A.711.711,0,0,0,90.766,209.9Z"
          transform="translate(24.066 -144.506)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.407,220.3c0,.167.167.23.335.272C89.784,220.221,89.575,220.3,89.407,220.3Z"
          transform="translate(24.358 -130.203)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.41,220.31v.1a.5.5,0,0,0,.606,0C86.828,220.352,86.7,220.373,86.41,220.31Z"
          transform="translate(33.355 -130.188)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.53,220c0,.146.251.188.334.334C86.823,220,86.718,220,86.53,220Z"
          transform="translate(33.256 -130.526)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.522,220.324h-.1c-.1,0,.23.125.272.272v-.439a.94.94,0,0,1-.355-.167.293.293,0,0,0,0,.272C86.375,220.513,86.438,220.2,86.522,220.324Z"
          transform="translate(33.913 -130.537)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.07,220.39c0,.209.167,0,.272.1s0,.314.146,0C86.321,220.369,86.237,220.453,86.07,220.39Z"
          transform="translate(34.594 -130.101)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.5,220.382a1.95,1.95,0,0,0,.418.1A.627.627,0,0,0,85.5,220.382Z"
          transform="translate(36.356 -130.113)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.234,220.253h.188C85.61,220.253,85.171,220.023,85.234,220.253Z"
          transform="translate(37.375 -130.361)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.034,219.76c0,.1,0,0,.251,0S85.076,219.718,85.034,219.76Z"
          transform="translate(37.888 -130.808)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.946,219.67v.125h.272C88.322,219.8,88.134,219.67,87.946,219.67Z"
          transform="translate(28.914 -130.886)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.413,220.4s0,.1.125,0h0S87.413,220.383,87.413,220.4Z"
          transform="translate(30.68 -130.136)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.378,220.545h.209c.084,0,0-.125-.167-.125S87.42,220.545,87.378,220.545Z"
          transform="translate(30.798 -130.068)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M142.48,224.547c.4.314.564.293,1.087.648,0-.146,0-.293.209-.335,0-.4-.523,0-.376-.481C143.065,224.589,143,224.422,142.48,224.547Z"
          transform="translate(-140.62 -125.749)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.58,218.9c0,.23.209,0,.314.125s-.125.125-.209,0c0,.314.293-.146.314.209a.376.376,0,0,1,.23,0c0-.146-.125,0-.188,0s0-.146,0-.335a.606.606,0,0,1-.46,0Z"
          transform="translate(32.788 -131.726)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.435,219.834v.251c0,.251,0,0,.125,0S85.561,219.772,85.435,219.834Z"
          transform="translate(36.797 -130.716)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.3,220.842c.125.188.4.272.293-.188C90.4,220.592,90.487,220.905,90.3,220.842Z"
          transform="translate(21.627 -129.822)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.94,221.828c0,.314.585.188.648,0s.146.334.209.544a2.341,2.341,0,0,0,.272-.815c-.125-.167-.732,0-.564.167C89.3,221.786,89.128,221.7,88.94,221.828Z"
          transform="translate(25.014 -128.905)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M84.689,219.23c0,.1.209,0,.23,0Z"
          transform="translate(39.048 -131.366)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M84.279,218.713a.963.963,0,0,0,.272.146C84.655,218.88,84.383,218.671,84.279,218.713Z"
          transform="translate(40.253 -131.936)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M82.146,220.284c0,.272.146.523.355.544s0-.523,0-.648S82.271,220.284,82.146,220.284Z"
          transform="translate(46.693 -130.371)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M99.709,246.56c-.272.481.5-.146.23.293a2.5,2.5,0,0,0,.251-.293Z"
          transform="translate(-7.496 -101.562)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.1,215.58c0,.188.188.314.418.272S87.248,215.664,87.1,215.58Z"
          transform="translate(31.347 -135.346)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.026,215.842c0,.334.544,0,.815,0s-.334,0-.272-.272C85.444,215.9,85.319,215.737,85.026,215.842Z"
          transform="translate(37.352 -135.357)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M92.135,216.894a.71.71,0,0,0,0,.355c.125,0,0-.627.355-.355.188,0-.146-.146-.146-.272S92.323,216.915,92.135,216.894Z"
          transform="translate(15.903 -134.235)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.881,217.052c.146,0,.188-.188.376,0a.251.251,0,0,1,.1-.272S91.86,216.759,91.881,217.052Z"
          transform="translate(16.575 -134.038)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.22,217.818a.251.251,0,0,0,.272.167c0-.146,0-.188,0-.355h-.209C88.429,217.8,88.408,217.839,88.22,217.818Z"
          transform="translate(28.096 -133.111)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M84.115,217.65c0,.334.314,0,.46.167S84.3,217.671,84.115,217.65Z"
          transform="translate(40.563 -133.089)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M98.842,182.707c-.146,0-.125-.314-.146-.5-.753,0-1.192,1.087-1.944.5,0-.355.606,0,.669-.334-.314-.314-.46.167-.941-.146v.983c.983.188.857-.1,2.091,0-.376-.564.627-.251.564-.481,0,.648.46.523.69,0,.334.167-.146.439.146.669.376-.376,1.338.23,1.129-.5a.167.167,0,0,0,.23-.167c.293.167-.188.732.4.648-.439-.544.23-.439.418-.648-.669-.983-2.091-.23-2.948-.648A2.8,2.8,0,0,0,98.842,182.707Z"
          transform="translate(-2.825 -171.878)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.93,221.323c.4.167.9-.251,1.129-.544C87.766,220.486,87.369,221.134,86.93,221.323Z"
          transform="translate(31.226 -129.758)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.13,223.1c0,.523.334-.335.376.167v-.355Z"
          transform="translate(31.36 -127.353)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.13,221.709c0,.188.23,0,.314,0a.794.794,0,0,1,0-.439C87.276,221.4,87.276,221.646,87.13,221.709Z"
          transform="translate(31.423 -129.141)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.92,221.783c0,.23.272.439.334.188A.293.293,0,0,0,86.92,221.783Z"
          transform="translate(32.051 -128.588)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.4,221.578c.188.188.627.46.773.167s-.251,0-.146-.355C86.927,221.6,86.655,221.578,86.4,221.578Z"
          transform="translate(33.173 -129.01)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.473,221.478c0,.5,1.192.418.941-.188v.272C86.414,221.666,85.745,221.75,85.473,221.478Z"
          transform="translate(35.881 -129.119)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M84.048,221.744c0,.209.314,0,.439,0S84.216,221.64,84.048,221.744Z"
          transform="translate(40.797 -128.675)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M81.841,221.88c0,.188.167.314.4.272S81.967,221.943,81.841,221.88Z"
          transform="translate(47.624 -128.476)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M139.22,231.662c.188.606.983,0,1.505,0,0-.439-.627.146-.481-.5C139.973,231.766,139.764,231.474,139.22,231.662Z"
          transform="translate(-130.753 -118.356)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.8,223.152v.355c0,.125,0-.627.355-.355.209,0-.146-.146-.125-.272S86.8,223.257,86.8,223.152Z"
          transform="translate(32.34 -127.407)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.443,222c0,.376.4,0,.564,0a.523.523,0,0,1,0-.272c.272.209.167-.209.376-.272s0,.167.125.167.293,0,.23-.355c-.314.146-.272.125-.627,0v.272a.334.334,0,0,0-.314,0V221.1c0-.125.293,0,.188-.272l-.376.272c.293,0,.146.293,0,.627,0-.167.167,0,.314,0C86.924,222.084,86.59,222,86.443,222Z"
          transform="translate(32.548 -129.621)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.616,223.411c.167-.125.188-.209.4-.1s0-.23,0-.272S86.616,223.1,86.616,223.411Z"
          transform="translate(32.836 -127.226)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.23,221.427c.209.335.314-.167.355-.355s0,.209.209.188C86.606,220.632,86.439,221.385,86.23,221.427Z"
          transform="translate(33.954 -129.423)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.93,217.024a.837.837,0,0,0,.585-.293C88.641,216.564,88.16,216.961,87.93,217.024Z"
          transform="translate(28.658 -134.135)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.046,217.964c0,.272.167-.167.188.1s0-.125,0-.188Z"
          transform="translate(28.71 -132.838)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.05,217.24h.167a.439.439,0,0,1,0-.23S88.134,217.2,88.05,217.24Z"
          transform="translate(28.726 -133.787)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.92,217.284c0,.125.146.23.167,0A.146.146,0,0,0,87.92,217.284Z"
          transform="translate(29.128 -133.517)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.668,217.235s.335.251.4,0-.125,0,0-.188S87.794,217.255,87.668,217.235Z"
          transform="translate(29.651 -133.802)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.192,217.116c0,.251.606.209.481,0s0,0,0,.146S87.338,217.241,87.192,217.116Z"
          transform="translate(31.048 -133.767)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.881,218.017a.418.418,0,0,0,0,.188s0-.314.188-.188,0,0,0-.146S87.881,218.038,87.881,218.017Z"
          transform="translate(29.167 -132.891)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.793,217.386c0,.188.209,0,.293,0a.293.293,0,0,1,0-.146c.146.1,0,0,.188-.146s0,.1,0,.1.146,0,.125-.188-.146,0-.334,0v.146a.146.146,0,0,0-.167,0v-.23c0-.063.146,0,0-.146a1.486,1.486,0,0,1-.188.146c.167,0,0,.146,0,.314s0,0,.167,0S87.793,217.386,87.793,217.386Z"
          transform="translate(29.255 -134.037)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.77,218.079h.188c.188,0,0-.125,0-.146S87.77,217.954,87.77,218.079Z"
          transform="translate(29.487 -132.786)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.626,217.132c0,.167.167,0,.188-.188s0,.1,0,0S87.73,217.132,87.626,217.132Z"
          transform="translate(30.008 -133.929)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.978,216.124c0,.209.314,0,.439,0S91.146,216.02,90.978,216.124Z"
          transform="translate(19.38 -134.803)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.78,216.26c0,.188.167.314.4.272S88.947,216.323,88.78,216.26Z"
          transform="translate(26.177 -134.605)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.683,216.522c.1.314.544,0,.836,0s-.355,0-.272-.272C87.1,216.564,86.934,216.417,86.683,216.522Z"
          transform="translate(32.204 -134.615)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M97.047,188.549c.125.23.209.523.5.481.251-.355,0-.627,0-1.15C96.879,187.859,97.486,188.445,97.047,188.549Z"
          transform="translate(0.476 -165.554)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M93.35,188.181c0,.355.585,0,.815.146C94.04,187.721,93.705,188.223,93.35,188.181Z"
          transform="translate(11.698 -165.394)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M92.843,192.283c0,.585.941.188.794-.334C93.219,191.844,93.219,192.3,92.843,192.283Z"
          transform="translate(13.272 -161.134)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M141.12,235.83c.188.5.794.439,1.066.334C141.935,235.893,141.455,236,141.12,235.83Z"
          transform="translate(-136.186 -113.263)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M140.07,235.854c.146.544,1.024,0,1.526.314C141.366,235.687,140.279,235.812,140.07,235.854Z"
          transform="translate(-133.401 -113.287)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M138.88,235.35c.125.585.732.439,1.192.481,0-.418-.815-.272-.376-.648C139.507,234.764,139.194,235.433,138.88,235.35Z"
          transform="translate(-129.389 -114.121)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.822,229.6c.4-.188.146-.293.293-.606-.272,0-.544-.251-.794.209a.523.523,0,0,1,.4,0C90.759,229.33,90.592,229.56,90.822,229.6Z"
          transform="translate(21.083 -120.791)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.032,229.4c0,.544.941-.188,1,.46C91.391,229.112,90.471,229.07,90.032,229.4Z"
          transform="translate(21.685 -120.488)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.21,216.948c0,.376.314.125.314,0S85.377,217.094,85.21,216.948Z"
          transform="translate(37.357 -133.892)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M143.275,225.09c0,.523.669.732.564,0Z"
          transform="translate(-142.355 -124.975)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M142.1,225.186a.9.9,0,0,0,.836.314C143,224.851,142.37,225.29,142.1,225.186Z"
          transform="translate(-138.983 -124.925)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M141.549,226.122c-.523.314.815.648.585,0C141.821,225.913,141.675,226.582,141.549,226.122Z"
          transform="translate(-136.804 -123.896)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M84.67,218.168c0,.209.46-.146.69-.1C85.234,217.791,84.963,218.147,84.67,218.168Z"
          transform="translate(38.649 -132.749)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M82.62,216.337c.376.146.878-.251,1.108-.564C83.435,215.459,83.059,216.128,82.62,216.337Z"
          transform="translate(44.567 -135.225)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M82.81,218.1c0,.5.334-.335.4.167v-.355Z"
          transform="translate(44.69 -132.805)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M82.85,216.71c0,.188.23,0,.314,0a.857.857,0,0,1,0-.46C83.059,216.4,83.059,216.647,82.85,216.71Z"
          transform="translate(44.65 -134.615)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M82.61,216.773c0,.23.272.439.334.167a.314.314,0,0,0-.335-.167Z"
          transform="translate(45.371 -134.051)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M141.726,221.374c-.146.878,2.091.732,1.714-.334-.125,0,0,.293,0,.481C142.9,221.186,142.249,221.855,141.726,221.374Z"
          transform="translate(-138.737 -129.392)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M82.523,218.155v.376c0,.125,0-.627.376-.376.188,0-.146-.146-.146-.272S82.711,218.155,82.523,218.155Z"
          transform="translate(45.542 -132.862)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M82.127,217c.1.376.418,0,.564,0a.46.46,0,0,1,0-.272c.272.23.167-.188.376-.272s0,.188.125.188.293,0,.251-.376c-.334.167-.293.125-.648,0v.272a.272.272,0,0,0-.314,0v-.46c0-.146.293,0,.188-.272l-.376.293c.293.1.146.293.1.627,0-.167.167,0,.314,0C82.608,217.085,82.315,216.981,82.127,217Z"
          transform="translate(45.875 -135.095)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M82.306,218.352c.146-.125.167-.209.376,0s0-.23,0-.272S82.285,218.038,82.306,218.352Z"
          transform="translate(46.177 -132.641)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M100.15,183.682c.376.585.585-.334.648-.648.125.1.146.376.376.314C100.84,182.24,100.547,183.578,100.15,183.682Z"
          transform="translate(-9.526 -170.972)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M81.88,221.775c.376.167.878-.23,1.108-.544C82.737,221.022,82.319,221.671,81.88,221.775Z"
          transform="translate(46.854 -129.228)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M82.07,223.648c0,.523.355-.334.4.188v-.376Z"
          transform="translate(46.977 -126.753)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M82.08,222.229c0,.188.23,0,.314,0a1.046,1.046,0,0,1,0-.439C82.226,221.915,82.226,222.229,82.08,222.229Z"
          transform="translate(47.03 -128.574)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M81.84,222.292c0,.23.293.46.334.188A.314.314,0,0,0,81.84,222.292Z"
          transform="translate(47.751 -128.031)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M142.08,231.564c.355.355,1.171.836,1.422.314-.23-.334-.439,0-.272-.648C143.063,231.606,142.561,231.585,142.08,231.564Z"
          transform="translate(-139.509 -118.28)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M140.376,231.394c-.146.878,2.09.732,1.714-.334-.146,0,0,.293,0,.481C141.526,231.206,140.815,231.875,140.376,231.394Z"
          transform="translate(-134.565 -118.465)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M94.86,194.3c0,.355.585,0,.815.146C95.529,194.046,95.194,194.109,94.86,194.3Z"
          transform="translate(7.032 -158.716)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M81.793,223.7v.355c0,.125,0-.627.376-.355.188-.1-.146-.146-.146-.272S82,223.725,81.793,223.7Z"
          transform="translate(47.798 -126.809)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M81.367,222.511c0,.376.418,0,.564,0a.838.838,0,0,1,0-.272c.272.209.167-.209.376-.272s0,.167.125.167.293,0,.251-.355c-.334.146-.293.125-.648,0v.272a.355.355,0,0,0-.314,0c0-.23,0-.314,0-.439s.293,0,.167-.272l-.376.272c.293.125.146.293,0,.627.125-.167.167,0,.314,0C81.827,222.594,81.534,222.511,81.367,222.511Z"
          transform="translate(48.224 -129.065)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M81.566,223.866c.146-.125.167-.209.376,0s0-.251,0-.272S81.545,223.573,81.566,223.866Z"
          transform="translate(48.464 -126.615)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M141.74,231.312c.376.585.585-.334.648-.669.125,0,.146.4.376.335C142.43,229.87,142.221,231.208,141.74,231.312Z"
          transform="translate(-138.061 -119.031)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M84.509,222.709a.774.774,0,0,0,.523-.251C85.157,222.312,84.718,222.667,84.509,222.709Z"
          transform="translate(39.291 -127.883)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M82.85,225.389c0,.188.209,0,.314,0a.627.627,0,0,1,0-.439A.433.433,0,0,0,82.85,225.389Z"
          transform="translate(44.65 -125.128)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M82.69,225.461c0,.23.272.46.314.188A.293.293,0,0,0,82.69,225.461Z"
          transform="translate(45.145 -124.573)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M82.164,225.258c.209.188.648.46.794.167s-.251,0-.167-.355C82.708,225.279,82.436,225.258,82.164,225.258Z"
          transform="translate(46.256 -124.997)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M83.073,225.848c0,.481,1.192.4.941-.188v.272C84.014,226.036,83.345,226.12,83.073,225.848Z"
          transform="translate(43.298 -124.354)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M92.572,230.555c-.167.334.46.272.606.46C93.325,230.6,92.969,230.513,92.572,230.555Z"
          transform="translate(14.337 -119.026)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.348,232.38c0,.355,0,.627.334.711C89.85,232.714,89.515,232.589,89.348,232.38Z"
          transform="translate(24.501 -117.025)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M84.371,223.087c0,.167.188,0,.272,0a.167.167,0,0,1,0-.125c.125,0,0,0,.167-.125h0s.125,0,.1-.167-.125,0-.293,0v.125H84.5c-.021,0,0-.146,0-.209s.125,0,0-.125l-.167.125c.146,0,0,.146,0,.293s0,0,.146,0S84.475,223.087,84.371,223.087Z"
          transform="translate(39.806 -127.843)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M84.25,222.8c0,.146.167,0,.167-.167h0C84.417,222.525,84.355,222.776,84.25,222.8Z"
          transform="translate(40.47 -127.72)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.22,220.263s-.376-.125-.293.188c.355-.1.46.4.293.627s.146.1.125.272C89.513,221.057,89.22,220.681,89.22,220.263Z"
          transform="translate(25.737 -130.266)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.179,221.073c0-.272.167-.564,0-.648s0,.4-.146.648.125,0,.146.167c.376-.209.544.125,1.024,0,0-.167-.251-.23-.334,0,.125-.376-.251-.272-.481-.544a3.1,3.1,0,0,1,0,.439Z"
          transform="translate(27.865 -130.073)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.1,222.92c-.146-.46.188-.335.293-.46S86.642,222.878,87.1,222.92Z"
          transform="translate(31.806 -127.864)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.5,220s-.544,0-.125.1C86.42,220,86.441,220,86.5,220Z"
          transform="translate(34.265 -130.526)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.43,220.093a.627.627,0,0,0-.46,0v.335a.209.209,0,0,0,.293-.167v.209a.167.167,0,0,1,.23,0A.353.353,0,0,1,86.43,220.093Z"
          transform="translate(34.799 -130.472)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M84.068,219.86a1.84,1.84,0,0,0,1.233.544v.167c0,.167.125-.125,0-.272s.188,0,.272,0a.167.167,0,0,1,0,.167,5.686,5.686,0,0,1,2.09.146c-.125-.167.125,0,0-.334a.147.147,0,0,0,.125,0c0,.1-.146,0,0,.209s.272,0,.314-.314-.188.251-.188,0a28.016,28.016,0,0,0-3.157.125.183.183,0,0,0-.125-.167c-.125-.042-.188,0-.188.1a.23.23,0,0,1-.125-.209S84.068,219.985,84.068,219.86Z"
          transform="translate(37.161 -130.679)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.7,220.442c0,.125.125,0,.167.125.209.293.5,0,.773.125,0-.23.564-.334.648,0s0-.1,0-.125.125.167.23.209a.23.23,0,0,1,0-.167c.125,0,.167-.209.272-.293s.125,0,.209,0,0-.167.146-.125-.251-.251-.439-.251,0,0,0,.125a.355.355,0,0,1-.355.125.188.188,0,0,0,0,.209c0-.146-.355,0-.418-.209a.314.314,0,0,1-.209,0c0,.167,0,0,.188,0a.685.685,0,0,1,0,.125,9.225,9.225,0,0,1-1.213.125Z"
          transform="translate(30.573 -130.591)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.994,219.45c0,.1-.209,0-.251.209s.272-.146.355,0S88.014,219.471,87.994,219.45Z"
          transform="translate(29.472 -131.126)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M144.736,224.7c-.69-.251-1.045,0-1.756-.314a.319.319,0,0,0,.355.314,1.152,1.152,0,0,0,0,.5c.627-.523,1.15-.209,1.756.146C144.84,225.205,143.983,224.683,144.736,224.7Z"
          transform="translate(-142.98 -125.739)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M142.359,225.028c-.209.418.46-.167.293-.648a.5.5,0,0,1-.439.167C142.15,224.777,142.672,224.944,142.359,225.028Z"
          transform="translate(-138.951 -125.749)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M81.809,221.7c.23.167.314.167.606.355s0-.167.125-.188-.293,0-.209-.272C82.164,221.725,82.164,221.621,81.809,221.7Z"
          transform="translate(47.427 -128.781)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.292,223.642c.125.188.4.272.314-.167C86.4,223.412,86.5,223.725,86.292,223.642Z"
          transform="translate(33.996 -126.746)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M99.17,183.562c.69.293,1.61-.439,2.091-1C100.654,182.182,99.964,183.373,99.17,183.562Z"
          transform="translate(-7.564 -171.437)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M142.454,224.554c.167.941.648-.606.711.314.167,0,0-.4,0-.648Z"
          transform="translate(-140.029 -125.924)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M142.49,221.9c0,.355.418,0,.585.146a1.359,1.359,0,0,1,0-.815C142.762,221.355,142.741,221.774,142.49,221.9Z"
          transform="translate(-139.94 -129.185)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M142.12,222.106c.146.4.523.794.606.314a.544.544,0,0,0-.606-.314Z"
          transform="translate(-138.817 -128.242)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M141.21,221.714c.335.376,1.15.857,1.4.334-.23-.355-.439,0-.272-.669C142.172,221.777,141.691,221.735,141.21,221.714Z"
          transform="translate(-136.799 -129.021)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.4,218.678c0,.481,1.192.4.941-.188v.272C89.344,218.866,88.675,218.95,88.4,218.678Z"
          transform="translate(26.826 -132.173)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.965,218.934c0,.209.314,0,.46,0S87.154,218.83,86.965,218.934Z"
          transform="translate(31.755 -131.739)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M141.888,224.621a2.287,2.287,0,0,0,.146.669c.272-.1.146-1.15.669-.669.355-.167-.272-.272-.272-.481C142.264,224.307,142.222,224.663,141.888,224.621Z"
          transform="translate(-138.418 -126.011)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M141.314,222.524c.188.69.753,0,1.024-.167a1.421,1.421,0,0,1-.146-.5c.481.4.293-.355.669-.5s.1.335.251.335.523-.146.439-.669c-.585.293-.523.23-1.171,0v.481a.606.606,0,0,0-.564.167c0-.418.125-.564-.167-.815s.544,0,.334-.5c-.23.167-.439.355-.669.5.523.23.251.564.167,1.15.209-.314.293,0,.585,0C142.13,222.67,141.67,222.5,141.314,222.524Z"
          transform="translate(-137.969 -130.144)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M141.557,225.045c.293-.209.335-.376.69-.167,0-.293,0-.439.209-.5S141.557,224.481,141.557,225.045Z"
          transform="translate(-137.397 -125.767)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M97.93,183.867c.376.585.585-.334.648-.669.125.1.146.376.376.335C98.62,182.4,98.327,183.762,97.93,183.867Z"
          transform="translate(-2.665 -170.78)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M83.083,221.777a8.051,8.051,0,0,0-.983-.167.171.171,0,0,0,.209.167.376.376,0,0,0,0,.272c.335-.293.627,0,.962,0C83.124,222.049,82.664,221.756,83.083,221.777Z"
          transform="translate(46.111 -128.77)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M142.788,231.349c-.188.418.46-.167.293-.669a.418.418,0,0,1-.439.167C142.579,231.1,143.1,231.349,142.788,231.349Z"
          transform="translate(-140.28 -118.879)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M141.353,226.849c0,.125,0,.146.125.146a1.7,1.7,0,0,0,0,.334c0-.753,1.38,0,1.213-1.15-.272,0-.209.23-.209.481C142.043,226.807,141.667,226.744,141.353,226.849Z"
          transform="translate(-137.193 -123.787)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M83.334,221.815c.125,0,.125-.125.23-.125s-.188-.23-.314-.146,0-.125,0-.23-.146,0-.272,0,0-.1,0-.1-.125,0-.188-.209,0,.23,0,.272,0,.146-.188.146.1,0,.1.167S83.23,221.585,83.334,221.815Z"
          transform="translate(44.877 -129.497)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.645,218.805h0v.125c0,.125.125-.125.188-.167S86.645,218.91,86.645,218.805Z"
          transform="translate(33.037 -131.881)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.653,219.429a.162.162,0,0,0,.188.167.912.912,0,0,1,.251,0c.084,0,0,.167,0,.167s.585,0,.753-.272c0,.272.209,0,.272.167s0-.125,0-.167.293,0,.418,0,0-.334,0-.167c0-.167-.167,0-.188,0a.418.418,0,0,0-.314,0s0,0,0-.167-.167.125-.334,0c0,.46-.4,0-.523.355C86.113,219.429,85.883,219.513,85.653,219.429Z"
          transform="translate(34.363 -131.502)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.931,221.439H86.1c.167,0,0,.1,0,.209a.627.627,0,0,1,.376.167c0-.251.585-.167.606-.5h.125a.272.272,0,0,1,.251-.209c.125-.314.439-.376.523-.627H87.77c-.523-.125-1.359.46-1.8,0a.4.4,0,0,0,.4.125c-.167-.125-.209-.314-.418-.418s.209,0,.146-.125-.1,0-.209,0c.146.272-.272.251-.376.209s0,0,0,.167.167-.1.251,0-.314.125-.544.167c.23,0,.46.46.711.585s.272,0,.314.167,0-.146.125-.125,0,.23.23.167,0,0,0,.167-.4,0-.314.167h0s.146,0,0-.125-.146,0,0,.125S86.1,221.418,85.931,221.439Z"
          transform="translate(34.88 -130.522)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.319,219.217h.146c.146,0,0-.125,0-.209S86.444,219.2,86.319,219.217Z"
          transform="translate(34.032 -131.625)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.65,219.164a.648.648,0,0,0,.376,0S85.65,219.018,85.65,219.164Z"
          transform="translate(35.934 -131.509)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.042,220.082c0-.355-.355,0-.5,0A.774.774,0,0,0,86.042,220.082Z"
          transform="translate(36.149 -130.608)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.151,221.023c0-.125-.146,0-.146-.272,0,.188-.272.1-.23.376-.209,0-.376-.272-.355-.711-.418-.355-.941,0-1.15.355a1.087,1.087,0,0,0,.439,1.233c.314.084.92-.627,1.275-.334a1.32,1.32,0,0,0,.69-.272c.167-.167,0-.125,0-.335s-.4,0-.4-.209a.23.23,0,0,0-.125.167Z"
          transform="translate(41.325 -130.232)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.852,231.87a.355.355,0,0,0,.167.46C92.438,231.975,92.124,231.954,91.852,231.87Z"
          transform="translate(16.833 -117.582)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.04,200.79s.146.1,0,.146a.732.732,0,0,0,.794,0Z"
          transform="translate(18.858 -151.475)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.19,200.35c.125.188.314.251.439.418C91.545,200.371,91.441,200.392,91.19,200.35Z"
          transform="translate(18.75 -151.955)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.142,200.8H91c-.146,0,.293.146.376.355s0-.167,0-.146v-.418a1.15,1.15,0,0,1-.46-.209.418.418,0,0,0,0,.355C90.954,201.049,91.016,200.631,91.142,200.8Z"
          transform="translate(19.634 -151.922)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.6,200.89c0,.272.23,0,.355.125s0,.418.188,0C90.934,200.848,90.83,200.974,90.6,200.89Z"
          transform="translate(20.469 -151.366)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.86,200.883c0,.1.4.125.564.146A.794.794,0,0,0,89.86,200.883Z"
          transform="translate(22.735 -151.38)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.54,200.7c0-.125.125,0,.251,0S89.456,200.431,89.54,200.7Z"
          transform="translate(24.038 -151.701)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.309,200.127c0,.125.125,0,.314,0C89.644,199.981,89.351,199.981,89.309,200.127Z"
          transform="translate(24.686 -152.317)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.628,231.93v.125c0,.167.188,0,.293.146S89.774,232.076,89.628,231.93Z"
          transform="translate(23.699 -117.516)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.639,233v.146C89.869,233,89.66,233,89.639,233Z"
          transform="translate(23.876 -116.349)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.6,233.2a.261.261,0,0,1,.209.146c.084.146,0-.188-.125-.23S89.625,233.2,89.6,233.2Z"
          transform="translate(23.848 -116.234)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M84.795,198.065c.272.23.4.209.753.46,0-.1,0-.209.167-.23s-.376,0-.272-.355A1.035,1.035,0,0,1,84.795,198.065Z"
          transform="translate(38.002 -154.583)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.26,199c0,.293.272,0,.4.146s-.167.167-.251.125c0,.4.376-.209.4.251a.627.627,0,0,1,.293,0c0-.209-.146,0-.23,0s-.146-.188,0-.439A.752.752,0,0,1,91.26,199Z"
          transform="translate(18.137 -153.427)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.785,200.176c.125.146,0,.125,0,.314s0,0,.167,0S89.973,200.092,89.785,200.176Z"
          transform="translate(23.291 -152.157)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M93.08,231.947c0,.272.23.481.5,0C93.414,231.717,93.247,232.135,93.08,231.947Z"
          transform="translate(12.846 -117.575)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M92.367,233.413c-.272.335.5.523.669.418s0,.46-.188.711a4.454,4.454,0,0,0,.962-.773c0-.23-.794-.418-.773,0C92.869,233.559,92.744,233.371,92.367,233.413Z"
          transform="translate(14.228 -115.905)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.859,199.39c0,.125.272,0,.293,0Z"
          transform="translate(26.098 -153.001)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.3,198.735c.125,0,.209.167.355.209C88.634,198.652,88.446,198.694,88.3,198.735Z"
          transform="translate(27.765 -153.748)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.59,200.725c0,.355.167.669.46.69a1.52,1.52,0,0,0-.125-.815A.418.418,0,0,1,85.59,200.725Z"
          transform="translate(36.036 -151.682)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M141.7,233.91c.1.669.439-.418.5.23v-.46Z"
          transform="translate(-137.414 -115.608)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.98,194.68a.382.382,0,0,0,.5.355C92.44,194.743,92.168,194.764,91.98,194.68Z"
          transform="translate(16.246 -158.138)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.29,195.015c.125.439.69,0,1.066,0,0-.314-.46,0-.355-.355C89.813,195.1,89.666,194.89,89.29,195.015Z"
          transform="translate(23.995 -158.16)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M141.273,233.989a.66.66,0,0,0,.1.46c.1.167,0-.815.481-.46.251-.125-.188-.209-.188-.355S141.524,234.01,141.273,233.989Z"
          transform="translate(-136.256 -115.687)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M141.04,234.251c.209-.146.23-.251.481-.125a.418.418,0,0,1,.146-.355S141.02,233.875,141.04,234.251Z"
          transform="translate(-135.501 -115.51)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.23,229.716c0,.167,0,.251.167.335s.23-.188.334-.376l-.209-.1C88.5,229.8,88.418,229.842,88.23,229.716Z"
          transform="translate(27.835 -120.09)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.1,197.353c0,.439.4.146.585.23C88.664,197.249,88.33,197.374,88.1,197.353Z"
          transform="translate(28.153 -155.229)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.31,233.766c.293.376,1.192.188,1.714,0C90.857,233.348,89.9,233.87,89.31,233.766Z"
          transform="translate(23.285 -115.694)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.421,236.06c-.334.606.648-.188.293.4.125,0,.23-.23.355-.376Z"
          transform="translate(17.976 -113.012)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.074,234.481c-.146.209.23.146.293.251a1.171,1.171,0,0,1,.355-.5C90.492,234.21,90.283,234.481,90.074,234.481Z"
          transform="translate(22.062 -115.009)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.254,234.78c0,.293,0,.648.23.376a.293.293,0,0,0-.23-.376Z"
          transform="translate(21.84 -114.408)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.042,202.41c.251.272.836.606,1,.23s-.293,0-.188-.46C91.732,202.452,91.4,202.431,91.042,202.41Z"
          transform="translate(18.605 -149.959)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.83,202.311c0,.606,1.547.523,1.233-.251-.1,0,0,.209,0,.355C90.75,202.165,90.206,202.645,89.83,202.311Z"
          transform="translate(22.117 -150.09)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88,202.676c0,.251.418,0,.585,0C88.481,202.487,88.23,202.529,88,202.676Z"
          transform="translate(28.462 -149.556)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.18,202.81a.382.382,0,0,0,.5.355C85.619,202.873,85.368,202.915,85.18,202.81Z"
          transform="translate(37.261 -149.272)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M135.29,233.7c.355.335.585-.293.941-.376-.167-.293-.334.272-.5-.188C135.792,233.579,135.541,233.454,135.29,233.7Z"
          transform="translate(-118.043 -116.197)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.624,204.512a.878.878,0,0,0,0,.46c.188,0,0-.794.46-.46.251-.125-.188-.188-.188-.355S91.854,204.533,91.624,204.512Z"
          transform="translate(17.376 -147.838)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.043,234.927c-.188.46.481.167.732.188a.564.564,0,0,1,.125-.355c.125.376.355-.146.648-.1s-.1.23,0,.272a.539.539,0,0,0,.564-.293c-.481,0-.418,0-.732-.314l-.209.293a.376.376,0,0,0-.418,0c.209-.251.334-.314.272-.544s.293.188.418-.23l-.648.125c.23.272,0,.4-.4.753.272-.125.188,0,.355.167C89.44,235.262,89.21,235.011,89.043,234.927Z"
          transform="translate(23.824 -115.433)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.292,204.774c.188-.146.23-.251.481-.1,0-.209,0-.314.146-.355S91.271,204.4,91.292,204.774Z"
          transform="translate(18.23 -147.64)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.81,202.321c.272.418.418-.251.46-.481a.224.224,0,0,0,.272.23C91.312,201.192,91.144,202.154,90.81,202.321Z"
          transform="translate(19.632 -150.476)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M93.02,196.532c.251.1.585-.167.753-.376C93.564,196.009,93.313,196.448,93.02,196.532Z"
          transform="translate(12.781 -156.561)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.275,230c-.167.334.334,0,.146.209s.125-.125.188-.188Z"
          transform="translate(27.937 -119.621)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.537,229.178a.15.15,0,0,1,.146.125c.021.125,0-.1.188-.251S87.642,229.178,87.537,229.178Z"
          transform="translate(30.096 -120.701)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.647,229.31c0,.167,0,.335.125.188S87.751,229.352,87.647,229.31Z"
          transform="translate(29.965 -120.373)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.216,229.22c0,.167.167.418.376.293s-.125,0,0-.251C87.509,229.366,87.363,229.283,87.216,229.22Z"
          transform="translate(31.023 -120.471)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.639,229.39c-.251.251.544.544.648.146l-.146.125C86.994,229.787,86.7,229.62,86.639,229.39Z"
          transform="translate(32.709 -120.286)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.218,230.038c0,.1,0,.167-.1.23s.314-.335.376-.1,0-.125,0-.188S88.322,230.184,88.218,230.038Z"
          transform="translate(28.328 -119.659)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.08,229.406c0,.251.251,0,.376,0a.4.4,0,0,1,0-.188c0,.209.188,0,.334,0s0,.1,0,.125a.307.307,0,0,0,.293-.146c-.251,0-.209,0-.376-.167v.167a.188.188,0,0,0-.23,0c.125-.146.188-.167.146-.293s.146,0,.23-.1h-.334c.1.146,0,.209-.209.376s0,0,.188,0S87.205,229.469,87.08,229.406Z"
          transform="translate(30.888 -120.929)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.99,230.258h.272a.167.167,0,0,1,.167-.125S88.115,230.091,87.99,230.258Z"
          transform="translate(28.64 -119.482)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.953,229.186c0,.251.272,0,.355,0s0,.146,0,.146C87.517,228.894,87.1,229.207,86.953,229.186Z"
          transform="translate(31.872 -120.605)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M139.89,233.1c0,.251.418,0,.585,0C140.371,232.827,140.141,232.868,139.89,233.1Z"
          transform="translate(-131.904 -116.448)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M94.13,195.55a.392.392,0,0,0,.523.355C94.59,195.634,94.318,195.655,94.13,195.55Z"
          transform="translate(9.58 -157.189)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.48,195.884c.146.418.711,0,1.087,0,0-.293-.46.125-.355-.334C92.107,195.947,91.961,195.738,91.48,195.884Z"
          transform="translate(17.206 -157.189)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M138.069,230.184c.167.125.335.272.5.188s-.314-.4-.5-.732C137.671,229.786,138.3,230,138.069,230.184Z"
          transform="translate(-125.944 -120.013)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M134.61,236.734a.606.606,0,0,0,.815,0C135.07,236.545,134.8,236.734,134.61,236.734Z"
          transform="translate(-115.816 -112.369)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M133.99,236.624c.293.314.627-.293,1.087-.188C134.743,236.185,134.094,236.541,133.99,236.624Z"
          transform="translate(-114.172 -112.698)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M133.46,235.926c.314.335.627.1.941,0-.125-.293-.627,0-.5-.335C133.606,235.4,133.669,235.906,133.46,235.926Z"
          transform="translate(-112.388 -113.569)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M133.735,235.866c0-.251-.167-.188-.355-.376,0,.146-.4.146-.146.46a.23.23,0,0,1,.146-.188C133.5,235.8,133.609,235.971,133.735,235.866Z"
          transform="translate(-111.074 -113.634)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M132.811,236c.418.209.23-.523.732-.293C133.125,235.246,132.728,235.664,132.811,236Z"
          transform="translate(-110.15 -113.62)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.49,196.452c0,.481.418.146.418,0S89.72,196.6,89.49,196.452Z"
          transform="translate(24.025 -156.251)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.377,198.47c0,.376.481.523.418,0Z"
          transform="translate(36.73 -154.005)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M139.55,230.078a.648.648,0,0,0,.648,0C139.989,229.743,139.759,230.078,139.55,230.078Z"
          transform="translate(-130.916 -119.698)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M138.809,230.519c-.209.335.773.23.355-.146C138.809,230.31,139.1,230.812,138.809,230.519Z"
          transform="translate(-128.378 -119.22)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.74,198.017c0,.272.606-.188.9-.125C89.367,197.537,89.1,197.976,88.74,198.017Z"
          transform="translate(25.862 -154.786)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.15,195.548c.5.209,1.15-.314,1.442-.711C87.216,194.566,86.735,195.423,86.15,195.548Z"
          transform="translate(33.323 -158.024)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.373,197.971c0,.669.46-.418.5.23.125,0,0-.293,0-.481Z"
          transform="translate(33.518 -154.823)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.4,196.06c0,.251.293,0,.418.125a1.129,1.129,0,0,1,0-.585C86.588,195.684,86.588,196,86.4,196.06Z"
          transform="translate(33.575 -157.135)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.14,196.215c0,.293.355.585.418.23a.355.355,0,0,0-.418-.23Z"
          transform="translate(34.378 -156.474)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.52,195.951c.251.251.836.606,1,.23-.146-.251-.293,0-.188-.481C86.273,195.993,85.938,195.972,85.52,195.951Z"
          transform="translate(35.709 -157.025)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M140.935,227.838c.272.606,1.672,0,.962-.648a.483.483,0,0,0,.146.334C141.6,227.441,141.457,228.026,140.935,227.838Z"
          transform="translate(-135.709 -122.685)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.973,198.039a.576.576,0,0,0,.125.46c.125.167,0-.815.46-.481.251-.1-.188-.188-.188-.335S86.224,198.06,85.973,198.039Z"
          transform="translate(34.65 -154.891)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.514,196.506c.146.481.544,0,.732-.125a1.024,1.024,0,0,1,0-.355c.355.293.209-.251.481-.355s0,.251.188.251.355-.1.293-.481c-.418.209-.355.167-.815,0v.355a.4.4,0,0,0-.418.125c0-.293,0-.418,0-.585s.376,0,.209-.355l-.46.355c.376.146.167.4.125.815.146-.209.209,0,.418,0C86.2,196.611,85.785,196.485,85.514,196.506Z"
          transform="translate(35.025 -157.811)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.681,198.318c.209-.146.251-.272.5-.125,0-.209,0-.293.146-.355S85.681,197.921,85.681,198.318Z"
          transform="translate(35.547 -154.71)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.24,195.76c.272.418.418-.23.46-.46s.1.251.272.23C85.721,194.757,85.512,195.677,85.24,195.76Z"
          transform="translate(36.846 -157.546)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.19,202.689c.5.209,1.15-.314,1.442-.711C86.256,201.706,85.754,202.543,85.19,202.689Z"
          transform="translate(36.29 -150.238)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.44,205.12c.1.669.439-.418.5.23v-.46a3.173,3.173,0,0,1-.5.23Z"
          transform="translate(36.458 -147.004)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.44,203.305c0,.251.293,0,.418,0a.92.92,0,0,1,0-.585C85.628,202.825,85.628,203.138,85.44,203.305Z"
          transform="translate(36.541 -149.37)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.18,203.352c0,.293.355.564.418.23a.4.4,0,0,0-.418-.23Z"
          transform="translate(37.345 -148.685)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M137.039,234.372c.355.146,1.066.251,1.024-.146s-.293,0-.439-.355C137.666,234.142,137.353,234.267,137.039,234.372Z"
          transform="translate(-123.535 -115.401)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M136.071,233.948c.251.606,1.672,0,.941-.648a.52.52,0,0,0,.146.355C136.719,233.551,136.593,234.136,136.071,233.948Z"
          transform="translate(-120.664 -116.022)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.063,205.2v.46c0,.146,0-.794.481-.46.251-.125-.188-.188-.188-.355S85.314,205.2,85.063,205.2Z"
          transform="translate(37.566 -147.086)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M138.11,234.958c.4.376.439-.23.585-.376a.9.9,0,0,1-.293-.272c.46.125,0-.314.209-.5s.209.188.293.146.272-.209,0-.523c-.251.314-.23.272-.711.293l.209.314a.28.28,0,0,0-.293.251c-.146-.293-.167-.4-.439-.481s.376,0,0-.418a5.1,5.1,0,0,0-.209.5c.418,0,.376.293.564.69,0-.251.188,0,.376-.146C138.737,234.832,138.319,234.832,138.11,234.958Z"
          transform="translate(-125.4 -115.924)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M136.2,236.494c.1-.209,0-.314.355-.272q-.188-.251,0-.376C136.68,235.762,135.969,236.18,136.2,236.494Z"
          transform="translate(-120.186 -113.258)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M137.14,234.032c.481.272.251-.355.146-.585s.251.209.376.1C136.994,232.924,137.349,233.865,137.14,234.032Z"
          transform="translate(-123.343 -115.981)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.6,203.887a.945.945,0,0,0,.669-.334C89.081,203.427,88.851,203.824,88.6,203.887Z"
          transform="translate(26.525 -148.489)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.49,207.29c0,.251.293,0,.418.125a.941.941,0,0,1,0-.585C86.678,206.914,86.657,207.185,86.49,207.29Z"
          transform="translate(33.296 -144.888)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.23,207.444c0,.293.376.585.439.23a.4.4,0,0,0-.439-.23Z"
          transform="translate(34.079 -144.227)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.58,207.181c.251.251.836.606,1,.23-.167-.251-.314,0-.188-.481C86.27,207.223,85.914,207.2,85.58,207.181Z"
          transform="translate(35.523 -144.779)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.73,207.93c0,.627,1.547.523,1.233-.23v.335C87.441,207.8,87.106,208.327,86.73,207.93Z"
          transform="translate(31.698 -143.939)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M132.81,237.41c.188.209.4,0,.585,0a.334.334,0,0,0-.585,0Z"
          transform="translate(-110.023 -111.728)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.464,204.425c0,.23.251,0,.335,0a.313.313,0,0,1,0-.167c.167.125,0-.125.209-.167h0s.167,0,.146-.209-.167,0-.376,0v.167a.167.167,0,0,0-.188,0v-.272c0-.084.167,0,0-.167l-.23.167c.188,0,0,.167,0,.376s0,0,.188,0S88.589,204.425,88.464,204.425Z"
          transform="translate(27.142 -148.4)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.19,204c.125.188.188,0,.209-.209s0,.1.125.1C88.42,203.516,88.315,203.955,88.19,204Z"
          transform="translate(28.126 -148.284)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M92,232.177c-.167,0-.334-.334-.5,0,.5,0,.209.69-.188.836v.376C91.749,233.223,91.728,232.658,92,232.177Z"
          transform="translate(18.128 -117.408)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.746,233.275c.146-.334.648-.523.564-.69s-.314.439-.711.627c.146,0,0,.146,0,.272.585,0,.5.418,1.15.523,0-.209,0-.4-.293-.272.439-.335,0-.418,0-.836l-.335.5Z"
          transform="translate(19.863 -116.833)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.9,236.026c.23-.585.481-.272.711-.355C91.654,235.231,90.42,235.733,90.9,236.026Z"
          transform="translate(19.604 -113.626)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.164,200.35s-.711,0-.146.125a.272.272,0,0,1,.146-.125Z"
          transform="translate(20.03 -151.955)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.076,200.56a.9.9,0,0,0-.606-.146c.125.167,0,.272,0,.439a.293.293,0,0,0,.376-.209v.272a.188.188,0,0,1,.314,0A.246.246,0,0,1,91.076,200.56Z"
          transform="translate(20.724 -151.892)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.086,200.21a2.342,2.342,0,0,0,1.589.69v.209c0,.084.146-.146,0-.334s.23,0,.334,0,0,0,.125.209a8.05,8.05,0,0,1,2.655.188c-.167-.209.146,0,0-.418h.146c.146,0-.188,0-.125.293.23-.125.355,0,.418-.418s-.251.314-.251-.146a37.851,37.851,0,0,0-4.181.167c-.125,0,0-.146-.167-.209s-.23,0-.23.146a.293.293,0,0,1-.167-.293C88.4,200.231,88.149,200.377,88.086,200.21Z"
          transform="translate(23.631 -152.107)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M88.907,232.6v.23a.272.272,0,0,1-.167,0c0,.418.5.335.773.544,0-.314.9,0,.794.23s.146,0,.23,0,0,.251,0,.334a.939.939,0,0,1,0-.209c0,.146.355-.125.544-.167s0,.146.209.146.146-.167.251,0c0-.209,0-.4-.272-.5s0,0,0,.167a.5.5,0,0,1-.523,0,.314.314,0,0,0-.167.23c0-.188-.439-.125-.314-.439s-.188,0-.293,0,0,0,.209.1a.647.647,0,0,1,0,.167,13.06,13.06,0,0,1-1.275-.836Z"
          transform="translate(23.96 -116.786)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.735,231.83h0C89.777,231.83,89.735,231.893,89.735,231.83Z"
          transform="translate(24.679 -117.625)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.424,198.2a12.086,12.086,0,0,0-1.254-.23.272.272,0,0,0,.272.23.606.606,0,0,0,0,.355c.439-.376.815-.146,1.233.1C86.487,198.555,85.881,198.179,86.424,198.2Z"
          transform="translate(36.289 -154.55)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M140.16,229.962c0,.314.23-.23,0-.5a.272.272,0,0,1-.209.23C140.014,229.857,140.411,229.815,140.16,229.962Z"
          transform="translate(-131.819 -120.21)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.12,202.565a7.345,7.345,0,0,0,.773.481.335.335,0,0,1,.146-.251c0-.272-.376,0-.272-.334C85.538,202.606,85.5,202.481,85.12,202.565Z"
          transform="translate(37.029 -149.654)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.89,205.134c.167.23.5.335.4-.23C91.079,204.779,91.079,205.134,90.89,205.134Z"
          transform="translate(19.698 -147.018)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M141.65,227.864c.544,0,.836-.69.878-1.15C142.047,226.61,142.089,227.55,141.65,227.864Z"
          transform="translate(-137.636 -123.213)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M84.793,198.12c0,.69.46-.418.5.23.125,0,0-.293,0-.46a2.655,2.655,0,0,1-.5.23Z"
          transform="translate(38.401 -154.637)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M84.8,196.251c0,.251.293,0,.418.1a1.108,1.108,0,0,1,0-.585C84.946,195.75,84.946,196.063,84.8,196.251Z"
          transform="translate(38.519 -156.95)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M140.745,228.041c.251.23.648.4.5,0a.439.439,0,0,0-.5,0Z"
          transform="translate(-134.494 -121.843)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M140.429,227.822c.355.146,1.066.251,1.024-.146s-.293,0-.439-.355C141.056,227.613,140.743,227.717,140.429,227.822Z"
          transform="translate(-134.011 -122.543)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.264,230.32c-.481.5,1.024,1.066,1.233.293a2.327,2.327,0,0,0-.293.272C90.059,230.55,89.369,230.759,89.264,230.32Z"
          transform="translate(24.104 -119.272)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M87.949,231.552c-.125.251.334.209.439.335C88.493,231.594,88.263,231.531,87.949,231.552Z"
          transform="translate(28.785 -117.932)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M139.806,229.76c.125.23.251.251.355.376s-.355-.773.167-.585c.146-.188-.293,0-.376-.251S139.952,229.7,139.806,229.76Z"
          transform="translate(-131.611 -120.447)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M141.477,228.387c.4.4.46-.209.585-.355a.94.94,0,0,1-.272-.293c.46.146,0-.314.209-.481s.188.188.293.146.272-.209,0-.523c-.251.314-.23.272-.732.293l.23.314a.293.293,0,0,0-.293.251c-.146-.293-.167-.418-.439-.5s.376,0,0-.4a5.1,5.1,0,0,0-.209.5c.418,0,.376.272.564.69,0-.251.188,0,.376-.146C142.125,228.262,141.707,228.282,141.477,228.387Z"
          transform="translate(-135.874 -123.067)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M139.6,229.944c0-.209,0-.314.355-.272-.125-.188-.125-.293,0-.376S139.345,229.609,139.6,229.944Z"
          transform="translate(-130.669 -120.401)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M140.53,227.512c.481.272.251-.355.146-.585s.251.209.376,0C140.384,226.4,140.739,227.261,140.53,227.512Z"
          transform="translate(-133.82 -123.153)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.7,202.69c-.481-.188-.732,0-1.233-.23,0,.146,0,.251.251.23a.606.606,0,0,0,0,.355c.439-.376.815-.146,1.254,0C86.787,203.045,86.181,202.669,86.7,202.69Z"
          transform="translate(35.362 -149.654)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M85.006,202.931c-.125.293.334-.125.23-.481a.313.313,0,0,1-.334.125C84.8,202.743,85.173,202.868,85.006,202.931Z"
          transform="translate(38.314 -149.665)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M138.637,231.206h.146l.146.209c-.23-.523.878-.355.293-1.045-.188,0,0,.209,0,.376C139.035,231.018,138.784,231.06,138.637,231.206Z"
          transform="translate(-128.206 -119.217)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M86.949,202.738c.146,0,.146-.167.293-.146s-.251-.314-.418-.209,0-.146,0-.272-.188,0-.355,0,0-.146.146-.146-.167,0-.272-.293,0,.314,0,.355,0,.209-.23.209.125,0,.1.23C86.656,202.445,86.782,202.445,86.949,202.738Z"
          transform="translate(33.883 -150.6)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M91.331,198.848h0v.167c0,.167.167-.167.272-.23S91.331,198.973,91.331,198.848Z"
          transform="translate(18.462 -153.672)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.079,199.574a.211.211,0,0,0,.251.209,1.6,1.6,0,0,1,.335,0c.1,0,.125.251,0,.23s.732,0,.962-.335c0,.355.272-.125.355.209v-.209a4.167,4.167,0,0,1,.523,0c.146,0,0-.418,0-.209,0-.23-.209-.125-.23.1a.543.543,0,0,0-.4,0v-.209c0-.209-.209.146-.418,0,.125.564-.523,0-.69.439C90.643,199.574,90.413,199.679,90.079,199.574Z"
          transform="translate(20.133 -153.102)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.3,202.191a.209.209,0,0,0,.251.1v.272c0,.125.314.125.46.209-.125-.314.773-.209.794-.648a.146.146,0,0,0,.167,0,.355.355,0,0,1,.314-.272c.167-.376.585-.481.669-.794h-.251c-.69-.146-1.777.606-2.341,0,.125,0,.251.251.5.146-.188-.146-.23-.4-.523-.523s.251,0,.188-.167-.125,0-.272,0c.188.355-.355.314-.481.272s.125,0,.1.209.23-.125.334,0-.418.146-.711.23c.293,0,.585.564.92.732s.355,0,.4.23,0-.188.167-.167,0,.293.293.209a.481.481,0,0,1,0,.209c0,.251-.5,0-.4.23s0-.1-.125-.1.167,0,0-.167-.188,0-.146.167S90.534,202.17,90.3,202.191Z"
          transform="translate(20.932 -151.852)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.93,199.375c0,.125,0,0,.188,0s0-.146,0-.272S91.055,199.354,90.93,199.375Z"
          transform="translate(19.721 -153.342)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.06,199.338a1.422,1.422,0,0,0,.481-.1S90.06,199.129,90.06,199.338Z"
          transform="translate(22.201 -153.2)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M90.568,200.46c0-.481-.439,0-.648.1a1.045,1.045,0,0,0,.648-.1Z"
          transform="translate(22.466 -152.064)"
          fill="#65b1db"
          fillRule="evenodd"
        />
        <path
          d="M89.331,201.693c0-.146-.167,0-.167-.355,0,.251-.355.146-.334.5-.272-.125-.46-.355-.439-.92a1,1,0,0,0-1.484.439c0,.878.146,1.505.544,1.61s1.212-.815,1.672-.418c.167-.125.669-.146.878-.355s0-.167,0-.439-.5,0-.523-.272A.293.293,0,0,0,89.331,201.693Z"
          transform="translate(29.243 -151.521)"
          fill="#65b1db"
          fillRule="evenodd"
        />
      </g>
      <path
        d="M121.031,211.168c-2.969,10.1,0,19.337,6.606,20.633s14.4-5.833,17.372-15.93,0-19.337-6.606-20.633S124,201.175,121.031,211.168Z"
        transform="translate(-95.316 -157.693)"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M126.612,219.707a6.041,6.041,0,1,0,1.909-4.247A6,6,0,0,0,126.612,219.707Z"
        transform="translate(-101.837 -137.257)"
        fill="#fff"
        fillRule="evenodd"
      />
    </g>
    <g transform="translate(80.163 119.691)">
      <ellipse
        cx={21.801}
        cy={28.887}
        rx={21.801}
        ry={28.887}
        transform="translate(373.966 218.918) rotate(-171.951)"
        fill="#ae41e2"
      />
      <ellipse
        cx={27.574}
        cy={36.542}
        rx={27.574}
        ry={36.542}
        transform="matrix(-0.99, -0.144, 0.144, -0.99, 364.858, 224.222)"
        fill="#ff884a"
      />
      <path
        d="M157.51,101.82l37.42,10.139-8.968,72.394s-33.887-.5-41.392-1.171Z"
        transform="translate(154.269 36.102)"
        fill="#ff884a"
        fillRule="evenodd"
      />
      <ellipse
        cx={31.504}
        cy={41.747}
        rx={31.504}
        ry={41.747}
        transform="matrix(-0.99, -0.144, 0.144, -0.99, 327.889, 224.071)"
        fill="#d5845a"
      />
      <path
        d="M155.36,284.057l100.951-31.211,58.534-18.125c20.4-6.272,33.448-57.092,10.913-74.861l-50.653-40.493L189.394,50.86Z"
        transform="translate(-10.948 -19.471)"
        fill="url(#e)"
      />
      <ellipse
        cx={79.439}
        cy={132.622}
        rx={79.439}
        ry={132.622}
        transform="matrix(-0.99, -0.144, 0.144, -0.99, 192.723, 286.501)"
        fill="#ff884a"
      />
      <ellipse
        cx={79.439}
        cy={132.622}
        rx={79.439}
        ry={132.622}
        transform="matrix(-0.99, -0.144, 0.144, -0.99, 185.144, 285.38)"
        fill="#cc6f3f"
      />
      <path
        d="M217.787,187.9c1.693,48.249,22.87,86.234,53.831,90.749,18.167,2.655,36.417-6.752,51.489-24.25a124.827,124.827,0,0,0,16.41-24.522h0a178.553,178.553,0,0,0,17.456-55.775c.46-3.219.836-6.439,1.15-9.658,0-.732.146-1.463.188-2.216.167-1.9.314-3.8.418-5.686,1.526-28.7-3.637-54.9-13.735-74.255C335.5,64.1,321.665,51.979,305.024,49.554c-28.494-4.181-57.134,21.261-73.5,61h0a183.506,183.506,0,0,0-11.77,43.524,185.512,185.512,0,0,0-1.965,33.825Z"
        transform="translate(-162.64 -21.384)"
        fill="url(#f)"
      />
      <path
        d="M217.761,170.3c1.108,31.985,13.421,58.1,32.048,66.9a124.823,124.823,0,0,0,16.411-24.626h0A178.554,178.554,0,0,0,283.675,156.8c.46-3.219.836-6.439,1.15-9.658,0-.732.146-1.463.188-2.216.167-1.9.314-3.8.418-5.686,1.526-28.7-3.638-54.9-13.735-74.255-17.247,4.181-33.344,21.637-43.4,45.991h0a140.857,140.857,0,0,0-9.031,33.448,142.7,142.7,0,0,0-1.505,25.881Z"
        transform="translate(-89.341 -4.073)"
        fill="#a4a0e5"
        opacity={0.3}
      />
      <path
        d="M219.725,154.09a187.5,187.5,0,0,0-1.9,33.825c30.333-17.895,67.628-31.818,90.059-10.745,35.539,33.281,31.88,51.3,31.609,52.723a177.693,177.693,0,0,0,17.477-55.8c.46-3.219.836-6.439,1.15-9.658a19.4,19.4,0,0,1,.209-2.216c.146-1.9.293-3.8.376-5.686,2.989-55.712-19.337-101.975-53.684-106.992-28.473-4.181-57.113,21.261-73.5,61h0A183.965,183.965,0,0,0,219.725,154.09Z"
        transform="translate(-162.674 -21.395)"
        fill="#a4a0e5"
        opacity={0.3}
      />
      <path
        d="M227.939,86.81l73.168,5.31a184.78,184.78,0,0,1-13.776,85.565l-69.593-20.905Z"
        transform="translate(-105.414 19.733)"
        fill="url(#g)"
      />
      <ellipse
        cx={21.177}
        cy={35.351}
        rx={21.177}
        ry={35.351}
        transform="matrix(-0.99, -0.144, 0.144, -0.99, 133.388, 179.543)"
        fill="url(#h)"
      />
      <ellipse
        cx={16.115}
        cy={26.901}
        rx={16.115}
        ry={26.901}
        transform="translate(126.689 172.221) rotate(-171.951)"
        fill="url(#i)"
      />
      <g transform="translate(0 73.327)">
        <path
          d="M242.621,71.44a.46.46,0,0,0,0,.564C243.081,71.649,242.768,71.565,242.621,71.44Z"
          transform="translate(-98.439 -70.355)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M278.79,138.72v.146c0,.314.314.46.585.5A2.426,2.426,0,0,1,278.79,138.72Z"
          transform="translate(-210.765 3.015)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M278.638,138.35v.627C278.888,138.58,278.784,138.517,278.638,138.35Z"
          transform="translate(-209.86 2.611)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M278.833,139.04h-.167c-.063,0,.1.314,0,.523s.146-.125,0-.146l.355-.355a1.317,1.317,0,0,1-.167-.481.689.689,0,0,0-.335.272C278.52,139.124,278.9,138.81,278.833,139.04Z"
          transform="translate(-209.846 2.862)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M278.664,139.15c-.167.293.1.23.146.355s-.334.334.146.125C278.936,139.338,278.747,139.38,278.664,139.15Z"
          transform="translate(-209.97 3.484)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M278.08,139.61a4.19,4.19,0,0,0,.293.481.564.564,0,0,0-.293-.481Z"
          transform="translate(-208.278 3.985)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M277.559,139.775c.167,0,0,0,.167.146C277.977,139.691,277.726,139.482,277.559,139.775Z"
          transform="translate(-206.649 4.009)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M276.97,139.322c0,.167,0,0,.167.251C277.368,139.364,277.054,139.3,276.97,139.322Z"
          transform="translate(-204.806 3.667)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.749,136.86l-.125.146c0,.167.167.125.251.209S279.875,136.944,279.749,136.86Z"
          transform="translate(-213.041 0.986)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.824,138.141v.188c0,.042,0,0,.1,0S279.866,138.12,279.824,138.141Z"
          transform="translate(-213.513 2.382)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.834,138.331c0,.125.1,0,.146.23.167-.167,0-.23,0-.293S279.855,138.352,279.834,138.331Z"
          transform="translate(-213.628 2.505)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M272.195,140.19a3.9,3.9,0,0,0,.188.878s.209-.146.293-.1a.275.275,0,0,1,0-.46C272.466,140.483,272.529,140.336,272.195,140.19Z"
          transform="translate(-190.288 4.618)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M277.749,137c-.23.251.188.146.167.376s-.251,0-.293,0c-.272.4.439,0,0,.481a.355.355,0,0,1,.251.146c.188-.167,0-.167,0-.251.272-.1,0-.251.355-.376A.585.585,0,0,1,277.749,137Z"
          transform="translate(-207.048 1.139)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M277.82,139.13c0,.209,0,.146-.251.293s0,.125,0,.146S278.008,139.193,277.82,139.13Z"
          transform="translate(-206.471 3.462)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.69,71.246c0,.314,0,.606.46,0C244.045,71.058,243.794,71.476,243.69,71.246Z"
          transform="translate(-102.162 -70.621)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.492,73c-.335.355.334.711.523.627s-.209.5-.355.794a5.058,5.058,0,0,0,1.087-.711c0-.272-.627-.648-.711-.251C243.973,73.334,243.889,73,243.492,73Z"
          transform="translate(-102.153 -68.654)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M276.053,139c0,.167.188.188.272.146S276.116,139.167,276.053,139Z"
          transform="translate(-202.007 3.32)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.231,138.76a2.465,2.465,0,0,0,0,.418C275.524,138.906,275.356,138.823,275.231,138.76Z"
          transform="translate(-199.324 3.058)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.268,126.69h-.418c.1,0,0,.439.188.376S240.226,126.857,240.268,126.69Z"
          transform="translate(-90.253 -10.104)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M294.874,92.34a.564.564,0,0,0-.481.481c.4,0,.5.209.815,0S294.769,92.654,294.874,92.34Z"
          transform="translate(-259.293 -47.563)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M291.862,91.11c0,.188-.188.606.355.523C292.112,91.4,291.862,91.34,291.862,91.11Z"
          transform="translate(-250.867 -48.905)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.083,142.14c-.209.355-.418.69-.23.9.251-.293.544-.585.564-.794A.314.314,0,0,1,275.083,142.14Z"
          transform="translate(-198.424 6.744)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.479,125.9c-.585.836.439,1.9,1.672,2.09C241.922,127.363,240.563,126.9,240.479,125.9Z"
          transform="translate(-93.098 -10.966)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.062,124.34c-.544.167,0,.314,0,.648s-.439,0-.439-.188c-.376.293.376.4.627.481s.23-.272.669-.272C241.752,124.654,241.313,124.57,241.062,124.34Z"
          transform="translate(-93.305 -12.667)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.979,121.5c-.439,0-.544.523,0,.4Z"
          transform="translate(-101.825 -15.764)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M278.88,122.44c-.293,0,0,.5,0,.564A1.1,1.1,0,0,1,278.88,122.44Z"
          transform="translate(-210.186 -14.739)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.766,122.22a.544.544,0,0,0-.209.585C244.08,122.805,243.683,122.408,243.766,122.22Z"
          transform="translate(-101.528 -14.979)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M244.569,122.864c-.314-.314-.481.606,0,.4C244.778,123.032,244.172,122.99,244.569,122.864Z"
          transform="translate(-103.836 -14.348)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M244.518,123.09c-.335,0-.4.753,0,.627C244.392,123.508,244.643,123.424,244.518,123.09Z"
          transform="translate(-103.721 -14.03)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M244.363,124.82c-.146,0,0,.167-.251.188.418,0,.334.146.46.334C244.97,125.2,244.531,124.862,244.363,124.82Z"
          transform="translate(-103.63 -12.143)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M274.252,122.71c-.544,0-.125.4-.209.585C274.5,123.316,274.252,122.919,274.252,122.71Z"
          transform="translate(-195.628 -14.444)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M294.544,96.181a.669.669,0,0,0,.4-.146c0-.5-1-.711-.606-1.275.314,0,0,.418.355.439s-.188-.293,0-.648h-.836c0,.69.167.564.188,1.422.46-.314.272.4.481.314-.564,0-.418.376,0,.481-.1.23-.4,0-.544.167.334.209,0,.92.523.711a.13.13,0,0,0,.146.125c.146,0-.627,0-.5.334.418-.334.4.125.585.23.753-.544,0-1.463.272-2.09A2.235,2.235,0,0,0,294.544,96.181Z"
          transform="translate(-257.96 -45.153)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M295.257,96.81c-.314.125-.293.564-.188.753A1.078,1.078,0,0,0,295.257,96.81Z"
          transform="translate(-260.534 -42.689)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M270.553,121.92c-.251.188,0,.564-.46.648C270.427,122.735,270.845,122.087,270.553,121.92Z"
          transform="translate(-183.859 -15.306)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M244.137,91.54a.355.355,0,0,0-.23.355h.544c.251,0,0-.167,0-.23S244.116,91.77,244.137,91.54Z"
          transform="translate(-103.028 -48.436)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.632,96.43c-.523,0-.125.4-.188.606C243.82,96.953,243.632,96.66,243.632,96.43Z"
          transform="translate(-100.996 -43.103)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.021,119.43c-.188.481.5,1.045,1,1.275C280.3,120.308,279.23,119.953,279.021,119.43Z"
          transform="translate(-211.873 -18.021)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M293.82,95.16c-.774.188.564.376-.209.5h.564A4.909,4.909,0,0,1,293.82,95.16Z"
          transform="translate(-256.463 -44.488)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M280.069,119.74c-.314,0,0,.272,0,.4a1.8,1.8,0,0,1,.669-.1C280.592,119.865,280.174,119.907,280.069,119.74Z"
          transform="translate(-214.511 -17.683)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M281.094,120c-.334.125-.627.418-.23.439a.355.355,0,0,0,.23-.439Z"
          transform="translate(-216.435 -17.4)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M280.37,120.33c-.293.272-.606.878-.146.983s0-.293.523-.23C280.411,121,280.39,120.664,280.37,120.33Z"
          transform="translate(-214.644 -17.04)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M280.114,121.36c-.753,0-.439,1.526.439,1.129h-.418C280.365,122.133,279.758,121.757,280.114,121.36Z"
          transform="translate(-213.824 -15.917)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M280.189,123.46c-.293,0,0,.4,0,.564C280.5,123.9,280.377,123.669,280.189,123.46Z"
          transform="translate(-214.421 -13.626)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M274.823,132.88c-.146.272-.167.5,0,.627C275.094,133.131,274.885,133.131,274.823,132.88Z"
          transform="translate(-197.808 -3.354)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M272.8,134.53c-.251.46.544.4.773.669.251-.251-.418-.188,0-.523C273.114,134.927,273.114,134.655,272.8,134.53Z"
          transform="translate(-192.399 -1.554)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M294.014,95.49a1.525,1.525,0,0,0-.544.167c.1.167.962,0,.606.376.167.23.209-.188.4-.209C294.306,95.72,294.014,95.72,294.014,95.49Z"
          transform="translate(-256.552 -44.128)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.434,120.109c-.544.188,0,.5.23.669a1.422,1.422,0,0,1,.418-.125c-.293.355.335.146.46.4s-.251,0-.251.188.167.334.585.23c-.272-.355-.23-.314-.1-.773h-.4a.4.4,0,0,0-.188-.376c.355,0,.481,0,.669-.167s0,.355.439.167l-.481-.418c-.125.376-.439.23-.941.23.272,0,0,.188,0,.4C279.392,120.673,279.5,120.318,279.434,120.109Z"
          transform="translate(-213.541 -17.509)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M293.615,95.765c.188.167.335.188.188.439h.439C294.3,96.2,294.075,95.681,293.615,95.765Z"
          transform="translate(-256.634 -43.838)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.527,120.65c-.46.314.335.355.606.376,0,0-.293.125-.23.272C280.8,120.984,279.653,120.9,279.527,120.65Z"
          transform="translate(-212.882 -16.691)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.119,103.67c-.209.376-.4.711-.188.92a2.781,2.781,0,0,0,.523-.836A.293.293,0,0,1,254.119,103.67Z"
          transform="translate(-133.685 -35.208)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M277.786,134.5a.418.418,0,0,0,0,.418c.167-.188.272-.167.418-.376l-.167-.146C278.016,134.651,277.932,134.693,277.786,134.5Z"
          transform="translate(-207.377 -1.696)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M277.075,125.85c-.46,0-.564.523,0,.376Z"
          transform="translate(-204.074 -11.02)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M273.931,132.76c-.167.251.272.418.418.355C274.516,132.718,274.015,132.969,273.931,132.76Z"
          transform="translate(-195.537 -3.485)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M271.837,130.92a.523.523,0,0,0,.23.564C272.485,131.087,271.837,131.087,271.837,130.92Z"
          transform="translate(-189.032 -5.491)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M272.144,131.85c-.439,0,0,.773.293.251S271.935,132.164,272.144,131.85Z"
          transform="translate(-189.611 -4.477)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M271.772,132c-.251.251.209.836.481.418C271.961,132.439,272.086,132.23,271.772,132Z"
          transform="translate(-188.821 -4.313)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.546,138.15a.481.481,0,0,0-.209.5C246.776,138.61,246.776,138.234,246.546,138.15Z"
          transform="translate(-110.224 2.393)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M250.076,138.78c-.146,0,0,.167-.251.188.418,0,.335.146.46.335C250.661,139.114,250.264,138.822,250.076,138.78Z"
          transform="translate(-121.28 3.08)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M274.114,137.63c-.376.355.167.376.251.585C274.595,137.9,274.26,137.8,274.114,137.63Z"
          transform="translate(-195.782 1.826)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.244,144.711a2.926,2.926,0,0,1,.4-.146c0-.5-1-.711-.585-1.254.314,0,0,.418.335.418s-.188-.293,0-.648h-.836c0,.669.167.544.209,1.4.439-.314.251.4.46.335-.543,0-.418.355,0,.46,0,.23-.376,0-.543.167.355.209,0,.92.523.711a.143.143,0,0,0,.146.146c.146,0-.606,0-.5.314.418-.334.4.125.585.23.753-.543,0-1.463.272-2.09a2.238,2.238,0,0,0-.46-.042Z"
          transform="translate(-127.232 7.769)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M271.366,132.39c-.146.293.167.606.355.669C271.763,132.787,271.45,132.62,271.366,132.39Z"
          transform="translate(-187.515 -3.888)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M271.651,134.507c0,.314.4.418,0,.794C272.09,135.2,271.985,134.381,271.651,134.507Z"
          transform="translate(-188.406 -1.594)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.68,138.83c.188.481,1.066.439,1.61.293C281.227,138.642,280.2,139.06,279.68,138.83Z"
          transform="translate(-214.54 3.135)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.2,76.37c-.46.648.648,0,.167.523.125,0,.272-.23.418-.376Z"
          transform="translate(-100.548 -64.979)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M280.552,139.65c-.188.23.188.23.209.355a1.569,1.569,0,0,1,.46-.523C281.012,139.441,280.762,139.692,280.552,139.65Z"
          transform="translate(-216.186 3.841)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M280.984,140.06c-.167.334-.209.753.125.481A.355.355,0,0,0,280.984,140.06Z"
          transform="translate(-216.868 4.476)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.99,140c0,.376.125,1.045.544.815,0-.314-.188-.209.23-.523C280.471,140.376,280.22,140.146,279.99,140Z"
          transform="translate(-214.661 4.411)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.111,140.54c-.585.481.69,1.442,1.066.564a3.07,3.07,0,0,0-.334.272C279.8,140.958,279.09,141.084,279.111,140.54Z"
          transform="translate(-211.922 4.999)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M278.109,142.28c-.167.272.251.314.334.481C278.674,142.426,278.381,142.322,278.109,142.28Z"
          transform="translate(-208.412 6.897)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M276.433,144.26c-.146.272-.167.523,0,.648C276.7,144.615,276.5,144.469,276.433,144.26Z"
          transform="translate(-202.784 9.056)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M250.141,124.88c-.5.188.146.669.125,1.024.376,0-.188-.418.376-.376C250.12,125.4,250.329,125.235,250.141,124.88Z"
          transform="translate(-121.742 -12.078)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.152,76.623c-.209.209-.188.314-.293.481s.732-.648.711-.125c.272,0,0-.293.146-.418C243.528,76.6,243.4,76.79,243.152,76.623Z"
          transform="translate(-99.972 -64.772)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.621,140.153c-.293.523.4.314.627.355a.921.921,0,0,1,.209-.355c0,.439.355,0,.627,0s-.146.251,0,.314.334.146.585-.209c-.46,0-.376,0-.585-.523l-.272.314a.376.376,0,0,0-.4-.146c.272-.251.4-.314.4-.585s.209.293.439-.167h-.627c.146.355-.188.439-.564.794.293-.1.167.1.314.251C280,140.655,279.83,140.3,279.621,140.153Z"
          transform="translate(-214.648 3.484)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.715,77.018c.251,0,.376,0,.439.209.146-.188.272-.251.376-.209S242.945,76.662,242.715,77.018Z"
          transform="translate(-99.515 -64.435)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.436,139.978c-.146.523.5,0,.711-.125,0,.125-.146.293,0,.376C280.627,139.372,279.708,140.188,279.436,139.978Z"
          transform="translate(-212.978 4.223)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M276.484,133.57c.1.251.564.23.836.167S276.756,133.7,276.484,133.57Z"
          transform="translate(-203.944 -2.601)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M277.957,134.85c-.251.335.335,0,0,.272s.146-.125.209-.188Z"
          transform="translate(-207.57 -1.205)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M276.94,134.044c0,.125.1.125.125.188a.67.67,0,0,1,.209-.272A1.493,1.493,0,0,1,276.94,134.044Z"
          transform="translate(-204.796 -2.176)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M277.137,134.21v.251A.188.188,0,0,0,277.137,134.21Z"
          transform="translate(-205.119 -1.903)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M276.67,134.16c0,.188,0,.544.272.418s0-.1.125-.272C276.92,134.39,276.8,134.285,276.67,134.16Z"
          transform="translate(-204.024 -1.958)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M276.215,134.46c-.293.251.355.753.544.293,0,0-.1,0-.167.146S276.194,134.732,276.215,134.46Z"
          transform="translate(-202.608 -1.631)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M277.856,135a.649.649,0,0,0-.146.251s.355-.334.355,0v-.209C278.065,134.981,277.94,135,277.856,135Z"
          transform="translate(-207.197 -1.044)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M276.535,134.2c-.146.272.209.167.334.188a.418.418,0,0,1,.1-.188c0,.23.188,0,.314,0s0,.146,0,.167.167,0,.293-.1c-.23,0-.188,0-.293-.272l-.146.167a.188.188,0,0,0-.209,0c.146-.125.209-.146.209-.293s.1.146.23,0h-.335c0,.188,0,.23-.272.418.146,0,0,0,.146.125S276.744,134.2,276.535,134.2Z"
          transform="translate(-204.182 -2.329)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M277.6,135.221h.23c.042,0,.146-.125.209-.125A.5.5,0,0,0,277.6,135.221Z"
          transform="translate(-206.937 -0.949)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M276.37,134.164c0,.272.251,0,.376,0s0,.146,0,.188C276.976,133.893,276.516,134.206,276.37,134.164Z"
          transform="translate(-203.139 -2.025)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M278.638,122.58c-.188.5.5,1.045,1,1.275C279.934,123.458,278.868,123.1,278.638,122.58Z"
          transform="translate(-210.696 -14.586)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M250.41,142.74c-.773.188.564.376-.209.523h.564A2.53,2.53,0,0,1,250.41,142.74Z"
          transform="translate(-122.304 7.399)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.67,122.89c-.293,0,0,.293,0,.418a1.3,1.3,0,0,1,.69,0C280.151,123.12,279.8,123.12,279.67,122.89Z"
          transform="translate(-213.317 -14.248)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M280.7,123.19c-.334.125-.627.418-.209.418a.314.314,0,0,0,.209-.418Z"
          transform="translate(-215.251 -13.921)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.984,123.49c-.272.272-.606.857-.146.983s0-.293.523-.251C280.026,124.138,280.005,123.824,279.984,123.49Z"
          transform="translate(-213.464 -13.594)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.735,124.52c-.752,0-.439,1.526.418,1.129h-.418C279.965,125.273,279.359,124.9,279.735,124.52Z"
          transform="translate(-212.63 -12.471)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M281.487,121.53c-.293,0,0,.4,0,.564C281.759,121.969,281.675,121.739,281.487,121.53Z"
          transform="translate(-218.416 -15.731)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M277.064,132.28c-.146.272-.167.5,0,.627C277.315,132.614,277.064,132.489,277.064,132.28Z"
          transform="translate(-204.712 -4.008)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.04,133.94c-.23.46.564.376.773.669.272-.272-.418-.188,0-.523C275.375,134.337,275.437,134.065,275.04,133.94Z"
          transform="translate(-199.343 -2.198)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M250.6,143.08a1.526,1.526,0,0,0-.544.167c0,.167.962,0,.606.376.167.23.209-.209.4-.209C250.9,143.31,250.6,143.31,250.6,143.08Z"
          transform="translate(-122.392 7.769)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.043,123.228c-.564.188.1.5.23.69a.878.878,0,0,1,.4-.146c-.293.355.334.167.481.418s-.272,0-.251.188.167.335.585.23c-.293-.376-.23-.334-.1-.774h-.418a.355.355,0,0,0-.188-.376c.355,0,.5,0,.669-.188s0,.376.439.167l-.46-.4c-.146.376-.439.209-.962.23.293,0,0,.188,0,.376C278.98,123.855,279.085,123.479,279.043,123.228Z"
          transform="translate(-212.335 -14.084)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M250.205,143.343c.188.188.334.209.188.46h.439C250.895,143.8,250.665,143.28,250.205,143.343Z"
          transform="translate(-122.475 8.05)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.143,123.8c-.46.314.314.376.606.4,0,0-.314.125-.251.272C280.419,124.218,279.269,124.072,279.143,123.8Z"
          transform="translate(-211.683 -13.256)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.144,142.55c-.69.335-.355.753-.125,1.213C247.624,143.491,246.893,142.947,247.144,142.55Z"
          transform="translate(-111.637 7.191)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M271.543,142.456c0,.188-.209.4,0,.523s.335-.439.627-.753C271.836,141.954,271.815,142.581,271.543,142.456Z"
          transform="translate(-188.215 6.763)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.7,143.85c-.188.251.251.293.314.46C270.307,143.871,269.847,144.038,269.7,143.85Z"
          transform="translate(-182.401 8.609)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.259,145.74c-.209,0-.46.669,0,.544Z"
          transform="translate(-109.101 10.67)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.481,127.28c-.481,0,0,.648.355.523C248.878,127.489,248.5,127.531,248.481,127.28Z"
          transform="translate(-116.444 -9.461)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.192,146.16c-.46.711-.209.648-.188,1.547C246.569,147.372,246.339,146.724,246.192,146.16Z"
          transform="translate(-109.034 11.128)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M245.77,147.37c.627.209.376.711.46,1.212C246.585,148.248,246.857,147.349,245.77,147.37Z"
          transform="translate(-108.904 12.448)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M245.015,128.547c-.23-.188-.523.188-.355.376C245.2,128.965,244.659,128.589,245.015,128.547Z"
          transform="translate(-104.95 -8.135)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.754,148.81a.481.481,0,0,0-.293.857C248.963,149.416,248.315,149.207,248.754,148.81Z"
          transform="translate(-116.362 14.018)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.787,148.67c-.125,0-.167.251-.251.125s.146.334.439.251a.334.334,0,0,0,0-.335A.136.136,0,0,1,246.787,148.67Z"
          transform="translate(-110.945 13.865)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.011,149.7c-.23-.209-.146.481,0,.481h.125l.125-.1C249.387,149.972,249.011,149.972,249.011,149.7Z"
          transform="translate(-118.124 14.946)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M244.389,130.52c-.272-.146,0,.585,0,.794C244.745,131.043,244.348,130.917,244.389,130.52Z"
          transform="translate(-103.761 -5.948)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.2,130.525v.314C241.786,130.818,241.724,130.379,241.2,130.525Z"
          transform="translate(-94.426 -5.954)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.58,131.28c-.732.272.272.5.125.857C241.956,131.97,241.789,131.385,241.58,131.28Z"
          transform="translate(-94.867 -5.099)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.337,124.14a.5.5,0,0,0-.188.753C254.442,124.7,254.232,124.37,254.337,124.14Z"
          transform="translate(-134.007 -12.885)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M282.862,125.42c-.23.4.544.439.606.878C283.614,125.9,283.008,125.483,282.862,125.42Z"
          transform="translate(-223.303 -11.489)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M281.854,125.63c-.272.418.1.606.314.836.272-.23-.251-.523.188-.585S281.979,125.818,281.854,125.63Z"
          transform="translate(-220.079 -11.26)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M281.852,126.594c.293-.125.146-.23.293-.481-.188,0-.314-.272-.564,0a.314.314,0,0,1,.272,0C281.852,126.343,281.705,126.531,281.852,126.594Z"
          transform="translate(-219.366 -10.865)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M281.452,126.345c0,.46.669,0,.606.523C282.393,126.3,281.808,126.136,281.452,126.345Z"
          transform="translate(-219.113 -10.575)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M274.328,136c-.4.418.167.4.293.251S274.349,136.272,274.328,136Z"
          transform="translate(-196.414 0.049)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.2,138.4c-.544,0-.23.564.188.376A.355.355,0,0,1,249.2,138.4Z"
          transform="translate(-118.313 2.666)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M271.481,135.23c0,.23,0,.564.334.46S271.565,135.4,271.481,135.23Z"
          transform="translate(-188.028 -0.791)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M244.706,140.86c-.251,1.129.376,2.091.585,3.261C246.546,142.8,244.435,142.01,244.706,140.86Z"
          transform="translate(-105.708 5.348)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M272.99,140.37c-.314.334,0,.773.293.272Z"
          transform="translate(-192.276 4.814)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M272.356,140.89a.565.565,0,0,0,.23.585C272.983,141.12,272.419,141.12,272.356,140.89Z"
          transform="translate(-190.638 5.381)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M272.661,141.83c-.439,0,0,.753.314.251S272.452,142.185,272.661,141.83Z"
          transform="translate(-191.236 6.406)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.439,142.607c.4,0,.251-.564,0-.648s-.188.167-.4.23C247.4,142.063,247.586,142.21,247.439,142.607Z"
          transform="translate(-112.706 6.528)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.075,146.92c0,.125-.5.773.1.711C245.929,147.317,246.1,147.275,246.075,146.92Z"
          transform="translate(-108.77 11.957)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M244.291,139.6c-.167.1.355.857,0,1.129C244.793,140.854,244.5,139.746,244.291,139.6Z"
          transform="translate(-103.767 3.974)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.471,137.48c0,.23-.125.5.125.627.23-.293.376,0,.606-.4C241.827,137.814,241.639,137.856,241.471,137.48Z"
          transform="translate(-95.512 1.663)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M274.741,123.907c0-.293-.523,0-.481-.4-.314,0,0,.5.314.335,0,.125-.146.188-.376.23C274.406,124.346,274.615,124.074,274.741,123.907Z"
          transform="translate(-196.388 -13.572)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M271.515,132.81a.92.92,0,0,0-.188.753C272.455,134.023,271.2,133.563,271.515,132.81Z"
          transform="translate(-187.56 -3.43)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M272.51,134.41c-.125,0,0,.167-.272.334a6.3,6.3,0,0,1,.857.188C273.284,134.452,272.322,134.954,272.51,134.41Z"
          transform="translate(-190.813 -1.685)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M274.819,137.59c-.272.209.585.209.753.46C275.655,137.569,275.091,137.736,274.819,137.59Z"
          transform="translate(-198.557 1.782)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M271.441,134.445c-.1.251.481,0,.627.355.272-.272-.4-.209-.125-.481C271.775,134.362,271.587,134.508,271.441,134.445Z"
          transform="translate(-188.134 -1.783)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.111,141.64a1.087,1.087,0,0,0,.1.857.857.857,0,0,0-.1-.857Z"
          transform="translate(-115.447 6.199)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M270.51,136.69c.188.481,1.066.439,1.61.272C272.057,136.5,271.1,136.92,270.51,136.69Z"
          transform="translate(-186.2 0.801)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M273.113,139.15c-.46.669.669,0,.167.523.125,0,.272-.209.418-.376Z"
          transform="translate(-192.983 3.484)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M271.393,137.51c-.209.23.167.23.209.355a1.505,1.505,0,0,1,.439-.523C271.832,137.3,271.581,137.552,271.393,137.51Z"
          transform="translate(-187.835 1.507)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M271.8,137.92c-.146.334-.188.732.125.481A.355.355,0,0,0,271.8,137.92Z"
          transform="translate(-188.513 2.142)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M270.82,137.81c0,.4.125,1.066.544.836,0-.314-.188-.209.23-.523C271.217,138.207,271.071,138.061,270.82,137.81Z"
          transform="translate(-186.321 2.022)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.934,138.4c-.564.481.69,1.442,1.066.564a2,2,0,0,0-.314.272C270.561,138.818,269.913,138.944,269.934,138.4Z"
          transform="translate(-183.575 2.666)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M273.029,139.37c-.23.209-.209.334-.293.481s.711-.648.711,0c.272,0,0-.293.146-.418C273.342,139.349,273.029,139.558,273.029,139.37Z"
          transform="translate(-192.314 3.724)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M270.45,138.024c-.293.523.418.314.627.355a1.151,1.151,0,0,1,.209-.376c0,.46.355,0,.627,0s-.146.251,0,.314.355.146.585-.209c-.439-.1-.376-.1-.585-.523l-.272.314a.356.356,0,0,0-.376-.146c.251-.251.376-.314.376-.585s.23.293.439-.167h-.627c.146.355-.167.439-.544.794.272,0,.146.1.293.251C270.826,138.526,270.659,138.171,270.45,138.024Z"
          transform="translate(-186.306 1.139)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M272.593,139.813c.272,0,.376,0,.439.188.146-.167.272-.23.4-.209S272.844,139.457,272.593,139.813Z"
          transform="translate(-191.878 4.033)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M270.256,137.853c-.125.523.5,0,.732-.125s-.146.293,0,.376C271.447,137.247,270.528,137.957,270.256,137.853Z"
          transform="translate(-184.628 1.875)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.25,148.577a1.833,1.833,0,0,0,1.38-1.087C253.149,147.323,252.835,148.305,252.25,148.577Z"
          transform="translate(-129.537 12.558)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.7,130.147c.272.648.376-.544.585.1.1,0,0-.314,0-.5Z"
          transform="translate(-93.087 -6.767)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.27,148.914c0,.251.335,0,.481,0a.94.94,0,0,1-.167-.564C252.4,148.475,252.458,148.789,252.27,148.914Z"
          transform="translate(-128.7 13.516)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.88,148.958c.188.272.544.481.523.125a.46.46,0,0,0-.523-.125Z"
          transform="translate(-127.536 14.142)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.29,148.668c.314.188,1.024.376,1.108,0-.23-.209-.334,0-.314-.418C252.022,148.522,251.645,148.584,251.29,148.668Z"
          transform="translate(-126.298 13.407)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M250.043,148.305c0,.648,1.756.1,1.233-.585v.376C250.88,148.034,250.5,148.619,250.043,148.305Z"
          transform="translate(-122.668 12.829)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.26,130.08c0,.251.167.293.23.439s0-.836.4-.585c.23-.188-.251-.146-.293-.314C240.511,129.787,240.532,130.059,240.26,130.08Z"
          transform="translate(-91.792 -6.909)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.78,149.291c.272.46.564-.167.773-.314a.69.69,0,0,1-.209-.314c.439.188.167-.334.418-.481s.146.209.251.188a.392.392,0,0,0,.209-.564c-.376.314-.355.251-.878.23l.1.334a.543.543,0,0,0-.4.23c0-.293,0-.418-.272-.544s.418,0,.167-.418c-.146.167-.272.334-.418.481.439,0,.272.355.335.794,0-.272.209,0,.439-.125C252.49,149.25,252.072,149.208,251.78,149.291Z"
          transform="translate(-127.666 12.742)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.042,130.338c.188-.209.188-.314.5-.251a.355.355,0,0,1,0-.4S239.938,129.941,240.042,130.338Z"
          transform="translate(-90.905 -6.833)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.16,148.36c.4.334.4-.355.4-.606,0,0,.167.251.334.167C251.453,147.211,251.453,148.214,251.16,148.36Z"
          transform="translate(-125.52 12.774)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.52,143.52c.188.481,1.066.439,1.61.293C277.067,143.332,276.126,143.75,275.52,143.52Z"
          transform="translate(-201.683 8.249)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.963,122.6c-.773.188.544.376-.209.5h.564a4.922,4.922,0,0,1-.355-.5Z"
          transform="translate(-130.187 -14.564)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M276.392,144.34c-.188.23.188.23.209.376a1.463,1.463,0,0,1,.46-.544C276.852,144.13,276.6,144.4,276.392,144.34Z"
          transform="translate(-203.329 8.956)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M276.825,144.76c-.167.314-.209.732.125.46a.334.334,0,0,0-.125-.46Z"
          transform="translate(-204.012 9.601)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.83,144.65c0,.376.125,1.045.544.836,0-.314-.188-.23.23-.544C276.248,145.11,276.06,144.88,275.83,144.65Z"
          transform="translate(-201.805 9.482)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M250.364,124c-.753,0-.439,1.526.439,1.129h-.418C250.677,124.773,250.008,124.4,250.364,124Z"
          transform="translate(-121.881 -13.038)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M250.447,126.11c-.293,0,0,.4,0,.564C250.719,126.528,250.635,126.319,250.447,126.11Z"
          transform="translate(-122.487 -10.737)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M277.663,126.09c-.146.272-.167.5,0,.627C277.934,126.424,277.725,126.278,277.663,126.09Z"
          transform="translate(-206.585 -10.758)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.714,134.69c-.23.481.564.355.794.648.251-.293-.418-.188,0-.544C231.048,135.087,231.09,134.795,230.714,134.69Z"
          transform="translate(-62.364 -1.38)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M253.174,122.93c-.293,0-.355.125-.544.167s.962,0,.606.376c.167.23.209-.188.4-.209C253.467,123.076,253.154,123.076,253.174,122.93Z"
          transform="translate(-130.294 -14.204)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.461,144.843c-.293.523.4.314.627.355a1.108,1.108,0,0,1,.209-.355c0,.46.355,0,.627,0s-.146.251,0,.314.334.146.585-.209c-.46,0-.376-.1-.585-.523l-.272.314a.376.376,0,0,0-.4-.146c.272-.251.4-.293.4-.585s.209.293.439-.167h-.627c.146.376-.188.46-.564.794.293-.1.167.125.314.251C275.837,145.345,275.67,145.011,275.461,144.843Z"
          transform="translate(-201.792 8.598)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.753,123.2c.209.167.334.188.209.439h.439C253.484,123.642,253.213,123.14,252.753,123.2Z"
          transform="translate(-130.374 -13.913)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.271,144.678c-.125.544.5,0,.711-.125,0,.125-.146.314,0,.376C276.463,144.093,275.543,144.783,275.271,144.678Z"
          transform="translate(-200.117 9.328)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.226,142.83c0,.23.481.209.732.125S279.456,142.935,279.226,142.83Z"
          transform="translate(-212.308 7.497)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.8,146.91c-.773.188.564.376-.209.5h.564A2.527,2.527,0,0,1,246.8,146.91Z"
          transform="translate(-111.147 11.946)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M244.159,146.69c-.314,0,0,.293,0,.418a1.463,1.463,0,0,1,.669,0C244.64,146.836,244.264,146.857,244.159,146.69Z"
          transform="translate(-103.531 11.706)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M245.184,147c-.335.125-.627.418-.23.439A.355.355,0,0,0,245.184,147Z"
          transform="translate(-105.454 12.044)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M244.454,147.29c-.272.272-.606.878-.146.983s0-.293.523-.23C244.5,147.959,244.475,147.624,244.454,147.29Z"
          transform="translate(-103.658 12.36)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M245.535,146.18c-.753,0-.439,1.526.418,1.129h-.418C245.765,146.933,245.159,146.556,245.535,146.18Z"
          transform="translate(-106.934 11.15)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M283.386,127c-.167.251.251.293.334.46C283.867,127.188,283.658,127,283.386,127Z"
          transform="translate(-224.685 -9.766)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.7,136.19c-.125.293-.146.523,0,.627C236.969,136.524,236.76,136.4,236.7,136.19Z"
          transform="translate(-80.013 0.256)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.633,137.78c-.23.46.564.355.794.627.251-.272-.418-.167,0-.523C235.031,138.24,235.073,137.884,234.633,137.78Z"
          transform="translate(-74.479 1.99)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M261.509,104.6c-.146.251.251.251.146.46a.564.564,0,0,1-.355,0c-.125.418.376.188.585-.251C261.613,104.814,261.781,104.479,261.509,104.6Z"
          transform="translate(-156.67 -34.219)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.014,147.24c-.293,0-.355.125-.544.167s.962,0,.606.4c.167.209.209-.209.4-.23C247.307,147.47,247.014,147.491,247.014,147.24Z"
          transform="translate(-111.257 12.306)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.259,143.392c-.125.23.188.146.293.167a.626.626,0,0,1,0-.167c0,.209.167,0,.293,0s0,.1,0,.146.146,0,.272-.1c-.209,0-.188,0-.272-.251l-.125.146a.188.188,0,0,0-.188,0c.125,0,.188-.146.188-.272s.1.146.188,0h-.272c0,.167,0,.209-.272.355.146,0,0,0,.146.125S279.343,143.455,279.259,143.392Z"
          transform="translate(-212.425 7.708)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.6,147.512c.188.167.314.188.188.439.23,0,.376,0,.439.1S247.055,147.449,246.6,147.512Z"
          transform="translate(-111.318 12.598)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.128,143.353c0,.251.23,0,.314,0s0,.125,0,.167C279.671,143.081,279.253,143.416,279.128,143.353Z"
          transform="translate(-211.604 7.998)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M262.409,104.255c-.125-.167-.648.125-.606.418S262.137,104.36,262.409,104.255Z"
          transform="translate(-158.28 -34.623)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M259.919,106.03c-.293.188.627,0,.564.439,0-.439.439-.314.669-.355.167-.481-.251-.335-.481-.544C260.755,106.051,260.191,105.988,259.919,106.03Z"
          transform="translate(-153.01 -33.136)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.073,71.937c-.146,0-.23-.46-.46,0,.439.188,0,.836-.376.941a.606.606,0,0,1-.146.439C242.592,73.107,242.7,72.459,243.073,71.937Z"
          transform="translate(-97.74 -70.036)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M280.845,138.132c.209-.376.732-.5.69-.711s-.4.46-.794.606c.125,0,0,.167,0,.314.564,0,.376.585.962.815,0-.23,0-.46-.23-.355.5-.314,0-.5.125-1l-.439.523Z"
          transform="translate(-217.168 1.554)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.517,76.375c.355-.627.5-.209.753-.272C243.416,75.623,242.1,75.957,242.517,76.375Z"
          transform="translate(-98.669 -65.527)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M278.6,138.83c0-.1-.544-.418-.23,0A.46.46,0,0,1,278.6,138.83Z"
          transform="translate(-208.9 2.905)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M278.906,139.262a.732.732,0,0,0-.335-.5c0,.23-.251.209-.376.335s.251.209.439,0,0,0-.167.293a.167.167,0,0,1,.209.188C278.8,139.638,278.613,139.429,278.906,139.262Z"
          transform="translate(-208.999 3.058)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M276.25,138.76a2.091,2.091,0,0,0,.585,1.61.3.3,0,0,1-.125.23c-.125.084.209,0,.335-.272.146.146,0,.251.293.167a.23.23,0,0,1,0,.251,5.54,5.54,0,0,1,1.756,1.84c0-.293.209,0,.4-.334a.23.23,0,0,0,0,.167c-.1.1-.167,0-.314.167.272,0,.293.167.648-.1s-.439.125,0-.293c-1.108-.92-1.777-1.38-3.052-2.425-.146,0,0-.188,0-.272s-.167-.167-.272,0a.293.293,0,0,1,0-.334C276.5,138.99,276.25,138.948,276.25,138.76Z"
          transform="translate(-205.988 3.058)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.016,137.6c-.1.146,0,.167,0,.293h-.146c0,.481.4.481.585.773.146-.355.857,0,.69.418s.167,0,.23,0,0,.293,0,.4a.543.543,0,0,1,.146-.209c0,.167.376,0,.564,0s0,.188.146.209.167-.167.251,0a.94.94,0,0,0-.125-.627c-.125-.146,0,.1,0,.188a.4.4,0,0,1-.481-.167.335.335,0,0,0-.188.251c0-.23-.376-.23-.188-.585a.334.334,0,0,1-.272,0c-.125.23,0,0,.167.146a.668.668,0,0,1,0,.188A13.658,13.658,0,0,1,279.016,137.6Z"
          transform="translate(-213.04 1.793)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.681,136.85h-.46c-.167,0,.4,0,.418.188S279.66,136.913,279.681,136.85Z"
          transform="translate(-211.906 0.976)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M273.545,140.743c-.209-.481-.481-.544-.732-1v.376a.921.921,0,0,0-.314.272c.606,0,.711.376.794.878C273.294,141.1,273.169,140.388,273.545,140.743Z"
          transform="translate(-191.785 4.127)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M272.022,140.856c-.334.188.334.1.544-.272a.314.314,0,0,1-.334-.1C272.064,140.605,272.252,140.961,272.022,140.856Z"
          transform="translate(-189.614 4.934)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M244.471,122.66c-.251.188-.335.523.335.355C244.869,122.765,244.409,122.932,244.471,122.66Z"
          transform="translate(-104.073 -14.499)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M276,143.92c0,.376.125.439.188.878,0,0,.209-.146.293-.1a.292.292,0,0,1,0-.481C276.276,144.213,276.339,144.087,276,143.92Z"
          transform="translate(-202.063 8.685)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.87,77.675c0,.314,0,.627.481,0C243.225,77.487,242.975,77.926,242.87,77.675Z"
          transform="translate(-99.649 -63.608)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.034,142.49c-.167.5.523,1.045,1.024,1.3C253.331,143.389,252.264,143.013,252.034,142.49Z"
          transform="translate(-128.506 7.126)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M272.073,140.26c-.46.648.669,0,.188.523.1,0,.272-.23.418-.376Z"
          transform="translate(-189.79 4.694)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M270.363,138.622c-.209.209.167.23.209.355a1.507,1.507,0,0,1,.439-.523C270.823,138.392,270.572,138.664,270.363,138.622Z"
          transform="translate(-184.652 2.715)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M270.787,139c-.167.314-.209.732.1.481A.376.376,0,0,0,270.787,139Z"
          transform="translate(-185.327 3.32)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.78,138.92c0,.376,0,1.045.544.836,0-.314-.209-.23.23-.523C270.24,139.38,270.094,139.087,269.78,138.92Z"
          transform="translate(-183.107 3.233)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M278.931,135c-.585.46.69,1.422,1.066.544,0,0-.209.167-.334.272C279.538,135.4,278.91,135.523,278.931,135Z"
          transform="translate(-211.366 -1.042)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M277.95,136.73c-.167.272.251.314.334.481C278.431,136.876,278.138,136.772,277.95,136.73Z"
          transform="translate(-207.876 0.845)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M272.02,140.53c-.209.209-.188.314-.293.481s.711-.648.711-.125c.272,0,0-.272.146-.418S272.187,140.718,272.02,140.53Z"
          transform="translate(-189.215 4.884)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.411,139.134c-.293.5.4.314.627.355a.711.711,0,0,1,.188-.376c0,.46.376-.1.627,0s-.125.23,0,.314.355.125.606-.23c-.46,0-.4,0-.606-.5l-.251.314a.355.355,0,0,0-.4-.146c.251-.251.4-.314.376-.585s.23.293.46-.167h-.627c.146.376-.188.46-.564.794.272,0,.167.125.293.272C269.767,139.636,269.6,139.281,269.411,139.134Z"
          transform="translate(-183.094 2.35)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M271.594,140.91c.272,0,.376,0,.46.209.146-.188.272-.23.376-.209S271.908,140.555,271.594,140.91Z"
          transform="translate(-188.789 5.236)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.547,143.72c-.46.293.335.355.606.376,0,0-.293.125-.23.272C253.823,144.054,252.673,143.971,252.547,143.72Z"
          transform="translate(-129.5 8.467)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M277.355,144.453c-.209-.46-.481-.523-.732-.983v.376a.879.879,0,0,0-.313.272c.627,0,.711.376.815.878C277.1,144.829,276.979,144.1,277.355,144.453Z"
          transform="translate(-203.56 8.195)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.832,144.532c-.335.188.334.1.544-.272a.314.314,0,0,1-.334,0C275.832,144.281,276.062,144.615,275.832,144.532Z"
          transform="translate(-201.389 9.056)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M281.019,119.89a6.769,6.769,0,0,0-.439.794h.293c.042,0-.125-.335.376-.293C281.019,120.287,281.165,120.224,281.019,119.89Z"
          transform="translate(-216.38 -17.52)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M281.254,120.492c.146-.481,0-.711.1-1.212a.241.241,0,0,0-.23.272.919.919,0,0,0-.418,0c.481.376.272.753,0,1.192C280.752,120.618,281.212,119.991,281.254,120.492Z"
          transform="translate(-216.761 -18.185)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M280.664,120.487c-.376,0,.188.293.585.146a.334.334,0,0,1-.188-.293C280.852,120.32,280.768,120.7,280.664,120.487Z"
          transform="translate(-216.297 -17.029)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M272.668,142v.146l-.188.209c.5-.4.732.627,1.275-.167,0-.167-.23,0-.376.209S272.814,142,272.668,142Z"
          transform="translate(-191.953 6.592)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M276.363,143.915c0,.1.23,0,.335,0s0-.439-.125-.439.146,0,.251-.23-.209,0-.251-.23a.146.146,0,0,1,.209,0c.1-.23,0-.146,0-.418s-.209.293-.23.355-.23.1-.335,0,0,.125,0,.251S276.488,143.518,276.363,143.915Z"
          transform="translate(-202.756 7.164)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M277.607,137h0a.208.208,0,0,0-.167.1c-.063.1.251,0,.355,0S277.5,137,277.607,137Z"
          transform="translate(-206.362 1.139)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M276.931,137.62c0,.146-.1.272,0,.355,0-.334,0,.209.272.167s-.1.272-.125.209.606.4.962.334c-.209.355.293,0,0,.4s.146-.146.209-.146a.847.847,0,0,1,.439.293c.084.146.272-.439.23-.125.23-.167,0-.251-.23,0a.439.439,0,0,0-.4-.146.389.389,0,0,1,.1-.251c.1-.1-.272,0-.293-.251-.376.564-.4-.334-.857,0C277.37,138.017,277.077,137.934,276.931,137.62Z"
          transform="translate(-206.418 1.815)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M278,140.313a.167.167,0,0,0,0,.23c-.1.167-.146.1-.23.23s.125.314.167.481c.167-.355.711.293,1.087,0a.188.188,0,0,0,0,.146.418.418,0,0,1,.46,0c.418-.23.794,0,1.129-.272-.146,0-.209-.251-.146-.23-.355-.544-1.756-.585-1.589-1.547.146,0,0,.4.23.46,0-.251.125-.5,0-.794s.125.209.272,0-.125,0-.209-.167c-.146.418-.5,0-.544,0s0,.125-.125.23.293,0,.251.23c-.4.23-.418-.146-.669-.272.146.23,0,.878,0,1.233s.314.167.125.439.188-.125.251,0-.23.251,0,.355a.586.586,0,0,1-.1.251c-.272.146-.314-.4-.481,0v-.167c0-.042.1.146.209,0s-.1-.146-.23,0C278,140.69,278.188,140.439,278,140.313Z"
          transform="translate(-209.828 3.157)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M277.405,137.663c0,.125.125,0,.125.125s.125-.125.188-.272S277.531,137.768,277.405,137.663Z"
          transform="translate(-206.223 1.667)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M276.84,138.131a1.234,1.234,0,0,0,.439.209S276.944,137.921,276.84,138.131Z"
          transform="translate(-204.592 2.308)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M278.165,139.426c.314-.439-.355-.251-.564-.293C277.789,139.279,277.956,139.467,278.165,139.426Z"
          transform="translate(-207.15 3.459)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.7,123.606c0,.314.627.146.753.355S238.241,123.418,237.7,123.606Z"
          transform="translate(-83.961 -13.501)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.689,116.3c-.335,0,.293.167.188.376a.5.5,0,0,0,.355-.46C242,116.324,241.856,116.554,241.689,116.3Z"
          transform="translate(-95.855 -21.522)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M278.623,142.708c.125-.125,0-.146.146-.418-.23.188-.355,0-.627.23a1.453,1.453,0,0,1,.418-1.087c0-.732-.836-.794-1.4-.544-.774.69-1.108,1.4-.9,1.756s1.505,0,1.547.669c.188,0,.564.314.9.251s.125-.146.334-.4-.439-.251-.146-.564a.377.377,0,0,0-.272.1Z"
          transform="translate(-205.058 5.235)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M277.128,127.457a.46.46,0,0,0,.418.376C277.588,127.29,277.316,127.437,277.128,127.457Z"
          transform="translate(-205.465 -9.304)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M273.48,127.2s.146.125,0,.167a.585.585,0,0,0,.753,0A2.116,2.116,0,0,0,273.48,127.2Z"
          transform="translate(-194.521 -9.548)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M273.64,126.67c.125.23.314.272.439.481C273.974,126.67,273.87,126.691,273.64,126.67Z"
          transform="translate(-194.702 -10.126)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M273.555,127.192h-.125c-.125,0,.293.167.376.4s0-.188,0-.167v-.5a.983.983,0,0,1-.439-.23.564.564,0,0,0,0,.439C273.387,127.484,273.45,126.983,273.555,127.192Z"
          transform="translate(-193.802 -10.104)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.552,75.351c.272.167.23,0,.355-.125s.314.355.125-.125C256.74,75.1,256.782,75.267,256.552,75.351Z"
          transform="translate(-142.013 -66.364)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.1,74.876a3.314,3.314,0,0,0,.481-.272C256.706,74.5,256.246,74.709,256.1,74.876Z"
          transform="translate(-140.557 -66.935)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.288,74.345c0-.167,0,0,.167-.167C256.225,73.927,256,74.157,256.288,74.345Z"
          transform="translate(-140.515 -67.49)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.463,73.69c.167,0,0,0,.251-.146C256.526,73.313,256.442,73.627,256.463,73.69Z"
          transform="translate(-141.422 -68.152)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.416,126.331v.188c.1.146.209,0,.335,0S275.562,126.31,275.416,126.331Z"
          transform="translate(-200.116 -10.497)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M274.73,127.427a.125.125,0,0,0,.167.1.125.125,0,0,1,0-.125C274.9,127.26,274.751,127.385,274.73,127.427Z"
          transform="translate(-197.799 -9.399)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M274.63,127.628h.251c.125,0-.125-.188-.23-.188S274.651,127.607,274.63,127.628Z"
          transform="translate(-197.553 -9.286)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M273.784,125.011c0,.355.23,0,.376.167s-.146.188-.251.146c.1.481.355-.272.4.293h.272c.125,0-.146,0-.209-.125a.42.42,0,0,1,0-.5A.606.606,0,0,1,273.784,125.011Z"
          transform="translate(-195.536 -11.958)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.568,74.04c.209,0,.146,0,.293.272s0,0,.146,0S256.63,73.768,256.568,74.04Z"
          transform="translate(-141.966 -67.603)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M278.173,128.371c.188.272.523.376.376-.314C278.3,127.953,278.424,128.455,278.173,128.371Z"
          transform="translate(-208.684 -8.628)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M276.45,129.8c0,.5.732.251.794,0s.23.523.335.815a5.1,5.1,0,0,0,.251-1.275c-.167-.23-.9,0-.669.334C276.91,129.736,276.68,129.61,276.45,129.8Z"
          transform="translate(-204.327 -7.317)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.741,72.8a.168.168,0,0,0,.146-.251C256.846,72.465,256.8,72.736,256.741,72.8Z"
          transform="translate(-142.202 -69.164)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.87,71.83a2.573,2.573,0,0,0,.418,0C257.016,71.558,257.016,71.7,256.87,71.83Z"
          transform="translate(-142.853 -70.097)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M253.25,71.784c.334.251.669.46.878.293a3.073,3.073,0,0,0-.773-.627.314.314,0,0,1-.1.335Z"
          transform="translate(-132.126 -70.344)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M281.06,122.394c.146.794.4-.544.5.23v-.564Z"
          transform="translate(-217.696 -15.153)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M280.707,122.43c0,.293.1.355.146.544s0-.962.4-.585c.23-.167-.188-.209-.188-.418C281.021,122.137,281,122.451,280.707,122.43Z"
          transform="translate(-216.716 -15.251)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M280.447,122.762c.188-.188.209-.334.46-.188,0-.23,0-.355.1-.418S280.4,122.3,280.447,122.762Z"
          transform="translate(-215.871 -15.061)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.91,123.493a.376.376,0,0,0,.334.272V123.2h-.188C276.182,123.451,276.056,123.514,275.91,123.493Z"
          transform="translate(-201.613 -13.91)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M274.08,128.791c.481.209,1.045-.46,1.317-.941C275.021,127.537,274.6,128.582,274.08,128.791Z"
          transform="translate(-196.94 -8.902)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.57,79.355c.648.481,0-.669.544-.146,0-.125-.209-.293-.355-.439a3.9,3.9,0,0,1-.188.585Z"
          transform="translate(-135.871 -62.362)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M274.25,129.355c0,.314.272,0,.4.125a1.38,1.38,0,0,1,0-.711C274.4,128.9,274.417,129.251,274.25,129.355Z"
          transform="translate(-196.546 -7.836)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M255.742,77.508c.314.188.711.251.46,0A.334.334,0,0,0,255.742,77.508Z"
          transform="translate(-139.489 -63.838)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M255.654,77.263c.376,0,1.045,0,.857-.481-.314,0-.23.188-.523-.272C256.135,76.824,255.822,77.054,255.654,77.263Z"
          transform="translate(-139.568 -64.826)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M255,76.545c.439.606,1.463-.606.606-1.045a1.912,1.912,0,0,0,.251.355C255.419,75.939,255.524,76.65,255,76.545Z"
          transform="translate(-137.598 -65.928)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M253.43,75.13c.251.188.293-.251.481-.293C253.576,74.649,253.472,74.859,253.43,75.13Z"
          transform="translate(-132.285 -66.728)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.3,73.243c.251.167.481.209.627,0C251.634,72.992,251.488,73.2,251.3,73.243Z"
          transform="translate(-125.848 -68.521)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.54,72.1c.46.272.418-.544.711-.732-.251-.272-.209.4-.523,0C249.958,71.783,249.686,71.72,249.54,72.1Z"
          transform="translate(-120.493 -70.51)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.361,79.094c.188.23.314.23.46.334s-.606-.753,0-.711c0-.272-.293,0-.418-.188C254.34,78.739,254.549,78.969,254.361,79.094Z"
          transform="translate(-135.16 -62.623)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.2,78.069c.5.334.334-.376.376-.585a1.97,1.97,0,0,1-.355-.23c.46,0,0-.376,0-.627s.23.146.314,0,.146-.334-.209-.606c-.1.439-.1.376-.544.564l.314.272a.356.356,0,0,0-.167.376c-.23-.272-.293-.4-.564-.418s.314-.209-.146-.439v.606c.376-.125.46.209.773.606,0-.272.125-.146.272-.272C257.724,77.776,257.369,77.922,257.2,78.069Z"
          transform="translate(-141.805 -65.36)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.269,79.252c0-.251,0-.376.23-.418-.188-.167-.23-.293-.209-.4S253.935,78.938,254.269,79.252Z"
          transform="translate(-134.336 -62.739)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.058,76.775c.544.167,0-.481,0-.711s.293.146.376,0C255.515,75.563,256.184,76.524,256.058,76.775Z"
          transform="translate(-140.056 -65.468)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.67,122.278c.251.1.544-.23.69-.5C276.172,121.609,275.963,122.153,275.67,122.278Z"
          transform="translate(-201.227 -15.497)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.68,123.727c0,.418.209-.272.251.125v-.293A1.063,1.063,0,0,1,275.68,123.727Z"
          transform="translate(-200.819 -13.517)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.75,122.575c0,.167.146,0,.209,0a.983.983,0,0,1,0-.355S275.834,122.534,275.75,122.575Z"
          transform="translate(-200.993 -14.979)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.6,122.643c0,.167.209.334.23.125S275.7,122.622,275.6,122.643Z"
          transform="translate(-200.55 -14.545)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.291,122.523c.125.146.418.335.5,0s-.146,0-.1-.293C275.626,122.523,275.458,122.523,275.291,122.523Z"
          transform="translate(-199.886 -14.968)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M274.706,122.369c0,.376.773.272.585-.209v.23C275.291,122.474,274.873,122.557,274.706,122.369Z"
          transform="translate(-198.172 -15.044)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.486,123.757v.272c0,.084,0-.5.209-.293s-.1-.125-.1-.209S275.59,123.757,275.486,123.757Z"
          transform="translate(-200.248 -13.568)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.334,122.823c0,.314.251,0,.355,0a.606.606,0,0,1,0-.23c.167.167,0-.167.209-.23s0,.146.1.146.167,0,.125-.314c-.188.146-.167.125-.4,0v.209a.209.209,0,0,0-.188,0v-.355c0-.1.188,0,.1-.209a2.387,2.387,0,0,0-.23.23c.188,0,.1.23.1.5,0-.146.1,0,.188,0S275.459,122.823,275.334,122.823Z"
          transform="translate(-200.305 -15.393)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.358,123.925c0-.1.1-.167.23-.1s0-.188,0-.209A.269.269,0,0,0,275.358,123.925Z"
          transform="translate(-199.848 -13.464)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.2,122.344c.146.251.209-.167.209-.314s0,.167.146.125C275.409,121.675,275.346,122.281,275.2,122.344Z"
          transform="translate(-199.44 -15.249)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.41,121.176c0,.314.4,0,.564.1C279.849,120.967,279.619,120.967,279.41,121.176Z"
          transform="translate(-212.66 -16.276)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M276.75,121.15c.1.293.251.481.523.376C277.231,121.192,276.98,121.234,276.75,121.15Z"
          transform="translate(-204.397 -16.146)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.254,127.84c0,.5.627.125.523-.314C246.463,127.442,246.505,127.84,246.254,127.84Z"
          transform="translate(-110.162 -9.205)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.67,75.348a.481.481,0,0,0,.69-.314C248.046,74.972,247.879,75.285,247.67,75.348Z"
          transform="translate(-114.692 -66.444)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.19,75.082c.4.251.46-.523.92-.544C247.692,74.351,247.253,74.957,247.19,75.082Z"
          transform="translate(-113.439 -67.015)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.97,74.058c.418.293.606,0,.857-.272-.23-.293-.544.23-.564-.209S247.158,73.953,246.97,74.058Z"
          transform="translate(-112.696 -68.165)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.321,73.614c0-.314-.23-.167-.481-.334,0,.188-.293.314,0,.564v-.251C246.84,73.531,247.237,73.74,247.321,73.614Z"
          transform="translate(-111.647 -68.348)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.513,73.868c.439,0,0-.669.544-.564C246.492,72.927,246.3,73.512,246.513,73.868Z"
          transform="translate(-110.797 -68.455)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.139,73.879c.5-.167.5-1.045.355-1.589C252.014,72.311,252.411,73.377,252.139,73.879Z"
          transform="translate(-128.256 -69.428)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M271.28,135.755c.627.5,0-.669.523-.146,0,0-.209-.272-.355-.439A2.092,2.092,0,0,1,271.28,135.755Z"
          transform="translate(-187.492 -0.857)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.32,79.2c0,.293.188.5.481.439C248.759,79.3,248.508,79.325,248.32,79.2Z"
          transform="translate(-116.492 -61.893)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M245.76,79.729c0,.523.669,0,1.024,0,0-.376-.439,0-.314-.439C246.283,79.854,246.136,79.6,245.76,79.729Z"
          transform="translate(-109.124 -61.794)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.878,82.521a.355.355,0,0,0,.314.293c0-.251.1-.293,0-.544h-.209C250.15,82.5,250.108,82.584,249.878,82.521Z"
          transform="translate(-121.186 -58.545)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.837,78.723c0,.314.481.146.544,0S248.046,78.849,247.837,78.723Z"
          transform="translate(-115.068 -62.461)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.315,75.94a.564.564,0,0,0,.544.314C247.942,75.731,247.5,76.065,247.315,75.94Z"
          transform="translate(-113.459 -65.448)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247,76.774c-.355.251.523.564.4,0C247.229,76.607,247.062,77.276,247,76.774Z"
          transform="translate(-112.161 -64.568)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.539,76.772c0,.355.669.544.627,0C246.915,76.982,246.874,76.731,246.539,76.772Z"
          transform="translate(-111.137 -64.545)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M244.85,82.552c0,.523.376.188.544.293C245.393,82.447,245.08,82.573,244.85,82.552Z"
          transform="translate(-105.831 -58.241)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M245.94,76.8a.543.543,0,0,0,.711.293C246.525,76.863,246.17,76.925,245.94,76.8Z"
          transform="translate(-109.367 -64.51)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M244.915,78.673c.125.293.543,0,.523.585C245.668,78.944,245.062,78.38,244.915,78.673Z"
          transform="translate(-106.063 -62.557)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.44,81.3c.23.146.564-.146.732-.4C250,80.733,249.712,81.151,249.44,81.3Z"
          transform="translate(-120.204 -60.081)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.7,82.8c0,.4.251-.251.251.146V82.65Z"
          transform="translate(-120.527 -58.13)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.61,81.675c0,.146.146,0,.209,0a1,1,0,0,1,0-.355S249.715,81.634,249.61,81.675Z"
          transform="translate(-120.207 -59.581)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.5,81.732c0,.188.167.376.209.167S249.6,81.732,249.5,81.732Z"
          transform="translate(-119.864 -59.135)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.147,81.565c0,.167.376.4.481.167s-.146,0,0-.293A.474.474,0,0,1,249.147,81.565Z"
          transform="translate(-119.076 -59.45)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.543,81.525c0,.376.753.355.627-.125v.209C249.17,81.693,248.71,81.734,248.543,81.525Z"
          transform="translate(-117.343 -59.493)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.477,82.826a1.024,1.024,0,0,0,0,.293s0-.481.251-.272,0-.125,0-.209S249.6,82.867,249.477,82.826Z"
          transform="translate(-119.928 -58.16)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.189,81.9c0,.293.272,0,.376,0a.627.627,0,0,1,0-.209c.167.167.125-.167.251-.209s0,.146,0,.146.188,0,.167-.272-.188,0-.4,0v.209a.209.209,0,0,0-.209,0c0-.188,0-.251,0-.355s.188,0,.125-.209l-.251.188c.188,0,0,.251,0,.523,0-.146.1,0,.209,0S249.315,81.878,249.189,81.9Z"
          transform="translate(-119.494 -59.93)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.383,82.979c.1,0,.125-.167.251,0s0-.188,0-.209A.335.335,0,0,0,249.383,82.979Z"
          transform="translate(-119.604 -57.999)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.061,81.486c.1.251.209-.146.251-.293s0,.167,0,.167C249.354,80.838,249.207,81.444,249.061,81.486Z"
          transform="translate(-118.571 -59.767)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M250.424,80.12c0,.314.188.5.46.439S250.612,80.245,250.424,80.12Z"
          transform="translate(-123.049 -60.889)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.89,80.659c0,.5.669,0,1.024,0,0-.376-.439,0-.314-.439C248.392,80.764,248.266,80.513,247.89,80.659Z"
          transform="translate(-115.707 -60.78)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.089,81.423c0,.564.376.209.4,0S246.277,81.611,246.089,81.423Z"
          transform="translate(-109.516 -59.537)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M244.4,79.14c0,.23.4.418.543.167S244.565,79.245,244.4,79.14Z"
          transform="translate(-104.459 -61.958)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M245.606,77.1a.857.857,0,0,0,.293.69C247.132,77.455,245.878,77.873,245.606,77.1Z"
          transform="translate(-108.447 -64.183)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M245.46,79.039v.418a5.518,5.518,0,0,0,.794-.376C246.087,78.642,245.648,79.624,245.46,79.039Z"
          transform="translate(-107.967 -62.15)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M245.49,83.254c0,.334.585-.188.878,0C246.138,82.731,245.741,83.233,245.49,83.254Z"
          transform="translate(-108.143 -57.731)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M244.91,78.673c0,.251.439-.23.732,0,0-.376-.439,0-.4-.293C245.14,78.4,245.077,78.631,244.91,78.673Z"
          transform="translate(-106.204 -62.787)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.76,80.42c.439.272,1.108-.314,1.422-.753C243.847,79.374,243.3,80.42,242.76,80.42Z"
          transform="translate(-100.25 -61.44)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.22,83.341c0,.794.46-.481.46.293.1,0,0-.334.1-.544A3.658,3.658,0,0,1,243.22,83.341Z"
          transform="translate(-100.814 -57.651)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.09,81.124c0,.293.293,0,.4.146a1.546,1.546,0,0,1,0-.69C243.32,80.664,243.278,81.04,243.09,81.124Z"
          transform="translate(-100.245 -60.388)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.874,81.321c0,.355.293.69.376.293S243,81.3,242.874,81.321Z"
          transform="translate(-99.569 -59.603)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.22,80.98c.209.335.732.753.941.335-.146-.293-.293,0-.146-.564C242.868,81.064,242.534,81.022,242.22,80.98Z"
          transform="translate(-98.1 -60.202)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M280.127,145.83c-.146.711,1.443.69,1.192-.23v.4C280.921,145.7,280.461,146.248,280.127,145.83Z"
          transform="translate(-215.509 10.518)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.909,83.437a1.379,1.379,0,0,0,0,.544c.188,0,.167-.941.481-.523.251-.125-.167-.23-.146-.418C243.222,83.186,243.222,83.479,242.909,83.437Z"
          transform="translate(-99.792 -57.705)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.2,81.636c0,.564.523,0,.711,0a.878.878,0,0,1,0-.439c.293.355.209-.293.481-.376s0,.272.146.272.355,0,.334-.544c-.418.23-.355.167-.794,0v.418a.334.334,0,0,0-.4,0c0-.355.1-.481,0-.69s.355,0,.251-.4l-.481.4c.335.188.125.46,0,.962.167-.251.209,0,.4,0C242.785,81.782,242.429,81.615,242.2,81.636Z"
          transform="translate(-98.769 -61.151)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M281.03,145.737c.23.5.418-.251.5-.544,0,0,0,.335.209.293C281.594,144.546,281.3,145.654,281.03,145.737Z"
          transform="translate(-217.813 9.983)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M280.68,146.273c0,.293.272,0,.376.167a1.527,1.527,0,0,1,0-.69C280.889,145.813,280.868,146.273,280.68,146.273Z"
          transform="translate(-216.397 10.681)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M280.46,146.46c0,.355.293.711.4.314a.376.376,0,0,0-.4-.314Z"
          transform="translate(-215.738 11.455)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.789,146.131c.209.314.753.753.941.314s-.293,0-.146-.564C280.458,146.256,280.123,146.131,279.789,146.131Z"
          transform="translate(-214.251 10.823)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M250.217,83.79c-.146.732,1.442.69,1.192-.23v.418C251.011,83.665,250.53,84.208,250.217,83.79Z"
          transform="translate(-123.072 -57.138)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M279.779,146.775c0,.585.5,0,.711-.1a1.047,1.047,0,0,1,0-.418c.314.335.23-.293.5-.4s0,.272.146.293.355,0,.335-.544c-.418.209-.376.167-.794,0v.418a.4.4,0,0,0-.4.125c0-.355.125-.481,0-.69s.355,0,.251-.418l-.481.4c.335.209.146.481,0,.983.146-.251.188,0,.376,0C280.365,147.047,280.03,146.775,279.779,146.775Z"
          transform="translate(-214.932 9.907)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.135,77.99c.293.125.606,0,0-.46C253.968,77.614,254.386,77.906,254.135,77.99Z"
          transform="translate(-134.244 -63.714)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.78,78.6c.314.4.732-.251.669-.418s.481.251.753.439a4.827,4.827,0,0,0-.606-1.171c-.272,0-.711.544-.314.669C252.073,78.265,251.822,78.328,251.78,78.6Z"
          transform="translate(-128.126 -63.801)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M244.459,79.514c.815,0-.439-.481.335-.439,0-.1-.335,0-.544-.125A5.247,5.247,0,0,1,244.459,79.514Z"
          transform="translate(-103.977 -62.165)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.6,73.809c.209.209.251-.167.376-.188a1.629,1.629,0,0,1-.523-.481C251.413,73.328,251.664,73.6,251.6,73.809Z"
          transform="translate(-126.212 -68.501)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M250.863,73.659c.314.188.732.251.481,0A.355.355,0,0,0,250.863,73.659Z"
          transform="translate(-124.429 -68.037)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M250.784,73.393c.376,0,1.045,0,.857-.5-.314,0-.23.188-.523-.251A.651.651,0,0,1,250.784,73.393Z"
          transform="translate(-124.517 -69.046)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M250.14,72.714c.46.606,1.463-.585.606-1.024,0,0,.146.209.251.334C250.579,72.045,250.683,72.756,250.14,72.714Z"
          transform="translate(-122.577 -70.082)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.57,71.274c.251.188.314-.23.481-.293C248.737,70.793,248.612,71.086,248.57,71.274Z"
          transform="translate(-117.265 -70.921)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.521,75.294a1.693,1.693,0,0,0,.481.314c0-.188-.627-.753-.1-.711,0-.272-.272,0-.4-.167C249.521,74.835,249.709,75.064,249.521,75.294Z"
          transform="translate(-120.16 -66.767)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.322,74.26c.5.314.334-.4.4-.606a1.3,1.3,0,0,1-.376-.23c.481,0,0-.355,0-.627s.251.146.314,0,.146-.334-.188-.606c-.1.439-.1.376-.543.564l.314.293a.314.314,0,0,0-.167.376c-.23-.293-.293-.418-.564-.418s.293-.209-.146-.46v.627c.376-.125.439.209.773.606,0-.293.125-.167.272-.293C252.844,73.883,252.489,74.071,252.322,74.26Z"
          transform="translate(-126.765 -69.537)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.427,75.436c0-.272,0-.376.209-.439-.167-.167-.23-.293-.188-.4A.606.606,0,0,0,249.427,75.436Z"
          transform="translate(-119.313 -66.909)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.2,72.925c.523.188,0-.481,0-.711s.293.167.355,0C250.659,71.713,251.328,72.674,251.2,72.925Z"
          transform="translate(-125.019 -69.666)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M253.073,76.708c.23,0,.23-.481.167-.732S253.2,76.478,253.073,76.708Z"
          transform="translate(-130.903 -65.463)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.855,77.988c.23.23.251-.146.376-.188a1.337,1.337,0,0,1-.5-.46C248.6,77.528,248.918,77.8,248.855,77.988Z"
          transform="translate(-117.717 -63.921)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.113,77.859c.314.188.732.251.481,0a.355.355,0,0,0-.481,0Z"
          transform="translate(-115.93 -63.457)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.034,77.613c.376,0,1.045,0,.857-.481-.314,0-.23.188-.523-.272C248.515,77.153,248.264,77.4,248.034,77.613Z"
          transform="translate(-116.018 -64.444)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.211,79.285c.439.585,1.463-.606.606-1.045a1.433,1.433,0,0,0,.251.335C248.629,78.616,248.734,79.327,248.211,79.285Z"
          transform="translate(-116.613 -62.939)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M245.82,75.48c.251.188.314-.251.481-.293C245.925,75,245.925,75.209,245.82,75.48Z"
          transform="translate(-108.766 -66.347)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.69,73.593c.272.167.5.209.648,0C244.066,73.342,243.92,73.552,243.69,73.593Z"
          transform="translate(-102.35 -68.139)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M253.079,76.8c.23.146.146-.167.167-.272a.522.522,0,0,1-.167,0c.209,0,0-.167,0-.293s0,0,.146,0,0-.146-.1-.272c0,.209,0,.167-.251.251l.146.125a.23.23,0,0,0,0,.188c0-.125-.126-.188-.251-.209s.146,0,0-.209,0,.209,0,.293.209,0,.355.293c0-.146,0,0,.125-.146S253.142,76.713,253.079,76.8Z"
          transform="translate(-130.136 -65.426)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.63,76.188c.251,0,0-.209,0-.314s.146,0,.188,0C252.38,75.728,252.693,76.188,252.63,76.188Z"
          transform="translate(-129.416 -65.57)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M276.9,127.335c-.146,0-.481-.167-.355.293.439-.188.606.606.4.962s.209.125.209.4C277.379,128.506,277,127.963,276.9,127.335Z"
          transform="translate(-203.855 -9.433)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.526,128.518c-.125-.418.146-.878,0-.983s0,.585-.146.983c.125,0,.167.1.188.272.439-.355.669.146,1.254,0a.22.22,0,0,0-.418-.1c.125-.564-.314-.376-.627-.794v.711Z"
          transform="translate(-201.083 -9.194)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M255.559,78.864c-.606-.4-.188-.523-.251-.753C254.827,77.9,255.12,79.22,255.559,78.864Z"
          transform="translate(-137.34 -63.104)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M273.643,126.63c-.125,0-.669,0-.125.167A.335.335,0,0,1,273.643,126.63Z"
          transform="translate(-193.513 -10.17)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.3,74.63a.444.444,0,0,0-.5.293c.209,0,.188.272.293.418a.272.272,0,0,0,0-.439c.1,0,0,0,.272.188a.167.167,0,0,1,.209-.209C257.631,74.755,257.422,74.923,257.3,74.63Z"
          transform="translate(-142.987 -66.876)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M255.254,76.17a2.09,2.09,0,0,0,1.631-.481.282.282,0,0,1,.23.146c.084.146,0-.23-.251-.355.125-.146.23,0,.167-.293s.146,0,.251.1a5.561,5.561,0,0,1,1.9-1.631c-.293,0,0-.209-.293-.418a.167.167,0,0,0,.146,0c.1.125,0,.167.167.335,0-.272.167-.293,0-.648s.1.439-.293,0c-.983,1.066-1.463,1.693-2.55,2.906,0,.125-.188,0-.272,0s-.167.146,0,.272a.4.4,0,0,1-.355-.125C255.484,75.981,255.422,76.253,255.254,76.17Z"
          transform="translate(-141.468 -68.834)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M273.82,127.666c0,.188.188,0,.23.167a.334.334,0,0,1,0,.146c.251.4.606,0,.941,0-.146-.355.669-.564.773-.188.125,0,0-.167.125-.209s.167.272.293.314a.439.439,0,0,1,0-.251c.167,0,.188-.334.314-.481s.167,0,.251,0,0-.23.167-.209c-.23-.146-.314-.355-.544-.355s0,0,0,.188a.376.376,0,0,1-.439.23.461.461,0,0,0,0,.334c-.126-.209-.418,0-.523-.293a.424.424,0,0,1-.251.146h.23a.4.4,0,0,1,0,.188,12.286,12.286,0,0,1-1.568.272Z"
          transform="translate(-197.913 -10.202)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.46,126c0,.188-.272.146-.293.355s.314-.23.418-.167S275.481,126,275.46,126Z"
          transform="translate(-199.428 -10.857)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.556,73.284a5.123,5.123,0,0,0,.9-.125s-.125-.209,0-.293-.188.293-.481-.125C251.975,73.074,251.849,73.012,251.556,73.284Z"
          transform="translate(-126.941 -68.937)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M253.4,79.111c.314,0,.627,0,0-.481C253.23,78.735,253.648,79.006,253.4,79.111Z"
          transform="translate(-131.981 -62.514)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M276.054,124.916c0,.753,1.505.523,1.15-.376v.418C276.827,124.707,276.43,125.293,276.054,124.916Z"
          transform="translate(-202.928 -12.449)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M274.3,125.164c0,.293.376,0,.544,0C274.739,124.975,274.509,124.975,274.3,125.164Z"
          transform="translate(-196.846 -11.923)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.694,73.09c-.481.188-.564.439-1.024.669.146,0,.272.125.376,0a.814.814,0,0,0,.251.335c0-.627.418-.69.92-.753C253.05,73.362,252.318,73.445,252.694,73.09Z"
          transform="translate(-127.912 -68.556)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.794,73.094c.167.355.1-.334-.251-.544a.335.335,0,0,1-.125.314C251.543,73.031,251.9,72.884,251.794,73.094Z"
          transform="translate(-126.05 -69.145)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.009,73.446c.1,0,0-.23,0-.314s-.418,0-.439,0a.4.4,0,0,0-.209-.272c-.209-.1,0,.209-.23.23s0-.146,0-.209-.125,0-.4,0,.272.251.334.251.1.251,0,.355.146,0,.251,0A1.108,1.108,0,0,1,254.009,73.446Z"
          transform="translate(-130.815 -68.828)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M273.883,124.891h0v.209c0,.209.146-.209.23-.293S273.883,125.037,273.883,124.891Z"
          transform="translate(-195.259 -12.173)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M272.677,125.821c0,.125,0,.293.23.251s.23,0,.314-.1.125.293,0,.272.711-.167.9-.481c.1.418.23-.146.335.23v-.251a.393.393,0,0,1,.481,0c.146.167-.1-.523,0-.272,0-.272-.209-.125-.209.146a.418.418,0,0,0-.376.167v-.251c0-.251-.209.188-.4,0,.146.69-.5,0-.627.585C273.242,125.8,272.97,125.946,272.677,125.821Z"
          transform="translate(-193.593 -11.597)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.394,76.831a.167.167,0,0,0,.251,0c.146.1,0,.146.209.251s.314-.125.481-.146c-.355-.188.334-.69,0-1.087h.146a.376.376,0,0,1,0-.439c-.209-.439,0-.815-.23-1.15,0,.146-.251.188-.23.125-.564.334-.648,1.714-1.61,1.505,0-.146.4,0,.481-.209-.272,0-.5-.167-.794,0s.209-.125,0-.251,0,.125-.188.167c.439.188,0,.523,0,.564s.125,0,.251.125,0-.272.209-.23c.23.4-.146.4-.272.648.23-.146.878.1,1.233,0s.188-.293.439,0-.125-.188,0-.251.251.23.355,0a.692.692,0,0,1,.251.125c.146.272-.4.272,0,.46h-.167c-.042,0,.125,0,0-.209s-.146,0,0,.23C257.77,76.852,257.52,76.664,257.394,76.831Z"
          transform="translate(-142.04 -67.28)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M273.484,125.48c0,.146,0,0,.167,0s0-.167,0-.314S273.631,125.438,273.484,125.48Z"
          transform="translate(-194.024 -11.8)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M272.66,125.391a.732.732,0,0,0,.46-.167S272.66,125.161,272.66,125.391Z"
          transform="translate(-191.694 -11.711)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.984,74.172c-.439-.355-.272.334-.335.544C256.816,74.548,257,74.381,256.984,74.172Z"
          transform="translate(-142.089 -67.485)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M255.762,73.28c-.125-.146-.167,0-.418-.188.167.251-.1.376.209.648a1.268,1.268,0,0,1-1.066-.481c-.753,0-.836.773-.606,1.359.669.815,1.359,1.192,1.714,1.024s0-1.505.753-1.505c0-.209.314-.564.272-.9s-.146-.125-.376-.334-.272.4-.564.1A.293.293,0,0,0,255.762,73.28Z"
          transform="translate(-135.704 -68.808)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.78,142.646c0,.314,0,.606.46,0C258.177,142.458,257.926,142.876,257.78,142.646Z"
          transform="translate(-145.708 7.242)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M270.622,146.751c.272.167.23,0,.355-.125s.314.355.125-.125C270.831,146.5,270.852,146.667,270.622,146.751Z"
          transform="translate(-185.496 11.499)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M270.17,146.262a4.616,4.616,0,0,0,.5-.272A.585.585,0,0,0,270.17,146.262Z"
          transform="translate(-184.041 10.943)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M270.358,145.75c0-.167,0,0,.167-.167C270.3,145.311,270.065,145.561,270.358,145.75Z"
          transform="translate(-183.999 10.368)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M270.533,145.14c.167,0,0,0,.251-.146C270.6,144.7,270.512,145.014,270.533,145.14Z"
          transform="translate(-184.906 9.724)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M270.649,145.37c.209,0,.146,0,.293.272s.1,0,.146,0S270.711,145.2,270.649,145.37Z"
          transform="translate(-185.46 10.225)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M270.811,144.2a.168.168,0,0,0,.146-.251C270.916,143.866,270.874,144.075,270.811,144.2Z"
          transform="translate(-185.685 8.697)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M267.32,143.174c.334.251.669.46.878.293a2.654,2.654,0,0,0-.773-.627A.314.314,0,0,1,267.32,143.174Z"
          transform="translate(-175.609 7.508)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M268.64,150.755c.627.481,0-.669.523-.146,0-.125-.209-.293-.355-.439Z"
          transform="translate(-179.333 15.501)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.8,148.908c.314.188.711.251.46,0a.334.334,0,0,0-.46,0Z"
          transform="translate(-182.941 14.025)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.721,148.663c.376,0,1.066,0,.857-.481-.314,0-.23.188-.523-.272C270.2,148.224,270.035,148.454,269.721,148.663Z"
          transform="translate(-183.049 13.037)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.07,147.975c.46.606,1.463-.606.606-1.045a1.912,1.912,0,0,0,.251.355C269.488,147.306,269.592,148.017,269.07,147.975Z"
          transform="translate(-181.08 11.968)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M267.5,146.53c.251.188.293-.251.481-.293C267.667,146.049,267.542,146.259,267.5,146.53Z"
          transform="translate(-175.768 11.134)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M265.37,144.643c.251.167.481.209.627,0C265.725,144.392,265.558,144.6,265.37,144.643Z"
          transform="translate(-169.332 9.342)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M263.66,143.5c.46.272.418-.544.711-.732-.251-.272-.209.4-.523,0C264.183,143.183,263.911,143.12,263.66,143.5Z"
          transform="translate(-164.131 7.353)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M268.431,150.494c.188.23.314.23.46.335s-.606-.773,0-.732c0-.251-.293,0-.418-.167C268.41,150.076,268.619,150.369,268.431,150.494Z"
          transform="translate(-178.643 15.239)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M271.271,149.489c.5.335.334-.376.376-.585a1.96,1.96,0,0,1-.355-.23c.46,0,0-.376,0-.627s.23.146.314,0,.146-.334-.209-.606c0,.439,0,.376-.523.564l.293.272a.356.356,0,0,0-.167.376c-.23-.272-.293-.4-.564-.418s.314-.209-.146-.46v.627c.376-.125.46.209.773.606,0-.272.125-.146.272-.272C271.794,149.154,271.439,149.3,271.271,149.489Z"
          transform="translate(-185.289 12.524)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M268.339,150.652c0-.251,0-.376.23-.418-.188-.167-.23-.293-.209-.4S268,150.338,268.339,150.652Z"
          transform="translate(-177.82 15.124)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M270.129,148.175c.543.167,0-.481,0-.711s.293.146.376,0C269.585,146.963,270.254,147.924,270.129,148.175Z"
          transform="translate(-183.539 12.395)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M261.76,146.746a.46.46,0,0,0,.69-.314C262.178,146.39,261.948,146.683,261.76,146.746Z"
          transform="translate(-158.238 11.42)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M261.26,146.483c.4.251.46-.523.92-.544C261.762,145.751,261.323,146.336,261.26,146.483Z"
          transform="translate(-156.923 10.847)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M261.04,145.407c.418.293.606,0,.857-.272-.23-.293-.544.23-.564-.209C261,144.759,261.228,145.3,261.04,145.407Z"
          transform="translate(-156.18 9.748)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M261.391,145.014c-.1-.314-.23-.167-.481-.334,0,.188-.293.314,0,.564v-.251C260.91,144.931,261.307,145.14,261.391,145.014Z"
          transform="translate(-155.13 9.514)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M260.583,145.268c.439.1,0-.669.544-.564C260.562,144.327,260.374,144.912,260.583,145.268Z"
          transform="translate(-154.28 9.407)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M266.21,145.279c.5-.167.5-1.045.355-1.589C266.084,143.711,266.481,144.777,266.21,145.279Z"
          transform="translate(-171.739 8.435)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M263.74,146.905c.648.5,0-.669.543-.146,0-.1-.23-.293-.376-.439A2.512,2.512,0,0,1,263.74,146.905Z"
          transform="translate(-164.211 11.303)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M265.656,145.209c.23.209.251-.167.376-.188a1.38,1.38,0,0,1-.5-.481C265.467,144.728,265.718,145,265.656,145.209Z"
          transform="translate(-169.68 9.362)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M264.933,145.049c.314.188.732.251.481,0a.355.355,0,0,0-.481,0Z"
          transform="translate(-167.913 9.814)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M264.854,144.823c.376,0,1.045,0,.857-.5-.314,0-.23.188-.523-.251C265.335,144.384,265.084,144.593,264.854,144.823Z"
          transform="translate(-168.001 8.849)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M264.21,144.114c.46.606,1.463-.585.606-1.024,0,0,.146.209.251.334C264.649,143.445,264.753,144.156,264.21,144.114Z"
          transform="translate(-166.06 7.78)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M262.64,142.68c.251.188.314-.23.481-.293C262.807,142.2,262.682,142.408,262.64,142.68Z"
          transform="translate(-160.748 6.936)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M263.57,146.654a1.693,1.693,0,0,0,.481.314c0-.188-.627-.753,0-.711,0-.272-.293,0-.418-.167C263.57,146.278,263.758,146.508,263.57,146.654Z"
          transform="translate(-163.622 11.052)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M266.381,145.66c.5.314.314-.4.376-.606a1,1,0,0,1-.355-.23c.46,0,0-.355,0-.627s.251.146.314,0,.167-.335-.188-.606c0,.439,0,.376-.544.564l.314.272a.335.335,0,0,0-.167.376c-.23-.272-.293-.4-.564-.418s.293-.188-.146-.439v.627c.376-.125.439.209.773.606,0-.293.125-.167.272-.293C266.883,145.325,266.527,145.451,266.381,145.66Z"
          transform="translate(-170.217 8.326)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M263.5,146.816c0-.272,0-.376.209-.439-.167-.167-.23-.293-.188-.4A.606.606,0,0,0,263.5,146.816Z"
          transform="translate(-162.797 10.932)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M265.273,144.325c.523.188,0-.481,0-.711s.293.167.355,0C264.729,143.113,265.4,144.074,265.273,144.325Z"
          transform="translate(-168.503 8.197)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M267.143,148.108c.23,0,.23-.481.167-.732S267.268,147.878,267.143,148.108Z"
          transform="translate(-174.387 12.4)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M262.945,149.388c.23.23.251-.146.376-.188a1.337,1.337,0,0,1-.5-.46C262.945,148.928,263.113,149.284,262.945,149.388Z"
          transform="translate(-161.326 13.942)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M262.183,149.259c.314.188.732.251.481,0a.356.356,0,0,0-.481,0Z"
          transform="translate(-159.414 14.405)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M262.1,149.013c.376,0,1.045,0,.857-.481-.314,0-.23.188-.523-.272C262.585,148.574,262.334,148.8,262.1,149.013Z"
          transform="translate(-159.502 13.418)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M262.28,150.685c.46.585,1.463-.606.606-1.045a1.431,1.431,0,0,0,.251.335C262.7,150.016,262.8,150.727,262.28,150.685Z"
          transform="translate(-160.095 14.923)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M259.89,146.88c.272.188.314-.251.481-.293C260.141,146.4,259.932,146.608,259.89,146.88Z"
          transform="translate(-152.249 11.516)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M267,148.2c.23.146.146-.167.167-.272a.526.526,0,0,1-.167,0c.209,0,0-.167,0-.293s.1,0,.146,0,0-.146,0-.272c0,.209,0,.167-.251.251l.146.125a.229.229,0,0,0,0,.188c-.1-.125-.125-.188-.251-.209s.146,0,0-.209,0,.209,0,.293.209.1.355.293c0-.146,0,0,.125-.146S267.063,148.113,267,148.2Z"
          transform="translate(-173.471 12.437)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M266.715,147.638c.251,0,0-.209,0-.314s.125,0,.167,0C266.464,147.094,266.777,147.533,266.715,147.638Z"
          transform="translate(-172.913 12.326)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.614,150.263c-.606-.4-.188-.523-.23-.753C268.9,149.322,269.175,150.555,269.614,150.263Z"
          transform="translate(-180.809 14.76)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M271.366,146a.669.669,0,0,0-.5.293c.23,0,.188.272.293.418a.272.272,0,0,0,0-.439c.1,0,0,0,.272.188a.167.167,0,0,1,.209-.209C271.7,146.188,271.492,146.355,271.366,146Z"
          transform="translate(-186.47 10.954)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.324,147.624a2.09,2.09,0,0,0,1.631-.481.282.282,0,0,1,.23.146c.084.146,0-.23-.251-.355.146-.167.251,0,.167-.293s.146,0,.251,0a5.561,5.561,0,0,1,1.9-1.631c-.293,0,0-.209-.293-.418a.167.167,0,0,0,.146,0c.125.125,0,.167.167.335,0-.272.167-.293,0-.648s0,.439-.293,0c-.983,1.066-1.463,1.693-2.55,2.906,0,.125-.188,0-.272,0s-.167.146,0,.272a.4.4,0,0,1-.355-.125C269.554,147.436,269.513,147.708,269.324,147.624Z"
          transform="translate(-184.951 8.974)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M265.556,144.7a5.122,5.122,0,0,0,.9-.125c0-.1-.125-.209,0-.293s-.188.293-.481-.125C265.828,144.453,265.7,144.39,265.556,144.7Z"
          transform="translate(-170.208 8.947)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M267.467,150.511c.314,0,.627,0,0-.481C267.3,150.135,267.718,150.406,267.467,150.511Z"
          transform="translate(-175.464 15.348)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M266.774,144.49c-.481.188-.564.439-1.024.669.146,0,.293.1.376,0a.816.816,0,0,0,.251.335c0-.627.418-.69.92-.753C267.151,144.762,266.419,144.845,266.774,144.49Z"
          transform="translate(-171.426 9.307)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M265.853,144.494c.167.334,0-.335-.251-.544a.355.355,0,0,1-.1.314C265.6,144.431,265.958,144.264,265.853,144.494Z"
          transform="translate(-169.522 8.718)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M268.1,144.792c.1,0,0-.23,0-.314s-.418,0-.439.1c-.125-.1,0-.167-.209-.272s0,.209-.23.23,0-.146,0-.209-.146,0-.418,0,.272.251.334.251.1.251,0,.335.146,0,.251.1S267.765,144.646,268.1,144.792Z"
          transform="translate(-174.319 9.089)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M271.485,148.231a.167.167,0,0,0,.251,0c.146,0,0,.146.209.251s.314-.125.481-.146c-.335-.188.334-.69,0-1.087h.146a.376.376,0,0,1,0-.439c-.23-.439,0-.815-.251-1.15,0,.146-.251.188-.209.125-.585.335-.669,1.714-1.631,1.505,0-.167.4,0,.481-.209-.272,0-.5-.167-.794-.1s.209-.125,0-.251,0,.125-.188.167c.439.188,0,.523,0,.564s.125,0,.251.125,0-.293.209-.251c.23.418-.146.418-.272.669.23-.146.878.1,1.233,0s.188-.293.439,0-.125-.188,0-.251.251.23.355,0a.48.48,0,0,1,.251.125c.146.272-.4.272,0,.46h-.167c-.042,0,.125-.1,0-.209s-.167,0,0,.23C271.861,148.252,271.611,148.064,271.485,148.231Z"
          transform="translate(-185.544 10.583)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M271.054,145.572c-.439-.355-.272.334-.334.544C270.887,145.948,271.075,145.781,271.054,145.572Z"
          transform="translate(-185.573 10.378)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.833,144.68c-.125-.146-.146,0-.418-.188.167.251-.1.376.209.648a1.268,1.268,0,0,1-1.066-.481c-.753,0-.836.773-.606,1.359.669.815,1.359,1.192,1.714,1.024s.1-1.505.753-1.505c0-.209.314-.564.272-.9s-.146-.125-.376-.334-.272.4-.564.1a.293.293,0,0,0,.084.272Z"
          transform="translate(-179.188 9.055)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.99,125.89a.376.376,0,0,0,.314.272C250.283,125.764,250.136,125.89,249.99,125.89Z"
          transform="translate(-121.486 -10.997)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.39,125.71s.1,0,0,.125a.439.439,0,0,0,.544,0A4.374,4.374,0,0,0,247.39,125.71Z"
          transform="translate(-113.681 -11.173)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.51,125.34c0,.146.209.188.293.334C247.74,125.34,247.656,125.34,247.51,125.34Z"
          transform="translate(-113.801 -11.576)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.348,125.705h0s.209.125.251.272v-.1a2.259,2.259,0,0,0,0-.376.627.627,0,0,1-.314-.146.44.44,0,0,0,0,.314C247.243,125.914,247.264,125.559,247.348,125.705Z"
          transform="translate(-113.074 -11.565)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.077,125.841c0,.209.167,0,.251,0s0,.335.125,0S247.244,125.9,247.077,125.841Z"
          transform="translate(-112.573 -11.178)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.6,125.755h.376a.4.4,0,0,0-.376,0Z"
          transform="translate(-111.072 -11.176)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.387,125.553c0-.125,0,0,.167,0S246.324,125.323,246.387,125.553Z"
          transform="translate(-110.232 -11.455)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.241,125h0Z"
          transform="translate(-109.856 -11.947)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.771,125.09v.146H249C249.084,125.236,248.875,125.09,248.771,125.09Z"
          transform="translate(-117.654 -11.849)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.253,125.85h.125S248.274,125.808,248.253,125.85Z"
          transform="translate(-115.987 -11.041)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.178,126.005h.188c.188,0-.1-.125-.167-.125S248.2,126.005,248.178,126.005Z"
          transform="translate(-115.807 -10.987)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.14,135.555c.188.188.272.167.523.355a.335.335,0,0,1,0-.209c0-.23-.251,0-.209-.272C237.412,135.576,237.37,135.472,237.14,135.555Z"
          transform="translate(-81.982 -0.573)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.62,124.192c0,.251.167,0,.251.125s0,.125-.167,0c0,.334.251-.188.293.209a.188.188,0,0,1,.188,0c0-.167,0,0-.167,0s0-.146,0-.376a.4.4,0,0,1-.4.042Z"
          transform="translate(-114.412 -12.874)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.633,125.117v.272h0S246.759,125.033,246.633,125.117Z"
          transform="translate(-110.854 -11.834)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M250.738,126.533c.1.209.355.272.251-.209C250.8,126.261,250.905,126.6,250.738,126.533Z"
          transform="translate(-123.76 -10.512)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M277.88,125.207c.251.272.5-.209.46-.334s.355.167.544.293a2.947,2.947,0,0,0-.46-.815c-.188,0-.481.418-.188.5C278.089,124.956,277.9,125,277.88,125.207Z"
          transform="translate(-208.37 -12.656)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M239.719,136.84h0Z"
          transform="translate(-89.641 0.965)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M239.389,136.243a.711.711,0,0,0,.251.167C239.744,136.431,239.472,136.2,239.389,136.243Z"
          transform="translate(-88.684 0.308)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.48,137.814c0,.314.146.564.334.585a1.839,1.839,0,0,0-.125-.69A.365.365,0,0,1,237.48,137.814Z"
          transform="translate(-82.845 1.913)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.83,122.28c.1.564.272-.376.355.188v-.418a1.608,1.608,0,0,1-.355.23Z"
          transform="translate(-130.305 -15.164)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.037,133c0,.209.167.355.355.272S242.163,133,242.037,133Z"
          transform="translate(-96.997 -3.223)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.228,133.142c0,.355.46,0,.711,0s-.293.125-.251-.272C240.584,133.142,240.479,133.016,240.228,133.142Z"
          transform="translate(-91.781 -3.365)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.555,122.318v.4c0,.125,0-.69.293-.418.167-.125-.125-.146-.146-.293S252.7,122.339,252.555,122.318Z"
          transform="translate(-129.445 -15.244)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.351,122.556c.146-.146.146-.23.335-.125s0-.251,0-.314S252.33,122.221,252.351,122.556Z"
          transform="translate(-128.885 -15.105)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.12,123.12a.251.251,0,0,0,.251.167v-.4h-.146C249.308,123.12,249.287,123.12,249.12,123.12Z"
          transform="translate(-118.735 -14.248)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M239.33,135.1c0,.376.272,0,.4.167C239.685,134.935,239.476,135.1,239.33,135.1Z"
          transform="translate(-88.625 -0.977)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.876,132.863v-.293c-.376,0-.544.69-.92.376,0-.23.293,0,.314-.23s-.209.1-.46,0v.606a3.069,3.069,0,0,1,.983,0c-.188-.335.293-.188.251-.335,0,.4.251.314.334,0,.167,0,0,.272.1.4.146-.251.648,0,.523-.355v-.1c0-.1,0,.46.209.4-.209-.335,0-.293.188-.418-.355-.564-1.024,0-1.422-.293A2.088,2.088,0,0,0,236.876,132.863Z"
          transform="translate(-79.941 -3.692)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.82,126.838c.335.167.753-.314.941-.669C248.384,125.96,248.2,126.713,247.82,126.838Z"
          transform="translate(-115.407 -10.712)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.82,124.728c.439.334,0-.481.376-.1,0,0-.167-.209-.272-.314A4.047,4.047,0,0,1,275.82,124.728Z"
          transform="translate(-201.377 -12.7)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.94,127.262c0,.23.209,0,.293,0a1.044,1.044,0,0,1,0-.5C248.107,126.99,248.107,127.262,247.94,127.262Z"
          transform="translate(-115.13 -10.028)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.74,127.354c0,.251.272.481.314.167a.272.272,0,0,0-.314-.167Z"
          transform="translate(-114.532 -9.388)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.323,127.131c.188.209.606.46.69.146s-.209,0-.146-.4C247.8,127.131,247.574,127.131,247.323,127.131Z"
          transform="translate(-113.634 -9.897)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.519,126.982c0,.544,1.066.376.794-.272v.314C247.062,126.835,246.77,127.316,246.519,126.982Z"
          transform="translate(-111.283 -10.082)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M245.26,127.182c0,.23.272,0,.4,0C245.553,126.973,245.406,126.973,245.26,127.182Z"
          transform="translate(-106.952 -9.739)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.324,127.14a.274.274,0,0,0,.376.272C243.91,127.349,243.45,127.2,243.324,127.14Z"
          transform="translate(-101.002 -9.613)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.55,127.3c.125.355.481-.1.732,0,0-.272-.314.125-.251-.293C241.926,127.324,241.8,127.156,241.55,127.3Z"
          transform="translate(-95.82 -9.755)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.64,124.549a.858.858,0,0,0,.334.23c0-.125-.439-.544,0-.5,0-.188-.209,0-.293-.125S275.765,124.465,275.64,124.549Z"
          transform="translate(-200.779 -12.897)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.37,127.683c.1.418.355,0,.481-.146a.481.481,0,0,1,0-.293c.251.23.125-.23.293-.314s0,.188.146.188.23-.1.167-.418c-.272.188-.23.146-.544,0v.314a.251.251,0,0,0-.272,0v-.481c0-.125.272,0,.146-.314l-.314.335c.272,0,.146.314.125.69.1-.209.146,0,.293,0C247.788,127.851,247.537,127.662,247.37,127.683Z"
          transform="translate(-114.183 -10.617)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M275.563,124.671c0-.188,0-.272.167-.314-.125,0-.167-.209-.146-.293S275.333,124.441,275.563,124.671Z"
          transform="translate(-200.179 -12.977)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.19,126.946c.209.334.272-.23.293-.418s0,.209.188.188C247.483,126.047,247.378,126.863,247.19,126.946Z"
          transform="translate(-113 -10.36)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.694,134.634a.617.617,0,0,0,.481-.334C243.259,134.132,242.882,134.571,242.694,134.634Z"
          transform="translate(-99.118 -1.847)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.92,123.289c0,.293.146-.209.167,0v-.209Z"
          transform="translate(-118.033 -14.041)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.76,134.861c0,.1.1,0,.146,0a.521.521,0,0,1,0-.251A.831.831,0,0,1,242.76,134.861Z"
          transform="translate(-98.974 -1.467)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.66,134.916c0,.125.146.23.146,0a.1.1,0,0,0-.146,0Z"
          transform="translate(-98.665 -1.167)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.433,134.819a.182.182,0,0,0,.355,0c.063-.251-.1,0,0-.209A.339.339,0,0,1,242.433,134.819Z"
          transform="translate(-98.188 -1.467)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.021,134.7c0,.293.544.188.418-.125v.146C242.439,134.863,242.146,134.842,242.021,134.7Z"
          transform="translate(-96.981 -1.511)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.8,123.278v.188c0,.063,0-.355.167-.209s0,0,0-.146S248.888,123.278,248.8,123.278Z"
          transform="translate(-117.75 -14.05)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.63,135.074c0,.23.188,0,.251,0a.293.293,0,0,1,0-.146c.125.1,0-.125.167-.167h0s.125,0,0-.209-.125,0-.272,0v.167h-.125v-.272c0-.084.125,0,0-.146l-.146.167c.125,0,0,.167,0,.355s0,0,.146,0S242.714,135.074,242.63,135.074Z"
          transform="translate(-98.656 -1.805)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.716,123.351s0-.125.167,0,0-.146,0-.167S248.7,123.183,248.716,123.351Z"
          transform="translate(-117.474 -13.935)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.42,134.679c.1.188.146-.1.146-.209v.1C242.566,134.219,242.524,134.637,242.42,134.679Z"
          transform="translate(-97.924 -1.661)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.608,121.4c0,.209.272,0,.4,0S251.754,121.274,251.608,121.4Z"
          transform="translate(-126.595 -15.935)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.437,133.84c0,.209.167.355.355.272S243.562,133.924,243.437,133.84Z"
          transform="translate(-101.324 -2.307)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.628,133.992c.1.355.46,0,.711,0s-.293.125-.251-.272C241.984,134.034,241.879,133.866,241.628,133.992Z"
          transform="translate(-96.108 -2.438)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.853,136.429c0,.146.125.314.251.272s0-.376,0-.69C235.749,135.99,236.062,136.345,235.853,136.429Z"
          transform="translate(-77.79 0.059)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.09,136.058c0,.23.272,0,.4,0C234.4,135.807,234.257,136.058,234.09,136.058Z"
          transform="translate(-72.431 -0.01)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.717,138.584c0,.355.439,0,.376-.23S233.884,138.584,233.717,138.584Z"
          transform="translate(-71.263 2.545)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.31,129.89a.335.335,0,0,0,.5.167C242.686,129.89,242.456,129.974,242.31,129.89Z"
          transform="translate(-97.939 -6.614)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.8,129.887c0,.335.481,0,.753.146C242.427,129.741,241.9,129.846,241.8,129.887Z"
          transform="translate(-96.614 -6.675)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.225,129.548c0,.334.355.23.585.251s-.4-.146-.209-.4S241.372,129.569,241.225,129.548Z"
          transform="translate(-94.722 -7.234)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.259,129.787v-.4c0,.1-.314,0-.251.314h.146C241.3,129.7,241.071,129.829,241.259,129.787Z"
          transform="translate(-93.648 -7.16)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.79,129.793c.188.272.314-.355.564,0C241.229,129.27,240.915,129.5,240.79,129.793Z"
          transform="translate(-93.304 -7.061)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.31,134.369c0,.418.293.125.293,0S240.477,134.494,240.31,134.369Z"
          transform="translate(-91.549 -1.77)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.454,135.89c0,.314.355.418.272,0Z"
          transform="translate(-82.714 -0.071)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.889,135.909a.314.314,0,0,0,.4.167C237.308,135.7,237.057,135.972,236.889,135.909Z"
          transform="translate(-81.084 -0.091)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.589,136.5c-.23.209.418.355.272,0S236.673,136.77,236.589,136.5Z"
          transform="translate(-79.947 0.47)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M239.71,135.686c0,.251.4-.188.606-.146C240.045,135.268,239.835,135.665,239.71,135.686Z"
          transform="translate(-90.008 -0.557)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.15,133.5c.335.146.753-.314.92-.669C238.819,132.6,238.526,133.355,238.15,133.5Z"
          transform="translate(-85.501 -3.453)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.16,135.52c.1.585.272-.376.355.188v-.418a1.609,1.609,0,0,1-.355.23Z"
          transform="translate(-84.967 -0.726)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.27,133.941c0,.209.188,0,.272,0a1.212,1.212,0,0,1,0-.481C238.374,133.585,238.4,133.857,238.27,133.941Z"
          transform="translate(-85.223 -2.721)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.069,134.014c0,.23.272.46.293.167S238.215,134.014,238.069,134.014Z"
          transform="translate(-84.625 -2.146)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.66,133.77c.188.209.585.481.69.146s-.209,0-.146-.376C238.161,133.77,237.931,133.77,237.66,133.77Z"
          transform="translate(-83.777 -2.634)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.842,133.642c0,.523,1.066.376.815-.272v.293C237.657,133.767,237.114,133.914,236.842,133.642Z"
          transform="translate(-81.391 -2.819)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.937,135.579v.376c0,.125,0-.69.293-.418.167-.1-.146-.146-.146-.293S238.146,135.579,237.937,135.579Z"
          transform="translate(-84.263 -0.806)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.692,134.289c.125.4.355,0,.5-.146a.543.543,0,0,1,0-.293c.23.23.125-.23.293-.314s0,.188.125.188.251-.1.188-.418c-.272.188-.23.146-.564,0v.293a.272.272,0,0,0-.272.125v-.481c0-.125.251,0,.125-.314l-.293.314c.251.1.146.335.125.69,0-.188.146,0,.272,0C238.173,134.352,237.88,134.268,237.692,134.289Z"
          transform="translate(-84.29 -3.299)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.721,135.8c.146-.125.146-.23.335-.125s0-.251,0-.314S237.7,135.461,237.721,135.8Z"
          transform="translate(-83.671 -0.667)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.51,133.615c.188.335.272-.23.293-.439s0,.23.188.188C237.782,132.7,237.7,133.532,237.51,133.615Z"
          transform="translate(-83.084 -3.106)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.41,127.078c.335.167.753-.314.941-.669C244.079,126.2,243.786,126.953,243.41,127.078Z"
          transform="translate(-101.778 -10.45)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.43,129.12c0,.564.293-.376.355.167v-.4Z"
          transform="translate(-101.254 -7.705)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.53,127.532c0,.209.209,0,.293,0a1.045,1.045,0,0,1,0-.5C243.655,127.2,243.655,127.448,243.53,127.532Z"
          transform="translate(-101.501 -9.733)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.33,127.594c0,.251.272.481.314.167A.272.272,0,0,0,243.33,127.594Z"
          transform="translate(-100.903 -9.126)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.913,127.371c.188.209.606.46.69.146s-.209,0-.146-.4C243.435,127.371,243.164,127.371,242.913,127.371Z"
          transform="translate(-100.005 -9.635)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.109,127.222c0,.544,1.066.376.794-.272v.314C242.9,127.389,242.36,127.494,242.109,127.222Z"
          transform="translate(-97.654 -9.821)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.8,127.369c0,.23.272,0,.4,0S240.8,127.244,240.8,127.369Z"
          transform="translate(-93.19 -9.424)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.207,129.175a1.632,1.632,0,0,0,0,.4c.125,0,0-.711.293-.439.167,0-.125-.146-.125-.272S243.375,129.175,243.207,129.175Z"
          transform="translate(-100.53 -7.76)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.96,127.923c.1.418.355,0,.481-.146a.481.481,0,0,1,0-.293c.251.23.125-.23.293-.314s0,.188.146.188.23,0,.167-.418c-.251.188-.23.146-.544,0v.314a.251.251,0,0,0-.272,0c0-.251,0-.355-.1-.481s.272,0,.146-.314l-.314.334c.272,0,.146.314.146.69,0-.209.125,0,.272,0C243.378,127.986,243.127,127.9,242.96,127.923Z"
          transform="translate(-100.554 -10.355)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.033,129.382c.125-.146.146-.251.314-.125v-.314C243.347,128.9,242.992,129.069,243.033,129.382Z"
          transform="translate(-99.98 -7.654)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.77,127.186c.209.334.272-.23.293-.418s0,.209.188.188C243.042,126.287,242.937,127.1,242.77,127.186Z"
          transform="translate(-99.34 -10.099)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M245.624,128.246a.561.561,0,0,0,.418-.314C246.125,127.766,245.791,128.121,245.624,128.246Z"
          transform="translate(-108.11 -8.797)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M244.04,131.061c0,.209.209,0,.293,0a.939.939,0,0,1,0-.481C244.165,130.705,244.165,130.977,244.04,131.061Z"
          transform="translate(-103.077 -5.862)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.85,131.136c0,.23.272.46.314.167a.251.251,0,0,0-.314-.167Z"
          transform="translate(-102.51 -5.268)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.423,130.89c.167.209.585.481.669.146s-.209,0-.146-.376C243.883,130.89,243.653,130.89,243.423,130.89Z"
          transform="translate(-101.56 -5.775)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M244.172,131.632c0,.523,1.066.355.815-.272v.293C244.987,131.757,244.444,131.9,244.172,131.632Z"
          transform="translate(-104.045 -5.011)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.348,130.909c0,.23.272,0,.4,0S241.494,130.784,241.348,130.909Z"
          transform="translate(-94.887 -5.564)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M239.427,130.91a.272.272,0,0,0,.376.272C239.992,131.1,239.573,131.1,239.427,130.91Z"
          transform="translate(-88.952 -5.502)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M245.605,128.641c0,.188.167,0,.23,0a1.316,1.316,0,0,1,0-.146c.125.1,0,0,.146-.146s0,.1,0,0v-.188c0-.188-.1,0-.251,0v.146h-.125c-.125,0,0-.167,0-.23s.125,0,0-.146l-.146.146c.125,0,0,.146,0,.314s0,0,.125,0S245.689,128.62,245.605,128.641Z"
          transform="translate(-107.757 -8.752)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M245.38,128.292c0,.167.125,0,.125-.188h0C245.505,128,245.464,128.271,245.38,128.292Z"
          transform="translate(-107.051 -8.633)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.819,125.8s-.335-.1-.251.23c.314-.146.439.418.293.669s.146,0,.146.293C250.174,126.634,249.9,126.237,249.819,125.8Z"
          transform="translate(-120.332 -11.094)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.852,126.655v-.711a2.852,2.852,0,0,1-.1.711c-.1.272.125,0,.146.188.314-.251.481,0,.878,0,0-.167-.209-.251-.293,0,0-.4-.23-.272-.439-.564a4.07,4.07,0,0,1,0,.5Z"
          transform="translate(-118.299 -10.927)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M276.483,124.38c-.439-.272-.125-.376-.167-.544C275.961,123.711,276.17,124.631,276.483,124.38Z"
          transform="translate(-202.312 -13.229)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.5,125.3s-.481,0-.1.125Z"
          transform="translate(-112.953 -11.62)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.448,125.425a.481.481,0,0,0-.418,0c.1.146,0,.23,0,.376a.23.23,0,0,0,.251-.209v.23a.125.125,0,0,1,.209,0A.461.461,0,0,1,247.448,125.425Z"
          transform="translate(-112.485 -11.536)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M245.427,125.188a1.443,1.443,0,0,0,1.087.523v-.125c0-.167.146,0,.209,0s0,0,0,.167a4.182,4.182,0,0,1,1.777,0c-.125-.167.1,0,0-.355a.23.23,0,0,0,.125,0c0,.125-.125,0,0,.251.146-.125.23,0,.272-.376s-.167.293-.188,0a22.877,22.877,0,0,0-2.718.334.183.183,0,0,0-.125-.167c-.125-.042-.167,0-.146.125a.23.23,0,0,1-.125-.23C245.615,125.209,245.469,125.334,245.427,125.188Z"
          transform="translate(-110.547 -11.78)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.67,126.087c0,.125.125,0,.167.125h0c.188.293.418,0,.669,0-.1-.251.481-.4.564-.125s0-.125,0-.146a.223.223,0,0,0,.188.209,1.336,1.336,0,0,1,0-.188c.125,0,.146-.23.23-.334s.125,0,.188,0,0-.167.1-.146-.23-.251-.376-.23,0,0,0,.125a.272.272,0,0,1-.314.167.23.23,0,0,0,0,.23c-.1-.146-.314,0-.376-.209,0,0-.1.1-.188.1h.167v.125a9.382,9.382,0,0,1-1.024.293Z"
          transform="translate(-116.135 -11.675)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.812,124.83c0,.125-.188,0-.209.251s.23-.167.293-.125S248.833,124.872,248.812,124.83Z"
          transform="translate(-117.193 -12.132)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.156,135.605c-.335-.125-.5,0-.836-.125,0,.125,0,.209.188.188a1.082,1.082,0,0,0,0,.293c.293-.334.544-.167.857,0C238.24,135.919,237.8,135.626,238.156,135.605Z"
          transform="translate(-83.061 -0.519)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.034,135.828c0,.251.23-.125.125-.418a.188.188,0,0,1-.209.125C236.867,135.682,237.034,135.744,237.034,135.828Z"
          transform="translate(-81.061 -0.595)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.327,126.955c.188.188.272.167.523.355s0-.188,0-.209-.251,0-.209-.272C243.6,126.955,243.557,126.872,243.327,126.955Z"
          transform="translate(-101.214 -9.951)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M274.973,124.544c.209,0,.439,0,0-.335C274.764,124.294,274.973,124.482,274.973,124.544Z"
          transform="translate(-198.439 -12.809)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M259.851,143.07c-.272,0,0,.5,0,.544A1.057,1.057,0,0,1,259.851,143.07Z"
          transform="translate(-151.395 7.759)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M255.232,143.33c-.523,0-.125.4-.188.585C255.525,143.853,255.232,143.56,255.232,143.33Z"
          transform="translate(-136.888 8.042)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.556,142.55c-.251.167,0,.544-.481.627C251.43,143.344,251.786,142.655,251.556,142.55Z"
          transform="translate(-125.08 7.191)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M260,140.05c-.188.5.5,1.045,1,1.275C261.276,140.928,260.23,140.573,260,140.05Z"
          transform="translate(-153.091 4.465)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M261.03,140.36c-.293,0,0,.293,0,.418a1.3,1.3,0,0,1,.69-.1C261.511,140.506,261.155,140.527,261.03,140.36Z"
          transform="translate(-155.71 4.803)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M262.067,140.65c-.334.146-.627.439-.209.439a.355.355,0,0,0,.209-.439Z"
          transform="translate(-157.646 5.119)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M261.344,141c-.272.272-.606.857-.146.983.272-.188,0-.293.523-.251A.769.769,0,0,1,261.344,141Z"
          transform="translate(-155.857 5.501)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M261.079,142c-.732,0-.418,1.526.439,1.129,0-.1-.251,0-.418,0C261.33,142.732,260.724,142.355,261.079,142Z"
          transform="translate(-155.027 6.592)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M261.169,144.09c-.314,0,0,.4,0,.564C261.42,144.508,261.42,144.278,261.169,144.09Z"
          transform="translate(-155.619 8.871)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M260.423,140.7c-.565.188,0,.5.23.69a.878.878,0,0,1,.4-.146c-.293.355.334.167.481.4s-.272,0-.272.209.167.334.606.23c-.293-.376-.23-.335-.1-.794h-.418a.313.313,0,0,0-.188-.355c.355,0,.481,0,.669-.188s0,.376.439.167l-.46-.4c-.146.376-.439.209-.962.23.272,0,0,.188,0,.376C260.36,141.325,260.548,140.949,260.423,140.7Z"
          transform="translate(-154.748 4.967)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M260.493,141.27c-.439.314.334.376.627.4,0,0-.314.125-.251.272C261.789,141.6,260.639,141.542,260.493,141.27Z"
          transform="translate(-154.086 5.796)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M258.055,146.47c-.46,0-.564.523,0,.4Z"
          transform="translate(-145.293 11.466)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.81,151.54a.564.564,0,0,0,.251.585C253.5,151.749,252.957,151.728,252.81,151.54Z"
          transform="translate(-130.285 16.995)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M253.124,152.47c-.46,0,0,.773.293.272S252.915,152.825,253.124,152.47Z"
          transform="translate(-130.808 18.009)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.755,152.64c-.251.251.188.836.46.418C252.922,153.058,253.047,152.828,252.755,152.64Z"
          transform="translate(-130.021 18.195)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.337,153c-.146.293.167.585.376.669C252.734,153.418,252.421,153.272,252.337,153Z"
          transform="translate(-128.725 18.587)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M259.605,143.21c-.167.481.523,1.024,1.024,1.275C260.9,144.088,259.793,143.733,259.605,143.21Z"
          transform="translate(-151.902 7.911)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M260.65,143.51c-.293,0,0,.293,0,.418a1.588,1.588,0,0,1,.69-.1C261.152,143.656,260.775,143.677,260.65,143.51Z"
          transform="translate(-154.536 8.238)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M261.7,143.81c-.335.125-.648.418-.23.439A.376.376,0,0,0,261.7,143.81Z"
          transform="translate(-156.459 8.565)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M260.96,144.11c-.293.272-.606.878-.146.983s0-.293.523-.23C261,144.779,260.98,144.444,260.96,144.11Z"
          transform="translate(-154.657 8.893)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M260.7,145.14c-.753,0-.439,1.526.439,1.129h-.418C260.955,145.913,260.348,145.537,260.7,145.14Z"
          transform="translate(-153.837 10.016)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M262.466,142.16c-.314,0,0,.4,0,.564C262.738,142.578,262.654,142.348,262.466,142.16Z"
          transform="translate(-159.613 6.766)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M260,143.869c-.544.188.1.5.23.669a1.274,1.274,0,0,1,.4-.125c-.272.355.335.146.481.4s-.272,0-.251.188.167.335.585.23c-.272-.355-.23-.314-.1-.773h-.418a.355.355,0,0,0-.188-.376c.355,0,.5,0,.669-.167s0,.355.46.167l-.481-.418c-.125.376-.439.23-.941.23.272,0,0,.188,0,.4C259.941,144.475,260.067,144.1,260,143.869Z"
          transform="translate(-153.534 8.402)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M260.117,144.43c-.46.314.334.355.606.376,0,0-.293.125-.23.272C261.393,144.764,260.243,144.681,260.117,144.43Z"
          transform="translate(-152.895 9.242)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M263.844,146.05c-.251.4.543.418.585.878C264.6,146.51,264.032,146.113,263.844,146.05Z"
          transform="translate(-164.503 11.008)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M262.8,146.26c-.272.4.125.585.335.815.272-.23-.272-.523.167-.564S262.8,146.427,262.8,146.26Z"
          transform="translate(-161.176 11.237)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M262.822,147.214c.314-.1.146-.23.314-.481-.188,0-.335-.272-.585,0h.272C262.884,146.733,262.7,147.151,262.822,147.214Z"
          transform="translate(-160.575 11.621)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M262.428,146.977c0,.439.669,0,.606.523C263.39,146.914,262.784,146.768,262.428,146.977Z"
          transform="translate(-160.328 11.921)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M255.711,144.548c0-.293-.523-.125-.481-.418-.293.125,0,.5.334.355a.683.683,0,0,1-.4.209C255.377,144.987,255.606,144.694,255.711,144.548Z"
          transform="translate(-137.597 8.914)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M258.643,146.71c-.146.272-.167.5,0,.627C258.914,147.065,258.705,146.919,258.643,146.71Z"
          transform="translate(-147.804 11.728)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M262,140.52c-.23.272-.188.4-.439.773.125,0,.251,0,.293.125s-.125-.355.376-.314C262.02,140.917,262.145,140.854,262,140.52Z"
          transform="translate(-157.599 4.978)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M262.224,141.123c.167-.5,0-.711.125-1.213a.245.245,0,0,0-.251.272,1.257,1.257,0,0,0-.418,0c.5.376.272.753,0,1.192C261.805,141.227,262.182,140.6,262.224,141.123Z"
          transform="translate(-157.97 4.312)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M261.635,141.076c-.355,0,.188.293.585.146a.335.335,0,0,1-.188-.293C261.844,141.076,261.74,141.348,261.635,141.076Z"
          transform="translate(-157.507 5.425)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M258.1,148.067a.523.523,0,0,0,.439.4C258.579,147.941,258.307,148.067,258.1,148.067Z"
          transform="translate(-146.674 13.193)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.45,147.82s.146.125,0,.167c.23.23.523,0,.753,0A2.232,2.232,0,0,0,254.45,147.82Z"
          transform="translate(-135.709 12.938)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.62,147.3c0,.209.293.272.418.46C254.954,147.3,254.829,147.321,254.62,147.3Z"
          transform="translate(-135.9 12.371)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.578,147.812h-.146c-.146,0,.293.167.376.376s0-.188,0-.146a4.391,4.391,0,0,1,0-.523.92.92,0,0,1-.46-.209.5.5,0,0,0,0,.418C254.411,148.1,254.452,147.624,254.578,147.812Z"
          transform="translate(-135.042 12.382)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.4,147v.209c.1.125.209,0,.314,0S256.545,147,256.4,147Z"
          transform="translate(-141.316 12.044)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M255.679,148c0,.125,0,.146.146.125s0-.125,0-.125Z"
          transform="translate(-138.965 13.135)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M255.58,148.238h.272c.125,0-.125-.188-.23-.167S255.622,148.217,255.58,148.238Z"
          transform="translate(-138.741 13.21)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.77,145.66c0,.355.23,0,.376.167s-.146.188-.23.146c0,.5.334-.272.376.293a.439.439,0,0,1,.293,0c0-.23-.146,0-.23,0s-.146-.209,0-.523a.544.544,0,0,1-.585-.084Z"
          transform="translate(-136.76 10.583)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M259.153,148.978c.167.272.5.376.355-.293C259.257,148.6,259.4,149.062,259.153,148.978Z"
          transform="translate(-149.882 13.871)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.43,150.424c0,.5.732.272.794,0s.209.5.314.815a5.476,5.476,0,0,0,.251-1.3c-.167-.209-.9,0-.669.334C257.869,150.362,257.6,150.153,257.43,150.424Z"
          transform="translate(-145.525 15.163)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M262.018,143c.146.794.4-.523.5.251.1,0,0-.355,0-.564A4.187,4.187,0,0,1,262.018,143Z"
          transform="translate(-158.893 7.344)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M261.647,143.039a1.733,1.733,0,0,0,.125.564c.167-.1,0-.983.418-.606.23-.146-.188-.209-.209-.4C261.877,142.767,261.877,143.06,261.647,143.039Z"
          transform="translate(-157.811 7.246)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M261.415,143.382c.188-.188.209-.314.439-.167,0-.251,0-.376.125-.439S261.373,142.922,261.415,143.382Z"
          transform="translate(-157.056 7.426)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.88,144.164a.355.355,0,0,0,.355.251v-.564h-.251C257.152,144.038,257.11,144.164,256.88,144.164Z"
          transform="translate(-142.822 8.609)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M255.06,149.428c.481.209,1.066-.46,1.317-.962C256,148.174,255.583,149.219,255.06,149.428Z"
          transform="translate(-138.158 13.588)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M255.23,150.04c0,.293.293,0,.4,0a1.547,1.547,0,0,1,0-.69C255.4,149.559,255.4,149.935,255.23,150.04Z"
          transform="translate(-137.764 14.607)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.68,142.877c.23.125.544-.23.669-.481C257.244,142.229,257.035,142.773,256.68,142.877Z"
          transform="translate(-142.517 6.99)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.65,144.357c0,.4.188-.293.251.125v-.293Z"
          transform="translate(-142.006 8.98)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.72,143.2c0,.146.146,0,.209,0a.586.586,0,0,1,0-.376S256.8,143.2,256.72,143.2Z"
          transform="translate(-142.181 7.486)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.58,143.253c0,.188.188.334.209.125a.167.167,0,0,0-.209-.125Z"
          transform="translate(-141.748 7.952)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.274,143.142c.146.167.439.355.5,0s-.146,0-.1-.272C256.63,143.142,256.442,143.142,256.274,143.142Z"
          transform="translate(-141.108 7.54)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M255.7,142.978c0,.4.773.272.606-.188V143C256.3,143.083,255.929,143.229,255.7,142.978Z"
          transform="translate(-139.445 7.453)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.466,144.38a1.027,1.027,0,0,0,0,.293s0-.5.209-.314,0-.1,0-.209S256.591,144.4,256.466,144.38Z"
          transform="translate(-141.467 8.915)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.347,143.5c0,.293.272,0,.355-.1a1.286,1.286,0,0,1,0-.209c.188.146.1-.167.23-.23s0,.125,0,.125.188,0,.146-.293c-.209.125-.188.1-.418,0v.23a.167.167,0,0,0-.188,0v-.355c0-.1.188,0,.1-.23l-.209.23c.188,0,0,.251,0,.5,0-.146.1,0,.209,0C256.661,143.622,256.473,143.475,256.347,143.5Z"
          transform="translate(-141.557 7.061)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.36,144.5a.128.128,0,0,1,.23,0,.23.23,0,0,1,0-.23A.314.314,0,0,0,256.36,144.5Z"
          transform="translate(-141.089 9.067)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.18,142.955c.146.251.209-.167.23-.314s0,.167.125.146C256.389,142.307,256.326,142.893,256.18,142.955Z"
          transform="translate(-140.658 7.246)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M260.39,141.791c0,.314.4,0,.564.1C260.829,141.561,260.6,141.624,260.39,141.791Z"
          transform="translate(-153.878 6.215)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.759,141.77a.363.363,0,0,0,.5.376C258.282,141.812,258.052,141.874,257.759,141.77Z"
          transform="translate(-145.687 6.341)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.873,147.952c-.125,0-.481-.146-.355.314.439-.188.606.585.418.941s.188.146.188.418C258.374,149.122,257.977,148.6,257.873,147.952Z"
          transform="translate(-145.069 13.058)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.445,149.14c-.1-.4.146-.857,0-.983s0,.606-.125,1c.125,0,.146,0,.167.251.46-.355.69.146,1.254,0-.1-.251-.314-.355-.4-.1,0-.585-.334-.4-.648-.794v.69Z"
          transform="translate(-142.157 13.291)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.618,147.25c-.1,0-.669,0-.125.188A.314.314,0,0,1,254.618,147.25Z"
          transform="translate(-134.727 12.317)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.84,148.221c0,.188.188,0,.251.188a.209.209,0,0,1,0,.125c.272.418.606,0,.962.125-.146-.355.648-.564.773-.188v-.209c0-.021.167.251.293.293a.4.4,0,0,1,0-.251c.167,0,.188-.335.314-.481,0,.188.167.125.251,0s0-.23.167-.209c-.23-.146-.314-.355-.543-.334s0,0,0,.188a.4.4,0,0,1-.439.23.334.334,0,0,0,0,.314c-.146-.188-.439,0-.543-.272,0,0-.125.146-.23.146h.209a.4.4,0,0,1,0,.188A12.3,12.3,0,0,1,254.84,148.221Z"
          transform="translate(-139.172 12.349)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.443,146.59c0,.167-.272.125-.293.334s.335-.23.418-.167S256.464,146.632,256.443,146.59Z"
          transform="translate(-140.65 11.597)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.034,145.536c0,.753,1.484.523,1.129-.376v.439C257.786,145.327,257.389,145.933,257.034,145.536Z"
          transform="translate(-144.125 10.038)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M255.27,145.8c0,.314.4,0,.564,0C255.709,145.545,255.479,145.608,255.27,145.8Z"
          transform="translate(-138.055 10.551)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.88,145.508h0v.188c0,.188.125-.209.23-.272S254.88,145.675,254.88,145.508Z"
          transform="translate(-136.536 10.317)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M253.614,146.407c0,.1,0,.272.23.23a.831.831,0,0,1,.335,0c.1,0,.1.272,0,.251s.711-.146.9-.46c.125.4.251-.167.355.23v-.272a.458.458,0,0,1,.5,0c.167.146,0-.5,0-.251,0-.272-.209-.125-.188.146s-.251,0-.376.146-.1-.1-.1-.251-.209.188-.4,0c.146.669-.481,0-.627.564C254.179,146.365,253.928,146.512,253.614,146.407Z"
          transform="translate(-134.769 10.944)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.464,146.11c0,.125,0-.1.167,0s0-.167,0-.314S254.611,146.068,254.464,146.11Z"
          transform="translate(-135.242 10.698)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M253.64,146.024a1.024,1.024,0,0,0,.46-.167S253.661,145.773,253.64,146.024Z"
          transform="translate(-132.913 10.783)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M258.85,145.837c.251.251.523-.209.46-.334s.376.167.564.272a3.657,3.657,0,0,0-.481-.794c-.188,0-.481.418-.188.481C259.059,145.586,258.892,145.628,258.85,145.837Z"
          transform="translate(-149.579 9.841)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.76,145.294c.46.355,0-.481.376,0a3.051,3.051,0,0,0-.251-.314Z"
          transform="translate(-142.472 9.841)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.62,145.232a1.942,1.942,0,0,0,.335.23c0-.125-.439-.544,0-.523,0-.188-.188,0-.293-.125S256.766,145.044,256.62,145.232Z"
          transform="translate(-141.997 9.631)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.562,145.305c0-.188,0-.272.146-.314-.125,0-.167-.188-.146-.272A.439.439,0,0,0,256.562,145.305Z"
          transform="translate(-141.395 9.558)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.338,145.006c-.418-.272-.125-.355-.167-.544S257.025,145.257,257.338,145.006Z"
          transform="translate(-143.405 9.251)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M255.924,145.174c.23,0,.46,0,0-.335C255.756,144.9,256.07,145.174,255.924,145.174Z"
          transform="translate(-139.67 9.689)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M265.434,75.671a.633.633,0,0,0,.4-.146c0-.5-1-.69-.585-1.254.314,0,0,.418.334.418s-.167-.293,0-.648h-.836c0,.669.167.544.188,1.4.439-.314.272.4.46.334-.544,0-.4.355,0,.46-.1.251-.376,0-.523.167.335.209,0,.92.5.711a.151.151,0,0,0,.167.146c.167,0-.627,0-.523.314.439-.334.4.125.606.23.753-.544,0-1.463.272-2.091a3.417,3.417,0,0,0-.46-.042Z"
          transform="translate(-167.996 -67.52)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M258.955,78.33c.627.209.376.732.439,1.212C259.77,79.208,260.021,78.309,258.955,78.33Z"
          transform="translate(-149.642 -62.842)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M261.923,79.77a.5.5,0,0,0-.314.857C262.132,80.376,261.463,80.084,261.923,79.77Z"
          transform="translate(-157.083 -61.271)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M259.983,79.63c-.125,0-.188.251-.272.125s.167.334.46.251a.293.293,0,0,0,0-.334A.136.136,0,0,1,259.983,79.63Z"
          transform="translate(-151.694 -61.424)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M262.156,80.661c-.23-.209-.125.481,0,.481s.1,0,.125-.1,0,0,.125,0S262.051,80.932,262.156,80.661Z"
          transform="translate(-158.759 -60.343)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M265.44,79.585A1.836,1.836,0,0,0,266.8,78.5C266.339,78.268,266,79.251,265.44,79.585Z"
          transform="translate(-170.28 -62.696)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M265.45,79.874c0,.251.334,0,.481,0-.167-.334-.146-.209-.188-.564C265.575,79.435,265.638,79.749,265.45,79.874Z"
          transform="translate(-169.433 -61.773)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M265.07,79.917c.167.272.523.481.5.125A.439.439,0,0,0,265.07,79.917Z"
          transform="translate(-168.279 -61.146)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M264.46,79.628c.335.188,1.024.376,1.129,0-.23-.188-.334,0-.314-.418C265.192,79.482,264.815,79.544,264.46,79.628Z"
          transform="translate(-167.021 -61.882)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M263.224,79.305c0,.648,1.777,0,1.254-.585-.1,0,0,.23,0,.376C264.081,78.95,263.7,79.556,263.224,79.305Z"
          transform="translate(-163.423 -62.416)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M265.084,80.272c.272.439.564-.188.753-.334a.669.669,0,0,1-.188-.314c.439.188.146-.314.418-.481s.125.209.251.188.355-.209.209-.564c-.4.314-.355.251-.878.23v.355a.418.418,0,0,0-.4.23c0-.314,0-.439-.272-.564s.418,0,.146-.418c-.146.167-.272.334-.418.481.439,0,.293.355.334.794.1-.272.23,0,.439-.125C265.795,80.21,265.356,80.168,265.084,80.272Z"
          transform="translate(-168.523 -62.547)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M264.35,79.384c.4.355.4-.355.376-.606s.188.251.335.167C264.622,78.151,264.643,79.175,264.35,79.384Z"
          transform="translate(-166.263 -62.495)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M259.993,77.87c-.773.188.544.376-.209.5h.544a3.43,3.43,0,0,1-.335-.5Z"
          transform="translate(-151.893 -63.343)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M260.18,78.21c-.293,0-.355.1-.523.146s.962,0,.606.4c.167.209.188-.209.376-.23C260.472,78.44,260.18,78.44,260.18,78.21Z"
          transform="translate(-151.995 -62.972)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M259.785,78.473c.209.188.335.188.209.46h.418C260.475,78.933,260.245,78.41,259.785,78.473Z"
          transform="translate(-152.082 -62.691)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M265.723,74.68c-.439.314.335.355.627.376,0,0-.314.125-.251.272C267.019,75.014,265.869,74.931,265.723,74.68Z"
          transform="translate(-170.249 -66.822)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M268.378,74.29c-.543,0-.125.4-.209.606C268.65,74.813,268.378,74.52,268.378,74.29Z"
          transform="translate(-177.482 -67.247)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M310.31,142.36c-.439,0-.544.523,0,.376Z"
          transform="translate(-306.818 6.984)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M265.989,82.5a.544.544,0,0,0,.23.585C266.616,82.709,266.051,82.709,265.989,82.5Z"
          transform="translate(-170.954 -58.294)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M266.3,83.43c-.439,0,0,.773.293.272S266.1,83.785,266.3,83.43Z"
          transform="translate(-171.562 -57.28)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M265.932,83.61c-.251.23.209.815.481.4C266.121,84.007,266.246,83.777,265.932,83.61Z"
          transform="translate(-170.772 -57.083)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M265.526,84c-.146.293.167.585.355.669C265.923,84.355,265.61,84.188,265.526,84Z"
          transform="translate(-169.467 -56.658)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M268.89,75.508c0-.293-.5-.125-.46-.418-.314.125,0,.523.314.355a.684.684,0,0,1-.376.23C268.576,75.947,268.785,75.654,268.89,75.508Z"
          transform="translate(-178.35 -66.375)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M310.877,142.6c-.125.272-.167.5.1.627C311.149,142.934,310.94,142.788,310.877,142.6Z"
          transform="translate(-309.309 7.246)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M310.368,144.057a.481.481,0,0,0,.439.376C310.849,143.785,310.577,143.931,310.368,144.057Z"
          transform="translate(-308.215 8.716)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M267.64,78.79s.146,0,0,.146a.543.543,0,0,0,.753,0A1.847,1.847,0,0,0,267.64,78.79Z"
          transform="translate(-176.473 -62.34)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M267.79,78.26c.125.209.314.272.439.481C268.1,78.26,268,78.281,267.79,78.26Z"
          transform="translate(-176.623 -62.918)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M267.709,78.772h-.125c-.125,0,.293.167.376.4s0-.209,0-.167a4.378,4.378,0,0,0,0-.523.857.857,0,0,1-.439-.209.585.585,0,0,0,0,.418C267.542,79.064,267.584,78.584,267.709,78.772Z"
          transform="translate(-175.747 -62.907)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.576,77.91V78.1c.1.146.209,0,.335,0S269.722,77.91,269.576,77.91Z"
          transform="translate(-182.067 -63.299)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M268.884,79.029c0,.125,0,.146.167.125s0-.125,0-.125S268.884,78.9,268.884,79.029Z"
          transform="translate(-179.808 -62.14)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M268.764,79.2h.251c.125,0-.125-.188-.23-.167a.125.125,0,0,1-.021.167Z"
          transform="translate(-179.478 -62.079)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M267.944,76.652c0,.334.23,0,.376.146s-.167.188-.251.146c0,.5.355-.251.4.293h.272c.125,0-.146,0-.23-.1s-.125-.209,0-.523a.606.606,0,0,1-.565.042Z"
          transform="translate(-177.488 -64.717)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M311.423,144.879c.167.272.5.376.355-.314C311.527,144.481,311.673,144.962,311.423,144.879Z"
          transform="translate(-311.423 9.378)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M309.69,146.3c0,.5.732.251.794,0s.23.5.314.815a4.577,4.577,0,0,0,.272-1.3c-.167-.23-.92,0-.669.334C310.129,146.3,309.92,146.153,309.69,146.3Z"
          transform="translate(-307.056 10.655)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M270.07,75.094a.334.334,0,0,0,.335.251V74.78h-.23C270.321,75.031,270.3,75.114,270.07,75.094Z"
          transform="translate(-183.565 -66.713)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M268.24,80.388c.481.209,1.045-.46,1.317-.962C269.181,79.134,268.763,80.179,268.24,80.388Z"
          transform="translate(-178.891 -61.701)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M268.41,81c0,.314.272,0,.4,0a1.525,1.525,0,0,1,0-.69C268.556,80.519,268.577,80.9,268.41,81Z"
          transform="translate(-178.497 -60.682)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.88,75.317c0,.4.209-.293.251.125V75.15Z"
          transform="translate(-182.894 -66.309)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.9,74.185c0,.167.146,0,.209,0a1.086,1.086,0,0,1,0-.355Z"
          transform="translate(-182.914 -67.749)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.77,74.213c0,.188.209.355.209.125a.167.167,0,0,0-.209-.125Z"
          transform="translate(-182.512 -67.338)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.452,74.1c.125.146.418.334.5.1s-.167,0-.125-.293C269.786,74.1,269.619,74.1,269.452,74.1Z"
          transform="translate(-181.838 -67.661)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M268.876,73.938c0,.4.773.272.585-.188v.209C269.461,74.043,269.064,74.147,268.876,73.938Z"
          transform="translate(-180.155 -67.836)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.656,75.358v.293c0,.1,0-.5.209-.314s-.1,0-.1-.209S269.781,75.379,269.656,75.358Z"
          transform="translate(-182.23 -66.392)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.515,74.456c0,.293.251,0,.355-.1a.272.272,0,0,1,0-.209c.167.167,0-.167.209-.23s0,.125.1.125.167,0,.125-.293c-.188.125-.167,0-.4,0v.23a.167.167,0,0,0-.188,0V73.62c0-.1.188,0,0-.23l-.23.23c.188,0,0,.251,0,.5,0-.125,0,0,.188,0S269.64,74.435,269.515,74.456Z"
          transform="translate(-182.277 -68.228)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.51,75.467c.1,0,.1-.167.251,0s0-.188,0-.23S269.489,75.237,269.51,75.467Z"
          transform="translate(-181.813 -66.229)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.36,73.915c.146.251.188-.146.209-.293s0,.146.146.125C269.569,73.267,269.485,73.853,269.36,73.915Z"
          transform="translate(-181.391 -68.043)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M310.162,143.842c-.146,0-.5-.146-.376.314.439-.209.606.585.418.941s.188.125.188.418C310.643,145.012,310.267,144.469,310.162,143.842Z"
          transform="translate(-306.608 8.576)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.635,80.1c-.1-.4.167-.857,0-.983s0,.606-.125,1c.125,0,.167,0,.188.272.439-.355.669.125,1.233,0,0-.251-.293-.334-.4,0,0-.564-.335-.4-.627-.794v.69Z"
          transform="translate(-182.921 -61.998)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M267.8,78.21c-.1,0-.648,0-.125.188A1.452,1.452,0,0,1,267.8,78.21Z"
          transform="translate(-175.482 -62.972)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M268,79.182c0,.209.167,0,.23.188a.312.312,0,0,1,0,.125c.251.418.585,0,.941.125-.146-.355.669-.564.773-.188.125,0,0-.188.125-.209s.167.272.272.293a.686.686,0,0,1,0-.251c.146,0,.188-.334.293-.481,0,.188.188.125.272,0s0-.23.146-.188c-.209-.167-.293-.376-.523-.355s0,0,0,.188a.4.4,0,0,1-.439.23.4.4,0,0,0,0,.314c-.125-.188-.418.125-.523-.272a.424.424,0,0,1-.251.146h.23v.188A7.858,7.858,0,0,1,268,79.182Z"
          transform="translate(-179.885 -62.94)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.612,77.55c0,.167-.251.125-.272.334s.313-.23.4-.167S269.653,77.592,269.612,77.55Z"
          transform="translate(-181.392 -63.692)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M270.214,76.5c0,.753,1.505.523,1.15-.376-.1,0,0,.272,0,.439C270.987,76.287,270.59,76.893,270.214,76.5Z"
          transform="translate(-184.879 -65.251)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M268.46,76.753c0,.314.376,0,.544,0C268.878,76.5,268.669,76.585,268.46,76.753Z"
          transform="translate(-178.798 -64.734)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M268.063,76.478h0v.188c0,.188.146-.209.23-.272S268.084,76.625,268.063,76.478Z"
          transform="translate(-177.272 -64.962)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M266.878,77.367s.125.272.251.23a.79.79,0,0,1,.314,0c.1,0,.125.272,0,.251s.69-.146.9-.46c.1.4.23-.167.334.23v-.272c0-.063.355-.146.5,0s-.1-.5,0-.251c0-.272-.209-.125-.209.146a.46.46,0,0,0-.376.146v-.251c0-.251-.188.209-.376,0,.125.669-.5,0-.627.564C267.567,77.325,267.3,77.472,266.878,77.367Z"
          transform="translate(-175.71 -64.345)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M267.64,77.07c0,.146,0,0,.188,0s0-.167,0-.314S267.807,77.028,267.64,77.07Z"
          transform="translate(-175.992 -64.591)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M266.78,76.981a1.233,1.233,0,0,0,.46-.167S266.78,76.751,266.78,76.981Z"
          transform="translate(-173.522 -64.503)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.94,76.254c.46.355,0-.481.4,0,0,0-.167-.209-.272-.314a1.421,1.421,0,0,1-.125.314Z"
          transform="translate(-183.226 -65.448)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.84,76.116a1.942,1.942,0,0,0,.335.23c0-.125-.439-.544,0-.523,0-.188-.209,0-.293,0S270.049,76.012,269.84,76.116Z"
          transform="translate(-182.854 -65.666)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.732,76.241c0-.188,0-.272.167-.293-.125-.125-.167-.209-.146-.293S269.481,76.011,269.732,76.241Z"
          transform="translate(-182.14 -65.769)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M270.643,75.96c-.439-.272-.146-.355-.167-.544C270.121,75.291,270.33,76.231,270.643,75.96Z"
          transform="translate(-184.263 -66.032)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M269.112,76.175c.209,0,.439,0,0-.334C268.987,75.82,269.28,76.008,269.112,76.175Z"
          transform="translate(-180.454 -65.558)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.08,133.532c.335.146.753-.335.941-.669C237.749,132.633,237.456,133.385,237.08,133.532Z"
          transform="translate(-82.215 -3.42)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.1,135.55c.1.564.293-.376.355.188v-.418a1.3,1.3,0,0,1-.355.23Z"
          transform="translate(-81.691 -0.693)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.2,133.971c0,.209.209,0,.293,0a.94.94,0,0,1,0-.481C237.325,133.615,237.325,133.887,237.2,133.971Z"
          transform="translate(-81.938 -2.689)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237,134.014c0,.23.272.46.293.167S237.125,134.014,237,134.014Z"
          transform="translate(-81.319 -2.146)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.582,133.8c.167.209.585.481.669.146s-.188,0-.146-.376C237.042,133.8,236.812,133.8,236.582,133.8Z"
          transform="translate(-80.421 -2.601)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.231,124.071c0,.544,1.045.376.794-.251v.293C250.026,124.238,249.482,124.364,249.231,124.071Z"
          transform="translate(-119.661 -13.234)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M228.865,114.483s.146-.125.188,0,.188-.523,0-.774A3.415,3.415,0,0,1,228.865,114.483Z"
          transform="translate(-56.168 -24.259)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.25,114.176c.23,0,.334-.251.544-.335C229.313,113.82,229.313,113.925,229.25,114.176Z"
          transform="translate(-57.619 -24.119)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.439,113.736c.1,0,0,0,0,.146s.23-.251.46-.293-.188,0-.167,0a4.189,4.189,0,0,1-.5,0,1.171,1.171,0,0,1-.293.418.669.669,0,0,0,.4.125C229.689,113.966,229.23,113.82,229.439,113.736Z"
          transform="translate(-57.138 -24.41)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M228.613,113.832c.314,0,.125-.209.209-.314s.46,0,0-.188C228.613,113.518,228.738,113.623,228.613,113.832Z"
          transform="translate(-55.539 -24.673)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M228.634,113.132c.1,0,.167-.355.23-.5a.523.523,0,0,0-.23.5Z"
          transform="translate(-55.393 -25.437)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.188,112.517c-.146,0,0-.125,0-.23C228.958,112.2,228.917,112.538,229.188,112.517Z"
          transform="translate(-56.491 -25.825)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.651,112.246c.146,0,0-.125.1-.272C229.463,111.912,229.588,112.2,229.651,112.246Z"
          transform="translate(-58.25 -26.161)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.054,115.84h.188c.167,0,0-.209,0-.334S230.075,115.693,230.054,115.84Z"
          transform="translate(-59.824 -22.333)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M228.929,115.156a.125.125,0,0,0,.146-.146h-.125S228.929,115.135,228.929,115.156Z"
          transform="translate(-56.232 -22.841)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.06,115.131v-.251c-.23,0-.209,0-.209.209a.167.167,0,0,1,.209.042Z"
          transform="translate(-56.049 -22.983)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.3,108.226c.293-.209.293-.334.627-.648-.125,0-.23,0-.251-.188s0,.376-.439.209C231.386,107.85,231.24,107.871,231.3,108.226Z"
          transform="translate(-63.915 -31.193)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.83,114.371c.355,0,0-.251.23-.355s.167.188.1.272c.481,0-.188-.4.355-.334a.627.627,0,0,1,0-.293c-.209,0,0,.125-.146.209s-.23,0-.5,0a.585.585,0,0,1-.042.5Z"
          transform="translate(-62.648 -24.314)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.327,112.64h.376c.23,0,0,0,0-.146S229.264,112.473,229.327,112.64Z"
          transform="translate(-57.78 -25.656)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.324,111.675c.167,0,0-.251,0-.293A1.182,1.182,0,0,1,230.324,111.675Z"
          transform="translate(-60.47 -26.802)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.969,111.093a1.8,1.8,0,0,0,.293-.293C230.927,110.78,230.927,110.968,230.969,111.093Z"
          transform="translate(-62.641 -27.433)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M228.2,108.918c.418,0,.794,0,.857-.314a2.445,2.445,0,0,0-.962,0,.334.334,0,0,1,.1.314Z"
          transform="translate(-54.483 -29.878)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.76,123.781c.251.209.335-.23.5-.272C241.032,123.321,240.885,123.51,240.76,123.781Z"
          transform="translate(-93.149 -13.655)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.017,114.069c.314,0,.523-.146.481-.439C236.143,113.651,236.143,113.9,236.017,114.069Z"
          transform="translate(-78.476 -24.346)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.021,112.1c.523,0,0-.69.125-1.024-.355,0,0,.46-.46.272C236.167,111.6,235.916,111.707,236.021,112.1Z"
          transform="translate(-77.539 -27.127)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.362,115.768a.334.334,0,0,0,.293-.293c-.23,0-.272-.125-.523-.125s0,.167,0,.23S233.424,115.538,233.362,115.768Z"
          transform="translate(-69.361 -22.471)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.771,119.531c.293.355.732.167.293-.251Z"
          transform="translate(-93.16 -18.185)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.688,113.649c.314,0,.188-.46,0-.544S236.834,113.461,236.688,113.649Z"
          transform="translate(-80.129 -24.93)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M239.27,112.943a.606.606,0,0,0,.335-.523C239.082,112.3,239.375,112.776,239.27,112.943Z"
          transform="translate(-88.377 -25.687)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.458,112.45c.23.4.606-.481,0-.4C238.312,112.241,238.876,112.429,238.458,112.45Z"
          transform="translate(-85.809 -26.072)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.4,112.327c.335,0,.564-.627,0-.627C238.627,111.909,238.377,111.93,238.4,112.327Z"
          transform="translate(-85.685 -26.451)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M245.269,90.372h.314c-.167-.376,0-.355,0-.564C245.227,89.7,245.227,90.225,245.269,90.372Z"
          transform="translate(-106.856 -50.34)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.52,110.9c.5.1.209-.355.334-.523C232.457,110.36,232.562,110.673,232.52,110.9Z"
          transform="translate(-67.516 -27.891)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.813,89.492a1.43,1.43,0,0,1-.334-.251c-.418.314,0,1.233-.711,1.213-.188-.251.293-.293.146-.523s-.125.314-.564.334c.293.4.23.251.523.648.585-.334.376-.46,1.045-.962-.5-.188.188-.439,0-.564.355.418.523.146.335-.314.272,0,.167.355.439.355,0-.418.815-.46.314-.836v-.209c0-.084.293.544.543.251-.5-.167,0-.4-.146-.627-.857-.314-1.192.836-1.84.92a3.1,3.1,0,0,0,.251.564Z"
          transform="translate(-91.488 -52.346)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.321,111.78c.334,0,.418-.481.355-.69C238.425,111.215,238.467,111.571,238.321,111.78Z"
          transform="translate(-85.483 -27.116)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.378,110.755c.272-.125.1-.544.606-.5C236.691,110,236.085,110.567,236.378,110.755Z"
          transform="translate(-79.547 -28.098)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M228.834,115.945c.314-.439-.23-1.15-.669-1.505C227.81,114.774,228.751,115.381,228.834,115.945Z"
          transform="translate(-54.318 -23.463)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.753,115.2c.293,0,0-.272.167-.376a1.547,1.547,0,0,1-.69,0A.832.832,0,0,0,227.753,115.2Z"
          transform="translate(-51.522 -23.048)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.6,115.056c.355,0,.711-.251.314-.376A.376.376,0,0,0,226.6,115.056Z"
          transform="translate(-49.363 -23.201)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.178,114.92c.335-.188.794-.69.376-.92-.293.125,0,.293-.564,0A.849.849,0,0,1,227.178,114.92Z"
          transform="translate(-50.823 -23.943)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.145,114.019c.711.188.794-1.38-.146-1.213a.705.705,0,0,1,.4.125C227.082,113.225,227.584,113.726,227.145,114.019Z"
          transform="translate(-50.81 -25.258)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.493,111.684c.314,0,0-.376.209-.544C226.347,111.2,226.368,111.433,226.493,111.684Z"
          transform="translate(-48.59 -27.062)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.767,108.978c.314,0,.523-.146.481-.418S226.018,108.811,225.767,108.978Z"
          transform="translate(-46.799 -29.959)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.38,98.5c.418.293.439-.523.753-.69-.23-.293-.23.376-.523,0C256.819,98.291,256.547,98.123,256.38,98.5Z"
          transform="translate(-141.674 -41.682)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M228.968,115.675c.585,0,0-.523,0-.732a.836.836,0,0,1-.418,0c.376-.272-.272-.23-.355-.5s.272,0,.293-.125,0-.376-.523-.376c.188.418.146.376,0,.794h.418a.376.376,0,0,0,0,.418c-.335,0-.46-.146-.69,0s.125-.355-.4-.293l.376.523c.209-.335.481,0,.962,0-.23-.167,0-.188,0-.376C229.177,115.09,228.989,115.424,228.968,115.675Z"
          transform="translate(-52.968 -24.008)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M228.1,114.382c.523-.188-.23-.439-.5-.523.1,0,.334,0,.293-.209C226.955,113.8,228.042,114.152,228.1,114.382Z"
          transform="translate(-52.48 -24.324)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.839,115.613c.146-.23-.125-.585-.355-.774C234.3,115.007,234.776,115.321,234.839,115.613Z"
          transform="translate(-73.556 -23.027)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.865,115.462c.4,0-.23-.272.167-.23h-.293a1.527,1.527,0,0,1,.125.23Z"
          transform="translate(-68.154 -22.604)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.324,115.259c.146,0,0-.146,0-.209a.69.69,0,0,1-.355,0A1.96,1.96,0,0,1,234.324,115.259Z"
          transform="translate(-72.08 -22.798)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.666,115.148c.188,0,.376-.125.167-.188a.188.188,0,0,0-.167.188Z"
          transform="translate(-70.982 -22.896)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.946,115.109c.167,0,.418-.355.209-.481s0,.146-.293,0A.94.94,0,0,1,233.946,115.109Z"
          transform="translate(-71.701 -23.295)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.857,114.622c.376,0,.418-.732,0-.627h.209C234.15,114,234.046,114.455,233.857,114.622Z"
          transform="translate(-71.613 -23.959)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.9,115.336a.585.585,0,0,0,.272,0s-.481-.125-.251-.272-.125,0-.23,0S232.92,115.231,232.9,115.336Z"
          transform="translate(-68.146 -22.853)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.89,115.425c.314,0,0-.251,0-.355h-.209c.188-.146-.146-.125-.188-.272s.146,0,.146,0,0-.188-.272-.188c.1.209,0,.188,0,.4h.209a.209.209,0,0,0,0,.209h-.355c-.125,0,0-.188-.188-.146a1.966,1.966,0,0,0,.188.251c.1-.167.23,0,.5,0-.125,0,0,0,0-.209S234.911,115.3,234.89,115.425Z"
          transform="translate(-72.855 -23.278)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.845,115.215s-.125-.146,0-.251-.167,0-.188,0A.192.192,0,0,0,232.845,115.215Z"
          transform="translate(-67.799 -22.942)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.457,114.771c.272,0-.125-.23-.251-.272s.167,0,.146,0C233.85,114.437,234.415,114.625,234.457,114.771Z"
          transform="translate(-72.609 -23.418)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.68,88.34c.585.544,0-.669.544,0a3.1,3.1,0,0,0-.335-.46A2.717,2.717,0,0,1,241.68,88.34Z"
          transform="translate(-96.034 -52.427)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.708,123.672c.376,0,1.066,0,.9-.439-.293,0-.251.167-.5-.293C241.23,123.254,241.021,123.463,240.708,123.672Z"
          transform="translate(-93.41 -14.194)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M239.984,123c.418.627,1.526-.5.69-1a2.8,2.8,0,0,0,.23.355C240.486,122.376,240.528,123.087,239.984,123Z"
          transform="translate(-91.244 -15.219)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.237,116.251c.314,0,.523-.146.481-.418S235.363,116.251,235.237,116.251Z"
          transform="translate(-76.066 -22.013)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.221,114.273c.523,0,0-.669.125-1-.355,0,0,.439-.46.272C235.221,113.793,235.075,113.9,235.221,114.273Z"
          transform="translate(-75.067 -24.739)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.47,88.164c.167.251.293.23.439.355s-.543-.794,0-.711c0-.272-.293,0-.418-.209C241.491,87.809,241.658,88.039,241.47,88.164Z"
          transform="translate(-95.301 -52.732)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.107,124.489c.481.355.355-.376.439-.585a.858.858,0,0,1-.355-.251c.46,0,0-.355,0-.606s.23.167.314,0,.188-.314-.146-.606c-.146.439-.146.376-.585.523l.293.314a.355.355,0,0,0-.209.355c-.209-.293-.251-.418-.523-.439s.314-.188,0-.481v.627c.4,0,.439.251.732.648,0-.293.146-.146.293-.251C242.651,124.175,242.275,124.3,242.107,124.489Z"
          transform="translate(-95.521 -14.739)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.341,88.335c0-.251,0-.376.251-.418a.418.418,0,0,1-.167-.4S241.027,88,241.341,88.335Z"
          transform="translate(-94.44 -52.82)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.042,123.189c.523.209.125-.481,0-.711s.272.167.355,0C240.6,121.956,241.188,122.939,241.042,123.189Z"
          transform="translate(-93.723 -14.861)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.48,106.2c.209,0,.46,0,.46-.272s-.544,0-.962,0C229.9,106.2,230.439,105.954,230.48,106.2Z"
          transform="translate(-60.271 -32.872)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230,103.864c.314,0,0-.4.188-.544C229.706,103.341,230,103.634,230,103.864Z"
          transform="translate(-59.391 -35.589)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.827,83.247c0,.314.481-.167.334-.314s0,0-.146,0h-.146C236.722,82.934,237.14,83.143,236.827,83.247Z"
          transform="translate(-80.854 -57.892)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.83,101.846a.481.481,0,0,0,.711-.272C255.185,101.512,255.081,101.8,254.83,101.846Z"
          transform="translate(-136.841 -37.502)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.059,124.382c.481,0,.418-.439.5-.732-.355,0-.293.5-.585.167C237.62,123.8,238.142,124.089,238.059,124.382Z"
          transform="translate(-84.364 -13.419)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.3,123.344c-.251-.188-.272,0-.544,0,.1.167,0,.439.355.439a.272.272,0,0,1-.1-.251C238.113,123.49,238.343,123.511,238.3,123.344Z"
          transform="translate(-83.917 -13.845)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.022,123.743c.418-.167-.4-.523,0-.773C237.374,123.033,237.646,123.6,238.022,123.743Z"
          transform="translate(-83.491 -14.161)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.837,111.928c.544,0,.23-.376,0-.4S234.025,111.74,233.837,111.928Z"
          transform="translate(-71.404 -26.637)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.085,89.656c.314.418.585-.125.188-.376A.355.355,0,0,1,246.085,89.656Z"
          transform="translate(-109.491 -50.9)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.905,110.3c.23,0,.439-.376.188-.544S236.01,110.072,235.905,110.3Z"
          transform="translate(-77.946 -28.606)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.849,108.346c.439,0,.669-.355,0-.376Z"
          transform="translate(-62.437 -30.519)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.75,107.733a.544.544,0,0,0,.335-.523C230.562,107.085,230.875,107.566,230.75,107.733Z"
          transform="translate(-62.045 -31.369)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.938,107.2c.209.4.606-.46,0-.376C229.771,107.053,230.335,107.2,229.938,107.2Z"
          transform="translate(-59.456 -31.776)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M244.043,118.513c-.251.125.188.481,0,.606.251.188.46-.23,0-.46a.455.455,0,0,1,.4.188C244.587,118.513,244.231,118.492,244.043,118.513Z"
          transform="translate(-103.059 -19.027)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.83,111.521a.9.9,0,0,0,.732-.251C238.206,110.079,238.624,111.375,237.83,111.521Z"
          transform="translate(-84.324 -27.464)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.1,111.545c0,.125.188,0,.418,0a2.064,2.064,0,0,0-.314-.815C235.7,110.9,236.623,111.4,236.1,111.545Z"
          transform="translate(-78.56 -27.509)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.316,111.907c.335.125-.146-.585,0-.857C231.835,111.238,232.316,111.656,232.316,111.907Z"
          transform="translate(-66.225 -27.16)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.987,110.881c.251,0-.209-.439,0-.711-.376,0,0,.439-.314.355C236.736,110.651,236.966,110.714,236.987,110.881Z"
          transform="translate(-80.407 -28.119)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.8,109.574c.293-.418-.251-1.129-.669-1.484C234.752,108.4,235.693,109.01,235.8,109.574Z"
          transform="translate(-75.811 -30.388)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.99,109.365c.794,0-.46-.5.314-.439,0-.125-.335,0-.544-.146A3.269,3.269,0,0,1,231.99,109.365Z"
          transform="translate(-65.376 -29.635)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.682,108.886c.293,0,.1-.272.188-.376a1.526,1.526,0,0,1-.69,0C234.243,108.635,234.619,108.7,234.682,108.886Z"
          transform="translate(-73.001 -29.93)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.547,108.706c.355,0,.711-.251.314-.376A.355.355,0,0,0,233.547,108.706Z"
          transform="translate(-70.842 -30.126)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.128,108.57c.334-.188.794-.69.376-.92-.293.125,0,.293-.564,0A.882.882,0,0,1,234.128,108.57Z"
          transform="translate(-72.302 -30.867)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.1,107.641c.732.188.794-1.4-.146-1.213a.8.8,0,0,1,.418.125C234.059,106.888,234.561,107.411,234.1,107.641Z"
          transform="translate(-72.316 -32.217)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.965,109.036a1.589,1.589,0,0,0,.564,0c0-.188-.941-.23-.5-.523-.1-.251-.23.146-.418.125C231.715,108.743,232.028,108.806,231.965,109.036Z"
          transform="translate(-65.289 -30.017)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.939,109.3c.585,0,0-.5,0-.711a.857.857,0,0,1-.418,0c.355-.293-.293-.251-.376-.5s.293,0,.293-.146,0-.355-.5-.355c.188.418.146.376,0,.774h.4a.376.376,0,0,0,0,.418c-.355,0-.481-.167-.69,0s0-.334-.4-.272c0,.167.251.335.355.523.23-.335.481,0,.983,0-.251-.167,0-.188,0-.4C236.127,108.719,235.939,109.053,235.939,109.3Z"
          transform="translate(-74.468 -30.933)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.007,108.884c-.146-.209-.272-.272,0-.481-.23,0-.355,0-.4-.188S231.548,108.842,232.007,108.884Z"
          transform="translate(-64.725 -30.282)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.057,108.062c.523-.188-.23-.439-.5-.523.1,0,.334,0,.314-.209C233.907,107.414,234.994,107.769,235.057,108.062Z"
          transform="translate(-73.983 -31.216)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.812,87.528c-.251-.523-1.15-.585-1.672-.5C236.265,87.507,237.248,87.214,237.812,87.528Z"
          transform="translate(-80.041 -53.392)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.494,86.756c.188-.188-.209-.251-.272-.376-.167.314,0,.209-.376.46C236.055,86.924,236.222,86.694,236.494,86.756Z"
          transform="translate(-78.159 -54.063)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.991,86.372c.1-.314.1-.711-.188-.5a.418.418,0,0,0,.188.5Z"
          transform="translate(-77.53 -54.682)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.226,86.592c0-.376-.272-1.066-.669-.9,0,.293.23.23-.167.481C236.683,86.049,236.954,86.362,237.226,86.592Z"
          transform="translate(-79.978 -54.839)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.5,85.921c.523-.4-.9-1.526-1.171-.711.1,0,.209,0,.293-.209C237.743,85.42,238.433,85.4,238.5,85.921Z"
          transform="translate(-83.317 -55.653)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.218,86.7c.23-.46-.46-.355-.69-.439a.71.71,0,0,1-.146.355c-.1-.46-.355,0-.648,0s0-.23,0-.314-.376-.167-.585.146c.481.146.418.146.69.606l.23-.293a.481.481,0,0,0,.418.209c-.23.209-.355.251-.314.523s-.272-.314-.439,0h.648c-.209-.4.125-.439.46-.732-.272,0-.188-.125-.355-.293C237.778,86.154,237.988,86.53,238.218,86.7Z"
          transform="translate(-80.509 -54.234)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.756,86.275c0-.5-.523-.125-.711,0s.1-.272,0-.355C236.627,86.714,237.463,86.087,237.756,86.275Z"
          transform="translate(-81.636 -54.564)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.328,109.844c.293-.439-.251-1.129-.669-1.484C226.282,108.674,227.223,109.28,227.328,109.844Z"
          transform="translate(-49.634 -30.093)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.212,109.2c.293,0,.1-.272.188-.376a1.526,1.526,0,0,1-.69,0C225.794,108.862,226.149,108.925,226.212,109.2Z"
          transform="translate(-46.825 -29.592)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.077,108.955c.355,0,.711-.251.314-.355a.334.334,0,0,0-.314.355Z"
          transform="translate(-44.666 -29.832)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.658,108.84c.335-.209.794-.69.376-.92-.293,0,0,.272-.564,0C225.784,108.087,225.721,108.505,225.658,108.84Z"
          transform="translate(-46.125 -30.573)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.019,99.083c.418.627,1.505-.523.69-1a2.8,2.8,0,0,0,.23.355C257.5,98.435,257.541,99.146,257.019,99.083Z"
          transform="translate(-143.88 -41.304)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M255.38,97.751c.251.209.335-.209.5-.272C255.589,97.291,255.443,97.48,255.38,97.751Z"
          transform="translate(-138.332 -42.041)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.007,120.251c.293,0,.5-.146.46-.418S240.133,120.251,240.007,120.251Z"
          transform="translate(-90.787 -17.651)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.469,109.574c.585,0,0-.5,0-.711a1.234,1.234,0,0,1-.418,0c.355-.293-.293-.251-.376-.523s.293,0,.314-.125,0-.355-.523-.355c.188.418.146.355,0,.774h.4a.355.355,0,0,0,0,.4c-.355,0-.481-.146-.69,0s0-.355-.4-.272l.355.5c.23-.314.481,0,.983,0-.251-.167,0-.209,0-.4C227.678,108.989,227.469,109.323,227.469,109.574Z"
          transform="translate(-48.291 -30.638)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.587,108.322c.523-.188-.23-.439-.5-.523.1,0,.334,0,.314-.209C225.437,107.695,226.525,108.05,226.587,108.322Z"
          transform="translate(-47.807 -30.933)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.635,112.55c.125-.188-.125-.523-.314-.69C225.154,112.006,225.593,112.3,225.635,112.55Z"
          transform="translate(-45.203 -26.276)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.716,112.382c.272,0,0-.23,0-.334a.272.272,0,0,1-.188,0c.167-.125-.146,0-.167-.23s.125,0,.125,0,0-.167-.23-.167,0,.167,0,.355h.188a.188.188,0,0,0,0,.188h-.314c-.1,0,0-.167-.188-.125a.92.92,0,0,1,.167.23c.1-.167.23,0,.439,0-.1,0,0,0,0-.188S225.716,112.382,225.716,112.382Z"
          transform="translate(-44.656 -26.505)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.306,111.8c.23,0-.1-.188-.23-.23s.146,0,.125,0C224.762,111.507,225.264,111.674,225.306,111.8Z"
          transform="translate(-44.372 -26.613)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.4,116.993c-.418,0-.815-.335-.962-.167s.585,0,.941.314c0-.125.125-.125.293-.125-.251-.523.272-.648.146-1.254-.251,0-.4.23-.188.376-.523-.23-.439.251-.878.481h.69A1.606,1.606,0,0,0,229.4,116.993Z"
          transform="translate(-55.931 -22.023)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.37,113.42s0,.669.167.167a.418.418,0,0,1-.167-.167Z"
          transform="translate(-57.613 -24.575)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.3,113.181a.773.773,0,0,0-.23.544c.209-.1.314,0,.5.146a.314.314,0,0,0-.209-.418h.335a.167.167,0,0,1,0-.293S229.572,113.348,229.3,113.181Z"
          transform="translate(-57.146 -24.859)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M228.79,115.735a2.09,2.09,0,0,0,1-1.4h.251c.1,0-.146-.167-.4-.125s.146-.209,0-.334a.272.272,0,0,1,.272,0,5.707,5.707,0,0,1,.523-2.488c-.272.125-.125-.167-.481-.125a.377.377,0,0,0,.1-.146c.146,0,0,.188.313.167-.125-.23,0-.334-.46-.46s.355.293-.188.209c-.146,1.463-.146,2.258-.272,3.909,0,.125-.188,0-.251,0s0,.23.125.272a.376.376,0,0,1-.335,0C228.853,115.4,229,115.693,228.79,115.735Z"
          transform="translate(-57.305 -27.434)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.6,117.1c.188,0,.1-.167.23-.209v.125c.46-.188.167-.585.293-.92-.376,0-.418-.773,0-.815s-.146,0-.167-.167.293,0,.355-.209a.586.586,0,0,1-.272,0c.125-.146-.272-.251-.4-.4.188,0,.146-.146,0-.251s-.209,0-.167-.188c-.188.188-.418.23-.439.46s0,0,.209,0a.376.376,0,0,1,.125.481.376.376,0,0,0,.334,0c-.23,0,0,.46-.376.481a.418.418,0,0,1,0,.272c.272,0,0-.1,0-.23h.188A9.751,9.751,0,0,1,229.6,117.1Z"
          transform="translate(-57.677 -23.866)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.282,115.293c.167,0,0,.272.293.355s-.167-.376,0-.439S230.345,115.272,230.282,115.293Z"
          transform="translate(-60.658 -22.643)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.607,107.929c-.272.439-.167.69-.4,1.15.167,0,.293,0,.293-.209a.9.9,0,0,0,.4,0c-.376-.481,0-.794.272-1.15C232.046,107.908,231.524,108.431,231.607,107.929Z"
          transform="translate(-64.094 -30.791)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.756,107.445c.334.188-.1-.334-.523-.272a.293.293,0,0,1,0,.314C231.526,107.549,231.714,107.215,231.756,107.445Z"
          transform="translate(-63.846 -31.394)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.073,109.236c.293-.209.293-.335.627-.648-.125,0-.23,0-.251-.188-.334,0,0,.376-.439.209C226.156,108.86,226.01,108.881,226.073,109.236Z"
          transform="translate(-47.752 -30.05)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.66,90.868c.523-.125.585-1.024.481-1.568C240.66,89.3,240.973,90.387,240.66,90.868Z"
          transform="translate(-92.86 -50.878)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.639,107.874c.815,0-.439-.5.335-.439,0-.1-.335-.1-.543-.125a4,4,0,0,1,.209.564Z"
          transform="translate(-64.356 -31.238)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.383,107.406c.293,0,0-.272.167-.376a1.547,1.547,0,0,1-.69,0C233.944,107.155,234.257,107.218,234.383,107.406Z"
          transform="translate(-72.012 -31.544)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.222,107.21c.355,0,.732-.251.334-.376S233.222,107.064,233.222,107.21Z"
          transform="translate(-69.849 -31.786)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.8,107.09c.334-.188.815-.69.4-.92-.314.125,0,.293-.585,0C233.926,106.442,233.863,106.755,233.8,107.09Z"
          transform="translate(-71.305 -32.481)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.825,117.009c.711.209.794-1.38-.146-1.213a.7.7,0,0,1,.4.125C231.7,116.235,232.264,116.716,231.825,117.009Z"
          transform="translate(-65.274 -21.997)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.173,114.663c.314,0,.1-.376.209-.523C231.027,114.182,231.048,114.433,231.173,114.663Z"
          transform="translate(-63.054 -23.79)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.656,107.556a1.589,1.589,0,0,0,.564,0c0-.188-.941-.23-.5-.523,0-.251-.251.146-.439.125C231.426,107.263,231.719,107.347,231.656,107.556Z"
          transform="translate(-64.29 -31.631)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.493,107.845c.585,0,0-.523,0-.732a.962.962,0,0,1-.439,0c.376-.272-.272-.23-.355-.5s.293,0,.293-.146,0-.355-.5-.355c.167.418.125.376,0,.794h.418a.376.376,0,0,0,.1.418c-.355,0-.481-.146-.711,0s.125-.334-.376-.272c.1.188.251.335.355.523.23-.334.481,0,.983,0-.251-.167,0-.188,0-.4C235.6,107.239,235.6,107.573,235.493,107.845Z"
          transform="translate(-73.353 -32.547)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.677,107.363c-.146-.209-.272-.251,0-.481-.23,0-.355,0-.4-.188S231.218,107.363,231.677,107.363Z"
          transform="translate(-63.705 -31.939)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M239.689,89.989c.523.209.125-.481,0-.732s.272.188.355,0C239.209,88.839,239.836,89.738,239.689,89.989Z"
          transform="translate(-89.507 -51.044)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.377,108.959c-.272.439-.167.69-.4,1.15.167,0,.293,0,.293-.209a.9.9,0,0,0,.4,0c-.376-.481,0-.794.272-1.15C226.9,108.938,226.294,109.461,226.377,108.959Z"
          transform="translate(-47.931 -29.668)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.526,108.475c.335.188-.1-.335-.523-.272a.292.292,0,0,1,.1.314C226.3,108.579,226.484,108.245,226.526,108.475Z"
          transform="translate(-47.683 -30.27)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.989,107.762h.418c-.606-.167.1-.941-.857-.962,0,.167.167.167.4.209S229.926,107.553,229.989,107.762Z"
          transform="translate(-58.86 -31.794)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M228.173,109.512c0-.125-.188-.146-.146-.293s-.4.188-.293.355-.167,0-.314,0,0,.209,0,.335-.167,0-.146-.146,0,.146-.355.188.355,0,.418,0,.23.146.209.251,0,0,.272,0S227.8,109.616,228.173,109.512Z"
          transform="translate(-50.919 -29.196)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.256,113.961h.188c.188,0-.167-.188-.23-.293S231.4,113.982,231.256,113.961Z"
          transform="translate(-63.409 -24.322)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.457,114.986c.125,0,.272,0,.272-.188-.335,0,.125-.209,0-.334s.293,0,.251,0,0-.732-.272-.983c.418,0-.125-.272.293-.293h-.251a3.883,3.883,0,0,1,0-.5c0-.125-.5,0-.23-.146-.251,0-.167.188.1.23v.418c0,.167-.125,0-.272,0s.146.23,0,.4c.69,0,0,.5.439.732C230.519,114.421,230.624,114.714,230.457,114.986Z"
          transform="translate(-61.042 -25.535)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.133,114.989a.188.188,0,0,0,.146-.209h.314c.146,0,.188-.293.314-.418-.4,0-.167-.774-.69-.857a.167.167,0,0,0,0-.146.4.4,0,0,1-.272-.355c-.439-.209-.5-.627-.878-.773,0,0-.1.314,0,.251-.251.606.5,1.777-.376,2.091,0-.146.335-.209.23-.46-.188.167-.481.167-.669.418s0-.23-.188-.209,0,.125,0,.251c.439-.125.335.4.272.5s0,0,.251,0-.125-.251,0-.334.125.439.167.711c.1-.251.753-.46.983-.753s0-.334.293-.334c0,0-.209,0-.167-.188s.355,0,.293-.23a.4.4,0,0,1,.251,0c.272.125-.146.481.209.439s-.125,0-.125,0,0-.167-.188-.125,0,.167.167.167S230.133,114.76,230.133,114.989Z"
          transform="translate(-56.077 -25.873)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.1,113.588c.125,0,0,0,0-.167a.376.376,0,0,0-.335,0C230.91,113.462,231.077,113.42,231.1,113.588Z"
          transform="translate(-62.143 -24.617)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.74,113.011a1.106,1.106,0,0,0,0-.481S230.51,112.969,230.74,113.011Z"
          transform="translate(-61.492 -25.546)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.55,112.59c-.544,0,0,.418,0,.627C229.633,112.987,229.7,112.736,229.55,112.59Z"
          transform="translate(-57.584 -25.48)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M228.7,110.547c-.188,0,0,.167-.439.1.272,0,.125.376.544.4-.167.23-.46.376-1.129.272-.606.439-.188,1.129.355,1.463,1,.251,1.756.125,1.944-.251s-.815-1.254-.314-1.652a1.091,1.091,0,0,0-.314-.878c-.314-.23-.188,0-.5,0s0,.5-.4.439A.313.313,0,0,0,228.7,110.547Z"
          transform="translate(-53.914 -28.413)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M239.458,119.305a.564.564,0,0,0,0-.585C239.04,119.075,239.332,119.305,239.458,119.305Z"
          transform="translate(-88.251 -18.795)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.91,116.662v-.146a.608.608,0,0,0-.46-.585A6.138,6.138,0,0,0,241.91,116.662Z"
          transform="translate(-95.239 -21.838)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.925,116.917a.966.966,0,0,1,.146-.627C241.674,116.624,241.82,116.708,241.925,116.917Z"
          transform="translate(-96.153 -21.445)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.033,116.434h.167c.042,0,0-.334,0-.523s-.167,0,0,.146a4.824,4.824,0,0,0-.4.293.94.94,0,0,1,0,.481.585.585,0,0,0,.376-.209C242.388,116.413,241.949,116.643,242.033,116.434Z"
          transform="translate(-96.303 -21.945)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.208,118.423l.146-.125c0-.167-.146-.167-.209-.251S241.1,118.3,241.208,118.423Z"
          transform="translate(-93.973 -19.544)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.9,117.108v-.188c0-.042,0,0-.1,0S240.856,117.108,240.9,117.108Z"
          transform="translate(-92.785 -20.779)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.689,116.994v.063C240.689,117.141,240.668,116.973,240.689,116.994Z"
          transform="translate(-92.409 -21)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.23,118.292c.272-.23-.167-.188,0-.4s.251,0,.251.1c.355-.355-.4-.125,0-.481a.481.481,0,0,1-.23-.188c-.188.125,0,.167,0,.251s0,.23-.418.314a.544.544,0,0,1,.4.4Z"
          transform="translate(-99.8 -20.311)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.508,119.65c.125-.293,0-.627-.46-.125C238.132,119.775,238.445,119.4,238.508,119.65Z"
          transform="translate(-84.772 -18.199)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.745,118.412c.4-.293-.209-.753-.4-.69s.293-.481.5-.732a4.18,4.18,0,0,0-1.212.523c0,.251.5.753.648.355C238.431,118.077,238.473,118.328,238.745,118.412Z"
          transform="translate(-84.235 -20.682)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.606,116.682c0-.5-.962-.606-1.526-.544C240.059,116.619,241.146,116.368,241.606,116.682Z"
          transform="translate(-92.072 -21.628)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.212,115.937c.23-.188-.125-.251-.146-.4a1.36,1.36,0,0,1-.523.46C239.71,116.084,240.024,115.854,240.212,115.937Z"
          transform="translate(-89.632 -22.263)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.492,120.052c.293-.293-.335,0,0-.272a.307.307,0,0,0-.251.146Z"
          transform="translate(-100.648 -17.64)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.617,119.947c.125,0,.125-.146.188-.23s-.418.272-.355,0,0,.146-.125.209S243.554,119.843,243.617,119.947Z"
          transform="translate(-101.002 -17.807)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.787,119.71c-.125,0-.188,0-.209-.146s-.146,0-.209,0S243.6,119.857,243.787,119.71Z"
          transform="translate(-101.109 -17.946)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.564,109.9c0-.815-.5.418-.418-.355-.1,0-.1.334-.146.544A2.489,2.489,0,0,1,236.564,109.9Z"
          transform="translate(-78.501 -28.806)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.06,112.032c.69-.293-.23-1.589-.857-.857a.582.582,0,0,0,.4-.167C222.537,111.426,223.248,111.531,223.06,112.032Z"
          transform="translate(-36.398 -27.264)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M222.983,112.375c.293-.481-.46-.209-.711,0s.23-.251.1-.355C221.687,112.584,222.754,112.187,222.983,112.375Z"
          transform="translate(-36.029 -26.102)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M222.528,111.658c.272-.146,0-.272,0-.418a1.192,1.192,0,0,1-.585.376C222.068,111.721,222.381,111.554,222.528,111.658Z"
          transform="translate(-35.197 -26.953)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M221.694,111.279c.251-.272.418-.648,0-.5A.376.376,0,0,0,221.694,111.279Z"
          transform="translate(-33.778 -27.493)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M222.9,111.435c.125-.355.188-1.045-.272-.962s.125.272-.376.439C222.591,110.85,222.737,111.143,222.9,111.435Z"
          transform="translate(-36.201 -27.796)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.034,111.719c.439-.4-.293-.418-.481-.523a1.087,1.087,0,0,1-.314.293c.125-.46-.376,0-.585-.188s.188-.188.146-.272-.293-.251-.627,0c.4.23.334.209.4.669l.355-.23a.376.376,0,0,0,.335.272c-.335.146-.46.167-.544.439s-.125-.355-.481,0l.606.188c0-.4.314-.4.773-.606-.293,0-.125-.146-.209-.335C223.846,111.113,223.888,111.51,224.034,111.719Z"
          transform="translate(-37.205 -27.327)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M239.17,119.15c.146,0,.167.481.46.125-.4-.251.146-.836.564-.878a.419.419,0,0,1,.209-.4C239.881,118,239.651,118.711,239.17,119.15Z"
          transform="translate(-88.967 -19.581)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.305,118.18c-.293.335-.815.376-.815.585s.481-.376.9-.46c-.125,0,0-.188.125-.293-.544-.167-.272-.648-.794-.983-.146.209,0,.46.146.4-.544.23-.125.481-.293.962l.523-.439Z"
          transform="translate(-89.747 -20.638)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.1,116.15s.439.5.23,0Z"
          transform="translate(-97.084 -21.598)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.871,118.643c.146-.125,0-.188,0-.293a.23.23,0,0,1,.167,0c.188-.46-.293-.523-.439-.857-.209.334-.836-.23-.585-.523s-.188,0-.251,0,.125-.293,0-.418a.648.648,0,0,1-.167.209c0-.188-.376,0-.564,0s0-.209-.1-.23-.209.125-.272,0c0,.272-.125.46,0,.648s0-.125.1-.188a.376.376,0,0,1,.439.251.418.418,0,0,0,.251-.209c0,.209.334.272,0,.585a.265.265,0,0,1,.272.125c.084.125,0,0-.146-.167a.858.858,0,0,1,.1-.188,11.027,11.027,0,0,1,1.192,1.254Z"
          transform="translate(-94.633 -21.185)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.451,118.285c.188,0,.251.146.439,0s-.355-.125-.355-.251S241.472,118.244,241.451,118.285Z"
          transform="translate(-95.261 -19.573)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.785,120.531c.648-.4-.418-1.547-.962-.732,0,0,.23-.125.376-.209C242.178,119.987,242.889,119.987,242.785,120.531Z"
          transform="translate(-97.097 -17.93)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.077,118.353c.23-.23-.188-.334-.23-.523C242.638,118.185,242.826,118.185,243.077,118.353Z"
          transform="translate(-99.208 -19.766)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.382,117.779h-.167C243.089,117.779,243.507,117.7,243.382,117.779Z"
          transform="translate(-100.579 -19.862)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M244.729,118.144s.167-.23,0-.334c-.146.334,0-.209-.251-.209s.167-.251.188-.188-.523-.481-.9-.481c.272-.314-.272,0,0-.418s-.188.125-.251.125a.723.723,0,0,1-.355-.355c-.042-.146-.355.376-.272,0-.23.125,0,.251.23,0a.481.481,0,0,0,.355.23.261.261,0,0,1-.146.209c-.146.084.272,0,.251.314.46-.5.314.376.815.188C244.374,117.81,244.646,117.81,244.729,118.144Z"
          transform="translate(-100.944 -21.481)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.486,117.251c.1,0-.125,0-.125-.125s-.125,0-.209.23S243.361,117.147,243.486,117.251Z"
          transform="translate(-100.37 -20.588)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.957,116.7a1.192,1.192,0,0,0-.4-.272S243.769,116.848,243.957,116.7Z"
          transform="translate(-101.698 -21.293)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.84,94.7c.293,0,.46-.418-.23-.418C237.484,94.531,237.965,94.468,237.84,94.7Z"
          transform="translate(-83.351 -45.448)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.591,103.091c0-.334-.209-.125-.293-.209s.1-.481-.188,0C227.277,103.07,227.4,102.945,227.591,103.091Z"
          transform="translate(-50.942 -36.322)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M228.281,102.881a3.074,3.074,0,0,0-.481-.251.564.564,0,0,0,.481.251Z"
          transform="translate(-53.075 -36.342)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M228.53,103c-.125.146-.125,0-.23,0C228.2,103.188,228.53,103.251,228.53,103Z"
          transform="translate(-54.328 -35.938)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M228.886,103.676c0-.167-.125,0-.272-.146C228.53,103.844,228.844,103.739,228.886,103.676Z"
          transform="translate(-55.352 -35.36)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M228.361,103.795v-.376c0-.209,0,0-.125,0S228.173,103.857,228.361,103.795Z"
          transform="translate(-53.928 -35.583)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.518,104.349c0-.167-.251,0-.293,0A1.18,1.18,0,0,1,229.518,104.349Z"
          transform="translate(-57.28 -34.549)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.185,102.779c0-.418,0-.815-.272-.9a2.819,2.819,0,0,0,0,.983.376.376,0,0,1,.272-.084Z"
          transform="translate(-65.487 -37.16)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.665,99.235c0-.794-.523.418-.439-.355-.1,0-.1.334-.146.544Z"
          transform="translate(-44.773 -40.431)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.085,101.173c0-.355-.209-.732-.355-.334a.355.355,0,0,0,.355.335Z"
          transform="translate(-46.552 -38.481)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.048,101.632c-.167-.355-.627-.857-.878-.439.1.314.272,0,0,.585C226.421,101.464,226.734,101.548,227.048,101.632Z"
          transform="translate(-48.435 -38.082)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M228.406,101.592c.251-.711-1.317-.878-1.192,0a.633.633,0,0,1,.146-.4C227.611,101.613,228.155,101.132,228.406,101.592Z"
          transform="translate(-51.987 -38.126)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.653,100.54c0-.314-.376-.125-.523-.23C229.172,100.665,229.4,100.665,229.653,100.54Z"
          transform="translate(-57.227 -38.872)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.216,99.963c0-.293-.125-.523-.4-.481S232.049,99.838,232.216,99.963Z"
          transform="translate(-65.247 -39.78)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235,99.265c0-.523-.669,0-1-.167,0,.376.46,0,.251.481C234.606,99.1,234.711,99.369,235,99.265Z"
          transform="translate(-72.759 -40.272)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.061,99.306v-.564c0-.188-.293.941-.544.481-.272,0,.125.251,0,.439C225.768,99.515,225.852,99.306,226.061,99.306Z"
          transform="translate(-45.879 -40.606)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.606,101.636c0-.585-.523,0-.732,0a.668.668,0,0,1,0,.418c-.251-.376-.251.272-.523.334s0-.272-.1-.293-.355,0-.4.5c.439-.167.376-.125.773.125V102.3a.334.334,0,0,0,.4,0c0,.334-.167.46,0,.69s-.334-.125-.293.376l.523-.334c-.314-.23,0-.481,0-.983-.167.23-.188,0-.376,0C227.021,101.406,227.292,101.615,227.606,101.636Z"
          transform="translate(-48.324 -37.71)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.323,98.7c-.23.125-.272.251-.481,0,0,.23,0,.334-.209.376S226.261,99.16,226.323,98.7Z"
          transform="translate(-46.518 -40.628)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.322,101.746c-.167-.523-.46.209-.544.481,0,0,0-.334-.188-.314C226.632,102.9,227.029,101.808,227.322,101.746Z"
          transform="translate(-49.586 -37.506)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.789,95.858a.481.481,0,0,0-.669-.4C232.245,95.711,232.58,95.69,232.789,95.858Z"
          transform="translate(-66.614 -44.203)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.642,95.78c0-.481-.669,0-.962-.46C232.764,95.759,233.5,95.78,233.642,95.78Z"
          transform="translate(-68.637 -44.314)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.393,96.194c0-.5-.418-.439-.711-.544-.1.355.481.334.146.606C233.871,96.612,234.205,96.11,234.393,96.194Z"
          transform="translate(-71.459 -43.954)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.174,95.541c-.188.251,0,.272,0,.564.146,0,.418,0,.439-.355a.272.272,0,0,1-.251,0S234.341,95.52,234.174,95.541Z"
          transform="translate(-72.556 -44.074)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.023,95.55c-.167-.439-.544.376-.773-.125C234.25,96.094,234.835,95.906,235.023,95.55Z"
          transform="translate(-73.301 -44.21)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.935,100.451c-.418-.314-1.15.188-1.505.606C231.744,101.455,232.371,100.535,232.935,100.451Z"
          transform="translate(-65.318 -38.825)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.585,97.405c0-.815-.523.418-.418-.355a4.621,4.621,0,0,0-.167.544,2.6,2.6,0,0,1,.585-.188Z"
          transform="translate(-63.069 -42.427)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.876,99.689c0-.293-.272,0-.376-.209a1.526,1.526,0,0,1,0,.69C231.6,100.107,231.688,99.752,231.876,99.689Z"
          transform="translate(-64.405 -39.777)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.994,99.343c0-.355-.209-.732-.334-.334a.355.355,0,0,0,.334.334Z"
          transform="translate(-64.858 -40.476)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.03,99.781c-.167-.334-.648-.836-.878-.418s.272,0,0,.564C232.382,99.635,232.717,99.7,233.03,99.781Z"
          transform="translate(-66.793 -40.078)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.328,99.747c.23-.711-1.317-.857-1.192,0a1.736,1.736,0,0,0,.125-.4C233.533,99.768,234.056,99.287,234.328,99.747Z"
          transform="translate(-70.285 -40.128)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.573,98.7c0-.314-.376,0-.523-.23C235.071,98.846,235.322,98.825,235.573,98.7Z"
          transform="translate(-75.523 -40.878)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.957,97.454a1.694,1.694,0,0,0,0-.564c-.188,0-.272.941-.544.481-.251,0,.146.251,0,.439C231.644,97.684,231.727,97.392,231.957,97.454Z"
          transform="translate(-64.152 -42.601)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.515,99.811c0-.606-.5,0-.711,0a1.385,1.385,0,0,1,0,.439c-.272-.4-.251.272-.523.334s0-.293-.125-.314-.355,0-.376.5c.418-.167.376-.125.773.125v-.4a.376.376,0,0,0,.418,0c-.1.355-.167.46,0,.711s-.355-.146-.293.376l.523-.334c-.314-.251,0-.5,0-.983-.188.23-.209,0-.4,0C232.951,99.581,233.264,99.79,233.515,99.811Z"
          transform="translate(-66.629 -39.71)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.241,96.86c-.23.146-.293.272-.481,0a.4.4,0,0,1-.23.4C231.426,97.3,232.157,97.32,232.241,96.86Z"
          transform="translate(-64.812 -42.634)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.252,99.92c-.146-.544-.439.209-.544.481,0,0,0-.334-.188-.314C232.562,101.049,232.98,99.962,233.252,99.92Z"
          transform="translate(-67.913 -39.507)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229,99.27c-.188-.146-.523,0-.69.272S228.728,99.312,229,99.27Z"
          transform="translate(-54.797 -40.069)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.955,95.049c0-.314-.251-.125-.355-.209a1.9,1.9,0,0,1,0,.69C229.684,95.467,229.767,95.174,229.955,95.049Z"
          transform="translate(-58.512 -44.837)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.105,94.714c0-.376-.209-.753-.355-.355A.376.376,0,0,0,230.105,94.714Z"
          transform="translate(-58.976 -45.547)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.068,95.147c-.167-.355-.627-.836-.878-.439.1.314.272,0,0,.585C230.441,94.98,230.754,95.063,231.068,95.147Z"
          transform="translate(-60.858 -45.144)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.016,94.462c.251-.711-1.317-.878-1.192,0,.1,0,.1-.251.146-.4C229.221,94.483,229.66,94,230.016,94.462Z"
          transform="translate(-56.963 -45.901)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.673,94.06c0-.314-.376-.125-.523-.23C233.192,94.185,233.422,94.185,233.673,94.06Z"
          transform="translate(-69.651 -45.938)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.215,98.963c0-.272-.23,0-.334,0a.459.459,0,0,1,0,.188c-.125-.167-.125.125-.251.167s0-.146,0-.146-.167,0-.188.23.167,0,.355,0v-.188a.188.188,0,0,0,.188,0v.334c0,.125-.167,0-.146.167l.251-.167c-.146,0,0-.209,0-.439,0,.1,0,0-.167,0S229.09,98.963,229.215,98.963Z"
          transform="translate(-55.347 -40.472)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.049,99.054c0-.251-.209,0-.251.23s0-.167,0-.146C228.736,99.514,228.924,99.054,229.049,99.054Z"
          transform="translate(-55.892 -40.355)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.838,99.58c0,.711-.314.439-.481.627C225.483,100.709,226.382,99.726,225.838,99.58Z"
          transform="translate(-45.698 -39.668)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.237,103.476a.648.648,0,0,0,.523.272c0-.23.1-.314.167-.5a.293.293,0,0,0-.418.188s0-.1,0-.334a.167.167,0,0,1-.293,0C227.133,103.142,227.4,103.225,227.237,103.476Z"
          transform="translate(-51.467 -35.829)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.418,104.581a2.091,2.091,0,0,0-1.317-1.087v-.251c0-.1-.188.146-.167.4s-.188-.167-.334,0a.251.251,0,0,1,0-.251,5.644,5.644,0,0,1-2.425-.669c.125.293-.167.125-.146.5l-.146-.125c-.146-.125.209,0,.188-.293-.23,0-.335,0-.481.418s.314-.335.209.209c1.422.209,2.216.251,3.826.46.146,0,0,.188.1.272s.23,0,.251-.125a.355.355,0,0,1,.1.355C232.125,104.5,232.4,104.371,232.418,104.581Z"
          transform="translate(-56.711 -36.244)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.51,100.439c-.209-.314-.334-.314-.606-.669,0,0-.1.23-.209.251s.376,0,.188.439C232.134,100.251,232.155,100.481,232.51,100.439Z"
          transform="translate(-65.416 -39.461)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.439,98.232c-.1-.314-.4-.481-.439.209C226.293,98.587,226.272,98.044,226.439,98.232Z"
          transform="translate(-47.47 -41.435)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.184,100.305c.418.293.669.209,1.108.46,0-.167,0-.293-.188-.314a1.277,1.277,0,0,0,.125-.4c-.5.355-.794,0-1.129-.335C231.184,99.929,231.664,100.473,231.184,100.305Z"
          transform="translate(-63.984 -39.515)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.425,99.88c.188-.335-.335,0-.314.523a.314.314,0,0,1,.335,0C232.508,100.11,232.195,99.922,232.425,99.88Z"
          transform="translate(-66.271 -39.487)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.222,100.63c-.125,0-.167.167-.293.125s.146.4.334.314,0,.167-.1.334.209,0,.335,0,0,.167-.167.146.146,0,.188.376v-.439a.328.328,0,0,1,.251-.209c.1,0,0,0,0-.251C231.452,100.923,231.306,100.985,231.222,100.63Z"
          transform="translate(-63.082 -38.523)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.691,102.815a.188.188,0,0,0-.209-.167v-.314c0-.146-.272-.209-.376-.334,0,.4-.773.125-.9.648a.1.1,0,0,0-.125,0,.4.4,0,0,1-.376.251c-.23.439-.648.481-.815.857h.251c.585.272,1.777-.439,2.09.481-.125,0-.167-.355-.418-.272.146.209.146.5.355.711s-.209-.1-.209.167.146,0,.251,0c0-.46.4-.314.523-.251s0,0,0-.251-.251.125-.314,0,.418-.1.711-.146c-.272,0-.418-.773-.69-1.024s-.355,0-.314-.314a.164.164,0,0,1-.209.167c-.209-.042,0-.355-.209-.293a.5.5,0,0,1,0-.272c.146-.272.46.167.439-.188v.146c0,.021-.167,0-.146.167s.167,0,.167-.167S229.461,102.794,229.691,102.815Z"
          transform="translate(-53.022 -37.029)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.81,103.378c0,.564.418,0,.627,0A.732.732,0,0,0,227.81,103.378Z"
          transform="translate(-53.252 -35.605)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.984,102.613c0,.188.146,0,0,.439,0-.272.376-.1.418-.523.23.167.355.481.209,1.15.418.627,1.108.23,1.463-.272.314-1.024.23-1.8-.125-2.09s-1.3.773-1.652.251a1.246,1.246,0,0,0-.9.272c-.251.272,0,.188,0,.5s.5,0,.418.418A.355.355,0,0,0,229.984,102.613Z"
          transform="translate(-60.172 -37.83)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.051,97.708a.355.355,0,0,0,0-.418C255.863,97.5,256.051,97.583,256.051,97.708Z"
          transform="translate(-139.798 -42.165)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.37,97.027h.1l-.146-.167C257.182,96.692,257.307,96.943,257.37,97.027Z"
          transform="translate(-143.855 -42.696)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M255.424,97.938c0-.209,0-.46-.335-.1C255.153,98.021,255.383,97.749,255.424,97.938Z"
          transform="translate(-137.269 -41.768)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.83,121.815c0-.355-.46-.314-.523-.188s0-.4,0-.627a3.636,3.636,0,0,0-.4.857c0,.167.627.167.523-.125A.359.359,0,0,0,241.83,121.815Z"
          transform="translate(-94.031 -16.309)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.791,119.691c0-.585-.376.293-.314-.251v.376a2.856,2.856,0,0,1,.314-.125Z"
          transform="translate(-98.253 -18.01)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.158,119.727a.836.836,0,0,0,0-.4c-.125,0-.188.669-.376.334-.188,0,0,.188,0,.314S243.012,119.685,243.158,119.727Z"
          transform="translate(-99.122 -18.13)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.37,119.31c-.167,0-.188.188-.335,0,0,.167,0,.251-.167.272S243.328,119.624,243.37,119.31Z"
          transform="translate(-99.605 -18.152)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M255.867,97.619c.1,0,.1.334.335,0-.293-.188,0-.585.376-.627s0-.167.167-.272C256.368,96.845,256.2,97.305,255.867,97.619Z"
          transform="translate(-140.22 -42.787)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.725,96.9c-.188.23-.564.251-.564.4s.335-.272.648-.314v-.23c-.4,0-.188-.439-.564-.69-.1.167,0,.334.1.293-.376.167-.1.334-.209.69l.376-.334Z"
          transform="translate(-140.848 -43.507)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.042,119.93c0,.523-.23.314-.355.46S243.418,120.076,243.042,119.93Z"
          transform="translate(-99.026 -17.476)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M258.451,97.2v-.188a.125.125,0,0,1,.125,0c.125-.334-.23-.376-.314-.606-.167.23-.606-.167-.439-.376s-.125,0-.167,0,0-.209,0-.293a.4.4,0,0,1-.126.146c0-.125-.272,0-.4,0s0-.146,0-.167-.146.1-.188,0,0,.334,0,.46v-.125a.251.251,0,0,1,.314.167.251.251,0,0,0,.188-.167c0,.167.23.209,0,.439s.146,0,.188,0,0,0-.1-.125v-.125a8.127,8.127,0,0,1,.92.962Z"
          transform="translate(-144.288 -43.897)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.5,96.957c.125,0,.188.1.314,0s-.251-.1-.251-.188S257.519,96.936,257.5,96.957Z"
          transform="translate(-144.715 -42.752)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.4,118.966c0-.209-.272-.334-.293.167C243.256,119.237,243.256,118.861,243.4,118.966Z"
          transform="translate(-100.203 -18.727)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.157,95.9c.23.167.439-.23.4-.376C236.178,95.289,236.387,95.833,236.157,95.9Z"
          transform="translate(-78.825 -44.158)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.55,91.728c.314.418.4-.125.606-.167C238.864,91.268,238.738,91.6,238.55,91.728Z"
          transform="translate(-86.423 -48.54)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.286,88.995c.293,0,.439-.334.794,0C242.018,88.64,241.2,88.66,241.286,88.995Z"
          transform="translate(-95.055 -51.493)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.175,98.818c.5-.125.564-1,.481-1.568C238.175,97.229,238.468,98.316,238.175,98.818Z"
          transform="translate(-85.171 -42.209)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.645,98.789c.209.23.251-.146.4-.167a1.38,1.38,0,0,1-.481-.5C237.5,98.308,237.728,98.6,237.645,98.789Z"
          transform="translate(-83.218 -41.26)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.992,98.675c.293.188.69.272.481,0A.376.376,0,0,0,236.992,98.675Z"
          transform="translate(-81.542 -40.749)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.848,98.382c.376,0,1.066,0,.9-.418-.314-.125-.251.167-.5-.314C237.454,98.047,237.1,98.256,236.848,98.382Z"
          transform="translate(-81.481 -41.773)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.129,97.753c.418.627,1.505-.523.69-1a2.8,2.8,0,0,0,.23.355C236.63,97.105,236.651,97.816,236.129,97.753Z"
          transform="translate(-79.319 -42.754)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.48,96.431c.23.209.314-.209.5-.272C234.668,95.929,234.543,96.159,234.48,96.431Z"
          transform="translate(-73.74 -43.501)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.3,99.261c.46.334.355-.376.418-.585a1.067,1.067,0,0,1-.334-.251c.46,0,0-.376,0-.627s.23.167.314,0,.167-.334-.146-.627c-.146.46-.146.376-.585.544l.293.293a.4.4,0,0,0-.209.376c-.209-.293-.251-.418-.523-.46s.314-.188-.125-.46v.627c.4,0,.439.23.732.648,0-.293.125-.146.293-.272C238.846,98.905,238.47,99.072,238.3,99.261Z"
          transform="translate(-83.646 -42.296)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.168,97.939c.5.209.1-.481,0-.711s.293.167.376,0C236.729,96.706,237.314,97.689,237.168,97.939Z"
          transform="translate(-81.78 -42.397)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.477,92.192c.293.335.753.167.293-.272Z"
          transform="translate(-73.716 -48.021)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.029,85.581a.523.523,0,0,0,.334-.523C232.841,84.953,233.134,85.413,233.029,85.581Z"
          transform="translate(-69.091 -55.521)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.218,85.1c.23.376.606-.481,0-.4C232.072,84.87,232.636,85.058,232.218,85.1Z"
          transform="translate(-66.524 -55.898)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.15,84.947c.355,0,.585-.627,0-.627C232.38,84.592,232.129,84.613,232.15,84.947Z"
          transform="translate(-66.393 -56.309)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.081,84.409c.334,0,.418-.481.355-.669C232.185,83.865,232.248,84.284,232.081,84.409Z"
          transform="translate(-66.199 -56.942)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.75,96.708c.523-.146.585-1.024.481-1.568C235.75,95.14,235.98,96.227,235.75,96.708Z"
          transform="translate(-77.686 -44.51)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.272,96.689c.188.23.251-.146.376-.167a1.506,1.506,0,0,1-.46-.5C235.1,96.208,235.335,96.5,235.272,96.689Z"
          transform="translate(-75.85 -43.55)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.608,96.569c.293.209.711.293.481,0A.355.355,0,0,0,234.608,96.569Z"
          transform="translate(-74.182 -43.054)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.468,96.312c.376,0,1.066,0,.9-.439-.293,0-.251.167-.5-.293C234.99,95.914,234.739,96.1,234.468,96.312Z"
          transform="translate(-74.125 -44.03)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.74,95.653c.4.627,1.505-.523.69-1a.442.442,0,0,0,.209.355C234.179,95.005,234.179,95.716,233.74,95.653Z"
          transform="translate(-71.935 -45.044)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.35,98.581c.251.209.335-.23.5-.272C235.559,98.121,235.413,98.31,235.35,98.581Z"
          transform="translate(-76.429 -41.136)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.9,97.14c.481.355.355-.355.439-.564a1.4,1.4,0,0,1-.355-.251c.46,0,0-.376,0-.627s.23.167.293,0,.188-.335-.146-.627c-.125.439-.125.376-.585.544l.293.293a.376.376,0,0,0-.209.376c-.209-.293-.251-.439-.523-.46s.314-.188,0-.46v.627c.376,0,.418.23.711.648,0-.293.146-.146.293-.272C236.425,96.847,236.09,97.014,235.9,97.14Z"
          transform="translate(-76.271 -44.586)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.8,95.849c.523.209.125-.481,0-.732s.272.188.355,0C234.363,94.595,234.948,95.6,234.8,95.849Z"
          transform="translate(-74.439 -44.696)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.247,98.093c.46,0,0-.69.4-.983C231.226,97.215,231.226,97.946,231.247,98.093Z"
          transform="translate(-63.63 -42.362)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.819,96.993c.481,0,.418-.439.5-.753-.355,0-.293.523-.585.188C231.38,96.491,231.9,96.784,231.819,96.993Z"
          transform="translate(-65.079 -43.31)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.074,96.044c-.272-.188-.272,0-.564,0,.1.146,0,.418.376.439,0,0-.146-.146-.125-.251S232.095,96.044,232.074,96.044Z"
          transform="translate(-64.624 -43.616)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.756,96.414c.418-.188-.4-.523,0-.794C231.171,95.641,231.38,96.226,231.756,96.414Z"
          transform="translate(-64.18 -43.986)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.766,91.15c-.251.125.188.481,0,.627.272.188.46-.23.1-.481a.549.549,0,0,1,.4.209C238.331,91.171,237.954,91.15,237.766,91.15Z"
          transform="translate(-83.737 -48.861)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.777,92.9c.293,0,.5-.146.46-.439C233.924,92.481,233.924,92.732,233.777,92.9Z"
          transform="translate(-71.533 -47.432)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.918,98.95a7.5,7.5,0,0,0,.9,0c0-.125-.125-.23,0-.314s-.23.293-.46-.146C237.231,98.72,237.106,98.636,236.918,98.95Z"
          transform="translate(-81.697 -40.857)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.176,98.79c-.5.146-.585.4-1.066.585.125.1.251.146.355,0a.9.9,0,0,0,.23.355c0-.627.46-.669.962-.69C238.49,99.062,237.779,99.1,238.176,98.79Z"
          transform="translate(-82.914 -40.529)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.178,98.824c.125.355.125-.334-.209-.564a.293.293,0,0,1-.146.314C236.886,98.741,237.283,98.615,237.178,98.824Z"
          transform="translate(-80.913 -41.107)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.222,91.964a.46.46,0,0,0,0-.564C232.741,91.672,233.013,91.818,233.222,91.964Z"
          transform="translate(-68.886 -48.588)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.67,89.312v-.146a.608.608,0,0,0-.46-.585A4.871,4.871,0,0,1,235.67,89.312Z"
          transform="translate(-75.955 -51.664)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.662,89.557a1.025,1.025,0,0,1,.146-.627C235.474,89.285,235.558,89.369,235.662,89.557Z"
          transform="translate(-76.846 -51.282)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.685,89.089h.146c.042,0,0-.314.1-.523s-.167,0,0,.146l-.418.293a1,1,0,0,1,0,.5.669.669,0,0,0,.4-.209C236.02,89.047,235.581,89.3,235.685,89.089Z"
          transform="translate(-76.89 -51.775)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.983,91.063l.146-.125c0-.167-.125-.167-.209-.251S234.857,90.958,234.983,91.063Z"
          transform="translate(-74.703 -49.38)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.668,89.718V89.53a.167.167,0,0,1-.125,0C234.459,89.7,234.627,89.739,234.668,89.718Z"
          transform="translate(-73.511 -50.628)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.611,89.634l-.125-.23c-.125-.23,0,.209,0,.293a.188.188,0,0,1,.125-.063Z"
          transform="translate(-73.265 -50.836)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237,90.96c.272-.209-.146-.167,0-.4s.251,0,.272.1c.334-.334-.418-.125,0-.481a.24.24,0,0,1-.23-.188c-.042-.188,0,.167,0,.251s0,.251-.418.314A.544.544,0,0,1,237,90.96Z"
          transform="translate(-80.613 -50.176)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.264,92.271c.146-.293,0-.606-.46,0C231.908,92.417,232.2,92.083,232.264,92.271Z"
          transform="translate(-65.483 -48.017)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.5,91.062c.4-.293-.209-.753-.4-.711s.314-.481.5-.711a5.166,5.166,0,0,0-1.213.5c0,.272.5.753.669.355C232.191,90.727,232.233,90.978,232.5,91.062Z"
          transform="translate(-64.95 -50.508)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.508,98.184c.564-.564-.669,0,0-.544-.1,0-.293.167-.481.293a3.905,3.905,0,0,1,.481.251Z"
          transform="translate(-75.563 -41.784)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.689,97.867c.251-.167.251-.293.376-.418s-.836.523-.732,0c-.251,0,0,.272-.209.376C235.313,97.825,235.564,97.658,235.689,97.867Z"
          transform="translate(-76.183 -42.01)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.078,97.432c-.251,0-.376,0-.4-.272-.188.167-.314.188-.418.146S235.723,97.745,236.078,97.432Z"
          transform="translate(-76.446 -42.307)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.578,93.218a.355.355,0,0,0,0-.418c-.188.167-.293,0-.481.293l.146.188C237.39,92.925,237.474,92.9,237.578,93.218Z"
          transform="translate(-81.877 -47.062)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.366,89.292c0-.5-.962-.606-1.526-.544C233.819,89.292,234.906,89.062,235.366,89.292Z"
          transform="translate(-72.787 -51.498)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.972,88.587c.23-.188-.125-.272-.146-.4a1.505,1.505,0,0,1-.523.439C233.491,88.713,233.784,88.5,233.972,88.587Z"
          transform="translate(-70.348 -52.089)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M239.146,93.809c0-.272-.5-.334-.794-.293S238.895,93.642,239.146,93.809Z"
          transform="translate(-85.869 -46.294)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.212,92.713c.293-.314-.335,0,0-.293a.321.321,0,0,0-.251.167A.649.649,0,0,1,237.212,92.713Z"
          transform="translate(-81.239 -47.476)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.245,93.4c.125,0,0-.125,0-.188a.982.982,0,0,1-.272.23A.325.325,0,0,1,238.245,93.4Z"
          transform="translate(-84.362 -46.615)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.921,93.207c.125-.167.188-.355,0-.251A.188.188,0,0,0,237.921,93.207Z"
          transform="translate(-83.892 -46.925)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.606,93.306c0-.188,0-.544-.209-.46s0,.125-.167.251C238.418,93.035,238.5,93.181,238.606,93.306Z"
          transform="translate(-85.204 -47.025)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M239.056,92.963c.355-.209-.209-.794-.481-.376a.234.234,0,0,0,.188-.1C238.826,92.378,239.119,92.691,239.056,92.963Z"
          transform="translate(-86.491 -47.455)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.365,92.572c.125,0,.125-.146.209-.209s-.439.251-.376,0,0,.146-.125.188S237.3,92.468,237.365,92.572Z"
          transform="translate(-81.705 -47.628)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M239.216,93.4c.209-.23-.167-.188-.272-.23a.522.522,0,0,1-.146.167c0-.23-.188,0-.314,0s0,0,0-.146-.167-.1-.335,0c.23,0,.188,0,.272.314l.146-.125a.209.209,0,0,0,.188,0c-.146,0-.23.125-.251.272s0-.167-.23,0h.314c0-.188.125-.209.355-.355-.146,0,0,0-.125-.146S239.133,93.315,239.216,93.4Z"
          transform="translate(-85.71 -46.74)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.557,92.35c-.146,0-.209,0-.209-.146s-.167,0-.23,0S237.369,92.517,237.557,92.35Z"
          transform="translate(-81.814 -47.783)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.96,93.148c.125-.272-.251,0-.376,0s.1-.146,0-.188C238.291,93.336,238.834,93.044,238.96,93.148Z"
          transform="translate(-86.143 -46.887)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.227,97.473c.23-.23-.188-.355-.23-.523C236.788,97.243,236.976,97.389,237.227,97.473Z"
          transform="translate(-81.128 -42.536)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.445,95.278c.188-.251.272-.481,0-.648C238.235,94.9,238.424,95.069,238.445,95.278Z"
          transform="translate(-85.461 -45.066)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.92,91.854c.125,0,.146.481.46,0-.418-.251.125-.815.544-.857a.38.38,0,0,1,.23-.4C233.61,90.767,233.38,91.436,232.92,91.854Z"
          transform="translate(-69.651 -49.461)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.045,90.83c-.272.314-.815.355-.794.564s.46-.376.878-.439c-.1,0,0-.188.125-.314-.543-.167-.272-.627-.794-.962-.125.209-.1.439.146.4-.523.23-.125.481-.293.962l.523-.46Z"
          transform="translate(-70.441 -50.464)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.863,88.79s.439.5.23,0a.293.293,0,0,1-.23,0Z"
          transform="translate(-77.799 -51.435)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.47,91.3c.146-.125,0-.188,0-.293s0,0,.146,0c.209-.439-.293-.523-.439-.836-.209.314-.836-.23-.585-.544s-.188,0-.251,0,.125-.272.1-.4l-.188.188c0-.188-.376,0-.564,0s0-.209,0-.251-.209.146-.272,0c0,.272-.125.46,0,.627s0,0,.1-.167a.376.376,0,0,1,.439.23.418.418,0,0,0,.251-.209c0,.23.335.293,0,.606h.251c.063,0,0,0-.146-.167a.858.858,0,0,1,.1-.188A10.621,10.621,0,0,1,236.47,91.3Z"
          transform="translate(-75.187 -51.034)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.2,90.933c.188,0,.272.125.46,0s-.376-.146-.376-.251S235.243,90.87,235.2,90.933Z"
          transform="translate(-75.967 -49.396)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.545,93.154c.648-.376-.418-1.526-.962-.732a.468.468,0,0,0,.376-.209C235.876,92.653,236.649,92.632,236.545,93.154Z"
          transform="translate(-77.812 -47.771)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.837,90.982c.23-.23-.188-.334-.23-.5C236.4,90.773,236.586,90.919,236.837,90.982Z"
          transform="translate(-79.923 -49.592)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.1,90.429h-.146C236.848,90.429,237.225,90.346,237.1,90.429Z"
          transform="translate(-81.251 -49.687)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.489,90.872c0-.125.167-.251,0-.334-.167.314,0-.23-.251-.209s.146-.251.167-.188-.523-.5-.878-.481c.251-.334-.272,0,0-.418s-.167.125-.23,0-.335-.188-.376-.334-.355.355-.272,0c-.23.125,0,.23.251,0s.167.167.335.209,0,.146-.146.23.272,0,.251.314c.481-.5.314.376.815.188C238.133,90.412,238.405,90.559,238.489,90.872Z"
          transform="translate(-81.658 -51.384)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.262,89.91s-.125,0-.1-.146-.146.1-.23.23S237.074,89.805,237.262,89.91Z"
          transform="translate(-81.101 -50.422)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.774,89.353c-.1,0-.188-.23-.4-.272S237.586,89.479,237.774,89.353Z"
          transform="translate(-82.47 -51.12)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.6,94.425c0-.355-.46-.314-.543-.209s0-.376,0-.606a3.469,3.469,0,0,0-.4.836c0,.188.627.167.523,0S235.413,94.593,235.6,94.425Z"
          transform="translate(-74.736 -46.178)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.648,92.331c0-.564-.376.314-.293-.251a.7.7,0,0,1-.125.4Z"
          transform="translate(-79.065 -47.847)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.911,92.4v-.418c0-.146-.188.669-.376.334-.188,0,.1.188,0,.334S236.765,92.335,236.911,92.4Z"
          transform="translate(-79.83 -47.976)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.1,92c-.146,0-.188.188-.334,0,0,.167,0,.251-.146.272S237.062,92.23,237.1,92Z"
          transform="translate(-80.295 -47.934)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.8,92.58c0,.5-.23.314-.355.439S237.178,92.685,236.8,92.58Z"
          transform="translate(-79.742 -47.302)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.174,91.625c0-.23-.293-.355-.314.146C237.027,91.876,237.006,91.52,237.174,91.625Z"
          transform="translate(-80.908 -48.562)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M258.452,98.544c.46-.272-.314-1.087-.69-.523a.359.359,0,0,0,.272-.146C258.1,98.189,258.515,98.189,258.452,98.544Z"
          transform="translate(-146.024 -41.58)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.97,124.244c0,.209.293,0,.4,0S248.138,124.1,247.97,124.244Z"
          transform="translate(-115.348 -12.843)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.79,135.588a.9.9,0,0,0,.1.4c.125,0,0-.69.293-.418.146-.1-.146-.146-.146-.293S236.79,135.609,236.79,135.588Z"
          transform="translate(-80.817 -0.773)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.626,134.319c.125.4.355,0,.481-.146a.816.816,0,0,1,0-.293c.251.209.125-.23.314-.334s0,.209.125.209.23-.125.188-.418c-.272.188-.251.146-.564,0v.293a.23.23,0,0,0-.251.125c0-.251,0-.355,0-.5s.251,0,.125-.293l-.293.314c.251.1.125.334.125.69,0-.188.125,0,.272,0C236.96,134.382,236.793,134.277,236.626,134.319Z"
          transform="translate(-80.987 -3.267)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.7,135.819c.125-.125.146-.23.314-.125a.334.334,0,0,1,0-.314A.427.427,0,0,0,236.7,135.819Z"
          transform="translate(-80.417 -0.628)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.45,133.645c.209.335.272-.23.293-.439s0,.23.188.188C236.743,132.725,236.638,133.562,236.45,133.645Z"
          transform="translate(-79.808 -3.074)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M244.4,127.005c-.335-.125-.5,0-.836-.125,0,.1,0,.188.188.188a.958.958,0,0,0,0,.293c.293-.335.544-.167.857,0C244.48,127.319,244.041,127.131,244.4,127.005Z"
          transform="translate(-102.346 -9.897)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M243.31,127.207c0,.272.23,0,.125-.4h-.209C243.122,126.81,243.477,127.207,243.31,127.207Z"
          transform="translate(-100.465 -9.973)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.522,136.974h0v.209c0-.46.669,0,.544-.753-.125,0,0,.146,0,.314S236.648,136.911,236.522,136.974Z"
          transform="translate(-80.11 0.518)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M244.7,127.075c.1,0,.1-.146.188-.125s-.167-.272-.272-.167,0-.125,0-.23-.125,0-.23,0,0-.125,0-.125-.1,0-.188-.209,0,.251,0,.293a.166.166,0,0,1-.146.188c-.146.021,0,0,0,.167S244.591,126.845,244.7,127.075Z"
          transform="translate(-103.523 -10.678)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.668,124.024h0v.146c0,.146,0-.146.167-.188S247.668,124.192,247.668,124.024Z"
          transform="translate(-114.209 -13.062)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.808,124.774s0,.188.167.167a.751.751,0,0,1,.23,0c.063,0,0,.188,0,.167s.5-.1.627-.314c0,.272.188-.125.251.146s0-.146,0-.188.251-.1.355,0,0-.376,0-.188c0-.188-.146,0-.146,0h-.251c-.084,0,0,0,0-.167s-.146.125-.272,0c0,.481-.355,0-.46.4C247.2,124.732,247.017,124.837,246.808,124.774Z"
          transform="translate(-113.015 -12.662)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.039,127.028a.1.1,0,0,0,.146,0v.23c0,.1.23.1.334.167,0-.272.5-.209.5-.585h.1a.376.376,0,0,1,.209-.251c0-.334.355-.439.418-.711h-.188c-.46,0-1.15.606-1.568,0a.224.224,0,0,0,.355,0c-.146,0-.188-.314-.376-.418s.167,0,.1-.146,0,0-.188,0c.146.314-.209.293-.293.272s0,0,0,.167.146-.125.23,0-.272.146-.46.23c.188,0,.418.46.648.585s.23,0,.272.167,0-.167,0-.146,0,.251.209.167,0,0,0,.167-.335,0-.251.209h0s.1,0,0-.146-.125,0,0,.146S247.185,126.986,247.039,127.028Z"
          transform="translate(-112.368 -11.676)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.393,124.5h.125c.125,0,0,0,0-.209S247.5,124.48,247.393,124.5Z"
          transform="translate(-113.329 -12.786)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.84,124.428a.564.564,0,0,0,.334-.125S246.84,124.261,246.84,124.428Z"
          transform="translate(-111.772 -12.713)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M247.121,125.35c0-.4-.314,0-.439.125S247.016,125.475,247.121,125.35Z"
          transform="translate(-111.342 -11.753)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.269,126.257c0-.146-.1,0-.125-.293,0,.209-.23.125-.188.439-.188,0-.334-.272-.355-.753-.376-.376-.794,0-.962.439a1.339,1.339,0,0,0,.439,1.338c.272.084.773-.753,1.108-.439a1.29,1.29,0,0,0,.564-.355c.125-.209,0-.146,0-.355s-.335,0-.355-.209C246.311,126.09,246.29,126.152,246.269,126.257Z"
          transform="translate(-106.706 -11.406)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.46,123c-.209.355-.376.711-.188.9a2.362,2.362,0,0,0,.523-.815.335.335,0,0,1-.335-.084Z"
          transform="translate(-97.671 -14.128)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.387,137.81a.523.523,0,0,0,0,.585C231.868,137.977,231.554,137.935,231.387,137.81Z"
          transform="translate(-63.749 2.022)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.95,101v.167s.355.4.627.418A2.637,2.637,0,0,0,257.95,101Z"
          transform="translate(-146.4 -38.119)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.858,100.55v.627C258.108,100.738,258,100.7,257.858,100.55Z"
          transform="translate(-145.639 -38.61)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M258.131,101.251h-.167c-.042,0,.125.314,0,.544s.125-.146,0-.167.1-.167.293-.4a.92.92,0,0,1-.209-.46c-.1,0-.209.125-.314.293S258.152,101,258.131,101.251Z"
          transform="translate(-145.599 -38.37)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.745,101.35c-.1.293.146.209.188.314s-.293.376.167.125C258.038,101.5,257.871,101.559,257.745,101.35Z"
          transform="translate(-145.443 -37.738)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.148,101.74c-.1,0,.23.334.335.439S257.336,101.865,257.148,101.74Z"
          transform="translate(-143.612 -37.312)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.638,101.84c.167,0,0,0,.188.146C257.056,101.715,256.784,101.548,256.638,101.84Z"
          transform="translate(-141.994 -37.371)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.079,101.327c0,.167,0,0,.188.23C256.518,101.327,256.142,101.286,256.079,101.327Z"
          transform="translate(-140.265 -37.778)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M258.958,99.18v.167c0,.167.188.125.272.188S259.168,99.264,258.958,99.18Z"
          transform="translate(-149.186 -40.104)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M259.064,100.481v.188c0,.021,0,0,.1,0S259.106,100.46,259.064,100.481Z"
          transform="translate(-149.354 -38.687)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M259.059,100.692s.125,0,.167.188c.146-.167,0-.209,0-.272S259.08,100.692,259.059,100.692Z"
          transform="translate(-149.432 -38.564)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.066,99.08c-.188.272.209.125.209.355s-.23,0-.293,0c-.209.439.439,0,.146.481a.334.334,0,0,1,.272.1c.146-.167,0-.146-.126-.23s0-.251.314-.418a.606.606,0,0,1-.523-.293Z"
          transform="translate(-143.3 -40.213)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.9,101.18c0,.209,0,.146-.209.314s0,0,0,.146S257.086,101.222,256.9,101.18Z"
          transform="translate(-141.94 -37.923)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M261.717,100.67c-.314.4.418.669.564.564s-.125.523-.251.815a4.912,4.912,0,0,0,1-.815c0-.272-.711-.585-.732-.167C262.115,100.9,261.989,100.67,261.717,100.67Z"
          transform="translate(-158.571 -38.479)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M258.83,101.3c.251.46,1.108.314,1.631,0C260.335,100.925,259.374,101.468,258.83,101.3Z"
          transform="translate(-150.123 -37.939)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M261.1,103.92c-.376.69.648-.167.251.481.1,0,.23-.251.355-.418Z"
          transform="translate(-155.952 -34.935)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M259.633,102.131c-.167.251.209.209.251.334a1.756,1.756,0,0,1,.376-.585C260.072,101.86,259.842,102.131,259.633,102.131Z"
          transform="translate(-151.511 -37.16)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M259.98,102.52c-.125.334-.1.753.167.439A.334.334,0,0,0,259.98,102.52Z"
          transform="translate(-152.089 -36.462)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M259,102.34c0,.376.251,1.024.648.753,0-.314-.23-.188.167-.544C259.523,102.737,259.272,102.549,259,102.34Z"
          transform="translate(-149.833 -36.658)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M258,102.82c-.5.544.857,1.338,1.15.418-.1,0-.209.188-.293.314C258.753,103.2,258.063,103.343,258,102.82Z"
          transform="translate(-146.848 -36.135)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.845,104.4c-.146.293.293.272.376.418C257.284,104.484,257.054,104.4,256.845,104.4Z"
          transform="translate(-142.682 -34.412)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.882,106.14c-.1.293-.1.523.167.627C255.2,106.454,254.965,106.328,254.882,106.14Z"
          transform="translate(-136.371 -32.514)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M260.946,104.206a1.733,1.733,0,0,0-.23.5c.188,0,.648-.732.69-.209.293,0,0-.272.1-.439S261.071,104.352,260.946,104.206Z"
          transform="translate(-155.145 -34.824)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M258.677,102.534c-.23.544.439.251.648.272a1.831,1.831,0,0,1,.167-.4c0,.46.334-.125.627,0s-.125.272,0,.334.355,0,.564-.293c-.481,0-.4,0-.669-.439l-.23.334a.376.376,0,0,0-.4,0c.23-.272.355-.334.313-.606s.251.251.418-.23h-.606c.188.355-.146.481-.46.857.251-.125.167,0,.313.23C259.1,103.057,258.887,102.743,258.677,102.534Z"
          transform="translate(-149.929 -37.563)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M260.6,104.554c.272,0,.355,0,.46.167.125-.209.251-.272.355-.272A.666.666,0,0,0,260.6,104.554Z"
          transform="translate(-154.804 -34.461)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M258.469,102.361c0,.544.5,0,.711-.209,0,.125-.1.314,0,.355C259.577,101.629,258.761,102.423,258.469,102.361Z"
          transform="translate(-148.194 -36.93)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.14,105.459c.251.46,1.108.314,1.631,0C255.645,105.083,254.683,105.647,254.14,105.459Z"
          transform="translate(-135.629 -33.401)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.943,106.29c-.167.251.209.23.251.355a1.422,1.422,0,0,1,.376-.585C255.361,105.955,255.152,106.331,254.943,106.29Z"
          transform="translate(-137.017 -32.622)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M255.24,106.68c-.125.335-.1.753.167.46A.355.355,0,0,0,255.24,106.68Z"
          transform="translate(-137.439 -31.925)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.31,106.5c0,.4.251,1.024.648.773,0-.314-.23-.209.167-.564C254.833,106.918,254.582,106.709,254.31,106.5Z"
          transform="translate(-135.339 -32.122)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M253.99,106.723c-.209.544.46.272.669.293a.9.9,0,0,1,.167-.4c0,.439.334-.146.606,0s-.1.251,0,.314.376,0,.564-.293c-.46,0-.4,0-.648-.439l-.23.355a.335.335,0,0,0-.4,0c.23-.272.355-.355.314-.627s.251.272.418-.209h-.627c.209.334-.125.46-.46.857.272-.146.188,0,.334.209C254.429,107.183,254.22,106.849,253.99,106.723Z"
          transform="translate(-135.458 -32.972)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M253.781,106.53c0,.544.5,0,.69-.209v.376C254.889,105.735,254.137,106.613,253.781,106.53Z"
          transform="translate(-133.702 -32.402)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.906,105.126c.1.209.5.146.753,0S258.157,105.21,257.906,105.126Z"
          transform="translate(-146.44 -33.674)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.887,105.731c0,.23.209,0,.314.125a.478.478,0,0,1,0-.188c0,.209.146,0,.272,0s0,.125,0,.146.167,0,.251-.125-.188,0-.293-.209l-.1.167a.167.167,0,0,0-.188,0c.1-.125.167-.167.146-.293s.1.125.188-.1h-.293c.1.167,0,.209-.209.4s0,0,.146,0S257.992,105.773,257.887,105.731Z"
          transform="translate(-146.421 -33.485)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.789,105.614c0,.251.23,0,.334,0s0,.146,0,.167C258.416,105.279,258.04,105.634,257.789,105.614Z"
          transform="translate(-145.717 -33.2)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M261.406,99.357c-.146,0-.293-.418-.481,0,.481.146.125.836-.251,1a.816.816,0,0,1-.1.439C261.072,100.569,261.093,99.921,261.406,99.357Z"
          transform="translate(-154.706 -40.114)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M260.115,100.654c.167-.4.669-.564.585-.773s-.334.481-.711.69c.125,0,0,.167,0,.314.564,0,.439.523,1.045.711,0-.251,0-.46-.251-.334.439-.376,0-.5,0-1.024l-.376.585Z"
          transform="translate(-153.123 -39.383)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M260.537,103.886c.272-.648.481-.251.711-.355C261.331,103.029,260.056,103.53,260.537,103.886Z"
          transform="translate(-154.255 -35.591)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.8,100.942s-.585-.355-.209,0a.355.355,0,0,1,.209,0Z"
          transform="translate(-144.594 -38.354)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M258.052,101.39a.752.752,0,0,0-.4-.46c0,.23-.209.23-.335.376a.293.293,0,0,0,.439,0l-.146.293a.167.167,0,0,1,.251.167C257.989,101.724,257.78,101.557,258.052,101.39Z"
          transform="translate(-144.558 -38.196)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M255.261,100.87a2.091,2.091,0,0,0,.773,1.526.322.322,0,0,1-.1.23c-.1.084.23,0,.314-.293.167.1.1.23.314.125s0,.125,0,.251a5.519,5.519,0,0,1,1.944,1.631c0-.314.209,0,.355-.376a.167.167,0,0,0,0,.146c0,.125-.188,0-.293.209.272,0,.314.125.627-.188s-.418.188-.1-.272c-1.212-.794-1.923-1.15-3.324-2.091-.146,0,0-.188,0-.272s-.188-.146-.293,0a.334.334,0,0,1,0-.355C255.491,101.142,255.2,101.142,255.261,100.87Z"
          transform="translate(-141.453 -38.261)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.646,129.44c-.1.167,0,.167,0,.272a.23.23,0,0,1-.146,0c0,.481.439.418.669.69.1-.376.878,0,.732.335s.146-.1.23,0,0,.314,0,.418a.585.585,0,0,1,.1-.251c0,.188.355-.125.543-.167s0,.188.167.209.146-.188.251,0c0-.251,0-.481-.209-.606s0,.125,0,.188a.439.439,0,0,1-.5,0,.334.334,0,0,0-.167.251c0-.23-.4-.167-.251-.544a.418.418,0,0,1-.272,0c-.1.23,0,0,.167.125a.271.271,0,0,1,0,.209A11.836,11.836,0,0,1,224.646,129.44Z"
          transform="translate(-45.092 -7.105)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M259.055,99.11c-.146.125-.293,0-.439,0s.4,0,.439.125S259.055,99.173,259.055,99.11Z"
          transform="translate(-148.237 -40.18)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.542,105.78c0,.376.188.418.293.857.1,0,.188-.167.293-.125a.286.286,0,0,1,0-.481C254.856,106.052,254.9,105.905,254.542,105.78Z"
          transform="translate(-135.843 -32.907)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M260.66,105.195c0,.314.167.606.481,0C260.994,104.965,260.806,105.425,260.66,105.195Z"
          transform="translate(-154.629 -33.617)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.34,98.87c-.125.272.293.272.4.418C257.842,98.954,257.633,98.87,257.34,98.87Z"
          transform="translate(-144.264 -40.442)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.019,106.289c-.272-.439-.544-.481-.836-.9v.355a1.443,1.443,0,0,0-.293.314c.606-.125.753.293.92.773C255.789,106.665,255.6,105.975,256.019,106.289Z"
          transform="translate(-137.445 -33.332)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.373,106.384c-.314.23.355,0,.5-.314a.335.335,0,0,1-.335,0C254.394,106.154,254.6,106.446,254.373,106.384Z"
          transform="translate(-135.046 -32.59)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M255.138,105.8h.334c.1,0,0-.439-.188-.418s.146-.125.23-.272-.209,0-.272-.188.146,0,.209,0c0-.251-.1-.125,0-.418s-.188.314-.188.376-.23.125-.335,0,0,.125,0,.251C255.055,105.442,255.285,105.442,255.138,105.8Z"
          transform="translate(-136.941 -34.388)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.983,99h0a.165.165,0,0,0-.146.125c-.042.125.23,0,.334,0S256.837,99.167,256.983,99Z"
          transform="translate(-142.674 -40.3)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.1,99.73v.335c0-.355.125.209.314.125s0,.293-.1.23.648.334.983.209c-.146.4.293,0,.146.4s.125-.167.188-.167.376,0,.46.23.23-.46.23-.146c.188-.188,0-.23-.251,0-.1-.125-.23-.125-.4-.125s0-.125,0-.251-.272,0-.314-.209c-.314.606-.439-.272-.857,0C256.521,100.064,256.292,100,256.1,99.73Z"
          transform="translate(-142.359 -39.504)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.864,102.6a.188.188,0,0,0,.125.23c0,.167-.146.125-.209.251s.167.293.23.46c.125-.376.732.209,1.066-.188a.168.168,0,0,0,0,.125.439.439,0,0,1,.46,0c.376-.272.773-.146,1.087-.418-.146,0-.23-.23-.188-.188-.418-.5-1.8-.376-1.756-1.359.146,0,0,.4.293.439v-.794c0-.272.167.188.272,0s-.146,0-.209-.146c-.1.439-.5,0-.564,0s0,.125,0,.251.272,0,.272.188c-.376.272-.439,0-.711-.167.188.188,0,.878.209,1.213s.314.125.146.418.188-.146.272,0-.209.293,0,.355,0,.146,0,.251-.335-.335-.46,0v-.167c0-.042.125.125.188,0s-.125-.125-.23,0C256.927,103.08,257.053,102.7,256.864,102.6Z"
          transform="translate(-144.98 -38.109)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.747,99.809c0,.125.125,0,.146,0a.565.565,0,0,0,.146-.293C257.082,99.35,256.894,99.893,256.747,99.809Z"
          transform="translate(-142.354 -39.772)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.1,100.133a.92.92,0,0,0,.46.167S256.246,99.924,256.1,100.133Z"
          transform="translate(-140.516 -39.134)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.324,101.491c.272-.481-.376-.209-.585-.251A.671.671,0,0,0,257.324,101.491Z"
          transform="translate(-142.68 -37.9)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M257.484,104.552c.125-.125,0-.125.1-.418-.209.209-.376,0-.585.293-.146-.251-.125-.585.272-1.108,0-.753-.92-.711-1.463-.4-.69.794-.92,1.526-.69,1.861s1.505-.146,1.61.481c.209,0,.627.23.941.125s.1-.146.272-.418-.439-.209-.209-.544a.356.356,0,0,0-.251.125Z"
          transform="translate(-139.705 -36.257)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236,142.355a.481.481,0,0,0,.669-.355C236.376,142,236.23,142.251,236,142.355Z"
          transform="translate(-78.605 6.592)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.6,142.066c.418.209.4-.564.836-.648C236.06,141.293,235.6,141.941,235.6,142.066Z"
          transform="translate(-77.536 5.94)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.46,140.983c.439.251.585-.146.815-.376-.272-.251-.523.293-.606-.125S235.627,140.879,235.46,140.983Z"
          transform="translate(-77.083 4.807)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.818,140.411c-.146-.272-.251-.125-.5-.251,0,.188-.251.335.1.544a.272.272,0,0,1,0-.251C235.567,140.432,235.755,140.557,235.818,140.411Z"
          transform="translate(-76.144 4.585)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.082,140.717c.46,0,0-.648.481-.606C234.957,139.776,234.853,140.383,235.082,140.717Z"
          transform="translate(-75.346 4.425)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.8,142.574c0-.272-.1-.376.167-.46-.188-.146-.251-.251-.251-.376S237.426,142.3,237.8,142.574Z"
          transform="translate(-83.271 6.289)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.923,145.068c.251.188.23-.188.355-.23a1.588,1.588,0,0,1-.564-.418C236.672,144.629,236.965,144.859,236.923,145.068Z"
          transform="translate(-80.699 9.231)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.093,144.914c.335.146.753.167.46-.146a.355.355,0,0,0-.46.146Z"
          transform="translate(-78.782 9.573)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.113,144.664c.4,0,1.045-.167.794-.585-.314,0-.209.209-.544-.209C236.552,144.142,236.343,144.414,236.113,144.664Z"
          transform="translate(-79.136 8.631)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.116,146.358c.544.544,1.4-.774.481-1.108a2.777,2.777,0,0,0,.293.314C236.471,145.647,236.659,146.337,236.116,146.358Z"
          transform="translate(-79.181 10.136)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.14,142.188c.272.167.293-.272.439-.355A.35.35,0,0,0,234.14,142.188Z"
          transform="translate(-72.627 6.403)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.21,140.145c.272.125.523.146.627-.125C232.524,139.874,232.4,140.041,232.21,140.145Z"
          transform="translate(-66.85 4.37)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
      </g>
      <ellipse
        cx={81.655}
        cy={87.404}
        rx={81.655}
        ry={87.404}
        transform="translate(178.526 309.586) rotate(-177.3)"
        fill="url(#j)"
        style={{ mixBlendMode: 'screen', isolation: 'isolate' }}
      />
    </g>
    <g transform="translate(228.339 391.135)" opacity={0.88}>
      <path
        d="M202.417,229.8l5.833,10.515L201.539,305.1l-36.1-2.195-8.216-5.582,7.526-68.611Z"
        transform="translate(-33.671 -143.91)"
        fill="#197de5"
      />
      <path
        d="M148.166,346.063l175.269,9.993L340.326,190.55l1.756.167-17.059,167.2-177.234-10.1Z"
        transform="translate(-147.79 -185.524)"
        fill="#197de5"
      />
      <path
        d="M271.274,210.907l-14.4,124.365-40.514-2.927-11-4.62L222.021,195.5l37.4.209Z"
        transform="translate(-197.332 -180.126)"
        fill="#197de5"
      />
      <path
        d="M231.169,326.974l-.021.251-.732-.314-33.365-2.07L184.4,319.95l-4.725-.251,13.4-114.079,37.483.481,10.954,15.658-10.2,103.857,3.909,1.61Z"
        transform="translate(-113.892 -169.09)"
        fill="#197de5"
      />
      <path
        d="M256.394,237.345A170.375,170.375,0,0,1,242.2,225.031c-18.48-17.2-41.455-38.633-87.613-34.64l-.146-1.756c46.911-4.035,70.241,17.665,88.972,35.1a169.6,169.6,0,0,0,14.027,12.188c21.428,15.825,41.225,16.891,41.413,16.891v1.777C298.581,254.57,278.282,253.525,256.394,237.345Z"
        transform="translate(-118.462 -188.146)"
        fill="#197de5"
      />
    </g>
    <g transform="translate(190.856 352.658)">
      <path
        d="M171.65,290.771l37.943,1.986,7.233-69.489-37.65-1.108Z"
        transform="translate(-34.93 -112.575)"
        fill="#19b8ff"
        fillRule="evenodd"
      />
      <path
        d="M196.56,313.159l37.922,1.965,12.94-115.564-37.483-.481Z"
        transform="translate(-117.601 -137.744)"
        fill="#197de5"
        fillRule="evenodd"
      />
      <path
        d="M222.66,321.185l37.922,1.965,16.139-133.981-37.4-.209Z"
        transform="translate(-201.462 -148.78)"
        fill="#ff884a"
        fillRule="evenodd"
      />
      <path
        d="M349.813,351.387l17.059-167.2L365.116,184,348.224,349.527,159.514,338.761l-.084,1.777Z"
        transform="translate(-159.43 -154.189)"
        fill="#58fffe"
      />
      <path
        d="M233.139,189.06l11.832,15.2-14.4,124.365L217,323.041Z"
        transform="translate(-157.88 -148.671)"
        fill="#eb7f47"
        fillRule="evenodd"
      />
      <path
        d="M204.25,199.31l10.975,15.658-10.39,105.445-13.526-5.54Z"
        transform="translate(-74.429 -137.493)"
        fill="#19b8ff"
        fillRule="evenodd"
      />
      <path
        d="M168.86,292.179l6.355,5.812,6.711-64.785-5.833-10.515Z"
        transform="translate(5.802 -111.997)"
        fill="#58fffe"
        fillRule="evenodd"
      />
      <path
        d="M261.812,254.175l13.567,5.582-40.493-2.948-11-4.6Z"
        transform="translate(-202.692 -79.805)"
        fill="#cc6f3f"
        fillRule="evenodd"
      />
      <path
        d="M194.33,253.65l13.275,5.143,38.173,2.362-13.5-5.54Z"
        transform="translate(-111.295 -78.235)"
        fillRule="evenodd"
        fill="url(#k)"
      />
      <path
        d="M172.07,254.98l8.216,5.6,36.082,2.2-6.355-5.812Z"
        transform="translate(-35.35 -76.785)"
        fill="#19b8ff"
        fillRule="evenodd"
      />
      <path
        d="M310.523,248.03v-1.756c-.209,0-19.985-1.066-41.434-16.891A169.612,169.612,0,0,1,255.061,217.2c-18.815-17.435-42.04-39.134-88.951-35.1l.146,1.756c46.242-3.993,69.238,17.393,87.676,34.64A174.343,174.343,0,0,0,268.127,230.8C290.015,246.964,310.314,248.03,310.523,248.03Z"
        transform="translate(-117.046 -156.8)"
        fill="#58fffe"
      />
      <g transform="translate(4.544)">
        <path
          d="M227.926,225.31a.481.481,0,0,0,.251.523C228.428,225.352,228.094,225.394,227.926,225.31Z"
          transform="translate(-168.542 -109.14)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.39,226.51v.188a1.312,1.312,0,0,0,.732.251C224.85,226.761,224.683,226.719,224.39,226.51Z"
          transform="translate(-158.024 -107.832)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.422,226c0,.23.167.355.209.585C224.735,226.209,224.61,226.188,224.422,226Z"
          transform="translate(-157.637 -108.388)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.491,226.693h-.167c-.063,0,.209.251.188.5s0-.167,0-.188a3.068,3.068,0,0,0,.209-.46,1.172,1.172,0,0,1-.335-.376,1.152,1.152,0,0,0-.209.376C224.115,226.755,224.47,226.463,224.491,226.693Z"
          transform="translate(-157.163 -108.202)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.07,226.8c0,.314.167.167.251.272s-.188.439.188,0C224.384,226.863,224.237,226.967,224.07,226.8Z"
          transform="translate(-156.742 -107.515)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.4,227s.314.251.439.335S223.606,227.125,223.4,227Z"
          transform="translate(-154.69 -107.297)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M222.972,227.021h.23C223.348,226.791,223.034,226.687,222.972,227.021Z"
          transform="translate(-153.177 -107.507)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M222.542,226.36c0,.167.125,0,.251.167C222.94,226.255,222.605,226.318,222.542,226.36Z"
          transform="translate(-151.87 -108.036)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.839,225v.188c0,.167.188,0,.314,0S225.839,225,225.839,225Z"
          transform="translate(-162.107 -109.478)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.706,226.282v.063S225.726,226.261,225.706,226.282Z"
          transform="translate(-161.367 -108.084)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.6,226.5c0,.125.125,0,.23.146,0-.209,0-.209-.146-.251S225.642,226.5,225.6,226.5Z"
          transform="translate(-161.261 -107.966)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M217.64,225.42c.146.334.272.355.5.753,0-.1.146-.209.23-.209.125-.314-.355,0,0-.481C217.932,225.587,217.932,225.441,217.64,225.42Z"
          transform="translate(-137.183 -109.02)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.947,224.42c-.125.335.23,0,.272.314s-.209.1-.272,0c-.1.481.418,0,.23.439a.418.418,0,0,1,.314,0c0-.209-.125-.125-.167-.188s0-.251.188-.481a.564.564,0,0,1-.564-.084Z"
          transform="translate(-156.431 -110.111)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.222,226.31c0,.188,0,.167-.125.355s0,.1.125.125S223.452,226.289,223.222,226.31Z"
          transform="translate(-153.448 -108.05)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.12,225.668c0,.314.314.544.46-.125C229.371,225.354,229.308,225.856,229.12,225.668Z"
          transform="translate(-172.37 -108.934)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M228.03,227.288c-.188.46.564.544.711.4s0,.564,0,.878a4.786,4.786,0,0,0,.753-1.066c0-.272-.815-.376-.753,0C228.469,227.414,228.3,227.226,228.03,227.288Z"
          transform="translate(-169.942 -107.021)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M221.8,225.77c0,.167.23.1.293,0S221.879,225.812,221.8,225.77Z"
          transform="translate(-149.576 -108.68)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M221.1,225.16a3.408,3.408,0,0,0,.23.355C221.43,225.181,221.242,225.16,221.1,225.16Z"
          transform="translate(-147.37 -109.304)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M193.08,200.8a2.088,2.088,0,0,1-.355.209c.125,0,0,.439.314.272S193.1,200.967,193.08,200.8Z"
          transform="translate(-59.901 -135.869)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M256.386,189.2a.585.585,0,0,0-.272.627c.335-.23.544,0,.753-.355S256.407,189.534,256.386,189.2Z"
          transform="translate(-256.101 -148.519)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.17,187.11c0,.209,0,.627.523.335C254.546,187.256,254.3,187.277,254.17,187.11Z"
          transform="translate(-249.85 -150.798)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M219.576,228.171c0,.418-.125.794,0,.92a2.509,2.509,0,0,0,.23-.941.314.314,0,0,1-.23.021Z"
          transform="translate(-142.527 -106.043)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M193.156,200.53c-.23,1,1.108,1.61,2.32,1.3C195.038,201.366,193.658,201.513,193.156,200.53Z"
          transform="translate(-63.029 -136.163)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M194.5,199c-.418.355.188.251.209.606s-.418.167-.481,0c-.251.4.5.23.753.209s.125-.334.523-.481C195.276,199,194.837,199.146,194.5,199Z"
          transform="translate(-65.253 -137.832)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M198.351,197.37c-.4.23-.314.711.188.355Z"
          transform="translate(-76.481 -139.609)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.411,211.28c-.23.209.125.5.272.481S230.411,211.531,230.411,211.28Z"
          transform="translate(-176.044 -124.44)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M197.878,198a.564.564,0,0,0,0,.627C198.38,198.481,197.878,198.272,197.878,198Z"
          transform="translate(-75.382 -138.922)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M198.456,198.871c-.4-.167-.209.753.209.355C198.728,198.954,198.143,199.163,198.456,198.871Z"
          transform="translate(-76.901 -137.995)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M198.19,199.09c-.314.146,0,.857.314.544C198.232,199.529,198.42,199.362,198.19,199.09Z"
          transform="translate(-76.362 -137.733)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M197.517,200.711c-.1,0,0,.167-.146.272.4,0,.355,0,.543.125C198.186,200.816,197.706,200.69,197.517,200.711Z"
          transform="translate(-74.415 -135.968)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.078,209.76c-.5.209,0,.4,0,.606C226.433,210.262,226.161,209.948,226.078,209.76Z"
          transform="translate(-162.22 -126.098)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M255.406,192.629c0-.1.167-.188.314-.293-.209-.46-1.192-.272-1.024-.941.293-.146.209.355.481.272s-.272-.209-.188-.627c-.46.188-.314.167-.773.355.209.648.376.46.711,1.233.293-.439.4.272.543.125-.481.272-.251.5.251.418,0,.272-.376,0-.439.355.4,0,.251.9.732.481h.209c.209,0-.606.167-.355.481.251-.46.4,0,.627,0,.5-.773-.544-1.359-.5-2.09a3.028,3.028,0,0,0-.585.23Z"
          transform="translate(-251.881 -146.512)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M255.115,193.5a.544.544,0,0,0,.1.753C255.241,194,255.115,193.751,255.115,193.5Z"
          transform="translate(-252.133 -143.829)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M222.793,207.6c-.167.272.209.523-.209.773C222.96,208.436,223.127,207.579,222.793,207.6Z"
          transform="translate(-152.099 -128.453)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M209.588,169.74a.355.355,0,0,0,0,.418c.209-.146.293,0,.523-.209l-.125-.209C209.755,169.949,209.672,169.949,209.588,169.74Z"
          transform="translate(-111.927 -169.74)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M207.4,174c-.481.209,0,.418,0,.627C207.778,174.439,207.506,174.23,207.4,174Z"
          transform="translate(-104.535 -165.094)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.607,208.76c0,.523.857.773,1.422.815C233.133,209.094,232.025,209.262,231.607,208.76Z"
          transform="translate(-181.024 -127.188)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.457,191.5c-.648.46.648.146,0,.544a.783.783,0,0,0,.523-.209Z"
          transform="translate(-250.346 -146.01)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.721,209.32c-.272.146,0,.293,0,.418a1.841,1.841,0,0,1,.585-.355C233.223,209.278,232.888,209.445,232.721,209.32Z"
          transform="translate(-183.371 -126.577)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.376,209.76c-.251.251-.418.627,0,.481a.335.335,0,0,0,0-.481Z"
          transform="translate(-184.633 -126.098)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.411,209.85c-.167.355-.251,1.024.23.962s-.125-.272.4-.418C232.683,210.456,232.558,210.164,232.411,209.85Z"
          transform="translate(-182.435 -125.999)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.855,210.7c-.69.272.167,1.589.836.9a.633.633,0,0,0-.4.146C232.482,211.327,231.855,211.2,231.855,210.7Z"
          transform="translate(-180.666 -125.073)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.343,212.75c-.251.188.146.355.167.543C231.762,213.043,231.594,212.855,231.343,212.75Z"
          transform="translate(-178.9 -122.837)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M222.755,219.49c0,.314,0,.544.314.564C223.132,219.72,222.881,219.657,222.755,219.49Z"
          transform="translate(-152.563 -115.487)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M220.321,220.506c0,.523.669.167.983.335.125-.335-.46,0-.188-.481C220.781,220.757,220.719,220.485,220.321,220.506Z"
          transform="translate(-145.718 -114.538)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.529,191.79c-.272.125-.293.251-.439.355.167.125.9-.355.711.125.23.146.1-.251.272-.335C254.884,191.894,254.613,192.02,254.529,191.79Z"
          transform="translate(-250.063 -145.694)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.888,209.828c-.439.376.293.418.481.544a1.046,1.046,0,0,1,.314-.272c-.125.439.376,0,.585.188s-.209.188-.146.272.272.251.627,0c-.4-.23-.335-.209-.4-.69l-.355.209a.355.355,0,0,0-.314-.272c.335-.146.481-.146.564-.418s.1.355.481,0l-.606-.209c0,.4-.314.376-.794.564.314,0,.125.167.209.355C232.076,210.413,232.034,210.037,231.888,209.828Z"
          transform="translate(-182.037 -126.73)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M254.09,192.152c.251,0,.376,0,.355.355a.46.46,0,0,1,.439,0S254.508,191.9,254.09,192.152Z"
          transform="translate(-249.875 -145.366)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.738,210c-.314.46.439.209.711.125s-.251.23-.125.355C233.034,209.791,231.842,210.146,231.738,210Z"
          transform="translate(-180.653 -125.836)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M174.562,245.582c0,.418-.1.794.146.9a2.3,2.3,0,0,0,.188-.941A.314.314,0,0,1,174.562,245.582Z"
          transform="translate(-3.586 -87.079)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.043,222.206a.334.334,0,0,0,.209.355c0-.23.188-.251.251-.5h-.23C225.315,222.248,225.252,222.311,225.043,222.206Z"
          transform="translate(-159.764 -112.684)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.459,213.68c-.4.209-.314.69.209.334Z"
          transform="translate(-166.465 -121.823)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M222.1,219.16c0,.293.418.293.523.188C222.643,218.867,222.267,219.327,222.1,219.16Z"
          transform="translate(-150.737 -115.889)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M220.823,216.64a.585.585,0,0,0,.439.46C221.492,216.619,220.949,216.807,220.823,216.64Z"
          transform="translate(-146.764 -118.595)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M220.786,217.56c-.418.125.335.69.376.125C221.037,217.455,220.723,217.978,220.786,217.56Z"
          transform="translate(-146.267 -117.592)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M220.332,217.65c-.146.314.481.69.585.209C220.646,217.943,220.687,217.692,220.332,217.65Z"
          transform="translate(-145.29 -117.493)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M194.569,213.87a.565.565,0,0,0,0,.544C194.966,214.142,194.84,213.87,194.569,213.87Z"
          transform="translate(-65.236 -121.616)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M197.658,215.791c-.125,0,0,.167-.167.272.418,0,.355,0,.544.125C198.306,215.9,197.825,215.77,197.658,215.791Z"
          transform="translate(-74.785 -119.523)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M220.4,223.68c-.209.481.314.293.439.439C220.961,223.743,220.605,223.764,220.4,223.68Z"
          transform="translate(-145.312 -110.918)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M198.105,222.009a2.813,2.813,0,0,1,.314-.272c-.188-.481-1.192-.293-1-.962.272-.125.209.355.46.272s-.272-.209-.188-.627c-.46.188-.293.167-.753.355.188.669.355.46.69,1.233.314-.439.4.293.564.146-.5.251-.251.481.23.4,0,.272-.376.1-.439.355.4,0,.272.9.753.481q0,.125.188,0c0,.251-.606.188-.355.5.272-.481.418,0,.627,0,.5-.773-.544-1.359-.5-1.986A2.091,2.091,0,0,0,198.105,222.009Z"
          transform="translate(-74.835 -114.473)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M219.761,217.83c0,.334.376.5.585.5S219.886,218.018,219.761,217.83Z"
          transform="translate(-143.611 -117.297)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M219.316,219.927c0,.293.5.23.355.711C219.985,220.408,219.567,219.676,219.316,219.927Z"
          transform="translate(-142.079 -115.067)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.24,227.369c.355.376,1.15,0,1.589-.335C226.641,226.617,225.846,227.39,225.24,227.369Z"
          transform="translate(-161.508 -107.395)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.67,230.2c-.188.773.585-.314.355.418.125,0,.188-.314.251-.5A2.571,2.571,0,0,1,226.67,230.2Z"
          transform="translate(-164.882 -103.895)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.79,228.147c0,.293.251.167.334.272a1.568,1.568,0,0,1,.209-.669C226.124,227.792,225.894,228.126,225.79,228.147Z"
          transform="translate(-162.162 -106.479)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.858,228.46c0,.355,0,.753.293.4A.355.355,0,0,0,225.858,228.46Z"
          transform="translate(-162.126 -105.705)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.09,228.14c.125.376.5.941.815.585,0-.335-.272-.146,0-.585C225.7,228.412,225.4,228.265,225.09,228.14Z"
          transform="translate(-160.271 -106.054)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.914,228.36c-.355.669,1.171,1.087,1.212.125a2.127,2.127,0,0,0-.209.376C224.708,228.485,224.1,228.862,223.914,228.36Z"
          transform="translate(-156.92 -105.814)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M222.439,229.549c0,.314.335.188.481.314C222.941,229.507,222.69,229.465,222.439,229.549Z"
          transform="translate(-151.746 -104.557)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M220.025,230.67c0,.293,0,.523.334.543C220.422,230.879,220.172,230.837,220.025,230.67Z"
          transform="translate(-144.147 -103.295)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M202.82,203c-.4.355.4.544.5.9.335-.167-.335-.334.209-.5C202.925,203.4,203.134,203.167,202.82,203Z"
          transform="translate(-91.111 -133.469)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.3,230.418a1.193,1.193,0,0,0,0,.564c.188,0,.418-.878.606-.376.272,0,0-.272,0-.439S226.505,230.523,226.3,230.418Z"
          transform="translate(-163.839 -103.879)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.92,228.751c0,.585.5.146.711,0a1.171,1.171,0,0,1,0-.418c.188.418.293-.23.564-.23s0,.272,0,.314.376,0,.481-.439c-.46,0-.4,0-.753-.251l-.125.4a.335.335,0,0,0-.418,0c.146-.314.251-.418.146-.669s.314.188.355-.334l-.585.251c.272.293,0,.481-.23.941.23-.188.209,0,.376.125C225.443,229.085,225.171,228.834,224.92,228.751Z"
          transform="translate(-160.686 -107.166)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.11,230.791c.23,0,.314-.209.481,0a.325.325,0,0,1,.272-.335.606.606,0,0,0-.753.335Z"
          transform="translate(-163.36 -103.563)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.632,228.124c0,.544.481-.125.627-.376,0,0,0,.334.125.334C225.51,227.142,224.924,228.124,224.632,228.124Z"
          transform="translate(-158.809 -106.561)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.24,221.07c.188.188.606,0,.836-.188C224.951,220.673,224.533,221.07,224.24,221.07Z"
          transform="translate(-157.665 -114.036)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.98,222.522c0,.418.314-.146.188.23a.385.385,0,0,1,.125-.272Z"
          transform="translate(-159.429 -112.226)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.54,221.469c0,.146.125,0,.167.125a.69.69,0,0,1,.1-.334C224.707,221.281,224.645,221.448,224.54,221.469Z"
          transform="translate(-158.027 -113.557)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.57,221.62c0,.188,0,.4.146.209a.209.209,0,0,0-.146-.209Z"
          transform="translate(-157.994 -113.164)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.155,221.46c0,.188.251.481.418.293s-.125,0,0-.293C224.468,221.585,224.322,221.523,224.155,221.46Z"
          transform="translate(-157.036 -113.339)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.569,221.57c-.188.355.606.564.627,0a.234.234,0,0,1-.1.188A.377.377,0,0,1,223.569,221.57Z"
          transform="translate(-155.321 -113.219)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.825,222.634a.925.925,0,0,0,0,.293c.1,0,.23-.46.334-.188.125,0,0-.146,0-.23S225.013,222.7,224.825,222.634Z"
          transform="translate(-159.002 -112.213)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.088,221.776c0,.293.251,0,.355,0a.419.419,0,0,1,0-.209c0,.209.146-.125.293-.125s0,.146,0,.167.209,0,.251-.23-.209,0-.376-.125v.209a.167.167,0,0,0-.209,0c0-.167.125-.23,0-.355s.146,0,.167-.167l-.293.125c.146.146,0,.251-.125.481.125-.1.1,0,.209,0S224.213,221.8,224.088,221.776Z"
          transform="translate(-157.262 -113.906)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.7,222.83h.251a.209.209,0,0,1,.146-.188S224.763,222.6,224.7,222.83Z"
          transform="translate(-158.647 -112.053)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.962,221.457c0,.272.251,0,.314-.188s0,.167,0,.167C224.422,220.934,224.108,221.457,223.962,221.457Z"
          transform="translate(-156.32 -113.607)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.072,211.54c0,.523.857.794,1.422.815C231.661,211.9,230.47,211.958,230.072,211.54Z"
          transform="translate(-176.291 -124.156)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M196.5,219.59c-.669.481.648.146,0,.564a2.227,2.227,0,0,0,.523-.209,2.237,2.237,0,0,1-.523-.355Z"
          transform="translate(-71.197 -115.378)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.181,212.11c-.272.146,0,.272,0,.418a1.5,1.5,0,0,1,.606-.355C231.683,212.068,231.348,212.235,231.181,212.11Z"
          transform="translate(-178.633 -123.535)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.858,212.54c-.251.251-.439.627,0,.5a.376.376,0,0,0,0-.5Z"
          transform="translate(-179.916 -123.066)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.883,212.64c-.167.355-.251,1.024.209.962s-.1-.272.418-.418C231.155,213.225,230.988,212.933,230.883,212.64Z"
          transform="translate(-177.708 -122.957)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.294,213.49c-.711.251.167,1.589.815.9a.7.7,0,0,0-.4.125C230.817,214.117,230.106,213.992,230.294,213.49Z"
          transform="translate(-175.802 -122.03)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.256,211.43c-.251.188.146.376.146.564C233.674,211.744,233.507,211.555,233.256,211.43Z"
          transform="translate(-184.806 -124.276)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.052,219.77c0,.314,0,.544.314.564C225.512,220,225.2,219.937,225.052,219.77Z"
          transform="translate(-159.689 -115.182)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M222.629,220.786c0,.523.648.167.962.335.146-.335-.46,0-.167-.481C223.089,221.037,223.026,220.765,222.629,220.786Z"
          transform="translate(-152.834 -114.233)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M196.559,219.89a1.609,1.609,0,0,0-.439.355c.167.1.9-.376.711.125.251.146.125-.272.293-.355C196.914,219.994,196.643,220.12,196.559,219.89Z"
          transform="translate(-70.927 -115.051)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.358,212.6c-.439.4.293.439.481.564a.857.857,0,0,1,.314-.293c-.125.439.376,0,.585.209s-.209.188-.167.272.293.251.648,0c-.4-.23-.335-.23-.4-.69l-.355.209a.355.355,0,0,0-.314-.272c.335-.146.46-.146.564-.418s.1.355.46,0l-.585-.209c0,.4-.335.376-.794.564.293,0,.1.167.188.334C230.588,213.2,230.588,212.827,230.358,212.6Z"
          transform="translate(-177.308 -123.688)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M196.13,220.249c.251,0,.376,0,.335.334q.314-.146.439,0S196.527,220.019,196.13,220.249Z"
          transform="translate(-70.727 -114.72)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.2,212.76c-.314.46.46.23.711.146s-.23.23-.125.334C231.521,212.614,230.434,212.969,230.2,212.76Z"
          transform="translate(-175.942 -122.826)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M193.353,218.1c-.523.585,0,.836.334,1.171C194.064,218.727,193.269,218.581,193.353,218.1Z"
          transform="translate(-61.344 -117.003)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M216.39,227.35c0,.23,0,.46.167.5s.146-.523.314-.92C216.494,226.786,216.7,227.371,216.39,227.35Z"
          transform="translate(-133.049 -107.397)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M214.041,227.86c0,.314.355.188.481.314C214.627,227.651,214.25,227.985,214.041,227.86Z"
          transform="translate(-125.808 -106.359)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M191.368,220.76c-.188.125-.188.794.272.481C191.347,221.115,191.473,221.011,191.368,220.76Z"
          transform="translate(-55.262 -114.102)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200.466,204.54c-.46.209.146.627.5.335C200.9,204.582,200.57,204.77,200.466,204.54Z"
          transform="translate(-83.509 -131.79)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M191.049,221.15c-.146.836,0,.669.4,1.526C191.781,222.153,191.321,221.652,191.049,221.15Z"
          transform="translate(-54.651 -113.677)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M190.485,222.4c.648,0,.606.523.857.962C191.551,222.9,191.468,221.977,190.485,222.4Z"
          transform="translate(-53.46 -112.434)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M196.822,204.278c-.272-.1-.418.355-.188.481S196.508,204.445,196.822,204.278Z"
          transform="translate(-71.461 -132.093)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M192.645,224.47a.481.481,0,0,0,0,.9C193.063,224.951,192.352,224.93,192.645,224.47Z"
          transform="translate(-58.672 -110.056)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M190.977,223.806c0,.1,0,.293-.188.209s.251.251.5,0a.313.313,0,0,0,0-.355C191.124,223.827,191.04,223.827,190.977,223.806Z"
          transform="translate(-53.91 -110.939)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M192.674,225.536c-.293-.1,0,.5.23.418s0,0,0-.146v-.125C192.9,225.557,192.653,225.829,192.674,225.536Z"
          transform="translate(-59.348 -108.907)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M195.46,206c-.314,0,.209.544.272.753C195.983,206.376,195.586,206.418,195.46,206Z"
          transform="translate(-68.03 -130.198)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M192.59,204.975a1.735,1.735,0,0,1,.146.272C193.238,205.142,193.029,204.661,192.59,204.975Z"
          transform="translate(-59.453 -131.43)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M192.5,205.65c-.585.5.418.355.418.753C193.088,206.152,192.733,205.671,192.5,205.65Z"
          transform="translate(-58.822 -130.58)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M207.045,203.7a.481.481,0,0,0,0,.753C207.275,204.2,207.024,203.951,207.045,203.7Z"
          transform="translate(-103.404 -132.706)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.01,215.67c0,.46.669.188.878.585C233.909,215.837,233.156,215.67,233.01,215.67Z"
          transform="translate(-184.81 -119.653)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.066,215.522c0,.481.314.5.606.648.167-.314-.439-.4,0-.606C232.63,215.209,232.233,215.648,232.066,215.522Z"
          transform="translate(-181.65 -119.923)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.263,216.237c.23-.209,0-.251,0-.544-.167,0-.4-.146-.5.272a.272.272,0,0,1,.272,0C232.158,216.027,232.1,216.237,232.263,216.237Z"
          transform="translate(-180.676 -119.655)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.5,216.132c0,.439.606-.272.753.251C232.361,215.714,231.755,215.8,231.5,216.132Z"
          transform="translate(-180.043 -119.425)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M221.225,222.24c-.209.523.314.314.376.125S221.351,222.47,221.225,222.24Z"
          transform="translate(-147.793 -112.488)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M196.984,215.07c-.481.209,0,.585.314.272A.355.355,0,0,1,196.984,215.07Z"
          transform="translate(-72.48 -120.307)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M218.928,220.54c0,.23.272.5.481.314S219.053,220.665,218.928,220.54Z"
          transform="translate(-140.938 -114.342)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M191.517,215.91c.188,1.129,1.129,1.819,1.756,2.822C193.963,217.039,191.706,217.081,191.517,215.91Z"
          transform="translate(-57.586 -119.391)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M218.363,225.81c-.146.418.23.732.376.125Z"
          transform="translate(-138.973 -108.595)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M217.617,226.11a.564.564,0,0,0,.439.439C218.265,226.068,217.742,226.256,217.617,226.11Z"
          transform="translate(-136.847 -108.268)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M217.584,227c-.439.146.335.711.376.125C217.814,227,217.522,227.46,217.584,227Z"
          transform="translate(-136.355 -107.297)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M194.183,218.316c.376-.209,0-.606-.188-.606s-.1.23-.272.334C193.995,217.856,194.183,217.835,194.183,218.316Z"
          transform="translate(-63.136 -117.428)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M190.781,221.84c0,.167-.188.92.355.627C190.844,222.174,190.928,222.174,190.781,221.84Z"
          transform="translate(-53.714 -112.924)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M191.915,214.47c0,.167.669.669.439,1.045C192.855,215.453,192.166,214.533,191.915,214.47Z"
          transform="translate(-57.544 -120.961)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M190.21,211.573c0,.209,0,.5.335.544,0-.376.314-.23.418-.606C190.649,211.761,190.482,211.865,190.21,211.573Z"
          transform="translate(-52.411 -124.189)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.389,210.849c0-.293-.5,0-.585-.209-.23.23.125.5.439.209,0,.125,0,.23-.293.355C226.263,211.393,226.368,211.037,226.389,210.849Z"
          transform="translate(-162.092 -125.138)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M219.652,218.2a.982.982,0,0,0,0,.773C220.969,218.974,219.631,219.015,219.652,218.2Z"
          transform="translate(-143.062 -116.894)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M220.056,220.09c-.146,0,0,.188-.146.4a5.913,5.913,0,0,0,.878-.146C220.767,219.9,220.077,220.654,220.056,220.09Z"
          transform="translate(-144.324 -114.833)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M221.1,224.054c-.167.293.627,0,.878.146C221.876,223.741,221.354,224.054,221.1,224.054Z"
          transform="translate(-147.963 -110.623)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M219.209,219.977c0,.272.5-.1.732,0,.146-.355-.439,0-.293-.4C219.5,219.789,219.376,220,219.209,219.977Z"
          transform="translate(-142.034 -115.389)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M194.79,217.76a1.108,1.108,0,0,0,.439.753.836.836,0,0,0-.439-.753Z"
          transform="translate(-66.252 -117.373)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M217.49,221.979c.355.376,1.171,0,1.61-.335C218.87,221.227,218.033,222,217.49,221.979Z"
          transform="translate(-137.577 -113.272)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M218.94,224.814c-.188.773.585-.314.355.418.1,0,.167-.314.251-.5A2.715,2.715,0,0,1,218.94,224.814Z"
          transform="translate(-140.992 -109.773)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M218.08,222.757c-.1.293.23.167.314.272a1.589,1.589,0,0,1,.23-.669C218.415,222.4,218.268,222.736,218.08,222.757Z"
          transform="translate(-138.293 -112.357)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M218.073,223.07c0,.355,0,.753.272.4S218.261,223.112,218.073,223.07Z"
          transform="translate(-138.139 -111.583)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M217.37,222.75c.1.376.5.941.794.585,0-.334-.251-.146,0-.585C217.976,223.022,217.663,222.875,217.37,222.75Z"
          transform="translate(-136.391 -111.932)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M216.194,223c-.355.669,1.171,1.087,1.192.125a2.847,2.847,0,0,0-.188.376C216.988,223,216.382,223.439,216.194,223Z"
          transform="translate(-133.04 -111.659)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M218.686,225.028a1.63,1.63,0,0,0-.1.564c.209,0,.439-.878.627-.376.272,0-.1-.272,0-.439S218.9,225.133,218.686,225.028Z"
          transform="translate(-140.07 -109.757)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M217.16,223.306c0,.585.481.146.711.1a1.068,1.068,0,0,1,0-.418c.209.418.314-.23.585-.23s0,.272,0,.314.376,0,.46-.439c-.439.1-.376,0-.732-.251l-.125.4a.334.334,0,0,0-.418,0c.146-.314.251-.418.125-.669s.314.188.355-.334l-.564.251c.272.293,0,.481-.23.941.23-.188.188,0,.376.125C217.62,223.641,217.327,223.39,217.16,223.306Z"
          transform="translate(-136.704 -112.99)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M218.373,225.4c.251-.1.314-.209.481,0a.324.324,0,0,1,.293-.335C219.272,225.04,218.52,224.956,218.373,225.4Z"
          transform="translate(-139.485 -109.434)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M216.9,222.749c0,.543.481-.125.606-.376,0,.1,0,.335.146.335C217.78,221.7,217.174,222.749,216.9,222.749Z"
          transform="translate(-134.919 -112.453)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M196.58,226.236c.564-.146.836-.983.878-1.526C196.956,224.731,197.019,225.755,196.58,226.236Z"
          transform="translate(-72.223 -109.794)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M192.32,204.524c.5.5.167-.648.606-.125,0,0-.146-.293-.23-.439A4.332,4.332,0,0,1,192.32,204.524Z"
          transform="translate(-58.785 -132.423)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M196.18,226.2c.146.209.293-.146.439-.188a1.15,1.15,0,0,1-.376-.46C196.138,225.738,196.305,226.01,196.18,226.2Z"
          transform="translate(-70.548 -108.878)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M195.665,226.048c.272.167.669.251.523,0A.418.418,0,0,0,195.665,226.048Z"
          transform="translate(-69.071 -108.436)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M195.337,225.832c.376,0,1.108,0,1.024-.46-.272,0-.293.167-.439-.272C195.985,225.414,195.65,225.623,195.337,225.832Z"
          transform="translate(-68.534 -109.369)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M194.366,225.153c.272.585,1.693-.544.941-.983a.483.483,0,0,0,.146.335C195.014,224.525,194.889,225.215,194.366,225.153Z"
          transform="translate(-65.66 -110.383)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M192.035,204.283c.125.23.251.209.376.335.125-.188-.4-.753.125-.711.146-.251-.272,0-.376-.167C192.14,203.928,192.265,204.158,192.035,204.283Z"
          transform="translate(-57.832 -132.663)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M196.441,226.637c.418.314.46-.376.585-.585a.877.877,0,0,1-.293-.23c.46,0,0-.355.209-.606s.209.167.293,0,.272-.314,0-.585c-.251.439-.23.355-.732.543l.23.272a.46.46,0,0,0-.293.355c-.167-.272-.167-.4-.46-.418s.376-.188,0-.439a2.322,2.322,0,0,1-.209.606c.418-.1.4.209.606.606,0-.272.167-.146.355-.272C197.089,226.323,196.671,226.449,196.441,226.637Z"
          transform="translate(-70.453 -109.882)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M191.8,204.431c.1-.272,0-.376.376-.418-.146-.167-.146-.293,0-.4S191.551,204.18,191.8,204.431Z"
          transform="translate(-56.866 -132.81)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M195.45,225.356c.5.167.251-.46.146-.69s.251.146.376,0C195.283,224.164,195.659,225.1,195.45,225.356Z"
          transform="translate(-68.375 -110.001)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M219.61,230.168c.355.4,1.171,0,1.61-.314C220.99,229.416,220.174,230.294,219.61,230.168Z"
          transform="translate(-144.129 -104.319)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M206.337,201.83c-.648.46.648.146,0,.544a3.576,3.576,0,0,0,.523-.209Z"
          transform="translate(-101.631 -134.745)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M220.209,230.967c-.1.272.251.146.334.251a1.693,1.693,0,0,1,.209-.648C220.564,230.591,220.418,230.946,220.209,230.967Z"
          transform="translate(-144.874 -103.404)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M220.239,231.27c0,.355,0,.753.293.4a.376.376,0,0,0-.293-.4Z"
          transform="translate(-144.758 -102.641)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M219.49,231.011c.1.355.481.92.794.564,0-.314-.251-.125,0-.585C220.1,231.178,219.783,231.011,219.49,231.011Z"
          transform="translate(-142.943 -102.946)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M203.205,202.13c-.69.251.167,1.589.815.878a.559.559,0,0,0-.376.146C203.728,202.736,203.017,202.632,203.205,202.13Z"
          transform="translate(-92.102 -134.418)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M202.736,204.17c-.251.188.146.376.146.544C203.154,204.484,202.987,204.3,202.736,204.17Z"
          transform="translate(-90.483 -132.194)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.915,214.25c0,.293,0,.523.314.544C228.291,214.459,228.061,214.4,227.915,214.25Z"
          transform="translate(-168.51 -121.201)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M181.191,205.109c0,.523.669.125.983.293.125-.355-.46,0-.188-.5C181.672,205.3,181.589,205.109,181.191,205.109Z"
          transform="translate(-24.787 -131.398)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M206.423,202.13c-.272.1-.293.23-.439.334s.9-.355.711.146c.23.125,0-.272.272-.355C206.778,202.234,206.507,202.339,206.423,202.13Z"
          transform="translate(-101.361 -134.418)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M219.31,231.527c0,.585.5.125.732.1a.752.752,0,0,1,0-.418c.209.418.293-.23.585-.251s0,.293,0,.314a.461.461,0,0,0,.46-.418.786.786,0,0,1-.732-.251l-.146.376a.334.334,0,0,0-.4,0c.146-.334.23-.439.125-.69s.314.188.355-.314l-.564.23c.272.293,0,.5-.23.962.209-.209.188,0,.376.125C219.854,231.861,219.644,231.59,219.31,231.527Z"
          transform="translate(-143.369 -104.047)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M205.963,202.485c.251,0,.376,0,.355.334.209-.125.334-.125.439,0S206.361,202.255,205.963,202.485Z"
          transform="translate(-101.152 -134.083)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M219.028,230.931c0,.544.481-.125.627-.376,0,.125,0,.334.146.355C219.864,229.948,219.32,230.931,219.028,230.931Z"
          transform="translate(-141.498 -103.493)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.341,230.627c.167.188.523,0,.732-.146S223.592,230.648,223.341,230.627Z"
          transform="translate(-154.822 -103.544)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M191.6,222.12c-.648.481.648.146,0,.564,0,0,.334-.125.523-.209a2.465,2.465,0,0,1-.523-.355Z"
          transform="translate(-56.076 -112.619)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M189.361,221c-.272.146,0,.293,0,.418a1.5,1.5,0,0,1,.606-.355C189.863,221,189.528,221.209,189.361,221Z"
          transform="translate(-49.387 -113.84)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M190.035,221.48c-.251.23-.439.606,0,.481a.334.334,0,0,0,0-.481Z"
          transform="translate(-50.667 -113.317)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M189.061,221.57c-.167.355-.251,1.024.23.962s-.125-.272.4-.418C189.333,222.155,189.187,221.863,189.061,221.57Z"
          transform="translate(-48.461 -113.219)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M190.485,220.92c-.69.272.167,1.589.836.9a.633.633,0,0,0-.4.146C191.007,221.547,190.3,221.422,190.485,220.92Z"
          transform="translate(-52.811 -113.927)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.99,217.314c0,.314.355.188.481.314C233.45,217.273,233.262,217.252,232.99,217.314Z"
          transform="translate(-184.351 -117.89)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M186.128,208.44c0,.314,0,.544.335.544C186.5,208.649,186.253,208.607,186.128,208.44Z"
          transform="translate(-39.381 -127.537)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M183.661,209.4c0,.523.669.125.983.293.125-.355-.46,0-.188-.5C184.121,209.629,183.954,209.357,183.661,209.4Z"
          transform="translate(-32.42 -126.719)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M181.1,249.224c0,.272.334.125.314.376a.648.648,0,0,1-.293.188c0,.439.418,0,.439-.439C181.267,249.371,181.309,249.015,181.1,249.224Z"
          transform="translate(-23.964 -83.133)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M191.683,222.42c-.272.125-.293.23-.439.355s.9-.376.711.125c.23.146.1-.272.272-.355C192.038,222.525,191.767,222.629,191.683,222.42Z"
          transform="translate(-55.807 -112.292)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.205,231.228c0,.272.23,0,.334,0a.708.708,0,0,1,0-.188c0,.188.146,0,.272,0s0,.125,0,.146.167,0,.23-.209-.188,0-.355-.1v.167a.209.209,0,0,0-.188,0c0-.146.125-.188,0-.314s.146,0,.167-.146l-.272.125c.125.125,0,.209-.1.418s.1,0,.188,0S223.331,231.249,223.205,231.228Z"
          transform="translate(-154.477 -103.393)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M191.223,222.775c.251,0,.376,0,.355.334.209,0,.335-.125.439,0S191.62,222.545,191.223,222.775Z"
          transform="translate(-55.598 -111.956)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.1,230.993c0,.251.23,0,.293-.167s0,.146,0,.146C223.523,230.533,223.251,230.993,223.1,230.993Z"
          transform="translate(-153.644 -103.179)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M182.22,249.011c-.167-.125-.564.355-.418.606S182.011,249.157,182.22,249.011Z"
          transform="translate(-26.024 -83.317)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M179.469,250.3c-.188.293.606-.188.69.209-.1-.439.293-.46.481-.585,0-.5-.355-.209-.627-.335C180.347,250.029,179.782,250.175,179.469,250.3Z"
          transform="translate(-19.573 -82.662)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.859,225.529c-.146,0-.376-.335-.439.146.481,0,.314.773,0,1.024,0,.146.146.188,0,.439C227.859,226.783,227.713,226.156,227.859,225.529Z"
          transform="translate(-167.095 -109.025)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.646,226.854c0-.418.481-.711.376-.9s-.209.564-.523.857c.125,0,0,.146,0,.314.544-.146.564.4,1.192.418,0-.251-.167-.439-.335-.272.335-.46-.146-.481-.272-.962l-.209.648Z"
          transform="translate(-165.005 -108.469)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.354,230.074c0-.711.376-.376.585-.523C226.9,229.028,225.831,229.823,226.354,230.074Z"
          transform="translate(-163.646 -104.706)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.353,226.251s-.648-.188-.188.1Z"
          transform="translate(-156.44 -108.179)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.522,226.634a.69.69,0,0,0-.5-.334c0,.209-.146.272-.23.439a.272.272,0,0,0,.439,0v.314a.167.167,0,0,1,.272,0C224.543,226.99,224.292,226.864,224.522,226.634Z"
          transform="translate(-156.169 -108.061)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M221.457,226.15a2.091,2.091,0,0,0,1.15,1.275v.251c0,.084.209,0,.23-.355.188,0,.167.188.335,0a.293.293,0,0,1,0,.272,5.6,5.6,0,0,1,2.3,1.066c0-.293.188,0,.251-.46,0,0,0,.125.1.146s-.188,0-.23.272c.251,0,.334,0,.543-.355s-.355.293-.167-.23c-1.38-.46-2.09-.627-3.742-1.108-.125,0,0-.209,0-.293s-.209,0-.272,0a.334.334,0,0,1,0-.355C221.75,226.275,221.457,226.359,221.457,226.15Z"
          transform="translate(-152.959 -108.224)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.67,225.84c0,.188.125.125.146.251h-.146c0,.481.544.293.836.5,0-.4.836-.251.794.125s.125-.125.188-.125,0,.293.146.376a1.537,1.537,0,0,1,0-.251c.125.146.314-.23.481-.314s.125.188.23.146,0-.209.23-.1c-.146-.23-.146-.46-.355-.544s0,.125,0,.209a.376.376,0,0,1-.5,0,.355.355,0,0,0-.1.314c0-.23-.439,0-.376-.481h-.084a.523.523,0,0,1,0,.188,10.459,10.459,0,0,1-1.484-.293Z"
          transform="translate(-161.063 -108.562)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.932,224.82c-.125.146-.293,0-.4.209s.376,0,.439,0S225.932,224.883,225.932,224.82Z"
          transform="translate(-161.259 -109.674)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M219.177,225.808c-.355-.355-.627-.314-1.024-.648,0,.167,0,.293.125.334a1.045,1.045,0,0,0-.188.376c.544-.272.794,0,1.066.523C219.093,226.226,218.7,225.62,219.177,225.808Z"
          transform="translate(-138.909 -109.304)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M217.5,226.01c-.251.293.355,0,.4-.46a.314.314,0,0,1-.335,0C217.459,225.759,217.751,226.01,217.5,226.01Z"
          transform="translate(-136.271 -108.878)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M198.436,198.76c-.188.272-.125.627.418.209C198.833,198.718,198.457,199.032,198.436,198.76Z"
          transform="translate(-77.298 -138.093)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M219.82,230.29a8.852,8.852,0,0,0,.481.773s.146-.209.251-.209c.125-.314-.376,0,0-.481C220.2,230.478,220.2,230.311,219.82,230.29Z"
          transform="translate(-143.921 -103.709)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.98,231.355c0,.314.314.523.46-.146C226.231,231.041,226.168,231.522,225.98,231.355Z"
          transform="translate(-162.666 -102.747)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M198,220.17c0,.523.857.794,1.422.815C199.422,220.525,198.4,220.588,198,220.17Z"
          transform="translate(-77.155 -114.745)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M217.57,225.464c-.188.773.585-.335.355.418.125,0,.167-.314.251-.5Z"
          transform="translate(-136.758 -109.064)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M216.76,223.408c-.1.272.23.146.314.251a1.421,1.421,0,0,1,.23-.648C217.2,222.99,217.053,223.366,216.76,223.408Z"
          transform="translate(-134.213 -111.649)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M216.69,223.71c0,.355,0,.753.293.4S216.878,223.752,216.69,223.71Z"
          transform="translate(-133.892 -110.885)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M216,223.411c0,.355.5.92.794.564,0-.314-.251-.125,0-.585C216.606,223.662,216.293,223.536,216,223.411Z"
          transform="translate(-132.157 -111.234)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.8,223.14c-.355.669,1.171,1.087,1.213.125-.1,0-.146.209-.209.376C226.493,223.265,225.992,223.642,225.8,223.14Z"
          transform="translate(-162.761 -111.507)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.338,224.329c0,.314.355.188.481.314C224.86,224.287,224.61,224.245,224.338,224.329Z"
          transform="translate(-157.616 -110.25)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M217.33,225.689c-.125.251,0,.355-.1.544s.439-.857.627-.376c.272,0-.1-.272,0-.439S217.539,225.793,217.33,225.689Z"
          transform="translate(-135.849 -109.059)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M215.82,223.967c0,.585.481.125.711.1a1.025,1.025,0,0,1,0-.418c.209.4.314-.23.585-.251s0,.272,0,.314.376,0,.46-.418c-.439,0-.376,0-.732-.251l-.125.376a.376.376,0,0,0-.418,0c.146-.335.251-.439.125-.69s.335.188.355-.314l-.564.23c.272.293,0,.5-.23.941.23-.188.188,0,.376.146C216.343,224.3,216.05,224.03,215.82,223.967Z"
          transform="translate(-132.562 -112.292)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M217,226.035c.251,0,.314-.209.5,0a.309.309,0,0,1,.272-.335C217.9,225.68,217.15,225.6,217,226.035Z"
          transform="translate(-135.251 -108.736)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M198.121,221.39c-.314.46.439.23.711.146,0,0-.251.23-.125.335C199.417,221.244,198.33,221.6,198.121,221.39Z"
          transform="translate(-76.774 -113.415)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M221.348,230.7c-.376-.376-.648-.335-1.045-.669,0,.188,0,.314.146.355a.94.94,0,0,0-.209.355c.564-.272.815,0,1.087.523C221.264,231.117,220.867,230.49,221.348,230.7Z"
          transform="translate(-145.574 -103.993)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M219.651,230.869c-.251.293.355,0,.4-.439a.314.314,0,0,1-.355,0C219.588,230.639,219.881,230.869,219.651,230.869Z"
          transform="translate(-142.915 -103.557)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.145,209.63c-.1.355,0,.46-.125.9h.314c.063,0-.23-.272.251-.418C233.313,210.006,233.417,209.9,233.145,209.63Z"
          transform="translate(-184.528 -126.239)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.157,210.241c0-.5-.272-.648-.355-1.171-.125.125-.188.23-.125.355a.858.858,0,0,0-.376.146c.585.167.523.585.46,1.087C233.8,210.512,233.927,209.781,234.157,210.241Z"
          transform="translate(-185.686 -126.85)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.982,210.334c-.376,0,.293.209.606,0a.293.293,0,0,1-.272-.334C233.107,210.125,233.17,210.5,232.982,210.334Z"
          transform="translate(-184.217 -125.836)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M217.38,227.4v.418c.314-.564.9.314,1.129-.648-.146,0-.209.146-.272.355S217.589,227.379,217.38,227.4Z"
          transform="translate(-136.756 -107.112)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M220.833,230.345c.125,0,.209-.146.314,0s0-.418-.272-.355.1-.146.146-.293-.209,0-.314-.125a.167.167,0,0,1,.188-.1c0-.251-.125,0-.125-.4-.1,0-.1.355,0,.418s-.188.188-.293.167,0,.1,0,.272C220.666,230.032,220.812,229.969,220.833,230.345Z"
          transform="translate(-145.812 -105.04)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.052,224.311h0l-.125.167c-.125.167.209-.146.314-.167S224.052,224.436,224.052,224.311Z"
          transform="translate(-156.139 -110.232)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M222.771,225.06c0,.125,0,.293.146.314,0-.355.167.167.314,0s0,.293,0,.251.711.146,1,0c0,.418.293,0,.23.355,0,0,.1-.188.167-.209a.724.724,0,0,1,.481.1c.1.1.125-.5.188-.209.146-.23-.146-.209-.251,0a.418.418,0,0,0-.418,0s0-.146,0-.272-.272,0-.376-.125c-.146.669-.481-.167-.815.251C223.231,225.248,222.938,225.29,222.771,225.06Z"
          transform="translate(-154.857 -109.413)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.324,228.315c0,.125,0,.167.167.188s-.1.146-.125.293.23.251.335.4c0-.4.773,0,.983-.481a.125.125,0,0,0,.125.1.418.418,0,0,1,.4-.188c.314-.376.732-.355.962-.69-.146,0-.293-.146-.23-.125-.544-.4-1.84,0-2.091-.857.125,0,0,.376.376.334-.125-.23,0-.5-.251-.753s.209.146.23-.125-.125,0-.23,0c0,.46-.46.251-.544.146s0,.1,0,.272.272,0,.314.1c-.272.355-.439,0-.732,0,.23.146.293.815.523,1.108s.334,0,.251.376c0,0,.146-.188.23-.125s-.1.314.167.314a.4.4,0,0,1,0,.272c-.188.23-.418-.23-.46.1v-.146c0-.021.146,0,.167-.146s-.167,0-.209.125S223.553,228.357,223.324,228.315Z"
          transform="translate(-156.184 -108.069)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.644,225.071c0,.146,0,0,.167,0s0-.146,0-.314S223.791,225.113,223.644,225.071Z"
          transform="translate(-155.229 -109.779)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M222.86,225.238a.9.9,0,0,0,.481,0S222.944,225.029,222.86,225.238Z"
          transform="translate(-153.044 -109.319)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.826,226.634c.146-.523-.418-.1-.627,0C223.429,226.634,223.638,226.739,223.826,226.634Z"
          transform="translate(-154.261 -107.977)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M191.862,197.347c.1.314.627,0,.815,0S192.3,196.971,191.862,197.347Z"
          transform="translate(-57.617 -139.816)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M198.328,191.992c-.272.23.335,0,.314.293a.522.522,0,0,0,.167-.564C198.621,191.908,198.558,192.159,198.328,191.992Z"
          transform="translate(-77.107 -145.77)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.284,229.11c0-.167-.125,0,0-.439-.146.251-.376,0-.5.46-.188-.23-.272-.544,0-1.171-.293-.69-1.066-.418-1.505,0-.46.92-.5,1.693-.188,1.965s1.422-.523,1.693,0a3.1,3.1,0,0,0,.92-.1c.293-.1,0-.188.167-.481s-.481,0-.355-.481A.355.355,0,0,0,223.284,229.11Z"
          transform="translate(-149.476 -106.71)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.05,215.454a.481.481,0,0,0,.544.209C227.426,215.141,227.238,215.37,227.05,215.454Z"
          transform="translate(-166.056 -120.022)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.77,213.989s.167,0,.125.125c.293.125.523-.1.669-.335A6.408,6.408,0,0,0,223.77,213.989Z"
          transform="translate(-156.17 -121.714)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.08,213.414c.188.167.376.146.564.293C224.373,213.288,224.289,213.351,224.08,213.414Z"
          transform="translate(-156.898 -122.163)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.972,213.6v.146c0,.042.314,0,.481.23s0-.188-.125-.125a4.592,4.592,0,0,0-.188-.481,1.024,1.024,0,0,1-.5,0,.627.627,0,0,0,.125.418C223.93,213.934,223.763,213.454,223.972,213.6Z"
          transform="translate(-155.85 -122.161)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M239.6,234.3c.314,0,.167-.167.272-.23s.439.209,0-.188C239.688,234.005,239.793,234.131,239.6,234.3Z"
          transform="translate(-204.805 -99.794)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M239.41,233.658a5.469,5.469,0,0,0,.355-.418A.523.523,0,0,0,239.41,233.658Z"
          transform="translate(-204.067 -100.492)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M239.876,233.056c-.125-.146,0,0,0-.209C239.667,232.679,239.542,232.972,239.876,233.056Z"
          transform="translate(-204.721 -100.977)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.18,232.62c.167,0,0-.125.188-.251C240.1,232.223,240.117,232.557,240.18,232.62Z"
          transform="translate(-206.195 -101.482)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.946,213.742v.167c.167,0,.188-.1.293-.146S226.155,213.658,225.946,213.742Z"
          transform="translate(-162.422 -121.801)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.91,214.484h.188a.126.126,0,0,1,0-.146C224.91,214.275,224.91,214.442,224.91,214.484Z"
          transform="translate(-159.087 -121.121)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.753,214.549H225c.146,0-.188-.125-.272,0h.021Z"
          transform="translate(-158.659 -120.936)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.82,212.164c.146.314.209-.1.4,0s0,.23-.167.23c.272.418.23-.376.481.125a.523.523,0,0,1,.23-.188c0-.209-.167,0-.251,0s-.23-.146-.209-.481A.648.648,0,0,1,224.82,212.164Z"
          transform="translate(-159.562 -123.818)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.049,232.941c.188,0,.167,0,.355.146s.1,0,.125-.1S240.049,232.752,240.049,232.941Z"
          transform="translate(-206.17 -100.925)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.809,216.719c.272.188.606.146.23-.439C227.809,216.3,228.1,216.7,227.809,216.719Z"
          transform="translate(-168.258 -118.987)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.68,217.7c.188.46.773,0,.753-.209s.418.376.606.627a5.476,5.476,0,0,0-.23-1.3c-.251-.146-.857.334-.5.564C226.056,217.472,225.805,217.451,225.68,217.7Z"
          transform="translate(-162.638 -118.424)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.787,231.847c.188,0,.125-.209,0-.272S240.85,231.784,240.787,231.847Z"
          transform="translate(-207.995 -102.318)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.18,231.156a.626.626,0,0,0,.376-.209C241.222,230.738,241.2,230.926,241.18,231.156Z"
          transform="translate(-209.558 -103.091)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.81,229.7c.418.1.794.188.941,0a2.635,2.635,0,0,0-.941-.272.314.314,0,0,1,0,.272Z"
          transform="translate(-199.707 -104.647)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.67,212.283c.439.669.167-.648.543,0,0,0-.1-.355-.188-.544a4.709,4.709,0,0,1-.355.544Z"
          transform="translate(-183.425 -123.938)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.381,212.154c0,.272.209.293.335.46.125-.146-.335-.92.146-.711.167-.23-.251-.125-.334-.293C232.507,211.8,232.59,212.07,232.381,212.154Z"
          transform="translate(-182.509 -124.08)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.171,212.4c.1-.251,0-.376.355-.334q-.157-.314,0-.439A.627.627,0,0,0,232.171,212.4Z"
          transform="translate(-181.63 -124.058)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.46,211.338a.376.376,0,0,0,.418.125c-.125-.23,0-.314-.188-.544l-.209.1C227.69,211.192,227.69,211.275,227.46,211.338Z"
          transform="translate(-167.198 -124.833)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.15,215.927c.523,0,.815-.815.878-1.38C224.568,214.4,224.568,215.509,224.15,215.927Z"
          transform="translate(-157.428 -120.892)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.423,237.216c.773.209-.293-.606.439-.335,0-.125-.314-.188-.5-.272A2.365,2.365,0,0,1,236.423,237.216Z"
          transform="translate(-194.787 -96.817)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.8,216.147c.146.293.293,0,.418,0a1.379,1.379,0,0,1-.314-.627A1.285,1.285,0,0,0,223.8,216.147Z"
          transform="translate(-155.887 -119.816)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.984,236c.355,0,.753,0,.4-.272A.355.355,0,0,0,237.984,236Z"
          transform="translate(-199.839 -97.779)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.2,235.733c.376,0,.962-.439.606-.753-.314,0-.146.251-.585,0C238.49,235.147,238.344,235.44,238.2,235.733Z"
          transform="translate(-200.68 -98.595)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.93,234.952c.648.376,1.129-1.108.167-1.192,0,.1.209.146.355.23C238.076,234.262,238.495,234.784,237.93,234.952Z"
          transform="translate(-199.848 -99.925)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.879,232.892c.314,0,.209-.334.334-.46C236.879,232.391,236.774,232.621,236.879,232.892Z"
          transform="translate(-196.142 -101.378)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.48,230.421c.293,0,.523,0,.564-.293C235.71,230.045,235.647,230.3,235.48,230.421Z"
          transform="translate(-192.13 -103.903)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.655,228.723c.523,0,.188-.648.376-.941-.335-.146,0,.46-.5.146C234.927,228.263,234.655,228.263,234.655,228.723Z"
          transform="translate(-189.132 -106.469)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.443,236.926a1.733,1.733,0,0,0,.564.125c0-.209-.857-.481-.355-.648,0-.272-.272,0-.439,0S236.569,236.717,236.443,236.926Z"
          transform="translate(-194.557 -97.175)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.129,236.537c.585,0,.146-.481.125-.711a1.064,1.064,0,0,1-.418,0c.418-.188-.209-.314-.209-.585s.272,0,.314,0,0-.355-.418-.481c0,.46,0,.4-.293.732l.4.146a.355.355,0,0,0,0,.4c-.314-.146-.418-.272-.669-.167s.209-.293-.314-.376a4.862,4.862,0,0,0,.23.585c.293-.251.481,0,.941.293-.188-.23,0-.188.146-.376C240.485,236.035,240.192,236.286,240.129,236.537Z"
          transform="translate(-203.135 -98.835)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.465,236.879c0-.251-.188-.314,0-.481a.34.34,0,0,1-.335-.293C236.109,236,236.026,236.712,236.465,236.879Z"
          transform="translate(-193.867 -97.379)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M239.088,235.249c.564,0-.1-.481-.335-.627.1,0,.335,0,.335-.1C238.147,234.329,239.109,234.977,239.088,235.249Z"
          transform="translate(-202.072 -99.136)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.869,210.241c.272,0,.418-.439.439-.711S228.037,210.032,227.869,210.241Z"
          transform="translate(-168.485 -126.411)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.16,211.442c.209.355,0-.334.272,0v-.272Z"
          transform="translate(-166.124 -124.56)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.7,210.324c0,.146.146,0,.209,0a.92.92,0,0,1-.167-.314A.631.631,0,0,1,227.7,210.324Z"
          transform="translate(-167.731 -125.825)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.464,210.345c.125.146.314.251.251,0a.188.188,0,0,0-.251,0Z"
          transform="translate(-167.056 -125.512)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.29,210.109c.167,0,.5.167.481,0s-.125,0-.188-.209C227.582,210.109,227.436,210.109,227.29,210.109Z"
          transform="translate(-166.735 -125.945)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.807,209.927c0,.376.815,0,.46-.4v.209C227.267,209.823,226.953,210.053,226.807,209.927Z"
          transform="translate(-165.312 -126.348)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.042,211.379c0,.146.125.167.167.251s-.167-.481,0-.376-.125,0-.167-.146S227.168,211.358,227.042,211.379Z"
          transform="translate(-165.693 -124.664)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.889,210.5c.188.251.23-.125.293-.23a.4.4,0,0,1-.146-.167c.23,0,0-.188.125-.314s0,.125.125.1.146-.146,0-.335c-.125.209-.125.167-.355.188v.188a.209.209,0,0,0-.146.146c0-.167,0-.251-.188-.293s.167,0,0-.251l-.125.293c.209,0,.188.188.272.439,0-.146,0,0,.188,0S227.994,210.5,227.889,210.5Z"
          transform="translate(-167.732 -126.337)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.87,211.5c0-.125,0-.188.188-.167s0-.167,0-.23S226.87,211.293,226.87,211.5Z"
          transform="translate(-165.228 -124.641)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.4,209.989c.23.188.125-.209,0-.355s.125.125.167,0C227.363,209.3,227.509,209.884,227.4,209.989Z"
          transform="translate(-166.704 -126.347)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.58,210.522c.167.272.376-.125.544-.125C231.894,210.124,231.705,210.271,231.58,210.522Z"
          transform="translate(-180.056 -125.563)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.02,209.43c.188.23.4.355.606.167C229.438,209.3,229.229,209.451,229.02,209.43Z"
          transform="translate(-172.207 -126.478)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M198.389,204.431c.188.46.627-.125.355-.481C198.451,204.055,198.619,204.347,198.389,204.431Z"
          transform="translate(-77.376 -132.433)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.429,231.083a.5.5,0,0,0,.523-.543C231.931,230.351,231.617,230.936,231.429,231.083Z"
          transform="translate(-179.571 -103.478)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.16,230.7c.46.1.23-.648.648-.836C231.369,229.839,231.181,230.55,231.16,230.7Z"
          transform="translate(-178.863 -104.178)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.374,229.532c.481,0,.523-.293.69-.585-.314-.167-.418.418-.627,0C231.081,228.946,231.5,229.364,231.374,229.532Z"
          transform="translate(-179.39 -105.209)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.7,228.745c-.209-.251-.272,0-.544-.125,0,.167-.167.376.251.523,0-.1-.1-.188,0-.272S231.676,228.913,231.7,228.745Z"
          transform="translate(-178.668 -105.531)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.157,229.133c.46,0-.251-.606.293-.732C230.781,228.255,230.844,228.882,231.157,229.133Z"
          transform="translate(-178.024 -105.793)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.819,231.25c.4-.334,0-1.15-.272-1.61C236.109,229.849,236.861,230.685,236.819,231.25Z"
          transform="translate(-195.017 -104.418)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M218.853,221.006c.774.209-.293-.606.439-.334,0-.125-.293-.188-.5-.272A2.364,2.364,0,0,1,218.853,221.006Z"
          transform="translate(-140.487 -114.495)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.4,234.71c.167.272.355.4.585.251C230.839,234.647,230.609,234.752,230.4,234.71Z"
          transform="translate(-176.451 -98.889)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.97,234.467c.272.439.627-.293.983-.334-.1-.355-.376.272-.46-.293C228.493,234.383,228.263,234.174,227.97,234.467Z"
          transform="translate(-169.339 -99.838)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.71,238.426a.376.376,0,0,0,.4.167v-.544h-.23C230.961,238.322,230.94,238.384,230.71,238.426Z"
          transform="translate(-177.221 -95.247)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.21,234.19c.167.272.523,0,.523-.167S230.461,234.232,230.21,234.19Z"
          transform="translate(-175.801 -99.686)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.7,231.429a.544.544,0,0,0,.627,0C231.223,230.99,230.93,231.45,230.7,231.429Z"
          transform="translate(-177.42 -102.675)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.082,231.982c-.251.376.69.334.376-.125C230.208,231.752,230.291,232.337,230.082,231.982Z"
          transform="translate(-175.234 -102.015)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.7,231.918c0,.355.815.251.585-.188C230.139,231.981,230.014,231.751,229.7,231.918Z"
          transform="translate(-174.33 -102.139)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.95,236.626c.125.5.418,0,.627,0C226.473,236.333,226.18,236.563,225.95,236.626Z"
          transform="translate(-162.74 -96.958)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.1,231.67c.188.272.606.167.773,0C229.643,231.523,229.351,231.711,229.1,231.67Z"
          transform="translate(-172.622 -102.267)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.435,233.014c.23.209.544-.125.711.335C228.251,232.993,227.477,232.68,227.435,233.014Z"
          transform="translate(-167.424 -100.894)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.88,237.252c.272,0,.481-.355.564-.648C231.193,236.521,231.193,237.085,230.88,237.252Z"
          transform="translate(-177.914 -96.833)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.42,238.592c.188.376.146-.314.293,0v-.272Z"
          transform="translate(-176.22 -94.953)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.81,237.444c0,.146.146,0,.209,0a1.087,1.087,0,0,1-.125-.335A.942.942,0,0,1,230.81,237.444Z"
          transform="translate(-177.342 -96.272)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.6,237.472c.1.167.272.293.251,0A.209.209,0,0,0,230.6,237.472Z"
          transform="translate(-176.734 -95.923)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.369,237.28c.167.125.5.23.523,0s-.146,0-.188-.23C230.7,237.2,230.536,237.238,230.369,237.28Z"
          transform="translate(-176.295 -96.337)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.84,237.094c0,.376.815,0,.523-.334v.209A.36.36,0,0,1,229.84,237.094Z"
          transform="translate(-174.72 -96.654)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.239,238.556c0,.146,0,.167.125.251s0-.481.146-.335-.125,0-.167-.188S230.344,238.535,230.239,238.556Z"
          transform="translate(-175.684 -95.042)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.965,237.727c.167.251.251,0,.334-.188a.731.731,0,0,1-.125-.188c.23.1,0-.188.167-.293s0,.125.125,0,.146,0,0-.314c-.167.188-.146.146-.4.125v.209c-.1,0-.146,0-.167.125s0-.251-.167-.314.188,0,0-.251a.269.269,0,0,1-.167.293c-.167.084.167.209.23.46,0-.146,0,0,.188,0S231.09,237.664,230.965,237.727Z"
          transform="translate(-177.288 -96.785)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.156,238.676c0-.125,0-.188.188-.146a.209.209,0,0,1,0-.23S230.072,238.446,230.156,238.676Z"
          transform="translate(-175.266 -94.974)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.36,237.149c.209.188.146-.209.125-.355s.1.146.167,0C230.4,236.459,230.464,237.044,230.36,237.149Z"
          transform="translate(-176.035 -96.729)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M232.04,236.36c.167.251.355.4.585.23C232.479,236.3,232.249,236.4,232.04,236.36Z"
          transform="translate(-181.52 -97.09)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.61,236.117c.272.439.606-.293.962-.335,0-.355-.376.251-.439-.293C230.112,236.034,229.9,235.845,229.61,236.117Z"
          transform="translate(-174.386 -98.039)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.578,235.977c.146.544.418,0,.376-.125S227.829,236.186,227.578,235.977Z"
          transform="translate(-167.525 -97.669)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.829,233.271c.167.167.523.23.564,0S227.038,233.292,226.829,233.271Z"
          transform="translate(-165.396 -100.565)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M228.623,231.8a.878.878,0,0,0,.523.544C230.128,231.591,229.1,232.448,228.623,231.8Z"
          transform="translate(-171.309 -102.063)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.87,233.717c0,.1,0,.167.146.4.188-.209.418-.418.606-.648C228.351,233.069,228.246,234.156,227.87,233.717Z"
          transform="translate(-168.799 -100.342)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.41,237.656c0,.334.481-.4.773-.418C226.828,236.862,226.64,237.552,226.41,237.656Z"
          transform="translate(-164.308 -96.254)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.58,233.122c.188.209.314-.376.627-.355,0-.376-.376.23-.46-.146C227.685,232.787,227.726,233.038,227.58,233.122Z"
          transform="translate(-167.778 -101.168)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.13,234.245c.523,0,.92-.69,1.066-1.233C225.757,232.823,225.611,233.931,225.13,234.245Z"
          transform="translate(-160.645 -100.765)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.24,236.789c.334.732.251-.606.543.1a1.262,1.262,0,0,0-.125-.544A4.481,4.481,0,0,1,224.24,236.789Z"
          transform="translate(-157.372 -97.101)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.98,234.618c0,.272.272,0,.418,0a1.547,1.547,0,0,1-.251-.648C225.022,234.033,225.105,234.472,224.98,234.618Z"
          transform="translate(-159.533 -99.696)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.576,234.661c.209.293.544.523.481.125a.376.376,0,0,0-.481-.125Z"
          transform="translate(-158.356 -98.987)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.14,234.32c.314.209.962.418.983,0s-.293,0-.334-.46C224.767,234.153,224.453,234.153,224.14,234.32Z"
          transform="translate(-157.501 -99.816)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.108,233.948c.125.732,1.589,0,1.024-.648v.4C223.8,233.551,223.568,234.22,223.108,233.948Z"
          transform="translate(-154.484 -100.427)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.908,236.723c0,.293.188.335.272.5s-.209-.962.251-.69c.188-.209-.251-.146-.293-.335C224.075,236.388,224.138,236.681,223.908,236.723Z"
          transform="translate(-156.371 -97.264)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.912,235.051c.293.5.481-.209.627-.376a.94.94,0,0,1-.23-.355c.418.209,0-.355.314-.544s.146.23.251.209.293-.23.1-.627c-.314.355-.272.293-.753.251l.146.4a.4.4,0,0,0-.334.251c0-.335,0-.481-.293-.606s.355,0,0-.481c-.1.188-.188.376-.293.544.4,0,.293.4.4.878,0-.293.188,0,.376-.125C225.581,234.905,225.183,234.905,224.912,235.051Z"
          transform="translate(-159.089 -100.569)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.12,233.965c.4.4.293-.4.251-.669.1,0,.188.272.314.188C224.2,232.752,224.35,233.8,224.12,233.965Z"
          transform="translate(-157.022 -100.506)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.51,234.268c.1.293.293,0,.418,0a1.422,1.422,0,0,1-.23-.648C223.552,233.766,223.656,234.122,223.51,234.268Z"
          transform="translate(-154.99 -100.078)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.106,234.307c.188.314.523.544.46.146A.356.356,0,0,0,223.106,234.307Z"
          transform="translate(-153.792 -99.365)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M222.66,233.98c.314.209.983.418,1,0s-.293,0-.355-.46C223.308,233.792,222.952,233.875,222.66,233.98Z"
          transform="translate(-152.948 -100.187)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.558,240.028c.125.732,1.589.125,1.024-.648v.418C231.269,239.631,231.018,240.3,230.558,240.028Z"
          transform="translate(-177.508 -93.797)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.463,234.691c.293.523.46-.188.606-.355a1.319,1.319,0,0,1-.209-.355c.418.188.1-.376.314-.564s.125.251.23.209.293-.23.1-.606c-.293.335-.272.272-.732.251l.146.4a.334.334,0,0,0-.334.251c0-.335,0-.481-.314-.627s.376,0,0-.46l-.314.544c.4,0,.314.4.418.878,0-.293.188,0,.376-.125C224.07,234.628,223.693,234.607,223.463,234.691Z"
          transform="translate(-154.505 -100.939)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.464,235.741c.314,0,.564-.251,0-.481C236.171,235.448,236.673,235.553,236.464,235.741Z"
          transform="translate(-194.744 -98.289)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.135,235.77c.439.251.606-.5.46-.648.167-.125.564,0,.878.125a5.146,5.146,0,0,0-.983-.857c-.272,0-.46.774,0,.753C234.3,235.352,234.094,235.5,234.135,235.77Z"
          transform="translate(-188.737 -99.238)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.135,233.483c.753-.314-.606-.293.146-.544h-.564a3.593,3.593,0,0,1,.418.543Z"
          transform="translate(-165.138 -100.819)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.916,230.814c.272.125.146-.23.272-.314a1.442,1.442,0,0,1-.648-.251C235.561,230.459,235.9,230.605,235.916,230.814Z"
          transform="translate(-192.399 -103.753)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.893,230.624c.355,0,.753,0,.4-.272A.355.355,0,0,0,234.893,230.624Z"
          transform="translate(-190.289 -103.646)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.107,230.4c.376-.1.962-.439.606-.773-.314,0-.146.251-.564,0C235.4,229.755,235.254,230.048,235.107,230.4Z"
          transform="translate(-191.13 -104.429)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M234.84,229.622c.648.4,1.129-1.087.167-1.192,0,.1.23.167.376.23C234.986,228.744,235.342,229.371,234.84,229.622Z"
          transform="translate(-190.299 -105.738)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.829,227.514c.293,0,.188-.335.334-.439C233.808,226.949,233.662,227.263,233.829,227.514Z"
          transform="translate(-186.654 -107.247)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.374,231.535a1.524,1.524,0,0,0,.544.125c0-.188-.857-.481-.335-.627,0-.272-.293,0-.46,0S233.479,231.326,233.374,231.535Z"
          transform="translate(-185.007 -103.031)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.057,231.168c.585.1.167-.481.125-.711a1.171,1.171,0,0,1-.418,0c.439-.167-.209-.314-.209-.585s.272,0,.314,0,0-.376-.4-.5c0,.46,0,.4-.293.732l.376.146a.334.334,0,0,0,0,.418c-.335-.167-.418-.272-.69-.188s.209-.293-.314-.355c0,.188.167.376.23.585.293-.251.481,0,.941.272-.188-.23,0-.188.146-.355C237.413,230.687,237.141,230.938,237.057,231.168Z"
          transform="translate(-193.603 -104.713)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M233.365,231.522c0-.251-.188-.334,0-.481a.358.358,0,0,1-.334-.314A.691.691,0,0,0,233.365,231.522Z"
          transform="translate(-184.266 -103.248)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.008,229.869c.543,0-.125-.481-.355-.648h.355C235.046,228.865,236.008,229.576,236.008,229.869Z"
          transform="translate(-192.512 -104.981)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.187,234.133c.188-.167,0-.544-.125-.753C235.873,233.484,236.291,233.861,236.187,234.133Z"
          transform="translate(-193.464 -100.34)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M231.806,233.694c.272.125.167-.23.272-.314a1.441,1.441,0,0,1-.648-.251C231.451,233.339,231.785,233.485,231.806,233.694Z"
          transform="translate(-179.697 -100.612)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.744,233.5c.355,0,.753,0,.4-.272A.355.355,0,0,0,230.744,233.5Z"
          transform="translate(-177.464 -100.505)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.992,233.233c.376,0,.962-.439.627-.753-.334,0-.146.251-.585,0C231.285,232.647,231.139,232.94,230.992,233.233Z"
          transform="translate(-178.423 -101.321)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M230.66,234.931c.648.4,1.129-1.087.167-1.171a2.586,2.586,0,0,0,.355.209C230.89,234.262,231.245,234.784,230.66,234.931Z"
          transform="translate(-177.38 -99.925)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M229.674,230.393c.293,0,.188-.335.335-.46C229.653,229.891,229.611,230.079,229.674,230.393Z"
          transform="translate(-173.907 -104.105)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M228.28,227.921c.314,0,.544,0,.564-.293C228.51,227.545,228.447,227.8,228.28,227.921Z"
          transform="translate(-169.878 -106.63)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.385,234.078c.272,0,0-.209,0-.314a.376.376,0,0,1-.188,0c.188,0-.1-.125-.1-.272s.125,0,.146,0,0-.188-.188-.23,0,.167-.125.335h.167a.167.167,0,0,0,0,.188c-.146,0-.188-.125-.314,0s0-.146-.146-.167a1.446,1.446,0,0,1,.1.272c.146-.125.23,0,.439.125s0,0,0-.167S236.427,233.974,236.385,234.078Z"
          transform="translate(-192.909 -100.473)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.843,233.482c.251,0,0-.23-.167-.293s.167,0,.167,0C235.4,233.044,235.843,233.357,235.843,233.482Z"
          transform="translate(-192.347 -100.589)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.64,215c0,.1-.5,0-.23.418.355-.355.794.314.753.732.1,0,.209,0,.335.293C227.538,215.983,226.974,215.627,226.64,215Z"
          transform="translate(-164.454 -120.383)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.653,215.891c-.251-.355-.188-.878-.376-.92s.251.544.23.983c.1-.1.188,0,.272.188.293-.5.69-.125,1.129-.564-.188-.188-.418-.209-.418,0,0-.564-.439-.23-.878-.5l.314.627Z"
          transform="translate(-161.566 -120.417)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M237.685,236.767c-.69-.146-.355-.418-.5-.627C236.661,236.161,237.392,237.269,237.685,236.767Z"
          transform="translate(-196.948 -97.33)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.135,213.14c-.1,0-.585.314,0,.209a.251.251,0,0,1,0-.209Z"
          transform="translate(-155.908 -122.412)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.346,233.58a.711.711,0,0,0-.376.46c.23,0,.293.167.439.272a.272.272,0,0,0,0-.439h.334a.146.146,0,0,1,0-.251C240.681,233.559,240.555,233.81,240.346,233.58Z"
          transform="translate(-206.216 -100.122)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M239.19,235.809a2.09,2.09,0,0,0,1.338-1.045h.251c.084,0,0-.188-.355-.23,0-.188.209-.146,0-.335a.251.251,0,0,1,.272,0,5.394,5.394,0,0,1,1.171-2.216c-.293,0,0-.209-.439-.272h.146c.021,0,0,.188.251.251,0-.251,0-.334-.314-.564s.272.376-.23.146c-.523,1.338-.732,2.091-1.3,3.637,0,.146-.209,0-.293,0s0,.209,0,.272a.355.355,0,0,1-.376,0C239.336,235.558,239.4,235.83,239.19,235.809Z"
          transform="translate(-205.707 -102.559)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.347,215.26c0,.167.188,0,.272,0a.231.231,0,0,1,0,.167c.4.272.585-.188.941-.251-.272-.272.4-.773.648-.46.125,0,0-.188,0-.23s.251.167.376.167a.6.6,0,0,1-.146-.209c.188,0,0-.376.1-.564.125.146.209,0,.272,0s0-.209,0-.251c-.251,0-.418-.209-.627-.1s0,0,.146.125a.418.418,0,0,1-.314.4.355.355,0,0,0,.146.272c-.209-.125-.355.272-.606,0a.24.24,0,0,1-.167.23c-.167.042.1,0,.188-.1l.167.146A15.431,15.431,0,0,1,224.347,215.26Z"
          transform="translate(-159.653 -122.023)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.97,213.25c0,.188-.188.209-.146.418s.23-.314.335-.293S226.012,213.292,225.97,213.25Z"
          transform="translate(-162.071 -122.292)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.76,230.6a5.056,5.056,0,0,0,.774-.439s-.188-.146-.188-.251c-.314-.146,0,.355-.481,0C236.032,230.27,235.8,230.249,235.76,230.6Z"
          transform="translate(-193.205 -104.149)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M235.452,236.48c.314,0,.543-.272-.125-.46C235.138,236.208,235.64,236.292,235.452,236.48Z"
          transform="translate(-191.433 -97.461)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.136,213.013c.23.711,1.568,0,.92-.773a.705.705,0,0,0,.125.4C227.763,212.533,227.617,213.223,227.136,213.013Z"
          transform="translate(-166.874 -123.393)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.35,212.311c.167.272.376-.1.544-.1C225.664,211.935,225.475,212.081,225.35,212.311Z"
          transform="translate(-160.802 -123.59)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.511,230.29c-.376.355-.355.627-.711.982h.355a.94.94,0,0,0,.355.209c-.251-.564.125-.774.585-1.024C236.929,230.415,236.281,230.75,236.511,230.29Z"
          transform="translate(-193.851 -103.709)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M236.147,230.078c.272.251,0-.355-.439-.418a.272.272,0,0,1,0,.335C235.9,230.1,236.168,229.827,236.147,230.078Z"
          transform="translate(-192.818 -104.396)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M238.03,230.81c0-.125-.125-.209,0-.334s-.418,0-.376.272-.146-.125-.293-.167,0,.209-.125.293-.146,0,0-.188-.1.125-.4,0,.334.125.4.1.188.188.146.314.125,0,.272,0C237.7,230.956,237.549,230.81,238.03,230.81Z"
          transform="translate(-196.812 -103.56)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M225.177,211.836h0v-.146C225.177,211.585,225.239,211.982,225.177,211.836Z"
          transform="translate(-159.751 -124.013)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.631,213.147c.1,0,.209.209.314.125-.272-.23.23,0,.251-.209s.23.23.167.23.585-.4.648-.773c.272.335.167-.23.418,0v-.251a.59.59,0,0,1,.418-.272c.167,0-.272-.439,0-.293,0-.251-.251,0-.125.209s-.23.146-.314.293,0,0-.167-.188-.125.251-.355.167c.376.585-.439.23-.376.773C224.154,212.9,223.966,213.126,223.631,213.147Z"
          transform="translate(-157.202 -124.091)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.952,235.662c.125,0,.167,0,.209-.146s.125,0,.272.146.251-.23.418-.314c-.4,0,0-.753-.439-1a.126.126,0,0,0,.125-.1.418.418,0,0,1-.188-.418c-.355-.314-.314-.732-.648-.983,0,.146-.167.272-.146.209-.4.523,0,1.84-.941,1.986,0-.146.376,0,.355-.355-.251.1-.523,0-.773.209s.146-.188-.125-.251,0,.146,0,.251c.46,0,.209.439.125.544s0,0,.272,0,0-.272,0-.293c.355.293,0,.439,0,.711.146-.23.836-.23,1.15-.439,0-.146,0-.334.376-.23,0-.1-.188-.146-.125-.251s.335.146.335-.125a.313.313,0,0,1,.272,0c.23.188-.251.4.1.46h-.167c-.021,0,0-.125-.146-.167s-.1.146.125.209C241.308,235.537,241.015,235.453,240.952,235.662Z"
          transform="translate(-205.191 -100.929)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M224.581,212.142c0,.125,0-.125.167,0a.376.376,0,0,0-.188-.272C224.581,211.974,224.706,212.142,224.581,212.142Z"
          transform="translate(-158.005 -123.797)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M223.82,211.924a1.044,1.044,0,0,0,.376-.314S223.82,211.715,223.82,211.924Z"
          transform="translate(-155.907 -124.08)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.352,233.016c-.544-.167-.146.4,0,.606C240.331,233.413,240.435,233.2,240.352,233.016Z"
          transform="translate(-206.075 -100.77)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M239.392,231.368c-.167,0-.125.125-.46,0,.251.167,0,.376.439.523-.23.167-.564.23-1.171,0-.711.251-.481,1.024,0,1.484.92.523,1.693.606,1.965.293s-.46-1.422.146-1.651v-.941c0-.293-.188,0-.481-.188s0,.481-.481.314a.4.4,0,0,0,.042.167Z"
          transform="translate(-201.144 -103.094)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M203.49,222.415c0,.314.314.523.46-.146C203.741,222.1,203.678,222.582,203.49,222.415Z"
          transform="translate(-93.16 -112.496)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M213.975,231.038c.314,0,.167-.167.272-.251s.439.23,0-.167C214.058,230.725,214.163,230.871,213.975,231.038Z"
          transform="translate(-125.595 -103.349)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M213.83,230.388a3.073,3.073,0,0,0,.355-.418A.607.607,0,0,0,213.83,230.388Z"
          transform="translate(-125.011 -104.058)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M214.276,229.805c-.125-.146,0-.125,0-.23C213.962,229.408,213.858,229.722,214.276,229.805Z"
          transform="translate(-125.457 -104.542)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M214.55,229.348c.167,0,0-.125.188-.23C214.466,228.93,214.487,229.286,214.55,229.348Z"
          transform="translate(-126.986 -105.046)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M214.413,229.688c.188,0,.167,0,.355.146s0,0,.125-.125S214.392,229.5,214.413,229.688Z"
          transform="translate(-126.953 -104.487)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M215.157,228.6c.188,0,.125-.23,0-.293S215.22,228.514,215.157,228.6Z"
          transform="translate(-128.785 -105.884)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M212.2,226.453c.418,0,.794.167.941,0a2.718,2.718,0,0,0-.962-.293A.313.313,0,0,1,212.2,226.453Z"
          transform="translate(-120.518 -108.213)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M210.793,233.946c.773.23-.293-.585.439-.334,0-.1-.314-.188-.5-.272a2.443,2.443,0,0,1,.063.606Z"
          transform="translate(-115.577 -100.383)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M212.354,232.725c.376,0,.773,0,.418-.251A.356.356,0,0,0,212.354,232.725Z"
          transform="translate(-120.65 -101.336)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M212.567,232.484c.376,0,.962-.439.606-.773-.314,0-.146.251-.585,0C212.86,231.9,212.714,232.191,212.567,232.484Z"
          transform="translate(-121.47 -102.161)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M212.3,231.671c.648.4,1.129-1.087.167-1.171a2.587,2.587,0,0,0,.355.209C212.446,230.9,212.8,231.545,212.3,231.671Z"
          transform="translate(-120.638 -103.48)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M211.254,229.632c.314,0,.209-.334.335-.46C211.233,229.131,211.191,229.361,211.254,229.632Z"
          transform="translate(-116.979 -104.933)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M209.85,227.155c.293,0,.523,0,.564-.272C210.1,226.779,210.1,227.009,209.85,227.155Z"
          transform="translate(-112.92 -107.453)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M209.025,225.506c.523,0,.188-.648.376-.941-.335-.167,0,.439-.5.125C209.193,224.984,209.025,225.025,209.025,225.506Z"
          transform="translate(-109.922 -109.984)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M210.813,233.645a1.317,1.317,0,0,0,.564.125c0-.188-.857-.481-.355-.627,0-.272-.272,0-.439,0S210.939,233.457,210.813,233.645Z"
          transform="translate(-115.347 -100.73)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M214.5,233.256c.585.125.146-.481.125-.69a1.129,1.129,0,0,1-.418,0c.418-.167-.209-.314-.23-.585s.293,0,.334,0,0-.376-.418-.481c0,.439,0,.376-.293.711l.4.146a.376.376,0,0,0,0,.418c-.314-.167-.418-.272-.669-.167s.209-.314-.314-.376l.209.585c.314-.251.5,0,.962.272-.188-.23,0-.188.146-.355C214.834,232.754,214.562,233.026,214.5,233.256Z"
          transform="translate(-123.925 -102.39)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M210.835,233.619c0-.251-.209-.334,0-.481a.34.34,0,0,1-.334-.293C210.479,232.741,210.4,233.452,210.835,233.619Z"
          transform="translate(-114.658 -100.934)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M213.449,232c.564,0,0-.481-.334-.627.1,0,.334,0,.334-.125C212.508,231.077,213.47,231.7,213.449,232Z"
          transform="translate(-122.853 -102.699)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M205.8,227.814a.481.481,0,0,0,.523-.564C206.047,227.313,205.9,227.647,205.8,227.814Z"
          transform="translate(-100.358 -107.025)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M205.53,227.436c.46.1.23-.648.648-.836C205.739,226.579,205.551,227.29,205.53,227.436Z"
          transform="translate(-99.653 -107.734)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M205.741,226.254c.481.125.523-.293.69-.564-.314-.188-.418.418-.627,0C205.469,225.669,205.887,226.108,205.741,226.254Z"
          transform="translate(-100.198 -108.767)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M206.066,225.485c-.209-.251-.272,0-.544-.125,0,.167-.167.376.251.5a.272.272,0,0,1,0-.272C205.837,225.548,206.046,225.632,206.066,225.485Z"
          transform="translate(-99.458 -109.086)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M205.527,225.873c.46,0-.251-.627.293-.732C205.151,225,205.214,225.6,205.527,225.873Z"
          transform="translate(-98.814 -109.348)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M211.174,227.969c.418-.334,0-1.15-.251-1.589C210.484,226.568,211.237,227.4,211.174,227.969Z"
          transform="translate(-115.812 -107.973)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M207.7,228.576c.773.209-.293-.606.439-.334,0-.125-.293-.188-.5-.272A2.279,2.279,0,0,1,207.7,228.576Z"
          transform="translate(-106.028 -106.239)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M210.265,227.554c.293.1.167-.251.293-.314a1.756,1.756,0,0,1-.648-.251C209.931,227.178,210.265,227.345,210.265,227.554Z"
          transform="translate(-113.189 -107.308)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M209.264,227.365c.355,0,.753,0,.4-.272a.335.335,0,0,0-.4.272Z"
          transform="translate(-111.079 -107.203)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M209.477,227.1c.376,0,.962-.439.606-.773-.314,0-.146.272-.564,0C209.77,226.518,209.624,226.811,209.477,227.1Z"
          transform="translate(-111.92 -108.028)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M209.21,226.312c.648.376,1.129-1.108.167-1.192,0,0,.23.146.376.23C209.356,225.517,209.712,226.144,209.21,226.312Z"
          transform="translate(-111.089 -109.347)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M208.154,224.241c.293,0,.188-.334.335-.46C208.133,223.76,208.091,223.99,208.154,224.241Z"
          transform="translate(-107.399 -110.809)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M207.733,228.265c.251.146.355,0,.544.125s-.857-.46-.335-.627c0-.272-.293,0-.46,0S207.838,228.077,207.733,228.265Z"
          transform="translate(-105.786 -106.597)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M211.427,227.927c.585.1.167-.481.125-.711a.859.859,0,0,1-.418,0c.439-.167-.209-.293-.209-.585s.272,0,.314,0,0-.355-.4-.481c0,.46,0,.4-.293.711l.376.167a.314.314,0,0,0,0,.4c-.335-.167-.418-.272-.69-.167s.209-.314-.314-.376c0,.209.167.4.23.585.293-.251.481,0,.941.272-.188-.23,0-.167.146-.355C211.783,227.342,211.574,227.613,211.427,227.927Z"
          transform="translate(-114.393 -108.224)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M207.745,228.239c0-.251-.188-.314,0-.481a.359.359,0,0,1-.335-.293C207.389,227.361,207.306,228.072,207.745,228.239Z"
          transform="translate(-105.108 -106.801)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M210.378,226.617c.544,0-.125-.481-.355-.627.1,0,.335,0,.355-.125C209.416,225.7,210.378,226.345,210.378,226.617Z"
          transform="translate(-113.302 -108.565)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M210.541,230.872c.188-.146,0-.523-.125-.732S210.561,230.621,210.541,230.872Z"
          transform="translate(-114.238 -103.915)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M206.176,230.413c.272.125.167-.23.272-.314a1.422,1.422,0,0,1-.648-.23C205.821,230.142,206.155,230.142,206.176,230.413Z"
          transform="translate(-100.487 -104.167)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M205.154,230.225c.355,0,.753,0,.4-.251A.334.334,0,0,0,205.154,230.225Z"
          transform="translate(-98.378 -104.062)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M205.367,229.984c.376,0,.962-.439.606-.773-.314,0-.125.251-.564,0C205.66,229.4,205.514,229.691,205.367,229.984Z"
          transform="translate(-99.218 -104.887)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M204.99,231.682c.648.4,1.129-1.108.167-1.192a2.06,2.06,0,0,0,.355.23C205.136,230.908,205.492,231.556,204.99,231.682Z"
          transform="translate(-98.047 -103.491)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M204.044,227.132c.293,0,.188-.334.335-.46C204.023,226.631,203.981,226.861,204.044,227.132Z"
          transform="translate(-94.697 -107.66)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M210.65,230.82c.272,0,0-.23,0-.314h-.188c.188,0,0-.146,0-.272s.125,0,.146,0,0-.167-.188-.23,0,.188-.125.334h.167a.167.167,0,0,0,0,.188c-.146,0-.188-.125-.314,0s0-.146-.146-.167a1.271,1.271,0,0,1,0,.251.606.606,0,0,1,.439.146c.209.146,0-.1,0-.167S210.776,230.716,210.65,230.82Z"
          transform="translate(-113.595 -104.031)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M210.213,230.172c.251,0,0-.209-.167-.293s.167,0,.167,0C209.774,229.838,210.213,230.172,210.213,230.172Z"
          transform="translate(-113.137 -104.198)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M212.053,233.486c-.69-.125-.355-.4-.5-.606C211.05,232.9,211.782,233.988,212.053,233.486Z"
          transform="translate(-117.758 -100.885)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M214.72,230.31c-.146.125-.334.209-.376.46s.293.188.439.272a.272.272,0,0,0,0-.439h.335a.167.167,0,0,1,0-.272C215.075,230.31,214.929,230.54,214.72,230.31Z"
          transform="translate(-127.009 -103.688)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M213.56,232.629a2.09,2.09,0,0,0,1.338-1.045h.251c.084,0,0-.209-.355-.251,0-.188.209-.146,0-.334a.314.314,0,0,1,.272,0,5.644,5.644,0,0,1,1.171-2.237c-.293,0,0-.188-.439-.251,0,0,.125,0,.146-.1s0,.188.251.251c0-.272,0-.334-.314-.585s.272.376-.23.167c-.523,1.338-.732,2.09-1.3,3.637,0,.125-.209,0-.293,0s0,.188,0,.272a.418.418,0,0,1-.376,0S213.769,232.65,213.56,232.629Z"
          transform="translate(-126.497 -106.194)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M210.13,227.374c.355-.125.376-.251.773-.46,0,0-.188-.146-.188-.23-.314-.146,0,.355-.481,0C210.318,226.977,210.172,226.956,210.13,227.374Z"
          transform="translate(-113.995 -107.671)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M209.842,233.24c.334,0,.564-.293-.1-.46C209.445,232.905,210.031,233.01,209.842,233.24Z"
          transform="translate(-112.265 -100.994)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M210.881,227c-.376.335-.355.606-.711.983.167,0,.314,0,.355-.125a.941.941,0,0,0,.355.23c-.251-.564.125-.794.585-1.045C211.3,227.167,210.651,227.523,210.881,227Z"
          transform="translate(-114.641 -107.297)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M210.517,226.808c.272.272,0-.334-.439-.418a.314.314,0,0,1,0,.355C210.266,226.85,210.517,226.578,210.517,226.808Z"
          transform="translate(-113.608 -107.962)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M212.4,227.529c0-.1-.125-.188,0-.314s-.418,0-.376.251-.146-.1-.293-.167,0,.209-.125.314-.146-.125,0-.188-.1.125-.4.1.334,0,.4,0,.188.188.146.293.125,0,.272,0C212.065,227.7,212.024,227.529,212.4,227.529Z"
          transform="translate(-117.603 -107.116)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M215.322,232.4a.209.209,0,0,0,.209-.146c.167,0,.125,0,.272.146s.251-.23.418-.314c-.4,0,0-.773-.439-1a.209.209,0,0,0,.125-.1.418.418,0,0,1-.188-.418c-.355-.335-.314-.732-.648-.983,0,.125-.167.272-.146.209-.4.523,0,1.84-.941,1.986,0-.146.376,0,.355-.355a1.188,1.188,0,0,0-.773.188c-.251.188.146-.188-.125-.23s0,.125,0,.23c.46,0,.209.46.125.543s0,0,.272,0,0-.251.1-.293c.355.314,0,.439,0,.732.146-.23.836-.23,1.15-.439,0-.167,0-.355.376-.251,0,0-.188-.146-.125-.23s.334.125.334-.146a.648.648,0,0,1,.272,0c.23.188-.272.418.1.46h-.167c-.021,0,0-.146-.146-.167s0,.146.125.188C215.678,232.277,215.385,232.193,215.322,232.4Z"
          transform="translate(-125.981 -104.484)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M214.722,229.756c-.543-.167-.146.4,0,.606C214.7,230.153,214.805,229.944,214.722,229.756Z"
          transform="translate(-126.865 -104.325)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M213.762,228.091c-.167-.1-.125.125-.46,0,.251.146,0,.376.439.5-.23.188-.564.251-1.171,0-.711.251-.481,1.045,0,1.484.92.523,1.693.606,1.965.314s-.46-1.443.146-1.672v-.92a2.841,2.841,0,0,1-.481-.188c-.125.146-.1.481-.481.314A.461.461,0,0,0,213.762,228.091Z"
          transform="translate(-121.934 -106.632)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M202.45,203.908a.335.335,0,0,0,.376.146C202.722,203.678,202.575,203.845,202.45,203.908Z"
          transform="translate(-89.862 -132.579)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200.161,202.9s.125,0,0,.1.376,0,.481-.251S200.411,202.861,200.161,202.9Z"
          transform="translate(-82.869 -133.832)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200.33,202.459a3.676,3.676,0,0,0,.418.188C200.56,202.354,200.5,202.417,200.33,202.459Z"
          transform="translate(-83.352 -134.099)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200.194,202.533v.1c0,.1.23,0,.334.167s0-.146,0,0,0-.125-.125-.335a.668.668,0,0,1-.355,0,.376.376,0,0,0,0,.293C200.152,202.784,200.047,202.428,200.194,202.533Z"
          transform="translate(-82.526 -134.047)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M199.818,202.714c.146.188.167,0,.251,0s.146.314.125,0C199.985,202.61,199.964,202.714,199.818,202.714Z"
          transform="translate(-81.732 -133.832)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M199.38,202.495h.4A.439.439,0,0,0,199.38,202.495Z"
          transform="translate(-80.395 -134.072)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M199.316,202.194c0-.125,0,0,.146,0S199.169,202.005,199.316,202.194Z"
          transform="translate(-79.913 -134.44)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M199.332,201.665h.209C199.75,201.665,199.332,201.6,199.332,201.665Z"
          transform="translate(-80.117 -134.956)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M201.664,202.673v.125h.209C201.936,202.8,201.748,202.632,201.664,202.673Z"
          transform="translate(-87.279 -133.833)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200.93,203.17h.146S200.93,203.128,200.93,203.17Z"
          transform="translate(-84.935 -133.305)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200.806,203.24h0Z"
          transform="translate(-84.581 -133.208)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M186.93,208.2a3.994,3.994,0,0,0,.627.146v-.23c0-.042-.188.167-.272-.188C187.181,208.076,187.118,208.076,186.93,208.2Z"
          transform="translate(-42.148 -128.093)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200.86,201.6c.1.209.146,0,.293,0s0,.167-.125.167.167-.272.335,0a.251.251,0,0,1,.167-.125c0-.167-.125,0-.188,0s-.146-.1-.146-.334a.439.439,0,0,1-.334.293Z"
          transform="translate(-85.241 -135.312)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M199.57,201.86c.125,0,0,.1.125.251h.125S199.654,201.756,199.57,201.86Z"
          transform="translate(-80.836 -134.734)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M202.982,204.793c.167.125.418.1.146-.293C202.941,204.5,203.212,204.772,202.982,204.793Z"
          transform="translate(-91.398 -131.834)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M228.827,213.846c.334.146.4-.376.314-.481s.4,0,.606,0a3.1,3.1,0,0,0-.732-.585c-.188,0-.293.585,0,.544C228.932,213.533,228.786,213.658,228.827,213.846Z"
          transform="translate(-171.91 -122.804)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M188.92,210.295s.167,0,.167-.125S188.92,210.274,188.92,210.295Z"
          transform="translate(-47.839 -125.692)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M188.78,209.569h.293C189.178,209.569,188.843,209.486,188.78,209.569Z"
          transform="translate(-47.553 -126.346)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M186.37,210.358c.167.251.355.481.523.418a1.777,1.777,0,0,0-.355-.606A.209.209,0,0,1,186.37,210.358Z"
          transform="translate(-40.313 -125.65)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M206.45,201.656c.293.481,0-.46.376,0a.656.656,0,0,0-.125-.376Z"
          transform="translate(-102.224 -135.345)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M192.426,207.543c.146.167.293.251.439.1S192.572,207.543,192.426,207.543Z"
          transform="translate(-58.975 -128.521)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M190.82,207.191c.23.293.4-.251.648-.314-.1-.251-.251.209-.355-.167C191.092,207.086,190.987,206.982,190.82,207.191Z"
          transform="translate(-54.191 -129.424)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M206.239,201.571a.919.919,0,0,0,.23.334s-.23-.669.125-.5c.1-.167-.188,0-.251-.209S206.407,201.529,206.239,201.571Z"
          transform="translate(-101.575 -135.49)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M206.039,201.734c0-.167,0-.272.251-.23s0-.251,0-.314a.439.439,0,0,0-.251.543Z"
          transform="translate(-100.893 -135.443)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M202.74,201.016a.251.251,0,0,0,.293,0,.482.482,0,0,0-.125-.376h-.146C202.907,200.912,202.907,200.974,202.74,201.016Z"
          transform="translate(-90.675 -136.043)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M189.136,208.485c.125.355.293,0,.439,0S189.282,208.422,189.136,208.485Z"
          transform="translate(-48.807 -127.519)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M188.04,205.582s-.125-.146-.188-.23-.251.836-.711.669c0-.209.251-.125.209-.314s-.167.188-.46.1c.125.334.1.23.23.564.481,0,.335-.251.9-.46-.314-.23.209-.272,0-.376.167.355.355.188.314-.146.167,0,0,.272.23.314,0-.272.627-.146.355-.5v-.146c0-.146.1.439.335.293-.335-.23,0-.314,0-.46-.523-.4-.983.334-1.422.272a1.567,1.567,0,0,0,.209.418Z"
          transform="translate(-43.76 -131.536)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200.38,204.236c.376,0,.585-.585.627-.983C200.673,203.17,200.673,203.964,200.38,204.236Z"
          transform="translate(-83.716 -133.2)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.022,212.378c.564.167-.209-.418.314-.23a3.779,3.779,0,0,0-.355-.188,1.731,1.731,0,0,1,.042.418Z"
          transform="translate(-165.652 -123.698)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200.15,204.4c0,.188.188,0,.293,0a.9.9,0,0,1-.23-.439C200.129,204.044,200.234,204.274,200.15,204.4Z"
          transform="translate(-82.671 -132.423)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M199.823,204.421c.167.209.439.334.355,0a.293.293,0,0,0-.355,0Z"
          transform="translate(-81.737 -131.985)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M199.58,204.218c.23.125.711.209.69-.125s-.209,0-.293-.293C200,204.009,199.789,204.093,199.58,204.218Z"
          transform="translate(-81.306 -132.597)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M198.916,203.844c.167.523,1.129,0,.648-.544v.293C199.355,203.509,199.251,204.011,198.916,203.844Z"
          transform="translate(-79.346 -133.142)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M197.629,203.3c.125.188.272,0,.4,0S197.733,203.132,197.629,203.3Z"
          transform="translate(-75.007 -133.225)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M195.779,202.636c.146.167.293.251.439,0S195.779,202.657,195.779,202.636Z"
          transform="translate(-69.331 -133.983)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M194.2,202.251c.23.293.4-.251.648-.314-.1-.251-.23.23-.335-.167C194.555,202.1,194.388,202,194.2,202.251Z"
          transform="translate(-64.637 -134.811)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227,212.169c.188.1.272,0,.4.1s-.606-.355-.251-.46c0-.188-.188,0-.314,0S227,212.023,227,212.169Z"
          transform="translate(-165.36 -123.949)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200.312,204.687c.251.335.314-.188.4-.314a.543.543,0,0,1-.188-.23c.314,0,0-.272.167-.418s.125.167.188.125.188-.188,0-.46c-.188.293-.167.23-.5.251l.125.272a.23.23,0,0,0-.188.209c-.1-.23-.1-.334-.293-.418s.251,0,0-.334a1.342,1.342,0,0,1-.167.418c.293,0,.251.251.376.585,0-.209.125,0,.251-.125C200.668,204.687,200.459,204.687,200.312,204.687Z"
          transform="translate(-82.582 -133.066)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.073,212.144c0-.167-.146-.23,0-.335a.251.251,0,0,1-.251-.209.439.439,0,0,0,.251.544Z"
          transform="translate(-165.013 -124.091)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M199.64,203.94c.314.23.167-.314.125-.5s.146.167.23,0C199.577,203,199.786,203.794,199.64,203.94Z"
          transform="translate(-81.157 -133.134)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M192.609,209.426c.188,0,.293-.293.314-.5S192.755,209.279,192.609,209.426Z"
          transform="translate(-59.388 -127.059)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M202.476,201.1c.167.251,0-.251.209,0s0-.125,0-.188A.731.731,0,0,1,202.476,201.1Z"
          transform="translate(-89.867 -135.749)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M192.49,209.49h.146a.6.6,0,0,1-.125-.23A.479.479,0,0,1,192.49,209.49Z"
          transform="translate(-58.851 -126.643)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M192.318,209.512s.23.167.188,0a.125.125,0,0,0-.188,0Z"
          transform="translate(-58.365 -126.414)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M192.2,209.346c.125,0,.376.125.355,0s0,0-.146-.146C192.429,209.263,192.325,209.3,192.2,209.346Z"
          transform="translate(-58.163 -126.708)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M191.814,209.268c0,.272.564,0,.335-.293s0,.1,0,.167S191.814,209.352,191.814,209.268Z"
          transform="translate(-57.004 -127.069)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M202.435,200.941a.375.375,0,0,0,.125.167s-.125-.335,0-.251-.1,0-.125,0S202.519,200.92,202.435,200.941Z"
          transform="translate(-89.596 -135.821)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M192.672,209.691c.125.167.167,0,.209-.167V209.4c.167,0,0-.146,0-.209h0v-.23c0-.125,0,.1-.251.125v.125l-.1.125c-.1.125,0-.188-.125-.23s.125,0,0-.167a.882.882,0,0,0,0,.209c.146,0,.125.125.188.314s0,0,.125,0S192.756,209.628,192.672,209.691Z"
          transform="translate(-58.908 -127.011)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M202.323,201.083a.123.123,0,0,1,.125-.125c.125,0,0-.125,0-.146S202.239,200.936,202.323,201.083Z"
          transform="translate(-89.234 -135.859)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M192.23,209.235c.167.125,0-.146,0-.251s0,0,.125,0S192.292,209.172,192.23,209.235Z"
          transform="translate(-58.068 -126.994)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M205.659,200.329c.125.188.272,0,.4,0S205.763,200.162,205.659,200.329Z"
          transform="translate(-99.824 -136.464)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M193.416,208.853c.146.167.293.251.439.1S193.562,208.853,193.416,208.853Z"
          transform="translate(-62.035 -127.093)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M191.81,208.5c.23.293.4-.251.648-.314-.1-.251-.23.209-.334-.167C192.061,208.4,192.061,208.292,191.81,208.5Z"
          transform="translate(-57.251 -127.995)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M185.573,208.439s.23.251.334.167-.188-.334-.314-.606C185.322,208.1,185.74,208.293,185.573,208.439Z"
          transform="translate(-37.53 -128.017)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M183.96,207.5c0,.188.251,0,.376,0C184.127,207.1,184.106,207.455,183.96,207.5Z"
          transform="translate(-32.719 -128.768)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M182.747,209.72c.146.314.46-.1.251-.355S182.914,209.657,182.747,209.72Z"
          transform="translate(-28.913 -126.601)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M193.84,204.814a.355.355,0,0,0,.544,0C194.2,204.668,194.049,204.814,193.84,204.814Z"
          transform="translate(-63.42 -131.562)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M193.39,204.715c.188.272.418-.23.732-.146C193.892,204.36,193.453,204.652,193.39,204.715Z"
          transform="translate(-62.218 -131.84)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M193,204.181c.209.272.439,0,.648,0s-.418,0-.334-.272S193.146,204.181,193,204.181Z"
          transform="translate(-60.97 -132.56)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M193.22,204.119c0-.23-.1-.167-.23-.334s-.272.146-.1.4a.188.188,0,0,1,0-.167S193.136,204.286,193.22,204.119Z"
          transform="translate(-60.103 -132.665)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M192.575,204.237c.272.167.146-.46.5-.251C192.784,203.548,192.533,203.9,192.575,204.237Z"
          transform="translate(-59.458 -132.616)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M190.363,208.222c.167.376.314,0,.251-.125S190.551,208.264,190.363,208.222Z"
          transform="translate(-52.396 -127.946)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M187.112,208.565c.146.314.481.272.251-.125Z"
          transform="translate(-42.415 -127.537)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M186.58,208.4a.376.376,0,0,0,.439,0C186.873,208.045,186.726,208.421,186.58,208.4Z"
          transform="translate(-40.879 -127.748)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M186.09,208.7c-.146.293.523.188.251,0S186.278,208.946,186.09,208.7Z"
          transform="translate(-39.197 -127.311)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M189.41,209.33c0,.23.293-.335.5-.376C189.619,208.765,189.556,209.225,189.41,209.33Z"
          transform="translate(-49.687 -127.026)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M188.93,206.817c.355,0,.564-.564.606-.962C189.223,205.75,189.223,206.545,188.93,206.817Z"
          transform="translate(-48.309 -130.366)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M187.95,208.526c.314.481.125-.46.4,0a.656.656,0,0,0-.125-.376A2.087,2.087,0,0,1,187.95,208.526Z"
          transform="translate(-45.071 -127.853)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M188.7,207c.1.209.209,0,.293,0a1.36,1.36,0,0,1-.23-.46C188.679,206.645,188.784,206.9,188.7,207Z"
          transform="translate(-47.284 -129.609)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M188.372,207.038c.167.188.418.335.334,0a.23.23,0,0,0-.334,0Z"
          transform="translate(-46.329 -129.145)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M188.129,206.734c.251.125.732.23.69,0s-.188,0-.272-.314C188.568,206.566,188.359,206.65,188.129,206.734Z"
          transform="translate(-45.919 -129.74)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M187.461,206.454c.167.5,1.129,0,.669-.564v.293C187.921,206.12,187.816,206.6,187.461,206.454Z"
          transform="translate(-43.955 -130.318)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M187.705,208.441c0,.209.146.23.23.334s-.23-.648.1-.5-.167,0-.23-.209S187.852,208.4,187.705,208.441Z"
          transform="translate(-44.366 -127.998)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M188.821,207.277c.272.334.314-.167.418-.293a.856.856,0,0,1-.188-.251c.293,0,0-.272.146-.418s.125.167.188.125.188-.188,0-.439c-.167.272-.167.23-.5.251l.146.251a.293.293,0,0,0-.209.209c0-.23,0-.334-.272-.4s.251,0,0-.355a.477.477,0,0,1-.146.418c.272,0,.251.251.376.585,0-.209,0,0,.23-.1C189.26,207.193,188.988,207.193,188.821,207.277Z"
          transform="translate(-47.154 -130.242)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M187.539,208.6c0-.167,0-.272.251-.23s0-.251,0-.314a.439.439,0,0,0-.251.543Z"
          transform="translate(-43.719 -127.951)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M188.276,206.53c.314.251.167-.314,0-.5s.167.188.251,0C188.193,205.589,188.423,206.384,188.276,206.53Z"
          transform="translate(-45.836 -130.31)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M196.2,202.827c.355,0,.564-.585.606-.983C196.493,201.74,196.493,202.556,196.2,202.827Z"
          transform="translate(-70.777 -134.739)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M195.22,204.516c.314.5.125-.46.4,0a.559.559,0,0,0-.146-.376A1.715,1.715,0,0,1,195.22,204.516Z"
          transform="translate(-67.539 -132.226)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M195.97,202.989c.1.188.209,0,.293,0a1.108,1.108,0,0,1-.23-.439C195.949,202.613,196.054,202.864,195.97,202.989Z"
          transform="translate(-69.752 -133.96)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M195.642,203.033c.167.188.418.334.334,0a.251.251,0,0,0-.335,0Z"
          transform="translate(-68.797 -133.503)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M195.4,202.8c.251.125.732.209.69-.125s-.188,0-.272-.314C195.838,202.611,195.608,202.694,195.4,202.8Z"
          transform="translate(-68.387 -134.167)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M194.731,202.434c.167.523,1.129,0,.669-.544v.293C195.191,202.12,195.086,202.6,194.731,202.434Z"
          transform="translate(-66.423 -134.68)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M193.449,201.889c.125.188.251,0,.376,0S193.532,201.722,193.449,201.889Z"
          transform="translate(-62.067 -134.762)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M195.019,204.456c0,.188.146.209.23.314s-.23-.648.1-.481c.1-.167-.188,0-.23-.23S195.166,204.393,195.019,204.456Z"
          transform="translate(-66.878 -132.375)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M196.1,203.277c.272.335.314-.188.418-.314a1.11,1.11,0,0,1-.188-.23c.293,0,0-.272.146-.418s.125.146.188.125.188-.188,0-.46c-.167.272-.167.23-.5.251l.146.272a.251.251,0,0,0-.209.209c0-.251,0-.355-.272-.418s.251,0,0-.334l-.146.4c.272,0,.251.251.376.606,0-.209.1,0,.23-.125C196.56,203.172,196.289,203.193,196.1,203.277Z"
          transform="translate(-69.653 -134.604)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M194.827,204.619c0-.188,0-.272.251-.251s0-.251,0-.314S194.827,204.3,194.827,204.619Z"
          transform="translate(-66.288 -132.329)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M195.45,202.494c.314.251.167-.293.1-.5s.167.188.251.1C195.366,201.553,195.6,202.368,195.45,202.494Z"
          transform="translate(-68.208 -134.656)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M197.689,204.541c.188,0,.272-.251.293-.439S197.835,204.415,197.689,204.541Z"
          transform="translate(-75.067 -132.314)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M195.13,206.459c0,.188.188,0,.293,0a1.109,1.109,0,0,1-.23-.439C195.109,206.1,195.214,206.334,195.13,206.459Z"
          transform="translate(-67.156 -130.176)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M194.832,206.48c.167.209.418.355.334,0A.272.272,0,0,0,194.832,206.48Z"
          transform="translate(-66.294 -129.737)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M194.56,206.218c.251.125.711.209.69-.125s-.188,0-.293-.293C195,206.218,194.769,206.218,194.56,206.218Z"
          transform="translate(-65.791 -130.416)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M195.011,207.294c.167.5,1.129,0,.669-.564v.293C195.471,206.96,195.366,207.462,195.011,207.294Z"
          transform="translate(-67.288 -129.402)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M192.609,205.359c.125.188.272,0,.4,0S192.713,205.192,192.609,205.359Z"
          transform="translate(-59.492 -130.978)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M190.785,204.64c.125.167.272.251.418.125S190.932,204.661,190.785,204.64Z"
          transform="translate(-53.885 -131.681)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M197.894,204.8c.1.167.146,0,.188-.125l-.1-.125c.146,0,0-.125,0-.188h0v-.209c0-.209,0,.1-.209.1v.125a.167.167,0,0,0-.1.1c0-.1,0-.167-.125-.188s.1,0,0-.167v.188c.125,0,.1.125.167.272h0S197.957,204.754,197.894,204.8Z"
          transform="translate(-74.77 -132.297)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M197.361,204.379c.146.125,0-.146,0-.23s0,0,.1,0S197.445,204.317,197.361,204.379Z"
          transform="translate(-73.903 -132.257)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M202.151,203.61s-.355,0-.146.293c.23-.23.544.23.523.523s.167,0,.23.209C202.8,204.258,202.4,204.007,202.151,203.61Z"
          transform="translate(-88.727 -132.804)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M201.459,204.209c-.188-.23-.125-.606-.272-.648s.188.4.167.69a.239.239,0,0,1,.209.146c.188-.355.481-.1.794-.4-.125-.146-.293-.146-.293,0,0-.4-.334-.167-.627-.355a4.437,4.437,0,0,0,.23.439Z"
          transform="translate(-86.676 -132.86)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M227.819,212.077c-.5-.1-.251-.293-.355-.439S227.63,212.412,227.819,212.077Z"
          transform="translate(-167.097 -124.067)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200.356,202.26s-.418.23,0,.146A.293.293,0,0,1,200.356,202.26Z"
          transform="translate(-82.626 -134.276)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200.372,202.273h-.418c-.146,0,0,.23,0,.355s.209-.125.146-.293,0,0,.146.209a.1.1,0,0,1,.188,0C200.561,202.44,200.372,202.5,200.372,202.273Z"
          transform="translate(-82.161 -134.268)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M198.4,202.821a1.4,1.4,0,0,0,1.192,0l.125.146c.125.146,0-.146,0-.293s.188,0,.188-.167a.314.314,0,0,1,.146.146,3.825,3.825,0,0,1,1.672-.648c-.188-.1,0-.125-.1-.334h.1v.251c.1-.167.188-.146.1-.439s0,.314-.209,0l-2.4,1.338a.2.2,0,0,1-.167-.125c-.063-.125-.146,0-.1.188a.272.272,0,0,1-.188-.167C198.529,202.654,198.529,202.925,198.4,202.821Z"
          transform="translate(-80.443 -135.214)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200.571,203.751c0,.125.125,0,.188,0a.231.231,0,0,1,0,.125c.272.209.418-.125.648-.167-.188-.209.293-.564.481-.335s0-.125,0-.167.167.125.272.125a.585.585,0,0,1-.125-.167c.146,0,0-.272,0-.4s.146,0,.188,0,0-.146,0-.167-.293-.167-.439,0h0a.293.293,0,0,1-.23.272.251.251,0,0,0,0,.188c-.146,0-.251.188-.418,0s0,.125-.125.167,0,0,.146,0h.1A5.871,5.871,0,0,1,200.571,203.751Z"
          transform="translate(-85.349 -133.991)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M201.685,202.33c0,.146-.146.167-.1.314s.167-.23.23-.209S201.706,202.372,201.685,202.33Z"
          transform="translate(-87.028 -134.2)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M187.976,208.12c-.376,0-.481.167-.836.188,0,0,.146.146.23,0a1.067,1.067,0,0,0,0,.293c.146-.418.439-.355.794-.293C188.143,208.371,187.642,208.266,187.976,208.12Z"
          transform="translate(-43.195 -127.886)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M186.992,208.229c0,.272.167-.209,0-.439a.23.23,0,0,1-.167.188C186.845,208.229,187.117,208.229,186.992,208.229Z"
          transform="translate(-41.437 -128.246)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M195.858,202.543a1.2,1.2,0,0,1,.627.125c.293.125,0-.167,0-.23s-.209.167-.293-.188C196.13,202.438,196.046,202.355,195.858,202.543Z"
          transform="translate(-69.87 -134.287)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M226.273,211.874c.23,0,.4-.209,0-.335C226.064,211.665,226.42,211.728,226.273,211.874Z"
          transform="translate(-163.21 -124.156)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M205.091,223.38c-.23.188.125.5.272.481S205.091,223.61,205.091,223.38Z"
          transform="translate(-97.792 -111.245)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200.751,221.85c-.481.23,0,.418,0,.627C201.128,222.268,200.856,222.164,200.751,221.85Z"
          transform="translate(-83.983 -112.913)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M197.476,219.7c-.167.272.23.5-.209.773C197.664,220.495,197.831,219.679,197.476,219.7Z"
          transform="translate(-73.871 -115.258)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M206.32,220.86c0,.523.857.773,1.4.794C207.909,221.153,206.78,221.257,206.32,220.86Z"
          transform="translate(-102.868 -113.993)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M207.391,221.42c-.272.146,0,.293,0,.418a1.61,1.61,0,0,1,.606-.355C207.892,221.378,207.558,221.545,207.391,221.42Z"
          transform="translate(-105.109 -113.382)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M208.068,221.86c-.251.23-.439.606,0,.481A.355.355,0,0,0,208.068,221.86Z"
          transform="translate(-106.393 -112.902)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M207.086,222c-.146.355-.23,1.024.23.962s-.1-.272.4-.418C207.378,222.5,207.232,222.188,207.086,222Z"
          transform="translate(-104.198 -112.75)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M206.5,222.8c-.711.272.167,1.589.815.9a.632.632,0,0,0-.4.146C207.006,223.427,206.316,223.218,206.5,222.8Z"
          transform="translate(-102.279 -111.877)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M206.04,224.85c-.251.188.146.355.146.544C206.437,225.164,206.207,224.954,206.04,224.85Z"
          transform="translate(-100.665 -109.642)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M206.571,221.928c-.439.376.272.418.46.544a1.19,1.19,0,0,1,.334-.293c-.125.46.355,0,.585.209s-.209.188-.167.272.293.251.648,0c-.418-.23-.335-.209-.4-.69l-.355.209a.376.376,0,0,0-.314-.272c.314-.146.46-.146.544-.418s.125.355.481,0l-.585-.209c0,.4-.335.376-.794.564.293,0,.1.167.188.355C206.759,222.513,206.717,222.137,206.571,221.928Z"
          transform="translate(-103.788 -113.535)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M206.417,222.08c-.314.46.439.209.711.125s-.23.23-.125.334C207.734,221.913,206.626,222.268,206.417,222.08Z"
          transform="translate(-102.421 -112.662)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M202.167,225.78c-.4.209-.335.69.188.335Z"
          transform="translate(-88.263 -108.628)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M195.512,228.74a.564.564,0,0,0,.418.46C196.16,228.719,195.638,228.907,195.512,228.74Z"
          transform="translate(-68.521 -105.4)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M195.476,229.66c-.418.125.334.69.376.125C195.727,229.555,195.413,230.057,195.476,229.66Z"
          transform="translate(-68.046 -104.396)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M195.006,229.75c-.125.314.5.69.606.188C195.32,230.043,195.362,229.792,195.006,229.75Z"
          transform="translate(-67.054 -104.298)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M194.44,229.93c0,.334.376.5.585.5S194.607,230.076,194.44,229.93Z"
          transform="translate(-65.358 -104.102)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M204.767,223.64c0,.523.857.794,1.422.815C206.293,224,205.185,224.037,204.767,223.64Z"
          transform="translate(-98.074 -110.961)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M205.831,224.21c-.251.146,0,.272,0,.418a1.421,1.421,0,0,1,.585-.355C206.25,224.168,205.831,224.315,205.831,224.21Z"
          transform="translate(-100.289 -110.34)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M206.55,224.64c-.272.251-.439.627,0,.5a.418.418,0,0,0,0-.5Z"
          transform="translate(-101.677 -109.871)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M205.565,224.74c-.167.334-.23,1.024.23.962s-.1-.272.4-.418C205.857,225.284,205.711,225.033,205.565,224.74Z"
          transform="translate(-99.478 -109.762)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M204.974,225.59c-.711.251.167,1.589.815.9a.7.7,0,0,0-.4.125C205.476,226.2,204.765,226.092,204.974,225.59Z"
          transform="translate(-97.55 -108.835)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M207.943,223.53c-.251.188.146.376.167.544C208.361,223.823,208.194,223.655,207.943,223.53Z"
          transform="translate(-106.582 -111.081)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M205.051,224.7c-.439.4.272.439.46.564a1.443,1.443,0,0,1,.314-.293c-.125.439.376,0,.606.209s-.209.188-.167.272.272.251.627,0c-.4-.23-.334-.23-.376-.69l-.355.209a.376.376,0,0,0-.314-.272c.314-.146.46-.146.543-.418s.125.355.481,0l-.585-.209c0,.4-.334.376-.794.564.293,0,.1.167.188.334C205.218,225.3,205.176,224.906,205.051,224.7Z"
          transform="translate(-99.069 -110.492)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M204.887,224.86c-.314.46.439.23.711.146s-.23.23-.125.334C206.2,224.714,205.1,225.153,204.887,224.86Z"
          transform="translate(-97.693 -109.631)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M207.69,227.77c0,.439.648.188.878.585C208.568,227.916,207.92,227.77,207.69,227.77Z"
          transform="translate(-106.558 -106.457)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M206.746,227.622c0,.481.334.5.627.648.167-.314-.439-.4,0-.606C207.331,227.309,206.871,227.748,206.746,227.622Z"
          transform="translate(-103.419 -106.728)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M206.842,228.3c.251-.209,0-.251.1-.544-.167,0-.4-.146-.5.272a.272.272,0,0,1,.272,0C206.758,228.15,206.7,228.3,206.842,228.3Z"
          transform="translate(-102.344 -106.503)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M206.192,228.213c0,.46.606-.251.732.272C207.049,227.816,206.422,227.9,206.192,228.213Z"
          transform="translate(-101.799 -106.232)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M201.077,222.949c0-.293-.523,0-.585-.209-.251.23.125.5.418.209a.422.422,0,0,1-.272.355C200.931,223.472,201.036,223.137,201.077,222.949Z"
          transform="translate(-83.849 -111.943)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M202.6,226.34c0,.314,0,.544.334.564C202.992,226.57,202.742,226.507,202.6,226.34Z"
          transform="translate(-90.279 -108.017)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M207.835,221.73a8.492,8.492,0,0,0-.125.9h.314c.063,0-.251-.272.23-.418C207.982,222.085,208.086,221.981,207.835,221.73Z"
          transform="translate(-106.286 -113.044)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M208.857,222.341c0-.5-.251-.669-.334-1.171-.125.125-.188.23-.125.355a1.212,1.212,0,0,0-.4.125c.606.167.543.606.46,1.108C208.543,222.612,208.648,221.881,208.857,222.341Z"
          transform="translate(-107.495 -113.655)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M207.672,222.369c-.376,0,.293.209.585,0a.314.314,0,0,1-.272-.209C207.8,222.16,207.86,222.515,207.672,222.369Z"
          transform="translate(-105.976 -112.575)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M201.73,227.558a.523.523,0,0,0,.564.209C202.106,227.224,201.918,227.475,201.73,227.558Z"
          transform="translate(-87.825 -106.831)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M198.46,226.109s.188,0,.125.125a.564.564,0,0,0,.669-.335A2.141,2.141,0,0,0,198.46,226.109Z"
          transform="translate(-77.949 -108.497)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M198.73,225.514c.188.167.376.146.564.293C198.96,225.388,198.855,225.451,198.73,225.514Z"
          transform="translate(-78.554 -108.967)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M198.634,225.7v.146c0,.146.334,0,.5.23s0-.188-.125-.146l-.188-.46a1.025,1.025,0,0,1-.5,0,.606.606,0,0,0,.1.418C198.592,226.034,198.467,225.554,198.634,225.7Z"
          transform="translate(-77.601 -108.966)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200.629,225.842v.167c.146,0,.188-.1.293-.146S200.754,225.758,200.629,225.842Z"
          transform="translate(-84.174 -108.605)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M199.567,226.567c0,.1.125.1.188,0s0-.1,0-.125S199.567,226.525,199.567,226.567Z"
          transform="translate(-80.791 -107.909)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M199.489,226.639h.251c.146,0-.188-.125-.293,0S199.51,226.618,199.489,226.639Z"
          transform="translate(-80.463 -107.752)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M199.55,224.253c.146.314.209-.125.418,0s0,.23-.167.23c.272.418.209-.376.46.125a.523.523,0,0,1,.23-.188c0-.209-.167,0-.251,0s-.209-.125-.209-.46A.585.585,0,0,1,199.55,224.253Z"
          transform="translate(-81.464 -110.612)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M202.485,228.8c.251.209.606.167.209-.418C202.443,228.4,202.757,228.8,202.485,228.8Z"
          transform="translate(-89.981 -105.792)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200.34,229.8c.209.46.773,0,.773-.23s.4.4.606.648a5.623,5.623,0,0,0-.251-1.3c-.23-.146-.836.335-.5.564C200.7,229.572,200.34,229.551,200.34,229.8Z"
          transform="translate(-84.345 -105.228)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M207.35,224.4c.439.669.188-.648.564,0,0,0-.1-.355-.188-.543a4.421,4.421,0,0,1-.376.543Z"
          transform="translate(-105.194 -110.721)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M207.065,224.284c.125.272.23.293.334.46s-.314-.92.167-.711c.146-.23-.272-.125-.334-.293C207.191,223.865,207.3,224.137,207.065,224.284Z"
          transform="translate(-104.282 -110.852)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M206.856,224.511c.1-.23,0-.376.355-.314-.125-.23-.125-.355,0-.46S206.668,224.114,206.856,224.511Z"
          transform="translate(-103.404 -110.871)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M202.14,223.438a.376.376,0,0,0,.418.125c-.125-.23,0-.314-.188-.544h-.209C202.37,223.292,202.37,223.375,202.14,223.438Z"
          transform="translate(-88.946 -111.637)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M198.84,228.006c.523,0,.815-.794.857-1.359C199.237,226.5,199.258,227.629,198.84,228.006Z"
          transform="translate(-79.186 -107.697)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M198.51,228.247c.125.272.272,0,.418,0a1.4,1.4,0,0,1-.334-.627C198.489,227.745,198.635,228.08,198.51,228.247Z"
          transform="translate(-77.728 -106.621)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M202.549,222.32c.251,0,.418-.418.439-.69S202.758,222.132,202.549,222.32Z"
          transform="translate(-90.233 -113.216)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M201.86,223.542c.23.355,0-.334.293,0v-.272a.858.858,0,0,1-.293.272Z"
          transform="translate(-87.955 -111.365)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M202.38,222.434c0,.146.146,0,.23,0a.732.732,0,0,1-.167-.335A.809.809,0,0,1,202.38,222.434Z"
          transform="translate(-89.5 -112.641)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M202.144,222.445c.125.146.314.251.251,0A.188.188,0,0,0,202.144,222.445Z"
          transform="translate(-88.804 -112.317)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M201.969,222.24c.188,0,.523.167.5,0s-.146,0-.209-.23C202.283,222.115,202.137,222.177,201.969,222.24Z"
          transform="translate(-88.504 -112.739)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M201.487,222.027c.125.376.815,0,.481-.4v.209A.369.369,0,0,1,201.487,222.027Z"
          transform="translate(-87.08 -113.153)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M201.723,223.479c0,.146,0,.167.167.251s-.167-.481,0-.376-.125,0-.167-.146S201.828,223.437,201.723,223.479Z"
          transform="translate(-87.442 -111.47)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M202.594,222.735c.188.23.23-.146.293-.23a.587.587,0,0,1-.125-.167c.209,0,0-.209,0-.314s.1.125.146,0,.125-.146,0-.334c-.125.209-.1.167-.355.188l.1.188a.167.167,0,0,0-.146.146c0-.167,0-.251-.209-.293s.188,0,0-.251,0,.209,0,.293.188.188.272.439c0-.167,0,0,.188,0S202.72,222.672,202.594,222.735Z"
          transform="translate(-89.526 -113.192)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M201.6,223.6c0-.125,0-.188.167-.167a.314.314,0,0,1,0-.23S201.476,223.388,201.6,223.6Z"
          transform="translate(-86.965 -111.441)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M202.087,222.089c.23.188.125-.23,0-.355s.1.125.167,0C202.024,221.4,202.191,221.984,202.087,222.089Z"
          transform="translate(-88.454 -113.152)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M206.26,222.622c.188.251.376-.125.564-.125C206.594,222.224,206.406,222.371,206.26,222.622Z"
          transform="translate(-101.825 -112.368)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M203.7,221.53c.209.23.418.355.627.167C204.118,221.4,203.909,221.55,203.7,221.53Z"
          transform="translate(-93.976 -113.283)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M201.32,227.14c0,.1-.5,0-.209.418.334-.355.773.314.732.732s.23,0,.334.293C202.219,228.039,201.654,227.684,201.32,227.14Z"
          transform="translate(-86.223 -107.144)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200.349,227.991c-.251-.355-.167-.878-.376-.92s.272.544.251.983a.32.32,0,0,1,.272.167c.272-.481.669-.1,1.108-.544-.167-.188-.418-.209-.418,0,0-.564-.439-.23-.878-.5l.334.627Z"
          transform="translate(-83.35 -107.222)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M198.816,225.24c-.1,0-.564.314,0,.209a.628.628,0,0,1,0-.209Z"
          transform="translate(-77.678 -109.216)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M199.1,227.329c0,.188.188,0,.293,0a.4.4,0,0,1,0,.167c.4.272.585-.188.92-.251-.272-.272.418-.773.648-.46.125,0,0-.188,0-.23s.251.167.376.167a.544.544,0,0,1-.146-.209c.188,0,0-.4,0-.564s.209,0,.251,0,0-.209,0-.251c-.272,0-.439-.209-.627,0s0,0,.146.125a.376.376,0,0,1-.334.376.4.4,0,0,0,.146.293c-.188-.125-.355.251-.585,0a.24.24,0,0,1-.167.23c-.167.042,0,0,.188,0l.146.146a9.29,9.29,0,0,1-1.254.46Z"
          transform="translate(-81.496 -108.818)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200.647,225.35c0,.188-.188.209-.146.418s.209-.334.335-.293S200.688,225.392,200.647,225.35Z"
          transform="translate(-83.815 -109.097)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M201.822,225.113c.23.711,1.589,0,.92-.773a.7.7,0,0,0,.125.4C202.449,224.633,202.3,225.322,201.822,225.113Z"
          transform="translate(-88.649 -110.198)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200.03,224.419c.188.272.376-.125.564-.125C200.364,224.021,200.176,224.189,200.03,224.419Z"
          transform="translate(-82.571 -110.402)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M199.867,223.936h0v-.146C199.867,223.685,199.929,224.082,199.867,223.936Z"
          transform="translate(-81.53 -110.818)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M198.312,225.247s.188.209.314.125c-.293-.23.23,0,.251-.209s.209.209.146.23.606-.4.669-.773c.251.334.146-.23.4,0v-.251a.733.733,0,0,1,.439-.293c.167-.021-.293-.418,0-.272,0-.251-.23,0-.125.209s-.209.146-.293.293-.125,0-.188-.188-.1.251-.355.167c.4.564-.439.23-.355.753C198.814,225,198.625,225.226,198.312,225.247Z"
          transform="translate(-78.951 -110.896)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M199.292,224.284c0,.125,0-.125.146,0s0-.167-.167-.272S199.418,224.179,199.292,224.284Z"
          transform="translate(-79.785 -110.581)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M198.485,224.052c.1,0,.272-.146.355-.314S198.4,223.822,198.485,224.052Z"
          transform="translate(-77.577 -110.913)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M203.537,225.946c.314.146.4-.376.293-.481s.4,0,.627,0a3.6,3.6,0,0,0-.732-.585c-.188,0-.293.564,0,.544C203.641,225.633,203.411,225.737,203.537,225.946Z"
          transform="translate(-93.688 -109.609)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M201.7,224.478c.544.167-.209-.418.314-.23,0,0-.23-.125-.355-.188a1.732,1.732,0,0,1,.042.418Z"
          transform="translate(-87.4 -110.503)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M201.717,224.273a.752.752,0,0,0,.4,0c0-.125-.606-.334-.251-.439,0-.188-.209,0-.314,0S201.8,224.273,201.717,224.273Z"
          transform="translate(-87.227 -110.841)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M201.735,224.284c0-.167-.146-.23,0-.334a.245.245,0,0,1-.23-.209.439.439,0,0,0,.23.544Z"
          transform="translate(-86.743 -110.852)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M202.5,224.177c-.5-.1-.251-.293-.355-.439S202.31,224.533,202.5,224.177Z"
          transform="translate(-88.845 -110.871)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200.956,223.974c.23,0,.4-.209,0-.334C200.747,223.765,201.082,223.828,200.956,223.974Z"
          transform="translate(-84.961 -110.961)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.186,237.579c0-.1.167-.188.314-.293-.209-.46-1.192-.272-1.024-.962.293-.125.209.376.481.272s-.272-.209-.188-.606c-.46.188-.314.167-.773.355.209.648.376.46.711,1.233.293-.46.4.272.544.125-.481.251-.251.5.23.418,0,.251-.355,0-.418.355.4,0,.251.878.732.46,0,0,0,.125.209,0s-.606.167-.376.481c.272-.46.418,0,.648,0,.5-.794-.544-1.359-.5-2.09A2.091,2.091,0,0,0,248.186,237.579Z"
          transform="translate(-229.567 -97.493)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M240.551,237.955c.648,0,.606.523.857.962C241.6,238.457,241.513,237.537,240.551,237.955Z"
          transform="translate(-208.177 -95.466)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.711,240a.481.481,0,0,0,0,.9C243.108,240.564,242.4,240.523,242.711,240Z"
          transform="translate(-213.388 -93.12)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.1,239.355c-.1.1,0,.293-.209.209-.251.251.272.251.523,0a.335.335,0,0,0,0-.335C241.292,239.376,241.1,239.4,241.1,239.355Z"
          transform="translate(-208.729 -93.96)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M242.729,241.1c-.293-.1,0,.5.251.418s0,0,0-.125v-.146C242.98,241.1,242.729,241.41,242.729,241.1Z"
          transform="translate(-214.075 -91.938)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.65,241.8c.564-.146.836-1,.857-1.526C247.005,240.291,247.068,241.336,246.65,241.8Z"
          transform="translate(-226.944 -92.826)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.24,241.758c.146.209.293-.146.439-.167-.293-.251-.209-.146-.376-.481C246.177,241.3,246.344,241.57,246.24,241.758Z"
          transform="translate(-225.259 -91.91)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M245.735,241.625c.272.188.669.251.523,0a.439.439,0,0,0-.523,0Z"
          transform="translate(-223.813 -91.442)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M245.4,241.382c.376,0,1.087,0,1.024-.46-.293,0-.293.188-.46-.272C246.025,240.984,245.712,241.194,245.4,241.382Z"
          transform="translate(-223.246 -92.412)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M244.44,240.713c.293.606,1.693-.544.962-.983a.483.483,0,0,0,.146.335C245.13,240.085,245,240.775,244.44,240.713Z"
          transform="translate(-220.427 -93.415)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M246.482,242.2c.418.314.46-.376.585-.564a1,1,0,0,1-.314-.23c.481,0,0-.355.23-.606s.188.146.293,0a.406.406,0,0,0,0-.606c-.251.439-.23.376-.732.544l.23.272a.5.5,0,0,0-.293.376c-.146-.272-.167-.4-.439-.418s.376-.188,0-.439a1.647,1.647,0,0,1-.209.585c.439,0,.4.23.606.606,0-.272.167-.125.376-.251C247.067,241.883,246.712,242.009,246.482,242.2Z"
          transform="translate(-225.145 -92.913)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M245.52,240.914c.481.188.23-.46.125-.69s.251.167.376,0C245.353,239.723,245.729,240.684,245.52,240.914Z"
          transform="translate(-223.096 -93.031)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.657,237.69c-.648.46.648.146,0,.544a.783.783,0,0,0,.523-.209Z"
          transform="translate(-210.788 -95.64)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.722,238c-.251.125-.272.251-.418.355s.878-.355.69.125c.251.146.125-.251.293-.334C242.1,238,241.806,238.188,241.722,238Z"
          transform="translate(-210.519 -95.301)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M241.3,238.353c.251,0,.376,0,.355.334a.418.418,0,0,1,.418,0S241.7,238.1,241.3,238.353Z"
          transform="translate(-210.326 -94.985)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.187,237c-.314.46.439.23.711.125,0,.125-.23.23-.125.355C249.5,236.749,248.4,237.1,248.187,237Z"
          transform="translate(-231.512 -96.392)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M250.818,237.42c-.481.209,0,.418,0,.627C251.174,237.838,250.9,237.629,250.818,237.42Z"
          transform="translate(-238.701 -95.934)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.209,241.34c-.4.23-.314.711.209.355Z"
          transform="translate(-242.956 -91.659)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M245.572,244.31a.564.564,0,0,0,.439.439C246.241,244.268,245.719,244.456,245.572,244.31Z"
          transform="translate(-223.253 -88.42)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M245.536,245.22c-.418.146.335.711.376.125C245.787,245.115,245.473,245.638,245.536,245.22Z"
          transform="translate(-222.757 -87.428)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M245.081,245.31c-.146.314.5.69.585.209C245.395,245.624,245.437,245.352,245.081,245.31Z"
          transform="translate(-221.78 -87.33)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M244.51,245.49c0,.335.376.5.585.5S244.677,245.7,244.51,245.49Z"
          transform="translate(-220.1 -87.134)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.139,238.5c0-.272-.5,0-.585-.188-.23.209.125.481.439.188,0,.125,0,.23-.272.355C251.013,239.042,251.118,238.686,251.139,238.5Z"
          transform="translate(-238.582 -94.963)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.665,241.91c0,.293,0,.523.314.543C253.042,242.1,252.791,242.1,252.665,241.91Z"
          transform="translate(-245 -91.038)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.8,243.138a.5.5,0,0,0,.543.188C252.176,242.8,251.925,243.012,251.8,243.138Z"
          transform="translate(-242.546 -89.861)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.52,241.649s.167,0,.125.125c.293.125.523,0,.669-.335C249.022,241.482,248.875,241.565,248.52,241.649Z"
          transform="translate(-232.66 -91.55)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.83,241.1c.188.146.376.125.564.272C249.039,240.973,249.039,240.973,248.83,241.1Z"
          transform="translate(-233.389 -92.003)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.726,241.281v.125c0,.125.334,0,.481.23s0-.188-.125-.125a4.58,4.58,0,0,0-.188-.481,1.024,1.024,0,0,1-.5,0,.69.69,0,0,0,.125.418C248.684,241.615,248.537,241.135,248.726,241.281Z"
          transform="translate(-232.343 -91.997)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M250.7,241.416v.167c.167,0,.188,0,.293-.146S250.824,241.353,250.7,241.416Z"
          transform="translate(-238.916 -91.651)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.637,242.129c0,.1.125.1.188,0s0-.1,0-.125S249.658,242,249.637,242.129Z"
          transform="translate(-235.533 -90.943)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.541,242.209h.251c.146,0-.167-.125-.272,0S249.561,242.188,249.541,242.209Z"
          transform="translate(-235.186 -90.772)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.535,239.824c.146.314.209,0,.4,0s0,.23-.167.23c.272.418.23-.376.481.125,0,0,.1-.167.23-.188s-.167,0-.251,0-.209-.146-.209-.481a.585.585,0,0,1-.481.314Z"
          transform="translate(-235.975 -93.655)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.549,244.368c.272.188.606.146.23-.418C252.508,244.055,252.821,244.347,252.549,244.368Z"
          transform="translate(-244.717 -88.813)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M250.43,245.373c.188.46.774,0,.753-.23s.418.4.606.648a5.149,5.149,0,0,0-.23-1.3c-.251-.146-.857.314-.5.564C250.806,245.143,250.6,245.1,250.43,245.373Z"
          transform="translate(-239.128 -88.25)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.21,239.008a.334.334,0,0,0,.4.1c-.125-.209,0-.314-.188-.523h-.209C252.419,238.862,252.419,238.945,252.21,239.008Z"
          transform="translate(-243.667 -94.658)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.9,243.585c.523,0,.815-.815.878-1.38C249.318,242.08,249.318,243.208,248.9,243.585Z"
          transform="translate(-233.918 -90.726)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.58,243.806c.146.272.293,0,.418,0a1.4,1.4,0,0,1-.314-.606C248.559,243.3,248.705,243.639,248.58,243.806Z"
          transform="translate(-232.47 -89.631)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.87,239.142c.23.355,0-.334.272,0v-.272Z"
          transform="translate(-242.491 -94.353)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.45,237.984c0,.146.146,0,.209,0a.919.919,0,0,1-.167-.314A.632.632,0,0,1,252.45,237.984Z"
          transform="translate(-244.221 -95.661)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.214,238.029c.1.125.293.23.23,0a.146.146,0,0,0-.23,0Z"
          transform="translate(-243.525 -95.331)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.03,237.854c.167,0,.523.146.5-.1s-.146,0-.209-.209C252.343,237.707,252.176,237.791,252.03,237.854Z"
          transform="translate(-243.215 -95.803)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.557,237.587c0,.376.815,0,.46-.4v.209A.344.344,0,0,1,251.557,237.587Z"
          transform="translate(-241.802 -96.185)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.771,239.063c0,.125.125.146.188.23s-.188-.481,0-.355-.125,0-.167-.167S251.9,239.021,251.771,239.063Z"
          transform="translate(-242.163 -94.504)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.639,238.245c.188.251.23-.125.293-.23a.605.605,0,0,1-.146-.167c.23,0,0-.188.125-.293s0,0,.125,0,.146-.125,0-.334c-.125.209-.125.167-.355.188v.188a.209.209,0,0,0-.146.167c0-.188,0-.251-.188-.314s.167,0,0-.251l-.125.314c.209,0,.188.167.272.418,0-.146,0,0,.188,0S252.744,238.183,252.639,238.245Z"
          transform="translate(-244.222 -96.174)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.613,239.225c0-.125,0-.209.188-.188s0-.167,0-.23S251.508,238.911,251.613,239.225Z"
          transform="translate(-241.648 -94.436)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.145,237.664c.23.167.125-.23,0-.376s.125.125.188,0C252.1,236.974,252.249,237.559,252.145,237.664Z"
          transform="translate(-243.184 -96.178)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.38,242.7c0,.125-.5,0-.209.418.335-.334.774.314.732.732s.209,0,.335.314C252.279,243.62,251.714,243.327,251.38,242.7Z"
          transform="translate(-240.935 -90.176)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M250.4,243.54c-.251-.334-.188-.857-.376-.9s.251.544.23.962c.1,0,.188,0,.272.188.293-.5.69-.125,1.129-.564-.188-.188-.418-.188-.418,0,0-.585-.439-.23-.878-.5l.314.606Z"
          transform="translate(-238.056 -90.243)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.885,240.8c-.1,0-.585.334,0,.23A.292.292,0,0,1,248.885,240.8Z"
          transform="translate(-232.398 -92.248)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.107,242.93c0,.167.188,0,.272,0s0,.125,0,.167c.4.293.585-.167.941-.23-.272-.293.4-.774.648-.481s0-.167,0-.23.23.188.355.167l-.146-.209c.188,0,0-.376.125-.564.1.146.188,0,.251,0s0-.23,0-.251c-.251,0-.418-.23-.627-.125s0,0,.146.146a.376.376,0,0,1-.314.376.356.356,0,0,0,.146.272c-.188-.125-.355.272-.606,0a.221.221,0,0,1-.167.209c-.167.042.1,0,.188,0s.1,0,.167.125A11.627,11.627,0,0,1,249.107,242.93Z"
          transform="translate(-236.153 -91.87)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M250.72,240.91c0,.188-.188.23-.146.439s.23-.335.334-.314S250.762,240.952,250.72,240.91Z"
          transform="translate(-238.561 -92.128)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.886,240.673c.23.711,1.568,0,.92-.773a.8.8,0,0,0,.125.418C252.513,240.109,252.367,240.9,251.886,240.673Z"
          transform="translate(-243.364 -93.229)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M250.1,239.992c.167.251.376-.125.543-.125C250.414,239.594,250.225,239.741,250.1,239.992Z"
          transform="translate(-237.293 -93.426)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.927,239.482h0v-.125C249.927,239.273,249.989,239.629,249.927,239.482Z"
          transform="translate(-236.241 -93.836)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.385,240.8c.1,0,.209.209.314.125-.272-.209.23,0,.251-.188s.23.209.167.209.585-.4.648-.753c.272.334.167-.251.418,0v-.272a.641.641,0,0,1,.418-.272c.146,0-.272-.439,0-.272,0-.272-.251,0-.125.209s-.23.125-.293.293-.125,0-.188-.209-.125.251-.355.188c.376.564-.439.23-.376.753C248.907,240.55,248.719,240.8,248.385,240.8Z"
          transform="translate(-233.696 -93.922)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M249.357,239.847c0,.125,0-.1.167,0s0-.146-.188-.272S249.482,239.763,249.357,239.847Z"
          transform="translate(-234.522 -93.616)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M248.55,239.584a1.044,1.044,0,0,0,.376-.314S248.466,239.375,248.55,239.584Z"
          transform="translate(-232.314 -93.917)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.772,240.059c.564.146-.209-.439.314-.251a3.774,3.774,0,0,0-.355-.188A1.8,1.8,0,0,1,251.772,240.059Z"
          transform="translate(-242.142 -93.535)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.786,239.894h.4c.125,0-.606-.335-.251-.46,0-.188-.188,0-.314,0S251.87,239.747,251.786,239.894Z"
          transform="translate(-241.968 -93.829)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.815,239.829c0-.188-.146-.251,0-.355a.252.252,0,0,1-.251-.209A.541.541,0,0,0,251.815,239.829Z"
          transform="translate(-241.495 -93.932)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M252.569,239.737c-.5,0-.251-.293-.355-.439S252.38,240.093,252.569,239.737Z"
          transform="translate(-243.587 -93.903)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M251.023,239.534c.23,0,.4-.209,0-.335C250.814,239.346,251.17,239.409,251.023,239.534Z"
          transform="translate(-239.7 -93.993)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M187.93,206.53c.376,0,.564-.585.606-.983C188.223,205.339,188.223,206.154,187.93,206.53Z"
          transform="translate(-45.218 -130.728)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M186.95,208.207c.314.5.125-.46.4,0a.705.705,0,0,0-.125-.4,1.4,1.4,0,0,1-.272.4Z"
          transform="translate(-41.98 -128.224)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M187.7,206.619c.1.209.209,0,.293,0a1.275,1.275,0,0,1-.23-.439C187.679,206.264,187.784,206.514,187.7,206.619Z"
          transform="translate(-44.194 -130.002)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M187.369,206.658c.167.188.439.335.334,0a.23.23,0,0,0-.334,0Z"
          transform="translate(-43.235 -129.56)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M187.129,206.364c.251.146.732.23.69,0s-.188,0-.272-.314C187.568,206.2,187.359,206.28,187.129,206.364Z"
          transform="translate(-42.828 -130.143)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M202.507,202.163c.146.5,1.129,0,.648-.543v.272C202.946,201.808,202.842,202.414,202.507,202.163Z"
          transform="translate(-90.442 -134.974)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M201.23,201.675c.125.188.251-.1.4,0C201.46,201.382,201.314,201.487,201.23,201.675Z"
          transform="translate(-86.113 -135.113)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M186.745,208.092c0,.188.146.209.23.314s-.23-.648.1-.5c.125-.167-.167,0-.23-.209S186.892,208.029,186.745,208.092Z"
          transform="translate(-41.316 -128.402)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M187.812,206.928c.251.335.293-.188.4-.314a.856.856,0,0,1-.188-.251c.293.125,0-.251.146-.418s.125.167.209.146.167-.188,0-.46c-.167.272-.167.23-.5.251l.146.251a.293.293,0,0,0-.209.23c0-.251,0-.355-.272-.418s.251,0,0-.355a2.463,2.463,0,0,1-.146.418c.272,0,.251.251.376.606,0-.23,0,0,.251-.125C188.23,206.823,187.958,206.823,187.812,206.928Z"
          transform="translate(-44.034 -130.645)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M186.549,208.289c0-.167,0-.251.251-.23s0-.251,0-.314S186.4,207.892,186.549,208.289Z"
          transform="translate(-40.639 -128.306)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M187.276,206.16c.314.251.167-.293,0-.5s.167.188.251,0C187.193,205.219,187.423,206.014,187.276,206.16Z"
          transform="translate(-42.746 -130.713)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M196.956,202.44c-.355,0-.481.167-.836.209,0,0,.167.146.251,0a.523.523,0,0,0,0,.293c.125-.418.439-.355.794-.272C197.123,202.712,196.622,202.586,196.956,202.44Z"
          transform="translate(-70.968 -134.08)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M195.955,202.578c0,.272.146-.188,0-.418a.23.23,0,0,1-.146.188C195.809,202.474,196.06,202.453,195.955,202.578Z"
          transform="translate(-69.173 -134.386)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M186.088,209.371h0v.167c-.146-.439.606-.293.23-.878-.125,0,0,.167,0,.314S186.193,209.162,186.088,209.371Z"
          transform="translate(-39.216 -127.297)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M197.38,202.614c.1,0,0-.146.125-.188s-.251-.167-.314,0,0-.125,0-.23,0,.125-.209.1,0-.125,0-.146a.624.624,0,0,1-.272-.146c-.146-.146.125.23.167.251s0,.209,0,.251.1,0,.146.125S197.192,202.447,197.38,202.614Z"
          transform="translate(-72.479 -134.589)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M201.111,201.332h0v-.1C201.111,201.144,201.153,201.437,201.111,201.332Z"
          transform="translate(-85.367 -135.419)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200,202.309s.146.146.23,0,.167,0,.167-.146.167.146.125.167.418-.293.46-.544c.188.23.125-.188.293,0s0-.146,0-.188a.466.466,0,0,1,.293-.209c.1,0-.188-.314,0-.188,0-.188-.188,0,0,.146s-.146,0-.209.209,0,0-.125-.146,0,.188-.251.125c.272.418-.314.167-.272.544C200.379,202.121,200.233,202.288,200,202.309Z"
          transform="translate(-83.548 -135.517)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200.448,203.861a.125.125,0,0,0,.188,0c0,.1,0,.125,0,.209s.23,0,.355,0c-.188-.209.4-.376.251-.732h.1a.314.314,0,0,1,0-.314c0-.335.167-.544.125-.815h-.188c-.46,0-.857,1-1.442.627,0,0,.251.125.355,0s-.293-.251-.5-.272.167,0,0-.167,0,0-.146,0c.23.23-.1.355-.188.355s0,0,.125.146,0-.188.209-.1-.209.23-.355.376c.188,0,.564.272.815.314s.209-.146.314,0,0-.167,0-.167,0,.209.251,0,0,0,.125.125-.335.1-.167.293,0,0-.1,0,.1,0,0-.167-.125,0,0,.167S200.573,203.715,200.448,203.861Z"
          transform="translate(-81.986 -134.331)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200.873,201.575h0a.223.223,0,0,0-.125-.188C200.622,201.324,200.978,201.492,200.873,201.575Z"
          transform="translate(-84.314 -135.243)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M200.138,201.4a.648.648,0,0,0,.251-.23S200.076,201.233,200.138,201.4Z"
          transform="translate(-82.575 -135.465)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M199.986,202.061c-.188-.335-.251.146-.334.272S199.965,202.207,199.986,202.061Z"
          transform="translate(-81.148 -134.621)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M199.012,202.068c0-.125-.1,0-.23-.23,0,.209-.146.23,0,.481-.209,0-.418-.125-.606-.564-.5-.209-.753.314-.732.753s.627,1.129.9,1.108.439-.983.857-.836c0-.125.355-.293.4-.544s0-.1-.167-.314-.272.209-.4,0a.23.23,0,0,0-.021.146Z"
          transform="translate(-76.181 -134.879)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M196.31,198.292c0,.418,0,.794.167.92a2.739,2.739,0,0,0,.188-.962A.355.355,0,0,1,196.31,198.292Z"
          transform="translate(-70.866 -138.649)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M180.648,208a.5.5,0,0,0,.314.5C181.171,208,180.857,208,180.648,208Z"
          transform="translate(-22.488 -128.017)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M179.352,244.62s.1.146,0,.167.481.251.732.167C179.854,244.808,179.687,244.787,179.352,244.62Z"
          transform="translate(-18.808 -88.082)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M179.406,244.12c0,.251.23.355.293.585C179.741,244.225,179.615,244.2,179.406,244.12Z"
          transform="translate(-18.569 -88.628)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M179.432,244.758c0,.1,0,0-.167,0s.23.23.251.481,0-.188,0-.188a1.2,1.2,0,0,1,.146-.481c.146-.293-.251-.209-.376-.334s-.125.188-.167.4S179.37,244.549,179.432,244.758Z"
          transform="translate(-17.948 -88.533)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M178.97,244.85c0,.335.188.146.293.23s-.146.46.188,0C179.284,244.892,179.158,245,178.97,244.85Z"
          transform="translate(-17.402 -87.831)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M178.28,245c0,.1.334.209.481.272A.522.522,0,0,0,178.28,245Z"
          transform="translate(-15.269 -87.668)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M177.883,244.939c0-.125,0,0,.23,0C178.2,244.667,177.883,244.6,177.883,244.939Z"
          transform="translate(-13.806 -87.983)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M177.52,244.232c0,.167.125,0,.272.146C177.9,244.086,177.562,244.169,177.52,244.232Z"
          transform="translate(-12.733 -88.572)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M180.969,243.29v.188c0,.167.209,0,.314,0S181.115,243.311,180.969,243.29Z"
          transform="translate(-23.435 -89.533)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M180.619,244.542c0,.1,0,.146.1.146a.336.336,0,0,1,0-.125C180.807,244.417,180.64,244.5,180.619,244.542Z"
          transform="translate(-22.144 -88.235)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M180.55,244.735c0,.1.125,0,.23.1,0-.209,0-.209-.167-.23a.146.146,0,0,1-.063.125Z"
          transform="translate(-22.034 -88.093)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M179.09,242.48c0,.334.251,0,.334.272s-.209.125-.272,0c0,.481.4-.146.293.4a.439.439,0,0,1,.293,0c0-.209-.125,0-.188-.167s0-.23.125-.481a.606.606,0,0,1-.585-.021Z"
          transform="translate(-17.94 -90.416)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M178,244.22v.376c0,.209,0,0,.125,0S178.187,244.2,178,244.22Z"
          transform="translate(-14.11 -88.519)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M182.884,245.934c-.125.46.627.46.753.293s0,.544,0,.857a5.162,5.162,0,0,0,.627-1.129c0-.251-.878-.272-.753.125C183.344,246,183.156,245.809,182.884,245.934Z"
          transform="translate(-30.368 -86.825)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M180.11,245.706c.4.334,1.15-.125,1.547-.523C181.385,244.786,180.674,245.664,180.11,245.706Z"
          transform="translate(-21.991 -87.58)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M181.14,248.576c0,.794.544-.376.418.376,0,0,.125-.314.188-.523a3.494,3.494,0,0,1-.606.146Z"
          transform="translate(-24.233 -83.927)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M180.58,246.44c0,.293.272.1.355.209a1.484,1.484,0,0,1,.146-.669C180.873,246.022,180.789,246.377,180.58,246.44Z"
          transform="translate(-22.398 -86.599)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M180.53,246.7c0,.355.188.732.334.355A.355.355,0,0,0,180.53,246.7Z"
          transform="translate(-22.077 -85.814)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M179.81,246.375c.167.355.606.878.878.481,0-.314-.272-.1,0-.585C180.458,246.563,180.124,246.479,179.81,246.375Z"
          transform="translate(-20.395 -86.283)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M178.62,246.42c-.272.69,1.3.92,1.212,0a.546.546,0,0,1-.167.376C179.415,246.441,178.871,246.88,178.62,246.42Z"
          transform="translate(-16.989 -86.119)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M177.026,247.4c0,.314.376.146.523.251C177.612,247.3,177.277,247.3,177.026,247.4Z"
          transform="translate(-11.444 -85.115)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M174.489,248.2c0,.314,0,.523.376.523C174.887,248.388,174.636,248.346,174.489,248.2Z"
          transform="translate(-3.451 -84.178)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M180.783,248.734v.564c0,.188.335-.9.564-.439.272,0-.125-.251,0-.439C181.118,248.545,180.992,248.817,180.783,248.734Z"
          transform="translate(-23.187 -83.938)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M179.68,247.025c0,.585.5,0,.711,0a.921.921,0,0,1,0-.418c.251.376.272-.272.544-.314s0,.272.1.314.376,0,.418-.481c-.439.146-.376.1-.773-.167v.4a.356.356,0,0,0-.418,0c.125-.335.209-.46,0-.69s.335.146.314-.376c-.188,0-.355.23-.543.314.314.251,0,.481,0,.962.188-.209.188,0,.376,0C180.244,247.3,179.931,247.067,179.68,247.025Z"
          transform="translate(-20.892 -87.352)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M180.575,249.082c.251-.125.293-.251.5,0,0-.251.125-.355.23-.376S180.68,248.643,180.575,249.082Z"
          transform="translate(-22.623 -83.639)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M179.42,246.259c.125.544.46-.167.564-.439,0,0,0,.334.188.334C180.173,245.214,179.713,246.259,179.42,246.259Z"
          transform="translate(-19.064 -86.837)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M174.26,247.836c.4.335,1.15-.125,1.547-.523C175.64,246.916,174.929,247.794,174.26,247.836Z"
          transform="translate(-3.911 -85.257)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M174.68,248.549c0,.293.251.125.355.23a1.484,1.484,0,0,1,.146-.669C174.973,248.152,174.868,248.507,174.68,248.549Z"
          transform="translate(-4.164 -84.276)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M174.63,248.83c0,.355.188.732.335.355a.334.334,0,0,0-.335-.355Z"
          transform="translate(-3.842 -83.491)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M173.91,248.5c.146.355.606.878.878.481,0-.314-.293-.1,0-.585C174.6,248.693,174.224,248.609,173.91,248.5Z"
          transform="translate(-2.161 -83.96)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M173.78,249.155c0,.585.523,0,.732,0a.94.94,0,0,1,0-.418c.251.376.272-.272.544-.314s0,.272.1.293.355,0,.418-.46c-.439.125-.4,0-.773-.167v.4a.355.355,0,0,0-.418,0c0-.355.209-.46,0-.711s.334.167.314-.355l-.544.314c.293.251,0,.481-.1.962.188-.209.188,0,.376,0C174.344,249.427,174.031,249.2,173.78,249.155Z"
          transform="translate(-2.679 -85.029)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M173.53,248.411c.125.544.46-.167.564-.439,0,0,0,.335.167.335C174.282,247.324,173.8,248.39,173.53,248.411Z"
          transform="translate(-0.84 -84.515)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M177.836,248.659c.188.146.544,0,.711-.251S178.108,248.638,177.836,248.659Z"
          transform="translate(-14.156 -84.031)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M177.639,249.2c0,.272.23,0,.314,0a.708.708,0,0,1,0-.188c0,.188,0-.125.251-.146s0,.125,0,.146.167,0,.188-.23-.188,0-.355,0v.188a.146.146,0,0,0-.188,0v-.314c0-.1.167,0,.146-.167l-.251.146c.146.1,0,.209,0,.439,0-.1,0,0,.167,0S177.639,249.222,177.639,249.2Z"
          transform="translate(-13.562 -83.862)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M177.557,248.922c0,.251.209,0,.272-.209s0,.167,0,.146C177.912,248.42,177.7,248.9,177.557,248.922Z"
          transform="translate(-12.853 -83.688)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M182.909,243.942c-.146,0-.418-.272-.439.188.481,0,.418.732.125,1.024,0,.146.146.167,0,.439C183.034,245.2,182.825,244.59,182.909,243.942Z"
          transform="translate(-28.177 -88.909)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M181.607,245.24c0-.418.4-.774.251-.92s-.125.564-.418.9c.146,0,.125.125.1.293.544-.209.606.335,1.233.272,0-.251-.188-.418-.355-.209.272-.5-.209-.46-.376-.941l-.125.69Z"
          transform="translate(-25.892 -88.431)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M180.965,248.383c0-.711.355-.418.523-.585C181.383,247.3,180.422,248.215,180.965,248.383Z"
          transform="translate(-23.264 -84.779)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M179.354,244.251s-.669-.1-.167.146C179.249,244.314,179.354,244.293,179.354,244.251Z"
          transform="translate(-17.367 -88.508)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M179.482,244.613a.711.711,0,0,0-.544-.293c0,.209-.1.293-.188.481a.293.293,0,0,0,.439-.167v.334a.146.146,0,0,1,.272,0C179.544,244.947,179.273,244.864,179.482,244.613Z"
          transform="translate(-16.973 -88.409)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M176.393,244.14a2.091,2.091,0,0,0,1.275,1.129v.251c0,.084.167-.125.167-.376s.188.167.334,0,0,.125,0,.272a5.624,5.624,0,0,1,2.4.773c0-.293.188-.1.188-.481a.134.134,0,0,0,.125.125c.125.021-.188,0-.209.293.272-.1.355,0,.523-.4s-.335.335-.188-.209c-1.442-.293-2.237-.376-3.867-.669-.125,0,0-.188,0-.272s-.209,0-.272,0a.418.418,0,0,1,0-.355C176.665,244.224,176.393,244.328,176.393,244.14Z"
          transform="translate(-13.801 -88.606)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M177.2,198.212c0,.188.146.125.167.251a.313.313,0,0,1-.125.1c.146.46.564.209.9.376,0-.376.794-.334.794,0s.1-.146.188-.146,0,.293.188.355a.5.5,0,0,1,0-.251c.146.125.293-.272.439-.355s.146.146.251.1,0-.209.209-.146c-.167-.209-.188-.439-.418-.481s0,.1,0,.209a.418.418,0,0,1-.5,0,.4.4,0,0,0,0,.314c0-.23-.439,0-.418-.418,0,0-.167.1-.272,0s.1,0,.209,0a.648.648,0,0,1,0,.209A10.02,10.02,0,0,1,177.2,198.212Z"
          transform="translate(-14.461 -138.904)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M181.038,243c-.1.146-.293,0-.376.251s.376-.125.439,0S181.038,243.146,181.038,243Z"
          transform="translate(-22.584 -89.849)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M174.335,247.831a7.948,7.948,0,0,0,.585.69s.125-.209.209-.23-.355,0-.146-.46C174.711,247.956,174.711,247.81,174.335,247.831Z"
          transform="translate(-3.401 -84.583)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M180.36,249.612c0,.314.376.5.439-.188C180.569,249.278,180.569,249.78,180.36,249.612Z"
          transform="translate(-21.656 -82.873)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M179.53,242.442c0,.314.376.146.523.251C180.032,242.337,179.781,242.337,179.53,242.442Z"
          transform="translate(-19.174 -90.524)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M175.827,248.163c-.4-.314-.669-.251-1.087-.523,0,.167,0,.293.167.314a.879.879,0,0,0-.146.4c.523-.334.794,0,1.129.4C175.806,248.6,175.346,248.037,175.827,248.163Z"
          transform="translate(-4.998 -84.789)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M174.156,248.381c-.209.314.355,0,.355-.481a.335.335,0,0,1-.334,0C174.094,248.109,174.407,248.36,174.156,248.381Z"
          transform="translate(-2.303 -84.505)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M175.6,247.935c.125,0,.188-.167.314-.1s-.146-.418-.334-.334.1-.146.125-.314-.209,0-.334,0,0-.167.167-.146-.125,0-.167-.376c-.1,0,0,.355,0,.418s-.167.209-.293.209.1,0,0,.251C175.387,247.643,175.533,247.58,175.6,247.935Z"
          transform="translate(-5.665 -85.858)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M179.025,242.354h0v.167c0,.167.188-.146.293-.188S178.983,242.5,179.025,242.354Z"
          transform="translate(-17.394 -90.583)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M177.95,243.12c0,.125,0,.293.167.293,0-.334.167.146.314,0s0,.314,0,.272.711,0,.983-.188c0,.418.293,0,.272.334,0,0,0-.209.125-.251.125.272.376,0,.5,0s0-.523.167-.23c0-.251-.167-.188-.23,0a.418.418,0,0,0-.418,0s0-.125,0-.251-.251.125-.4,0c0,.669-.5-.1-.773.355C178.514,243.245,178.284,243.308,177.95,243.12Z"
          transform="translate(-16.298 -89.781)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M178.349,246.43a.167.167,0,0,0,.188.167c0,.188,0,.146-.1.314s.272.209.376.334c0-.4.773,0,.941-.585h.125c.125,0,.23-.272.376-.251.272-.4.669-.439.857-.794-.125,0-.293-.125-.23,0-.585-.314-1.819.314-2.09-.606.125,0,.146.355.418.293-.146-.23-.125-.5-.334-.732s.209.125.209-.146-.125,0-.251,0c0,.439-.418.293-.523.209s0,.1,0,.251.251-.1.314,0-.439,0-.711.1c.251,0,.376.773.648,1.045s.334,0,.293.334c0,0,.1-.209.209-.146s0,.335.209.293a.376.376,0,0,1,0,.272c-.146.251-.439-.188-.439.167v-.146a.151.151,0,0,0,.146-.167c0-.167-.167,0-.188.146S178.579,246.451,178.349,246.43Z"
          transform="translate(-16.843 -88.429)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M178.813,243.064c0,.125,0,0,.167,0a.355.355,0,0,0,0-.314C178.959,242.9,178.98,243.064,178.813,243.064Z"
          transform="translate(-16.638 -90.122)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M177.98,243.163a1.274,1.274,0,0,0,.481,0S178.043,242.933,177.98,243.163Z"
          transform="translate(-14.342 -89.782)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M178.8,244.556c0-.544-.418,0-.627,0a.816.816,0,0,0,.627,0Z"
          transform="translate(-15.076 -88.416)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M177.978,246.743c0-.167-.146,0,0-.439-.1.272-.376.1-.439.5-.209-.188-.334-.481-.167-1.129-.376-.648-1.108-.314-1.484.188-.355.983-.293,1.735,0,1.965s1.338-.69,1.672-.146a1.469,1.469,0,0,0,.92-.23c.251-.23,0-.188.1-.481s-.5,0-.4-.439a.377.377,0,0,0-.209.209Z"
          transform="translate(-9.595 -87.32)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M183.532,214.2a.5.5,0,0,0,.46-.627C183.72,213.674,183.72,214.009,183.532,214.2Z"
          transform="translate(-31.497 -121.943)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M183.35,213.81c.46,0,.146-.69.543-.92C183.455,212.911,183.35,213.663,183.35,213.81Z"
          transform="translate(-31.001 -122.684)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M183.736,212.64c.481,0,.481-.355.606-.648-.334-.146-.355.46-.606.1C183.36,212.034,183.841,212.473,183.736,212.64Z"
          transform="translate(-31.952 -123.689)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M184.014,211.759c-.23-.209-.272,0-.564,0,.1.167-.1.418.314.481,0,0-.1-.146,0-.251S184.014,211.926,184.014,211.759Z"
          transform="translate(-31.331 -124.02)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M183.578,212.155c.439,0-.314-.564.209-.753C183.1,211.34,183.223,211.946,183.578,212.155Z"
          transform="translate(-30.769 -124.312)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M185.4,214.733c-.125-.251-.251-.314,0-.5a.439.439,0,0,1-.376-.251S184.857,214.607,185.4,214.733Z"
          transform="translate(-35.957 -121.496)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M183.649,216.723c.293,0,.146-.251.251-.355a1.338,1.338,0,0,1-.69-.167C183.252,216.409,183.607,216.514,183.649,216.723Z"
          transform="translate(-30.714 -119.075)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M182.557,216.541c.355,0,.753-.146.376-.314S182.578,216.332,182.557,216.541Z"
          transform="translate(-28.536 -119.102)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M182.922,216.306c.355-.125.9-.544.523-.836-.314,0-.125.272-.585,0C183.152,215.7,183.026,215.993,182.922,216.306Z"
          transform="translate(-29.653 -119.871)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M182.314,217.933c.69.293,1-1.233,0-1.213,0,.1.23.125.376.188C182.376,217.138,182.794,217.7,182.314,217.933Z"
          transform="translate(-27.895 -118.508)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M181.87,213.262c.314,0,.146-.355.272-.5C181.87,212.8,181.87,213.032,181.87,213.262Z"
          transform="translate(-26.155 -122.826)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M180.74,210.678c.314,0,.544,0,.544-.355C180.928,210.281,180.886,210.531,180.74,210.678Z"
          transform="translate(-22.935 -125.489)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
      </g>
    </g>
    <g transform="translate(350.613 112.29)">
      <path
        d="M243.8,123.5l7.275-68.548-1.756-.188L242.214,121.7l-127.082-2.446-.042,1.777Z"
        transform="translate(-110.7 -54.76)"
        fill="#58fffe"
      />
      <path
        d="M181.871,84.629l21.281-2.153,43.671-14.153-.544-1.672L202.8,80.762l-20.55,2.049-21.7-12.062-41.58-4.118-.167,1.756,41.392,4.1Z"
        transform="translate(-114.201 -41.816)"
        fill="#58fffe"
      />
      <path
        d="M117.821,69.285a4.536,4.536,0,1,0,5.038-4.955,5.08,5.08,0,0,0-5.038,4.955Z"
        transform="translate(7.798 -44.324)"
        fill="#58fffe"
      />
      <path
        d="M137.511,76.585a4.536,4.536,0,1,0,5.038-4.955A5.059,5.059,0,0,0,137.511,76.585Z"
        transform="translate(-53.054 -36.363)"
        fill="#58fffe"
      />
      <path
        d="M147.791,77.208a4.536,4.536,0,1,0,8.822,1,4.536,4.536,0,1,0-8.822-1Z"
        transform="translate(-84.343 -35.691)"
        fill="#fcba98"
      />
      <path
        d="M158.14,71.975a4.536,4.536,0,1,0,5.017-4.955,5.017,5.017,0,0,0-5.017,4.955Z"
        transform="translate(-116.811 -41.39)"
        fill="#58fffe"
      />
      <path
        d="M177.911,69.285a4.536,4.536,0,1,0,5.017-4.955,5.08,5.08,0,0,0-5.017,4.955Z"
        transform="translate(-177.91 -44.324)"
        fill="#fcba98"
      />
    </g>
    <path
      d="M118.71,61.888l48.333-1.442.815-5.435L119.5,56.452Z"
      transform="translate(315.565 57.803)"
      fill="#58fffe"
      fillRule="evenodd"
    />
    <path
      d="M85.55,98.475l48.354.209.815-5.435L86.365,93.04Z"
      transform="translate(418.026 99.275)"
      fill="#58fffe"
      fillRule="evenodd"
    />
    <path
      d="M92.52,268.145l49,2.341.815-5.435L93.314,262.71Z"
      transform="translate(395.837 284.303)"
      fill="#197de5"
      fillRule="evenodd"
    />
    <path
      d="M92.9,272.966l27.239,1.317.815-5.456L93.715,267.51Z"
      transform="translate(416.424 289.537)"
      fill="#197de5"
      fillRule="evenodd"
    />
    <g transform="translate(427.407 182.239)">
      <ellipse
        cx={49.838}
        cy={71.537}
        rx={49.838}
        ry={71.537}
        transform="matrix(-0.994, -0.112, 0.112, -0.994, 187.053, 198.827)"
        fill="#197de5"
        opacity={0.88}
      />
      <ellipse
        cx={49.838}
        cy={71.537}
        rx={49.838}
        ry={71.537}
        transform="matrix(-0.994, -0.112, 0.112, -0.994, 170.022, 180.929)"
        fill="#19e0ff"
      />
      <path
        d="M75.936,226.145c6.648,10.118,15.679,16.724,26.194,17.937,25.086,2.822,48.793-26.152,53.141-64.806s-12.355-72.123-37.337-74.945S69.56,129.981,64.9,168.049l-.125,1C62.243,191.484,66.8,212.159,75.936,226.145Z"
        transform="translate(14.26 -70.862)"
        fill="#19b8ff"
        fillRule="evenodd"
      />
      <path
        d="M139.17,174.965,122.8,103.49S94.245,116.43,86.05,167.648Z"
        transform="translate(-14.878 -71.568)"
        fill="#ff884a"
        fillRule="evenodd"
      />
      <path
        d="M101.06,207.336s-10.829-5.352-15.01-14.8l36.061-54.855Z"
        transform="translate(2.181 -34.283)"
        fill="#197de5"
        fillRule="evenodd"
      />
      <path
        d="M107.1,137.68,90.5,205.162l4.411,2.467-8.864-.23Z"
        transform="translate(17.191 -34.283)"
        fill="#199fff"
        fillRule="evenodd"
      />
      <path
        d="M94.454,107.546l-8.4-4.056,16.369,71.475L90.9,108.821Z"
        transform="translate(21.874 -71.568)"
        fill="#e67a41"
        fillRule="evenodd"
      />
      <path
        d="M97.905,192.762,132,140.854l-45.134-6.1-.125,1C84.213,158.1,88.77,178.776,97.905,192.762Z"
        transform="translate(-7.71 -37.478)"
        fill="#d49af0"
      />
      <g transform="translate(0)">
        <path
          d="M75.992,151.3a.5.5,0,0,0,.251.523C76.494,151.342,76.181,151.363,75.992,151.3Z"
          transform="translate(68.996 -19.43)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M113.4,145v.167a.585.585,0,0,0,.732.251C113.86,145.23,113.693,145.188,113.4,145Z"
          transform="translate(-47.015 -26.301)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M113.432,144.52c0,.23.167.376.209.606C113.745,144.645,113.62,144.625,113.432,144.52Z"
          transform="translate(-46.629 -26.824)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M113.467,145.162H113.3c-.167,0,.209.272.188.5s0-.167,0-.167a.794.794,0,0,1,.209-.46c.209-.251-.23-.251-.334-.376a.523.523,0,0,0-.209.355C113.2,145.371,113.425,144.932,113.467,145.162Z"
          transform="translate(-46.12 -26.671)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M113.08,145.28c0,.335.188.167.272.272s-.188.439.188,0C113.415,145.364,113.268,145.468,113.08,145.28Z"
          transform="translate(-45.754 -25.995)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M112.42,145.51s.314.251.439.334A.564.564,0,0,0,112.42,145.51Z"
          transform="translate(-43.694 -25.744)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M111.982,145.511c.125-.125,0,0,.23,0C112.358,145.281,112.044,145.177,111.982,145.511Z"
          transform="translate(-42.168 -25.976)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M111.556,144.839c0,.167.125,0,.251.188C111.932,144.734,111.619,144.8,111.556,144.839Z"
          transform="translate(-40.864 -26.516)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M73.915,151v.188c0,.167.188,0,.314,0S74.082,151,73.915,151Z"
          transform="translate(75.401 -19.758)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M73.776,152.271v.167h0S73.8,152.25,73.776,152.271Z"
          transform="translate(76.167 -18.373)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M73.677,152.486a.261.261,0,0,1,.209.146c.1-.209,0-.23-.146-.251S73.7,152.486,73.677,152.486Z"
          transform="translate(76.266 -18.254)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M106.643,143.9c.146.355.272.376.5.753,0,0,.125-.209.23-.209s-.376,0,0-.46C106.914,144.109,106.914,143.921,106.643,143.9Z"
          transform="translate(-26.147 -27.5)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M112.95,142.91c-.1.314.23,0,.293.293s-.209,0-.272,0c-.125.481.418,0,.23.418a.355.355,0,0,1,.293,0c0-.188,0-.125-.167-.188s0-.251.209-.46a.753.753,0,0,1-.585-.063Z"
          transform="translate(-45.436 -28.58)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M112.228,144.79c0,.209,0,.167-.125.376s0,0,.125.1S112.479,144.79,112.228,144.79Z"
          transform="translate(-42.436 -26.53)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M77.19,151.665c0,.314.314.544.46-.146C77.441,151.351,77.378,151.832,77.19,151.665Z"
          transform="translate(65.164 -19.231)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M76.082,153.294c-.188.46.564.544.711.4s0,.544,0,.857c.293-.188.5-.69.753-1.045s-.815-.376-.753,0C76.521,153.42,76.353,153.21,76.082,153.294Z"
          transform="translate(67.611 -17.327)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M110.854,144.25c0,.188.23.1.293,0S111.042,144.292,110.854,144.25Z"
          transform="translate(-38.72 -27.16)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M110.109,143.64a.618.618,0,0,0,.23.355C110.423,143.682,110.256,143.64,110.109,143.64Z"
          transform="translate(-36.366 -27.784)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M82.09,119.28l-.355.23c.125,0,0,.418.314.272S82.111,119.447,82.09,119.28Z"
          transform="translate(51.107 -54.349)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M145.427,107.68a.585.585,0,0,0-.293.627c.355-.209.564,0,.753-.355S145.448,108.014,145.427,107.68Z"
          transform="translate(-145.123 -66.999)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M108.6,146.651c0,.418-.146.815,0,.92a2.425,2.425,0,0,0,.23-.941.272.272,0,0,1-.23.021Z"
          transform="translate(-31.527 -24.523)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M82.166,119c-.23.983,1.108,1.589,2.32,1.275C84.027,119.857,82.689,119.92,82.166,119Z"
          transform="translate(47.98 -54.654)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M83.523,117.5c-.439.355.188.272.188.627s-.4.167-.46,0c-.251.4.481.23.753.188s0-.314.5-.481C84.3,117.542,83.858,117.625,83.523,117.5Z"
          transform="translate(45.744 -56.29)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.367,115.86c-.4.209-.334.69.188.334Z"
          transform="translate(34.521 -58.078)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.451,137.27c-.23.188.125.481.272.481C79.037,137.437,78.451,137.5,78.451,137.27Z"
          transform="translate(61.52 -34.73)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M86.892,116.51a.564.564,0,0,0,0,.627C87.414,116.928,86.913,116.719,86.892,116.51Z"
          transform="translate(35.603 -57.369)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.468,117.363c-.4-.188-.209.732.209.334C87.719,117.426,87.154,117.614,87.468,117.363Z"
          transform="translate(34.107 -56.467)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.2,117.57c-.314.167,0,.857.314.564C87.242,118.009,87.43,117.842,87.2,117.57Z"
          transform="translate(34.646 -56.213)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M86.549,119.191c-.125,0,0,.188-.167.272.418,0,.376,0,.564.125C87.218,119.3,86.717,119.17,86.549,119.191Z"
          transform="translate(36.572 -54.448)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M74.152,135.74c-.5.209,0,.418,0,.627C74.486,136.158,74.152,135.949,74.152,135.74Z"
          transform="translate(75.331 -36.399)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M98.613,88.22a.334.334,0,0,0,0,.418c.209-.125.293,0,.5-.209V88.22C98.76,88.45,98.7,88.45,98.613,88.22Z"
          transform="translate(-0.933 -88.22)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M96.414,92.49c-.481.209,0,.418,0,.627C96.79,92.908,96.5,92.7,96.414,92.49Z"
          transform="translate(6.471 -83.564)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.677,134.74c0,.523.857.794,1.422.815C81.2,135.1,80.074,135.137,79.677,134.74Z"
          transform="translate(56.511 -37.489)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M80.84,135.31c-.272.146,0,.272,0,.418a1.3,1.3,0,0,1,.585-.355C81.447,135.268,81.112,135.435,80.84,135.31Z"
          transform="translate(54.009 -36.868)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.445,135.74c-.251.251-.418.627,0,.5a.355.355,0,0,0,0-.5Z"
          transform="translate(52.903 -36.399)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M80.481,135.84c-.167.355-.251,1.024.23.962s-.125-.272.4-.418C80.753,136.425,80.628,136.174,80.481,135.84Z"
          transform="translate(55.1 -36.29)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.855,136.69c-.69.251.167,1.589.836.9a.7.7,0,0,0-.4.125C80.336,137.338,79.625,137.192,79.855,136.69Z"
          transform="translate(57.085 -35.363)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.416,138.73c-.251.188.146.376.146.564C79.834,139.044,79.667,138.855,79.416,138.73Z"
          transform="translate(58.631 -33.138)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.958,135.8c-.439.4.293.439.481.564a.857.857,0,0,1,.314-.293c-.125.439.376,0,.585.209s-.209.188-.146.272.272.251.627,0c-.4-.23-.334-.23-.4-.69l-.355.209a.335.335,0,0,0-.314-.272c.334-.146.481-.146.564-.418s0,.355.481,0l-.606-.209c0,.4-.314.376-.794.564.293,0,.125.167.209.334C80.146,136.4,80.1,136.006,79.958,135.8Z"
          transform="translate(55.498 -37.02)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.8,136c-.314.46.439.23.711.146s-.251.23-.125.334C81.095,135.77,80.049,136.125,79.8,136Z"
          transform="translate(56.89 -36.115)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M73.114,148.175a.355.355,0,0,0,.209.376c0-.251.188-.251.251-.5h-.23C73.385,148.238,73.323,148.3,73.114,148.175Z"
          transform="translate(77.77 -22.975)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.541,139.67c-.4.209-.314.69.188.334Z"
          transform="translate(71.057 -32.113)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M83.588,132.35a.543.543,0,0,0,0,.564C83.985,132.705,83.86,132.35,83.588,132.35Z"
          transform="translate(45.763 -40.096)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M86.647,134.271a.334.334,0,0,1-.146.272c.418,0,.355,0,.544.146C87.316,134.376,86.836,134.25,86.647,134.271Z"
          transform="translate(36.223 -38.003)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M109.409,142.16c-.209.481.314.293.439.439C109.953,142.223,109.618,142.264,109.409,142.16Z"
          transform="translate(-34.307 -29.398)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.115,140.478s.188-.167.314-.272c-.209-.46-1.192-.272-1.024-.962.293-.125.209.376.481.272s-.272-.209-.188-.606c-.46.188-.293.167-.753.355.188.648.355.439.69,1.233.293-.46.4.272.564.125-.5.251-.251.5.23.418,0,.251-.376,0-.439.335.4,0,.272.9.753.481,0,.1,0,.1.188,0s-.606.188-.355.5c.272-.481.418,0,.627,0,.5-.773-.544-1.359-.5-2.091A2.949,2.949,0,0,0,87.115,140.478Z"
          transform="translate(36.173 -32.942)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M73.29,153.338c.355.4,1.15,0,1.589-.314C74.649,152.607,73.854,153.38,73.29,153.338Z"
          transform="translate(76.088 -17.685)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M74.74,156.194c-.188.773.585-.335.355.418.125,0,.188-.314.251-.5Z"
          transform="translate(72.653 -14.185)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M73.86,154.137c0,.293.251.146.334.272a1.484,1.484,0,0,1,.209-.669C74.111,153.782,74.111,154.116,73.86,154.137Z"
          transform="translate(75.372 -16.77)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M73.91,154.45c0,.355,0,.753.293.376A.355.355,0,0,0,73.91,154.45Z"
          transform="translate(75.469 -15.995)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M73.16,154.13c.125.355.5.92.815.564,0-.314-.272-.125,0-.564C73.766,154.381,73.474,154.255,73.16,154.13Z"
          transform="translate(77.264 -16.344)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M112.924,146.84c-.355.669,1.171,1.087,1.212.125a3.52,3.52,0,0,0-.209.376C113.718,146.965,113.112,147.342,112.924,146.84Z"
          transform="translate(-45.911 -24.294)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M111.458,148c0,.314.355.188.481.314C111.98,148,111.73,148,111.458,148Z"
          transform="translate(-40.766 -23.029)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M109.039,149.15c0,.314,0,.544.314.564C109.457,149.359,109.185,149.317,109.039,149.15Z"
          transform="translate(-33.142 -21.775)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M91.842,121.44c-.4.355.376.544.5.9.314-.167-.334-.314.188-.481C91.968,121.921,92.156,121.691,91.842,121.44Z"
          transform="translate(19.886 -51.993)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M74.365,156.422a1.15,1.15,0,0,0,0,.544c.188,0,.418-.857.606-.355.272,0,0-.272,0-.46S74.574,156.526,74.365,156.422Z"
          transform="translate(73.697 -14.183)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M72.99,154.686c0,.585.5.146.711.1a1.066,1.066,0,0,1,0-.418c.188.418.293-.23.564-.251s0,.293,0,.314a.488.488,0,0,0,.481-.418c-.46,0-.4,0-.753-.251l-.125.4a.335.335,0,0,0-.418,0c.146-.334.251-.439.146-.69s.314.188.355-.314l-.585.23c.272.293,0,.5-.23.962.23-.209.209,0,.376.125C73.513,155.021,73.241,154.749,72.99,154.686Z"
          transform="translate(76.848 -17.402)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M74.175,156.783c.251-.1.334-.23.5,0a.35.35,0,0,1,.272-.355C75.053,156.407,74.321,156.344,74.175,156.783Z"
          transform="translate(74.158 -13.855)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M72.7,154.1c0,.564.481-.125.627-.355,0,.1,0,.314.125.335C73.58,153.116,72.994,154.12,72.7,154.1Z"
          transform="translate(78.725 -16.856)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M73.05,148.512c0,.4.293-.167.188.23a.385.385,0,0,1,.125-.272Z"
          transform="translate(78.105 -22.517)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M72.831,148.638a.815.815,0,0,0,0,.293s.23-.46.334-.209,0-.125,0-.23S72.831,148.7,72.831,148.638Z"
          transform="translate(78.742 -22.517)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.155,137.53c0,.523.857.773,1.4.815C79.681,137.885,78.553,137.927,78.155,137.53Z"
          transform="translate(61.23 -34.447)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M85.507,138.08c-.648.46.648.146,0,.544,0,0,.335-.125.523-.209Z"
          transform="translate(39.789 -33.847)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.251,138.1c-.272.146,0,.272,0,.418a1.5,1.5,0,0,1,.606-.355C79.752,138.058,79.418,138.2,79.251,138.1Z"
          transform="translate(58.902 -33.825)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.929,138.53c-.251.251-.439.627,0,.481a.355.355,0,0,0,0-.481Z"
          transform="translate(57.618 -33.356)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.946,138.62c-.146.355-.23,1.045.23.983s0-.272.4-.418C79.218,139.226,79.092,138.934,78.946,138.62Z"
          transform="translate(59.813 -33.258)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.364,139.48c-.711.251.167,1.589.815.878a.632.632,0,0,0-.4.146C78.887,140.086,78.176,139.961,78.364,139.48Z"
          transform="translate(61.732 -32.32)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.331,137.42c-.251.188.146.355.146.544C81.728,137.713,81.582,137.545,81.331,137.42Z"
          transform="translate(52.724 -34.567)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M73.135,145.76c0,.314,0,.523.314.544C73.511,145.969,73.281,145.927,73.135,145.76Z"
          transform="translate(77.832 -25.472)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M85.583,138.37c-.272.125-.293.23-.439.355s.9-.355.711.125c.251.146,0-.251.293-.334C85.938,138.475,85.667,138.6,85.583,138.37Z"
          transform="translate(40.068 -33.531)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.428,138.576c-.439.4.293.439.481.544a.836.836,0,0,1,.314-.272c-.125.439.376,0,.585.209s-.209.188-.167.272.293.251.648,0c-.4-.251-.334-.23-.4-.69l-.355.209a.355.355,0,0,0-.314-.272c.334-.146.46-.167.564-.418s0,.334.46,0l-.585-.188c0,.4-.334.355-.794.564.293,0,0,.167.188.334C78.616,139.182,78.574,138.785,78.428,138.576Z"
          transform="translate(60.226 -33.967)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M85.133,138.724c.272.1.4,0,.355.355.209-.125.334-.146.439,0S85.551,138.494,85.133,138.724Z"
          transform="translate(40.267 -33.194)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.288,138.75c-.314.46.439.23.711.125s-.23.251-.125.355C79.543,138.6,78.518,138.938,78.288,138.75Z"
          transform="translate(61.578 -33.116)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M82.349,136.58c-.5.585,0,.836.355,1.171C83.1,137.207,82.287,137.061,82.349,136.58Z"
          transform="translate(49.657 -35.483)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M105.41,145.851c0,.209,0,.46.167.5s.146-.523.293-.941C105.515,145.266,105.724,145.851,105.41,145.851Z"
          transform="translate(-22.05 -25.877)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M103.049,146.34c0,.314.355.188.481.314C103.655,146.131,103.258,146.465,103.049,146.34Z"
          transform="translate(-14.797 -24.839)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M80.388,139.24c-.188.125-.188.794.272.481C80.388,139.6,80.514,139.512,80.388,139.24Z"
          transform="translate(55.715 -32.582)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.464,123c-.439.209.167.627.523.355C89.923,123,89.568,123.272,89.464,123Z"
          transform="translate(27.491 -50.292)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M80.073,139.63c-.167.836,0,.69.376,1.526C80.8,140.654,80.345,140.153,80.073,139.63Z"
          transform="translate(56.344 -32.157)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.515,140.875c.648,0,.606.523.878.962C80.665,141.377,80.6,140.457,79.515,140.875Z"
          transform="translate(57.425 -30.914)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M85.846,122.75c-.293,0-.439.355-.188.481S85.512,122.917,85.846,122.75Z"
          transform="translate(39.533 -50.565)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.655,143a.481.481,0,0,0,0,.9C82.073,143.376,81.363,143.355,81.655,143Z"
          transform="translate(52.337 -28.482)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M80.029,142.286s0,.293-.209.209c-.251.251.272.251.523,0a.314.314,0,0,0,0-.355C80.175,142.307,80.092,142.328,80.029,142.286Z"
          transform="translate(57.057 -29.419)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.684,144c-.293,0,0,.5.23.418s0,0,0-.146v-.125C81.914,144.021,81.663,144.334,81.684,144Z"
          transform="translate(51.66 -27.391)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.472,124.49c-.314,0,.209.544.272.753C84.994,124.845,84.576,124.887,84.472,124.49Z"
          transform="translate(42.977 -48.667)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.6,123.462a.388.388,0,0,0,.146.293C82.251,123.525,82.021,123.149,81.6,123.462Z"
          transform="translate(51.553 -49.918)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.513,124.13c-.585.523.418.376.418.753C82.1,124.632,81.722,124.151,81.513,124.13Z"
          transform="translate(52.186 -49.06)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M95.983,122.18a.481.481,0,0,0,.125.753C96.233,122.682,95.983,122.431,95.983,122.18Z"
          transform="translate(7.655 -51.186)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.08,141.65c0,.46.669.209.878.606C81.958,141.817,81.226,141.671,81.08,141.65Z"
          transform="translate(52.724 -29.954)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M80.136,141.512c0,.481.314.5.606.648.167-.314-.439-.4,0-.606C80.7,141.2,80.3,141.638,80.136,141.512Z"
          transform="translate(55.884 -30.213)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M80.333,142.226c.23-.209,0-.272,0-.544-.167,0-.4-.146-.5.272a.272.272,0,0,1,.272,0S80.082,142.226,80.333,142.226Z"
          transform="translate(56.858 -29.945)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.574,142.139c0,.46.606-.272.753.272C80.431,141.7,79.825,141.784,79.574,142.139Z"
          transform="translate(57.491 -29.711)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M85.994,133.55c-.481.23,0,.606.314.272a.355.355,0,0,1-.314-.272Z"
          transform="translate(38.529 -38.787)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M80.528,134.4c.188,1.129,1.129,1.8,1.756,2.8C82.973,135.508,80.716,135.654,80.528,134.4Z"
          transform="translate(53.423 -37.86)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M107.382,144.29c-.167.439.209.732.376.125Z"
          transform="translate(-27.973 -27.075)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M106.627,144.59a.523.523,0,0,0,.439.439C107.275,144.548,106.752,144.736,106.627,144.59Z"
          transform="translate(-25.838 -26.748)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M106.607,145.51c-.439.125.314.69.355.125C106.837,145.385,106.544,145.907,106.607,145.51Z"
          transform="translate(-25.358 -25.744)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M83.2,136.766c.355-.209,0-.606-.188-.606s-.125.23-.272.355C83.009,136.369,83.2,136.348,83.2,136.766Z"
          transform="translate(47.868 -35.941)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.8,140.32c0,.167-.188.92.334.627C79.866,140.738,79.949,140.654,79.8,140.32Z"
          transform="translate(57.283 -31.404)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M80.925,133c0,.146.669.669.439,1.024C81.865,133.9,81.176,133,80.925,133Z"
          transform="translate(53.465 -39.387)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.23,130.033c0,.209,0,.523.355.544,0-.355.314-.23.4-.606C79.69,130.263,79.523,130.367,79.23,130.033Z"
          transform="translate(58.567 -42.691)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M74.467,136.839c0-.293-.5,0-.585-.209-.251.209.125.5.439.209,0,.1,0,.23-.293.334C74.342,137.4,74.425,137.006,74.467,136.839Z"
          transform="translate(75.434 -35.428)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M110.123,142.535c-.167.293.606,0,.857.146C110.9,142.221,110.374,142.6,110.123,142.535Z"
          transform="translate(-36.965 -29.083)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M83.79,136.24a1.066,1.066,0,0,0,.439.753.92.92,0,0,0-.439-.753Z"
          transform="translate(44.788 -35.854)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M106.5,140.458c.355.376,1.15,0,1.61-.334C107.88,139.705,107.064,140.5,106.5,140.458Z"
          transform="translate(-26.568 -31.751)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M107.944,143.283c-.167.794.585-.314.376.439.1,0,.167-.314.251-.5A2.507,2.507,0,0,1,107.944,143.283Z"
          transform="translate(-29.999 -28.242)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M107.128,141.55c0,.355,0,.753.293.4A.355.355,0,0,0,107.128,141.55Z"
          transform="translate(-27.197 -30.063)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M106.38,141.23c.125.376.5.941.815.585,0-.314-.251-.146,0-.585C107.007,141.5,106.694,141.376,106.38,141.23Z"
          transform="translate(-25.403 -30.412)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M105.2,141.45c-.334.669,1.192,1.087,1.212.125a3.52,3.52,0,0,0-.209.376C105.992,141.575,105.386,141.952,105.2,141.45Z"
          transform="translate(-22.047 -30.172)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M107.588,143.529c-.125.251,0,.355,0,.544s.439-.857.627-.376c.272,0,0-.272,0-.439S107.776,143.633,107.588,143.529Z"
          transform="translate(-28.953 -28.237)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M106.2,141.8c0,.585.5.125.732.1a.836.836,0,0,1,0-.439c.188.418.293-.209.585-.23s0,.272,0,.314.355,0,.46-.418c-.46,0-.4,0-.732-.272l-.146.4a.334.334,0,0,0-.4,0c.146-.314.23-.418.125-.669s.314.188.355-.314l-.585.23c.293.293,0,.5-.209.941.209-.188.188,0,.376.146C106.744,142.11,106.451,141.8,106.2,141.8Z"
          transform="translate(-25.809 -31.459)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M107.383,143.875c.251-.1.314-.209.481,0a.324.324,0,0,1,.293-.335C108.282,143.52,107.53,143.436,107.383,143.875Z"
          transform="translate(-28.476 -27.914)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M105.914,141.244c0,.544.481-.125.627-.376,0,.125,0,.334.146.334C106.792,140.22,106.207,141.244,105.914,141.244Z"
          transform="translate(-23.934 -30.907)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M85.59,144.726c.564-.146.836-1,.857-1.526C85.924,143.2,86.008,144.245,85.59,144.726Z"
          transform="translate(38.807 -28.264)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.34,123c.5.5.146-.648.585-.125a1.27,1.27,0,0,0-.23-.439,2.967,2.967,0,0,1-.355.564Z"
          transform="translate(52.213 -50.903)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M85.19,144.678c.146.209.293-.146.439-.167a1.088,1.088,0,0,1-.376-.481C85.127,144.218,85.315,144.49,85.19,144.678Z"
          transform="translate(40.461 -27.358)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.675,144.542c.272.188.669.251.523,0a.46.46,0,0,0-.523,0Z"
          transform="translate(41.938 -26.888)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.348,144.312c.376,0,1.087,0,1.024-.46-.293,0-.293.167-.439-.272C84.975,143.894,84.662,144.1,84.348,144.312Z"
          transform="translate(42.474 -27.849)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M83.38,143.633c.272.606,1.672-.544.941-.983a.483.483,0,0,0,.146.334C84.028,143.005,83.9,143.7,83.38,143.633Z"
          transform="translate(45.344 -28.863)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.045,122.764c.125.23.251.23.376.334s-.4-.753.125-.711c.146-.251-.272,0-.376-.167C81.15,122.408,81.275,122.638,81.045,122.764Z"
          transform="translate(53.177 -51.143)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M85.448,145.106c.418.314.46-.376.585-.585a.564.564,0,0,1-.293-.23c.46,0,0-.355.209-.585s.188.146.293,0,.272-.314,0-.585c-.251.439-.23.376-.732.544l.23.272a.46.46,0,0,0-.293.376c-.167-.272-.167-.418-.46-.418s.376-.209,0-.46a2.086,2.086,0,0,1-.209.606c.418-.1.376.23.585.606,0-.272.188-.146.376-.251C86.1,144.792,85.678,144.96,85.448,145.106Z"
          transform="translate(40.558 -28.351)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M80.857,122.924c0-.251,0-.376.355-.418a.293.293,0,0,1,0-.376S80.71,122.611,80.857,122.924Z"
          transform="translate(53.993 -51.241)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.47,143.836c.5.188.23-.46.146-.69s.251.167.355,0C84.3,142.644,84.679,143.585,84.47,143.836Z"
          transform="translate(42.624 -28.481)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M108.63,148.669c.355.376,1.15,0,1.589-.335C109.989,147.917,109.194,148.69,108.63,148.669Z"
          transform="translate(-33.13 -22.799)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M95.357,120.31c-.648.46.648.146,0,.564,0,0,.334-.125.5-.209a2.863,2.863,0,0,1-.5-.355Z"
          transform="translate(9.368 -53.225)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M109.219,149.447c-.1.293.251.146.335.272a1.484,1.484,0,0,1,.209-.669C109.574,149.092,109.428,149.426,109.219,149.447Z"
          transform="translate(-33.866 -21.884)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M109.25,149.76c0,.355,0,.753.293.376a.355.355,0,0,0-.293-.376Z"
          transform="translate(-33.75 -21.11)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M108.5,149.44c.125.376.5.92.815.564,0-.314-.272-.125,0-.564C109.106,149.691,108.814,149.565,108.5,149.44Z"
          transform="translate(-31.955 -21.459)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M92.224,120.61c-.711.251.167,1.589.815.9a.7.7,0,0,0-.4.125C92.747,121.237,92.036,121.112,92.224,120.61Z"
          transform="translate(18.898 -52.898)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M91.751,122.65c-.251.188.146.376.146.564C92.148,122.964,92,122.775,91.751,122.65Z"
          transform="translate(20.521 -50.674)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.99,140.23c0,.314,0,.544.314.564C76.283,140.46,76.115,140.4,75.99,140.23Z"
          transform="translate(69.019 -31.502)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M70.2,123.558c0,.523.669.125.983.293.125-.335-.46,0-.188-.481C70.661,123.788,70.6,123.516,70.2,123.558Z"
          transform="translate(86.222 -49.888)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M95.433,120.61c-.272.125-.293.23-.439.355s.9-.376.711.125c.23.146,0-.272.272-.355C95.788,120.715,95.517,120.819,95.433,120.61Z"
          transform="translate(9.647 -52.898)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M108.33,150c0,.585.5.146.711.1a1.129,1.129,0,0,1,0-.418c.188.418.293-.23.585-.251s0,.293,0,.314.355,0,.46-.418c-.46,0-.4,0-.753-.251l-.125.4a.334.334,0,0,0-.418,0c.146-.334.251-.418.146-.69s.314.188.355-.314l-.585.251c.272.272,0,.481-.209.941.209-.209.188,0,.355.125C108.874,150.331,108.581,150.059,108.33,150Z"
          transform="translate(-32.37 -22.517)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M94.973,120.965c.251,0,.376,0,.355.335.209,0,.334-.125.439,0S95.37,120.735,94.973,120.965Z"
          transform="translate(9.856 -52.563)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M108.036,149.409c0,.564.481-.125.627-.355,0,.1,0,.314.146.335C108.893,148.426,108.329,149.43,108.036,149.409Z"
          transform="translate(-30.488 -21.971)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M112.351,149.1c.167.188.523,0,.732-.146S112.6,149.1,112.351,149.1Z"
          transform="translate(-43.813 -22.036)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M80.617,140.61c-.648.46.648.146,0,.544,0,0,.335-.125.5-.209Z"
          transform="translate(54.922 -31.088)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.371,139.53c-.272.125,0,.272,0,.4a1.568,1.568,0,0,1,.606-.334C78.873,139.467,78.538,139.635,78.371,139.53Z"
          transform="translate(61.621 -32.266)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.049,140c-.251.251-.439.627,0,.481a.355.355,0,0,0,0-.481Z"
          transform="translate(60.337 -31.753)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.066,140.05c-.146.355-.23,1.024.23.962s0-.272.4-.418C78.358,140.656,78.212,140.343,78.066,140.05Z"
          transform="translate(62.532 -31.699)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.495,139.41c-.69.251.167,1.568.836.878a.632.632,0,0,0-.4.146C80.017,140.016,79.307,139.891,79.495,139.41Z"
          transform="translate(58.198 -32.397)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.059,143.309c0,.314.355.188.481.314C81.561,143.267,81.331,143.225,81.059,143.309Z"
          transform="translate(53.184 -28.185)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.138,126.93c0,.293,0,.523.334.523C75.514,127.076,75.263,127.076,75.138,126.93Z"
          transform="translate(71.627 -46.006)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M72.671,127.868c0,.523.669.125.983.293.125-.334-.46,0-.188-.481C73.131,128.1,72.985,127.826,72.671,127.868Z"
          transform="translate(78.588 -45.188)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M80.693,140.9c-.272.125-.293.23-.439.355s.9-.355.711.125c.23.146.1-.251.272-.334C81.048,141.109,80.777,141.109,80.693,140.9Z"
          transform="translate(55.201 -30.772)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M112.227,149.763c0,.272.23,0,.334,0a.71.71,0,0,1,0-.188c0,.188.125-.1.272-.1s0,.125,0,.146.167,0,.209-.209-.167,0-.334-.1v.167a.167.167,0,0,0-.188,0c0-.167,0-.209,0-.314s.146,0,.167-.146l-.272.1c.125.125,0,.23,0,.439,0-.1,0,0,.167,0S112.332,149.8,112.227,149.763Z"
          transform="translate(-43.48 -21.928)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M80.233,141.254c.251.1.376,0,.355.355.209-.125.334-.146.439,0S80.63,141.024,80.233,141.254Z"
          transform="translate(55.41 -30.435)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M112.117,149.473c0,.251.23,0,.293-.167s0,.146,0,.167C112.514,149.034,112.242,149.473,112.117,149.473Z"
          transform="translate(-42.638 -21.659)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M68.469,168.791c-.188.272.606-.209.69.188,0-.439.293-.46.481-.564,0-.523-.355-.209-.648-.335C69.326,168.5,68.761,168.644,68.469,168.791Z"
          transform="translate(91.467 -1.132)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.95,151.521c-.146,0-.376-.334-.46.125.5,0,.334.773,0,1.024,0,.146.146.209,0,.46C75.95,152.776,75.8,152.127,75.95,151.521Z"
          transform="translate(70.418 -19.317)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M74.767,152.842c0-.418.481-.732.355-.9s-.188.564-.523.836c.146,0,.125.167,0,.334.564-.146.585.4,1.213.4,0-.251-.167-.418-.334-.251.334-.46-.146-.481-.272-.983a2.746,2.746,0,0,1-.209.669Z"
          transform="translate(72.416 -18.756)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M74.424,156.068c0-.711.376-.376.585-.544C74.967,155.023,73.9,155.818,74.424,156.068Z"
          transform="translate(73.888 -15.001)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M113.353,144.729s-.648-.188-.188.125a.377.377,0,0,1,.188-.125Z"
          transform="translate(-45.4 -26.657)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M113.553,145.185c-.125-.167-.23-.335-.5-.355,0,.23-.146.293-.251.439a.272.272,0,0,0,.439-.1s0,.1,0,.335a.167.167,0,0,1,.272,0S113.323,145.29,113.553,145.185Z"
          transform="translate(-45.182 -26.486)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M110.5,144.63a2.091,2.091,0,0,0,1.15,1.275v.272c0,.1.188-.125.23-.376.188,0,.167.188.334,0a.251.251,0,0,1,0,.272,5.979,5.979,0,0,1,2.32,1.066c0-.293.188,0,.251-.439v.125a.324.324,0,0,0-.251.272c.272,0,.335,0,.564-.334s-.355.272-.167-.251c-1.38-.439-2.091-.627-3.742-1.108-.146,0,0-.188,0-.293s-.209,0-.272,0a.376.376,0,0,1,0-.355S110.5,144.839,110.5,144.63Z"
          transform="translate(-41.987 -26.704)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M72.74,151.83c0,.167.125.125.146.251H72.74c0,.481.544.293.836.481,0-.376.836-.23.794.146s.125-.125.188-.125,0,.293.146.376a.627.627,0,0,1,0-.251c.125.146.314-.23.481-.314s.125.167.23.146,0-.209.23-.125c-.146-.23-.146-.46-.376-.523s0,0,0,.209a.418.418,0,0,1-.5,0,.335.335,0,0,0-.1.293c0-.23-.439,0-.376-.46H74.2a.648.648,0,0,1,0,.209,13.49,13.49,0,0,1-1.463-.314Z"
          transform="translate(76.471 -18.852)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M74,150.81c-.125.146-.293,0-.4.188s.376,0,.439,0S74,150.873,74,150.81Z"
          transform="translate(76.275 -19.965)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M108.187,144.338c-.376-.355-.627-.314-1.024-.648,0,.167,0,.314.125.334a1.046,1.046,0,0,0-.188.376c.544-.272.794,0,1.066.523C108.1,144.673,107.706,144.045,108.187,144.338Z"
          transform="translate(-27.9 -27.729)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M106.511,144.479c-.251.293.355,0,.4-.439a.314.314,0,0,1-.334,0C106.469,144.228,106.741,144.479,106.511,144.479Z"
          transform="translate(-25.262 -27.347)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.446,117.25c-.188.272-.125.606.418.209C87.843,117.187,87.467,117.5,87.446,117.25Z"
          transform="translate(33.71 -56.562)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M108.837,148.78c.167.335.272.355.5.753,0,0,.146-.209.23-.209.146-.314-.355,0,0-.481C109.255,148.947,109.255,148.8,108.837,148.78Z"
          transform="translate(-32.94 -22.178)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M74.05,157.328c0,.314.314.544.46-.125C74.3,157.014,74.238,157.516,74.05,157.328Z"
          transform="translate(74.869 -13.041)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.01,138.66c0,.523.857.773,1.422.815C88.452,138.994,87.407,139.057,87.01,138.66Z"
          transform="translate(33.854 -33.214)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M106.58,143.944c-.188.773.564-.335.355.418,0,0,.167-.314.251-.5Z"
          transform="translate(-25.75 -27.544)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M105.74,141.887c0,.293.251.146.334.272a1.8,1.8,0,0,1,.209-.669C106.158,141.532,106.012,141.866,105.74,141.887Z"
          transform="translate(-23.153 -30.128)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M105.758,142.2c0,.355,0,.753.272.376A.334.334,0,0,0,105.758,142.2Z"
          transform="translate(-22.942 -29.354)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M105.01,141.88c0,.355.481.92.794.564,0-.314-.251-.125,0-.564C105.616,142.131,105.3,142.005,105.01,141.88Z"
          transform="translate(-21.148 -29.703)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M73.874,149.13c-.355.648,1.171,1.066,1.212.125-.1,0-.146.209-.209.355C74.71,149.255,74.062,149.632,73.874,149.13Z"
          transform="translate(74.773 -21.797)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M113.349,142.8c0,.314.355.188.481.314C113.851,142.763,113.621,142.742,113.349,142.8Z"
          transform="translate(-46.609 -28.725)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M106.187,144.161a1.527,1.527,0,0,0,0,.544c.188,0,.439-.857.627-.355.272,0,0-.272,0-.46S106.4,144.287,106.187,144.161Z"
          transform="translate(-24.687 -27.553)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M104.83,142.436c0,.585.481.146.711.1a.753.753,0,0,1,0-.418c.209.418.293-.23.585-.251s0,.293,0,.314a.461.461,0,0,0,.46-.418.786.786,0,0,1-.732-.251l-.146.4a.314.314,0,0,0-.4,0c.146-.335.23-.439.125-.69s.314.188.355-.314l-.564.23c.272.293,0,.5-.23.962.209-.209.188,0,.376.125C105.353,142.771,105.06,142.5,104.83,142.436Z"
          transform="translate(-21.554 -30.761)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M106.013,144.533c.251-.1.314-.23.481,0a.347.347,0,0,1,.293-.355C106.912,144.157,106.16,144.094,106.013,144.533Z"
          transform="translate(-24.242 -27.214)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.1,139.88c-.293.46.46.209.711.125s-.23.23,0,.355C88.417,139.713,87.33,140.131,87.1,139.88Z"
          transform="translate(34.245 -31.884)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M110.358,149.168c-.376-.355-.648-.314-1.045-.648,0,.167,0,.293.146.334a1.046,1.046,0,0,0-.209.376c.564-.272.815,0,1.087.5C110.274,149.523,109.877,148.98,110.358,149.168Z"
          transform="translate(-34.566 -22.462)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M108.672,149.37c-.251.293.335,0,.376-.46a.314.314,0,0,1-.335,0C108.61,149.1,108.9,149.37,108.672,149.37Z"
          transform="translate(-31.918 -22.037)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.215,135.62a2.5,2.5,0,0,1-.125.9H81.4c.063,0-.23-.272.251-.418C81.383,135.975,81.487,135.871,81.215,135.62Z"
          transform="translate(53.007 -36.53)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M82.248,136.231c0-.523-.272-.669-.355-1.171-.125.1-.188.23-.125.334a1.045,1.045,0,0,0-.4.146c.606.167.544.606.481,1.108C81.893,136.482,82.018,135.771,82.248,136.231Z"
          transform="translate(51.828 -37.14)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.052,136.267c-.376,0,.293.188.606,0a.293.293,0,0,1-.272-.209C81.177,135.954,81.24,136.414,81.052,136.267Z"
          transform="translate(53.317 -36.069)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M106.39,145.887v.418c.314-.564.9.314,1.129-.627-.146-.125-.209.125-.272.335S106.6,145.866,106.39,145.887Z"
          transform="translate(-25.748 -25.598)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M109.869,148.882c.125,0,.188-.125.314,0s0-.418-.272-.376,0-.146.146-.293-.209,0-.314-.1,0-.146.188-.125-.146,0-.125-.376,0,.334,0,.4-.188.188-.314.167,0,.125,0,.272S109.848,148.548,109.869,148.882Z"
          transform="translate(-34.829 -23.577)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M113.052,142.793h0l-.125.167c-.125.167.209-.125.314-.167S113.052,142.94,113.052,142.793Z"
          transform="translate(-45.099 -28.714)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M111.824,143.54c0,.125,0,.293.125.314,0-.355.167.167.335,0s0,.293,0,.251.711.146,1,0c0,.418.293,0,.23.334,0,0,0-.188.146-.209a4,4,0,0,1,.5,0c.1,0,.125-.5.188-.209.125-.23-.146-.188-.251,0h-.418c-.167,0,0-.125,0-.272s-.272,0-.376-.125c-.146.669-.481-.146-.815.251C112.368,143.749,112.075,143.77,111.824,143.54Z"
          transform="translate(-43.976 -27.893)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M112.323,146.8a.188.188,0,0,0,.167.209c0,.167,0,.125-.125.293s.23.23.334.376c0-.4.774,0,.983-.46a.209.209,0,0,0,.125.1.4.4,0,0,1,.4-.209c.314-.355.732-.334.941-.669-.125,0-.272-.167-.209-.146-.544-.376-1.84.1-2.091-.857.146,0,.125.376.4.355-.125-.251,0-.523-.251-.774s.209.146.23-.125-.125,0-.23,0c0,.46-.46.251-.544.146s0,.125,0,.272.272,0,.314,0c-.293.376-.439,0-.732,0,.23.146.293.836.523,1.129.146,0,.334,0,.251.355s.146-.188.23-.125,0,.335.167.335,0,.146,0,.251-.418-.23-.439.125v-.167c0-.021.146,0,.146-.146s-.146,0-.188.146C112.469,147.151,112.532,146.858,112.323,146.8Z"
          transform="translate(-45.164 -26.549)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M112.676,143.544c0,.125.125,0,.188,0a.439.439,0,0,0,0-.314C112.843,143.4,112.843,143.564,112.676,143.544Z"
          transform="translate(-44.263 -28.231)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M111.87,143.718a1.108,1.108,0,0,0,.481,0S111.954,143.509,111.87,143.718Z"
          transform="translate(-42.036 -27.799)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M112.836,145.121c.146-.523-.418,0-.627,0Z"
          transform="translate(-43.252 -26.422)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M80.873,115.822c0,.314.627,0,.815,0S81.291,115.467,80.873,115.822Z"
          transform="translate(53.391 -58.291)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.34,110.493c-.272.209.314,0,.314.272a.523.523,0,0,0,.167-.564C87.633,110.388,87.57,110.66,87.34,110.493Z"
          transform="translate(33.9 -64.251)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M112.3,147.611c0-.167-.125-.1,0-.46-.146.251-.4,0-.5.46-.188-.23-.272-.544,0-1.171-.314-.69-1.066-.418-1.505,0-.481.941-.5,1.693-.188,1.965s1.4-.523,1.672,0a3.225,3.225,0,0,0,.941-.1c.293-.1,0-.188.167-.481s-.5,0-.355-.481A.5.5,0,0,0,112.3,147.611Z"
          transform="translate(-38.474 -25.19)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.12,141.448a.481.481,0,0,0,.544.209C75.5,141.114,75.308,141.365,75.12,141.448Z"
          transform="translate(71.478 -30.317)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.675,160.288c.314,0,.167-.167.272-.251s.439.23,0-.167C87.758,159.974,87.863,160.142,87.675,160.288Z"
          transform="translate(32.729 -10.085)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.48,159.638a3.073,3.073,0,0,0,.355-.418.606.606,0,0,0-.355.418Z"
          transform="translate(33.467 -10.793)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.953,159.055c-.125-.146,0-.125,0-.23C87.723,158.658,87.619,158.971,87.953,159.055Z"
          transform="translate(32.806 -11.277)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M88.25,158.589c.167,0,0,0,.188-.23C88.166,158.213,88.187,158.547,88.25,158.589Z"
          transform="translate(31.339 -11.772)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M74.019,139.73v.188a.266.266,0,0,0,.293-.146C74.416,139.625,74.144,139.667,74.019,139.73Z"
          transform="translate(75.109 -32.11)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M72.957,140.476s.125.1.188,0,0,0,0-.125S72.957,140.434,72.957,140.476Z"
          transform="translate(78.491 -31.414)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M72.89,138.153c.167.314.23-.125.418,0s0,.23-.167.23c.272.418.23-.376.481.125a.418.418,0,0,1,.23-.188c0-.209-.167,0-.251,0s-.23-.146-.209-.46A.648.648,0,0,1,72.89,138.153Z"
          transform="translate(77.952 -34.087)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M88.12,158.938c.188,0,.167,0,.355.146s.1,0,.125-.125S88.1,158.749,88.12,158.938Z"
          transform="translate(31.364 -11.222)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.869,142.688c.272.188.606.167.23-.418C75.828,142.291,76.141,142.667,75.869,142.688Z"
          transform="translate(69.307 -29.278)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M73.75,143.692c.188.46.773,0,.753-.23s.4.4.606.648a5.31,5.31,0,0,0-.23-1.3c-.251-.146-.857.335-.5.564C74.126,143.462,73.875,143.441,73.75,143.692Z"
          transform="translate(74.897 -28.714)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M88.857,157.848c.188,0,.125-.23,0-.293S88.92,157.764,88.857,157.848Z"
          transform="translate(29.54 -12.62)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.25,157.065a.626.626,0,0,0,.376-.209C89.292,156.751,89.271,156.939,89.25,157.065Z"
          transform="translate(27.976 -13.405)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M85.9,155.692c.418,0,.794.167.941,0a2.718,2.718,0,0,0-.962-.272.272.272,0,0,1,.021.272Z"
          transform="translate(37.806 -14.937)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M80.74,138.273c.418.69.167-.627.544,0,0,0-.1-.334-.188-.523A4.791,4.791,0,0,1,80.74,138.273Z"
          transform="translate(54.109 -34.207)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M80.455,138.134c0,.293.209.314.334.481.125-.167-.334-.941.146-.711.146-.23-.251-.125-.334-.314C80.58,137.8,80.664,138.071,80.455,138.134Z"
          transform="translate(55.022 -34.381)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M80.245,138.412c0-.251,0-.376.355-.334-.1-.209-.1-.335,0-.439S80.036,137.973,80.245,138.412Z"
          transform="translate(55.9 -34.346)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.53,137.383a.334.334,0,0,0,.418,0c-.125-.209,0-.293-.188-.523l-.209.1C75.76,137.153,75.76,137.32,75.53,137.383Z"
          transform="translate(70.336 -35.177)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.493,163.2c.773.23-.293-.585.439-.334,0-.1-.314-.167-.5-.272A2.447,2.447,0,0,1,84.493,163.2Z"
          transform="translate(42.747 -7.118)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M86.054,161.975c.376,0,.774,0,.418-.251A.355.355,0,0,0,86.054,161.975Z"
          transform="translate(37.674 -8.072)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M86.267,161.733c.376,0,.962-.439.606-.773-.314,0-.146.251-.585,0C86.56,161.148,86.414,161.441,86.267,161.733Z"
          transform="translate(36.854 -8.896)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M86,160.921c.648.4,1.129-1.087.167-1.171a2.584,2.584,0,0,0,.355.209C86.146,160.147,86.5,160.774,86,160.921Z"
          transform="translate(37.686 -10.216)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.954,158.882c.314,0,.209-.334.334-.46C84.933,158.381,84.891,158.611,84.954,158.882Z"
          transform="translate(41.345 -11.668)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M83.55,156.391c.293,0,.523,0,.564-.272C83.78,156.035,83.717,156.286,83.55,156.391Z"
          transform="translate(45.404 -14.194)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M82.725,154.716c.523,0,.188-.648.376-.941-.334-.167,0,.439-.5.125C83,154.277,82.725,154.277,82.725,154.716Z"
          transform="translate(48.403 -16.763)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.513,162.895a1.317,1.317,0,0,0,.564.125c0-.188-.857-.46-.355-.627,0-.272-.272,0-.439,0S84.639,162.707,84.513,162.895Z"
          transform="translate(42.977 -7.465)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M88.2,162.506c.585.125.146-.481.125-.69a.836.836,0,0,1-.418,0c.418-.167-.209-.314-.23-.585s.293,0,.334,0,0-.355-.418-.481c0,.439,0,.4-.293.711l.4.146a.376.376,0,0,0,0,.418c-.314-.167-.418-.272-.669-.167s.188-.314-.314-.376l.209.585c.314-.251.5,0,.962.272-.188-.23,0-.188.146-.355C88.555,162,88.262,162.276,88.2,162.506Z"
          transform="translate(34.4 -9.125)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.535,162.869c0-.251-.209-.335,0-.481a.34.34,0,0,1-.334-.293C84.179,161.991,84.1,162.7,84.535,162.869Z"
          transform="translate(43.667 -7.669)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.158,161.247c.564,0-.1-.481-.334-.627.1,0,.314,0,.334-.125C86.217,160.327,87.179,160.954,87.158,161.247Z"
          transform="translate(35.462 -9.434)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.939,136.223c.272,0,.418-.418.439-.711S76.148,136.035,75.939,136.223Z"
          transform="translate(69.049 -36.715)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.23,137.432c.209.355,0-.335.272,0v-.272Z"
          transform="translate(71.41 -34.85)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.77,136.324c0,.146.146,0,.209,0a1,1,0,0,1-.167-.334A.71.71,0,0,1,75.77,136.324Z"
          transform="translate(69.804 -36.126)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.534,136.376c.125.146.314.251.251,0S75.618,136.313,75.534,136.376Z"
          transform="translate(70.479 -35.843)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.36,136.12c.188,0,.523.167.5,0s-.125,0-.188-.23C75.673,136.12,75.527,136.12,75.36,136.12Z"
          transform="translate(70.779 -36.235)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M74.877,135.917c0,.376.815,0,.46-.4v.209A.325.325,0,0,1,74.877,135.917Z"
          transform="translate(72.223 -36.639)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.112,137.383c0,.146.125.146.167.23s-.167-.481,0-.355-.125,0-.167-.167S75.238,137.341,75.112,137.383Z"
          transform="translate(71.841 -34.969)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.876,136.545c.188.23.23-.146.293-.23a.355.355,0,0,1-.146-.188c.23,0,0-.188.125-.293s0,.125.125,0,.146-.125,0-.314c-.125.188-.125.167-.376.167l.1.209a.167.167,0,0,0-.146.146c0-.167,0-.251-.188-.314s.167,0,0-.23l-.125.293c.209,0,.188.188.272.439,0-.167,0,0,.188,0S75.98,136.545,75.876,136.545Z"
          transform="translate(69.886 -36.66)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M74.963,137.487c0-.125,0-.188.188-.167a.251.251,0,0,1,0-.23S74.775,137.278,74.963,137.487Z"
          transform="translate(72.43 -34.927)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.491,135.979c.23.167.125-.23,0-.355s0,.125.167,0C75.449,135.289,75.6,135.874,75.491,135.979Z"
          transform="translate(70.814 -36.637)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.65,136.512c.167.251.376-.125.544-.125C79.964,136.114,79.775,136.261,79.65,136.512Z"
          transform="translate(57.478 -35.853)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M77.09,135.42c.188.23.4.355.606.167C77.508,135.294,77.3,135.441,77.09,135.42Z"
          transform="translate(65.327 -36.768)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.4,122.911c.188.46.627-.125.355-.481C87.461,122.472,87.629,122.827,87.4,122.911Z"
          transform="translate(33.632 -50.914)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.5,157.054a.481.481,0,0,0,.523-.544C79.728,156.552,79.686,156.907,79.5,157.054Z"
          transform="translate(57.965 -13.749)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.23,156.686c.46.1.23-.648.648-.836C79.439,155.829,79.251,156.54,79.23,156.686Z"
          transform="translate(58.671 -14.469)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.444,155.5c.481.125.523-.293.69-.564-.314-.188-.418.418-.627,0C79.151,154.919,79.569,155.358,79.444,155.5Z"
          transform="translate(58.144 -15.502)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.767,154.735c-.209-.251-.272,0-.544-.125,0,.167-.167.376.251.5a.272.272,0,0,1,0-.272C79.537,154.8,79.746,154.882,79.767,154.735Z"
          transform="translate(58.867 -15.821)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.227,155.123c.46,0-.251-.627.293-.732C78.851,154.245,78.914,154.852,79.227,155.123Z"
          transform="translate(59.51 -16.083)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.874,157.219c.418-.334,0-1.15-.251-1.589C84.184,155.818,84.937,156.654,84.874,157.219Z"
          transform="translate(42.512 -14.708)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.47,160.7c.167.251.355.4.585.23C78.888,160.637,78.679,160.742,78.47,160.7Z"
          transform="translate(61.083 -9.18)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M76.04,160.457c.272.439.606-.293.983-.335,0-.355-.376.272-.46-.293C76.563,160.374,76.333,160.185,76.04,160.457Z"
          transform="translate(68.196 -10.128)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.81,164.427a.335.335,0,0,0,.4.146.933.933,0,0,0-.125-.544h-.209C79.124,164.3,79.1,164.385,78.81,164.427Z"
          transform="translate(60.22 -5.548)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.28,160.194c.167.272.523,0,.523-.188S78.531,160.235,78.28,160.194Z"
          transform="translate(61.733 -9.99)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.76,157.4a.544.544,0,0,0,.627,0C79.262,156.984,78.885,157.444,78.76,157.4Z"
          transform="translate(60.145 -12.969)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.152,157.972c-.251.355.69.335.376-.125C78.278,157.742,78.361,158.327,78.152,157.972Z"
          transform="translate(62.3 -12.305)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M77.77,157.919c0,.334.815.251.585-.209C78.209,157.982,78.063,157.752,77.77,157.919Z"
          transform="translate(63.204 -12.44)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M74.02,162.6c.125.523.418,0,.627,0C74.5,162.31,74.25,162.561,74.02,162.6Z"
          transform="translate(74.794 -7.257)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M77.17,157.66c.188.272.606.146.773,0C77.714,157.513,77.421,157.7,77.17,157.66Z"
          transform="translate(64.913 -12.557)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.505,159.006c.23.209.544-.125.711.335C76.321,158.965,75.547,158.672,75.505,159.006Z"
          transform="translate(70.11 -11.186)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.957,163.272c.272,0,.481-.355.544-.648S79.207,163.1,78.957,163.272Z"
          transform="translate(59.614 -7.153)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.49,164.582c.167.355.125-.335.293,0v-.272Z"
          transform="translate(61.314 -5.243)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.9,163.424c0,.146.146,0,.209,0a.8.8,0,0,1-.125-.335A2.563,2.563,0,0,0,78.9,163.424Z"
          transform="translate(60.13 -6.573)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.669,163.462c.1.146.272.272.251,0a.209.209,0,0,0-.251,0Z"
          transform="translate(60.8 -6.213)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.439,163.281c.167,0,.5.23.523,0s-.146,0-.188-.251C78.774,163.2,78.606,163.239,78.439,163.281Z"
          transform="translate(61.239 -6.639)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M77.91,163.084c0,.376.815,0,.523-.334v.209A.352.352,0,0,1,77.91,163.084Z"
          transform="translate(62.814 -6.944)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.309,164.542c0,.146,0,.167.125.251s0-.481.146-.355-.125,0-.167-.167S78.414,164.521,78.309,164.542Z"
          transform="translate(61.85 -5.329)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.988,163.7c.167.272.251,0,.334-.188a.48.48,0,0,1-.125-.188c.23.125,0-.167.167-.272s0,.125.125,0,.146-.125,0-.314c-.167.167-.146.146-.4.125v.209a.167.167,0,0,0-.167.125c0-.167,0-.251-.167-.314s.188,0,0-.251l-.167.293c.209,0,.167.188.209.46,0-.167,0,0,.209,0C79.323,163.675,79.114,163.654,78.988,163.7Z"
          transform="translate(60.293 -7.075)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.214,164.666c0-.125,0-.188.209-.146a.272.272,0,0,1,0-.23A.355.355,0,0,0,78.214,164.666Z"
          transform="translate(62.259 -5.265)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.43,163.118c.188.209.146-.188.125-.335s.1.125.167,0C78.472,162.449,78.535,163.034,78.43,163.118Z"
          transform="translate(61.499 -7.019)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M80.11,162.35c.167.251.355.4.585.23C80.549,162.266,80.319,162.392,80.11,162.35Z"
          transform="translate(56.015 -7.38)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M77.68,162.086c.272.46.606-.272.962-.334,0-.334-.376.272-.439-.272C78.182,162,77.973,161.835,77.68,162.086Z"
          transform="translate(63.148 -8.329)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.648,161.97c.146.544.418,0,.376-.125S75.878,162.074,75.648,161.97Z"
          transform="translate(70.009 -7.961)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M74.9,159.261c.167.167.523.23.564,0S75.066,159.282,74.9,159.261Z"
          transform="translate(72.138 -10.855)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M76.7,157.79a.941.941,0,0,0,.523.544C78.182,157.581,77.178,158.438,76.7,157.79Z"
          transform="translate(66.221 -12.353)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.94,159.708c0,.1,0,.146.146.4.188-.23.418-.418.606-.648C76.358,159.06,76.316,160.126,75.94,159.708Z"
          transform="translate(68.735 -10.633)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M74.48,163.642c0,.335.481-.4.773-.418C74.9,162.868,74.71,163.537,74.48,163.642Z"
          transform="translate(73.226 -6.54)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.7,159.112c.188.188.314-.4.627-.376,0-.376-.376.251-.481-.125C75.909,158.777,75.951,159.007,75.7,159.112Z"
          transform="translate(69.602 -11.459)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M73.2,160.245c.523,0,.92-.711,1.066-1.254C73.827,158.8,73.681,159.911,73.2,160.245Z"
          transform="translate(76.889 -11.066)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M113.25,155.344c.335.711.251-.627.544,0a1.333,1.333,0,0,0-.125-.564Z"
          transform="translate(-46.363 -15.635)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M73.05,160.6c0,.293.272,0,.418,0a1.443,1.443,0,0,1-.251-.648C73.092,160.055,73.175,160.452,73.05,160.6Z"
          transform="translate(78.001 -9.997)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M113.594,153.14c.188.293.544.543.46.125A.355.355,0,0,0,113.594,153.14Z"
          transform="translate(-47.355 -17.466)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M113.15,152.811c.314.23.962.418.983,0s-.293,0-.335-.481C113.777,152.644,113.463,152.727,113.15,152.811Z"
          transform="translate(-46.493 -18.307)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M112.118,152.428c.125.732,1.589.125,1.024-.648v.418C112.808,152.031,112.578,152.7,112.118,152.428Z"
          transform="translate(-43.475 -18.907)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M112.921,155.192a1.129,1.129,0,0,0,.272.5c.125-.125-.209-.941.251-.669.167-.209-.251-.167-.293-.334C113.088,154.857,113.151,155.15,112.921,155.192Z"
          transform="translate(-45.366 -15.734)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M73.1,161.021c.293.5.481-.188.627-.355a.94.94,0,0,1-.23-.355c.418.188,0-.376.314-.564s.146.251.251.209.293-.23,0-.606c-.314.335-.272.272-.753.251l.146.4a.355.355,0,0,0-.334.251c0-.355,0-.481-.314-.627s.376,0,0-.46c-.1.167-.188.355-.293.544.376,0,.293.376.4.878,0-.293.188,0,.376-.125C73.728,160.916,73.33,160.916,73.1,161.021Z"
          transform="translate(78.368 -10.859)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M113.13,152.465c.4.4.314-.4.272-.669,0,0,.188.272.314.188C113.214,151.148,113.36,152.339,113.13,152.465Z"
          transform="translate(-46.034 -19.006)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M112.52,152.758c0,.272.293,0,.418,0a1.421,1.421,0,0,1-.23-.648C112.562,152.256,112.666,152.612,112.52,152.758Z"
          transform="translate(-43.982 -18.547)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M112.116,152.8c.209.293.544.523.481.125a.376.376,0,0,0-.481-.125Z"
          transform="translate(-42.805 -17.837)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M111.679,152.46c.314.209.962.418.983,0s-.272,0-.335-.46C112.328,152.293,111.993,152.376,111.679,152.46Z"
          transform="translate(-41.95 -18.667)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.628,166.018c.125.732,1.589.125,1.024-.648v.418C79.318,165.621,79,166.29,78.628,166.018Z"
          transform="translate(60.026 -4.087)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M112.57,153.191c.314.5.481-.209.627-.376a1.317,1.317,0,0,1-.209-.355c.4.209,0-.355.293-.544s.146.23.251.209.293-.23,0-.627c-.293.355-.272.293-.753.251l.146.4a.334.334,0,0,0-.314.251c0-.335,0-.481-.314-.606s.355,0,0-.481c-.125.188-.209.376-.314.544.4,0,.314.4.418.9,0-.293.167,0,.376-.146C113.2,153.108,112.821,153.087,112.57,153.191Z"
          transform="translate(-43.614 -19.419)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.534,161.731c.314,0,.564-.251,0-.481C84.241,161.438,84.743,161.543,84.534,161.731Z"
          transform="translate(42.79 -8.58)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M82.205,161.72c.439.23.606-.5.46-.648.167-.146.564,0,.878.125a5.06,5.06,0,0,0-.983-.857c-.272,0-.46.773,0,.732C82.373,161.385,82.164,161.511,82.205,161.72Z"
          transform="translate(48.797 -9.572)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.205,159.453c.753-.293-.606-.272.146-.523h-.564a3.7,3.7,0,0,1,.418.523Z"
          transform="translate(72.396 -11.11)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M83.965,156.8c.293.1.167-.251.293-.314a1.441,1.441,0,0,1-.648-.251C83.61,156.428,83.965,156.6,83.965,156.8Z"
          transform="translate(45.135 -14.043)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M82.964,156.614c.355,0,.753,0,.4-.272A.355.355,0,0,0,82.964,156.614Z"
          transform="translate(47.245 -13.936)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M83.177,156.393c.376,0,.962-.439.606-.753-.314,0-.146.251-.564,0C83.47,155.7,83.324,156,83.177,156.393Z"
          transform="translate(46.404 -14.698)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M82.91,155.562c.648.376,1.129-1.108.167-1.192,0,0,.23.146.376.23C83.056,154.788,83.433,155.394,82.91,155.562Z"
          transform="translate(47.236 -16.082)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.84,153.508c.293,0,.188-.334.334-.46C81.84,152.964,81.84,153.236,81.84,153.508Z"
          transform="translate(50.919 -17.541)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.433,157.536c.251.125.355,0,.544.125s-.857-.481-.335-.648c0-.272-.293,0-.46,0S81.538,157.327,81.433,157.536Z"
          transform="translate(52.538 -13.332)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M85.13,157.147c.585.1.146-.481.125-.711a.857.857,0,0,1-.418,0c.439-.167-.209-.293-.209-.585s.272,0,.314,0,0-.355-.4-.481c0,.46,0,.4-.293.732l.376.146a.314.314,0,0,0,0,.4c-.335-.167-.418-.272-.69-.167s.209-.314-.314-.376c0,.209.167.4.23.585.293-.251.481,0,.941.293-.188-.251,0-.188.146-.376C85.486,156.687,85.193,156.9,85.13,157.147Z"
          transform="translate(43.928 -14.992)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.445,157.489c0-.251-.188-.314,0-.481a.359.359,0,0,1-.334-.293C81.089,156.611,81.006,157.322,81.445,157.489Z"
          transform="translate(53.217 -13.536)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.052,155.873c.564,0,0-.481-.334-.627,0,0,.334,0,.355-.125C83.112,154.911,84.073,155.6,84.052,155.873Z"
          transform="translate(45.027 -15.307)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.241,160.122c.188-.146,0-.523-.125-.732S84.261,159.871,84.241,160.122Z"
          transform="translate(44.086 -10.65)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.876,159.664c.272.125.146-.23.272-.293a1.757,1.757,0,0,1-.648-.251C79.521,159.308,79.855,159.475,79.876,159.664Z"
          transform="translate(57.837 -10.903)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.854,159.475c.355,0,.753,0,.4-.251a.335.335,0,0,0-.4.251Z"
          transform="translate(59.947 -10.798)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.067,159.233c.376,0,.962-.439.606-.773-.314,0-.146.272-.564,0C79.36,158.648,79.214,158.941,79.067,159.233Z"
          transform="translate(59.106 -11.622)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.663,160.932c.627.4,1.129-1.087.167-1.192a2.06,2.06,0,0,0,.355.23C78.663,160.158,79.1,160.785,78.663,160.932Z"
          transform="translate(60.367 -10.226)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M77.744,156.383c.293,0,.188-.334.334-.46C77.723,155.881,77.681,156.09,77.744,156.383Z"
          transform="translate(63.628 -14.395)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M76.35,153.891c.293,0,.544,0,.564-.272C76.58,153.535,76.517,153.786,76.35,153.891Z"
          transform="translate(67.656 -16.92)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.455,160.07c.272,0,0-.209,0-.314a.376.376,0,0,1-.188,0c.188,0-.1-.146-.1-.272s.125,0,.146,0,0-.167-.188-.23,0,.188-.125.335h.167a.146.146,0,0,0,0,.188c-.146,0-.188-.125-.314,0s0-.146-.146-.167a1.046,1.046,0,0,1,0,.272c.146-.125.23,0,.439.125s0,0,0-.167S84.5,159.966,84.455,160.07Z"
          transform="translate(44.625 -10.766)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M83.932,159.473c.251,0,0-.23-.167-.293s.167,0,.167,0C83.409,159.034,83.932,159.327,83.932,159.473Z"
          transform="translate(45.169 -10.88)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M74.71,141c0,.125-.5,0-.23.439.355-.355.794.314.732.711s.23,0,.355.314C75.609,141.962,75.044,141.606,74.71,141Z"
          transform="translate(73.08 -30.663)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M73.75,141.86c-.251-.335-.188-.857-.4-.9s.272.544.251.983c0-.1.188,0,.272.167.293-.5.669-.125,1.129-.544-.188-.188-.418-.209-.418,0,0-.564-.46-.23-.878-.5l.314.627Z"
          transform="translate(75.942 -30.707)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M85.753,162.736c-.711-.125-.355-.4-.5-.606C84.75,162.151,85.481,163.238,85.753,162.736Z"
          transform="translate(40.567 -7.62)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M88.42,159.56c-.146.125-.334.209-.376.481s.293.167.439.251.125-.293,0-.439,0,0,.334,0V159.6A.247.247,0,0,1,88.42,159.56Z"
          transform="translate(31.315 -10.423)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.26,161.868a2.091,2.091,0,0,0,1.338-1.045h.251c.084,0,0-.209-.355-.23,0-.209.209-.167,0-.334a.251.251,0,0,1,.272,0,5.645,5.645,0,0,1,1.171-2.237c-.293,0,0-.188-.439-.251,0,0,.125,0,.146-.1s0,.188.251.251c0-.272,0-.335-.314-.585s.272.376-.23.167c-.523,1.338-.732,2.091-1.3,3.638,0,.146-.209,0-.293,0s0,.209,0,.272a.356.356,0,0,1-.376,0S87.469,161.889,87.26,161.868Z"
          transform="translate(31.827 -12.919)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M72.417,141.25c0,.167.188,0,.272,0a.5.5,0,0,1,0,.167c.4.272.585-.188.941-.251-.272-.293.4-.774.648-.46.125,0,0-.188,0-.251s.251.188.376.188a.732.732,0,0,1-.146-.23c.188,0,0-.376.1-.544.125.146.209,0,.272,0s0-.209,0-.251c-.272,0-.418-.23-.627-.125s0,0,.146.146a.376.376,0,0,1-.314.376.335.335,0,0,0,.146.293c-.209-.125-.355.251-.606,0a.24.24,0,0,1-.167.23c-.167.042,0,0,.188-.1s0,0,.167.146A11.584,11.584,0,0,1,72.417,141.25Z"
          transform="translate(77.882 -32.334)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M74.052,139.24c0,.188-.188.209-.146.418s.23-.335.334-.314S74.052,139.282,74.052,139.24Z"
          transform="translate(75.431 -32.582)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M83.83,156.586c.355-.125.376-.251.773-.439,0,0-.188-.167-.188-.251s0,.355-.481,0C84.018,156.251,83.872,156.23,83.83,156.586Z"
          transform="translate(44.33 -14.431)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M83.443,162.47c.334,0,.564-.293,0-.46C83.151,162.2,83.631,162.282,83.443,162.47Z"
          transform="translate(46.159 -7.751)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.206,138.983c.23.732,1.568,0,.92-.753a.812.812,0,0,0,.1.4C75.833,138.5,75.687,139.213,75.206,138.983Z"
          transform="translate(70.66 -33.683)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M73.42,138.3c.167.272.376-.125.544-.125C73.734,137.947,73.545,138.051,73.42,138.3Z"
          transform="translate(76.732 -33.881)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.591,156.28c-.376.335-.355.606-.711.983.167,0,.314,0,.355-.125a.942.942,0,0,0,.355.23c-.251-.564.125-.794.564-1.024C84.9,156.385,84.361,156.74,84.591,156.28Z"
          transform="translate(43.673 -14)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.217,156.058c.272.272,0-.335-.439-.418a.314.314,0,0,1,0,.355C83.862,156.1,84.217,155.828,84.217,156.058Z"
          transform="translate(44.716 -14.698)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M86.14,156.779c0-.1-.125-.188,0-.314s-.439,0-.376.251-.146-.1-.293-.146,0,.188-.125.293-.146,0,0-.188,0,.125-.4.1.334.125.4,0,.188.188.146.314.125,0,.272,0C85.806,156.946,85.806,156.779,86.14,156.779Z"
          transform="translate(40.681 -13.851)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M73.251,137.826h0v-.146C73.251,137.575,73.293,137.951,73.251,137.826Z"
          transform="translate(77.779 -34.304)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.022,161.652c.125,0,.167,0,.209-.146s.125,0,.272.146.251-.23.418-.314c-.4,0,0-.773-.439-1l.125-.1a.376.376,0,0,1-.188-.418c-.355-.335-.314-.732-.648-.983,0,.146-.167.272-.146.209-.418.523,0,1.84-.941,1.986,0-.146.376,0,.355-.355a1.188,1.188,0,0,0-.774.188c-.251.188.146-.188-.125-.23s0,.125,0,.23c.46,0,.209.46.125.544s0,0,.251,0,0-.272.125-.314c.355.314,0,.439,0,.732.146-.23.836-.23,1.15-.439,0-.167,0-.334.376-.251s-.188-.146-.125-.23.334.125.334-.146a.647.647,0,0,1,.272,0c.23.209-.272.418,0,.46H89.5c-.021,0,0-.146-.146-.167s0,.146.125.209C89.378,161.527,89.085,161.443,89.022,161.652Z"
          transform="translate(32.343 -11.219)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M88.436,159.016c-.544-.167-.146.4-.125.606a.731.731,0,0,0,.125-.606Z"
          transform="translate(31.446 -11.049)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.463,157.341c-.167-.1-.125.125-.46,0,.251.146,0,.376.439.5-.23.188-.564.251-1.171,0-.711.272-.481,1.045,0,1.505.92.5,1.693.585,1.965.293s-.46-1.422.146-1.672v-.92a1.757,1.757,0,0,1-.481-.188c-.125.146,0,.481-.481.314a.46.46,0,0,0,.042.167Z"
          transform="translate(36.391 -13.367)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M92.5,140.888c0,.314.314.544.46-.125C92.751,140.574,92.688,141.076,92.5,140.888Z"
          transform="translate(17.849 -30.969)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M102.985,149.518c.314,0,.167-.167.272-.23s.439.209,0-.188C103.068,149.2,103.152,149.372,102.985,149.518Z"
          transform="translate(-14.586 -21.83)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M102.83,148.878s.272-.293.355-.418A.523.523,0,0,0,102.83,148.878Z"
          transform="translate(-13.972 -22.527)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M103.266,148.266v-.23C103.057,147.973,102.932,148.182,103.266,148.266Z"
          transform="translate(-14.534 -23.002)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M103.565,147.819c.167,0,0-.1.188-.23C103.461,147.443,103.5,147.777,103.565,147.819Z"
          transform="translate(-15.982 -23.517)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M103.446,148.17c.188,0,.146,0,.355.146s0,0,0-.1S103.425,147.94,103.446,148.17Z"
          transform="translate(-15.968 -22.969)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M104.171,147.08c.188,0,.125-.209,0-.293S104.212,147,104.171,147.08Z"
          transform="translate(-17.779 -24.367)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M101.211,144.922c.418.1.794.167.941,0a2.717,2.717,0,0,0-.962-.272.272.272,0,0,1,.021.272Z"
          transform="translate(-9.51 -26.682)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M99.853,152.436c.773.209-.314-.606.439-.334,0-.125-.314-.188-.5-.272A2.364,2.364,0,0,1,99.853,152.436Z"
          transform="translate(-4.723 -18.852)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M101.365,151.226c.355,0,.773,0,.418-.272a.355.355,0,0,0-.418.272Z"
          transform="translate(-9.643 -19.816)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M101.577,150.953c.376,0,.962-.439.606-.753-.314,0-.146.251-.585,0C101.849,150.367,101.7,150.66,101.577,150.953Z"
          transform="translate(-10.461 -20.63)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M101.314,150.172c.648.376,1.15-1.108.188-1.192,0,0,.209.146.355.23C101.481,149.377,101.836,150,101.314,150.172Z"
          transform="translate(-9.654 -21.96)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M100.264,148.112c.314,0,.209-.335.334-.46C100.243,147.611,100.2,147.841,100.264,148.112Z"
          transform="translate(-5.971 -23.413)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M98.86,145.641c.314,0,.544,0,.585-.293C99.153,145.265,99.027,145.516,98.86,145.641Z"
          transform="translate(-1.933 -25.939)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M98.035,143.946c.523,0,.188-.648.376-.941-.334-.167,0,.46-.5.146C98.224,143.507,98.014,143.549,98.035,143.946Z"
          transform="translate(1.087 -28.508)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M99.823,152.125a1.317,1.317,0,0,0,.564.125c0-.188-.857-.46-.355-.627,0-.272-.272,0-.439,0S99.949,151.937,99.823,152.125Z"
          transform="translate(-4.338 -19.21)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M103.495,151.757c.585,0,.167-.481.146-.711a.837.837,0,0,1-.418,0c.418-.167-.23-.293-.23-.585s.293,0,.334,0,0-.355-.418-.481c0,.46,0,.4-.293.711l.4.167a.355.355,0,0,0,0,.4c-.314-.167-.418-.272-.669-.167s.188-.314-.314-.376a1.961,1.961,0,0,0,.209.585c.314-.251.5,0,.941.272-.188-.23,0-.167.146-.355C103.871,151.234,103.578,151.506,103.495,151.757Z"
          transform="translate(-12.923 -20.87)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M99.845,152.1c-.1-.251-.209-.314,0-.481a.34.34,0,0,1-.334-.293C99.489,151.221,99.406,151.932,99.845,152.1Z"
          transform="translate(-3.649 -19.414)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M102.468,150.477c.564,0,0-.481-.355-.627.125,0,.334,0,.355-.125C101.507,149.557,102.489,150.2,102.468,150.477Z"
          transform="translate(-11.854 -21.178)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M94.81,146.3a.513.513,0,0,0,.523-.544C95.333,145.571,94.935,146.156,94.81,146.3Z"
          transform="translate(10.647 -25.513)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M94.55,145.916c.46.1.23-.648.627-.836C94.738,145.059,94.55,145.77,94.55,145.916Z"
          transform="translate(11.346 -26.214)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M94.754,144.751c.481,0,.523-.314.69-.585-.314-.167-.439.418-.627,0C94.461,144.166,94.879,144.584,94.754,144.751Z"
          transform="translate(10.828 -27.244)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M95.078,143.965c-.209-.251-.272,0-.544-.125,0,.167-.167.376.23.5a.272.272,0,0,1,0-.251C94.849,144.049,95.058,144.174,95.078,143.965Z"
          transform="translate(11.549 -27.566)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M94.537,144.408c.46,0-.251-.627.293-.732C94.161,143.446,94.224,144.053,94.537,144.408Z"
          transform="translate(12.195 -27.799)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M100.184,146.449c.418-.314,0-1.129-.251-1.589C99.494,145.153,100.247,145.905,100.184,146.449Z"
          transform="translate(-4.804 -26.453)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M96.713,147.056c.774.23-.293-.606.439-.335,0-.125-.314-.188-.5-.272A2.363,2.363,0,0,1,96.713,147.056Z"
          transform="translate(4.981 -24.719)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M99.3,146.034c.293.125.167-.23.293-.314a1.526,1.526,0,0,1-.669-.251C98.941,145.679,99.275,145.825,99.3,146.034Z"
          transform="translate(-2.202 -25.788)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M98.274,145.844c.355,0,.753,0,.4-.272a.355.355,0,0,0-.4.272Z"
          transform="translate(-0.071 -25.681)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M98.487,145.573c.376,0,.962-.418.606-.753-.314,0-.146.251-.585,0C98.78,144.987,98.634,145.3,98.487,145.573Z"
          transform="translate(-0.912 -26.497)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M98.22,144.792c.648.376,1.129-1.108.167-1.192,0,.1.209.167.355.23C98.366,144.018,98.722,144.645,98.22,144.792Z"
          transform="translate(-0.08 -27.827)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M97.164,142.732c.314,0,.209-.335.355-.46C97.143,142.231,97.1,142.461,97.164,142.732Z"
          transform="translate(3.589 -29.28)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M96.754,146.766a1.526,1.526,0,0,0,.544.125c0-.209-.857-.481-.355-.648,0-.272-.272,0-.439,0S96.859,146.557,96.754,146.766Z"
          transform="translate(5.211 -25.077)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M100.419,146.388c.585.1.146-.481.125-.711a1.065,1.065,0,0,1-.418,0c.418-.167-.209-.314-.209-.585s.272,0,.314,0,0-.376-.418-.5c0,.46,0,.4-.293.732l.4.146a.376.376,0,0,0,0,.418c-.314-.167-.418-.272-.669-.188s.209-.293-.314-.355a1.612,1.612,0,0,0,.23.564c.293-.251.481,0,.941.293-.188-.23,0-.188.146-.376C100.775,145.886,100.482,146.137,100.419,146.388Z"
          transform="translate(-3.366 -26.748)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M96.755,146.721c0-.251-.188-.314,0-.481a.324.324,0,0,1-.335-.293C96.4,145.822,96.316,146.554,96.755,146.721Z"
          transform="translate(5.901 -25.283)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M99.378,145.077c.564,0-.1-.481-.335-.648h.335C98.437,144.136,99.4,144.784,99.378,145.077Z"
          transform="translate(-2.304 -27.004)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M99.588,149.382c.167-.146,0-.523-.125-.732C99.253,148.65,99.609,149.026,99.588,149.382Z"
          transform="translate(-3.267 -22.32)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M95.236,148.914c.293,0,.167-.251.293-.314a1.63,1.63,0,0,1-.669-.251C94.985,148.538,95.236,148.705,95.236,148.914Z"
          transform="translate(10.346 -22.647)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M94.163,148.724c.355,0,.753,0,.4-.272A.355.355,0,0,0,94.163,148.724Z"
          transform="translate(12.631 -22.54)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M94.377,148.453c.376,0,.962-.439.606-.753-.314,0-.146.251-.585,0C94.67,147.867,94.524,148.16,94.377,148.453Z"
          transform="translate(11.79 -23.356)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M94,150.151c.648.4,1.15-1.087.188-1.171a2.586,2.586,0,0,0,.355.209C94.171,149.377,94.526,150,94,150.151Z"
          transform="translate(12.937 -21.96)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M93.057,145.612c.293,0,.188-.334.335-.46C93.015,145.111,92.994,145.341,93.057,145.612Z"
          transform="translate(16.309 -26.14)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M99.765,149.287c.272,0,0-.209,0-.314a.335.335,0,0,1-.188,0c.188,0-.1-.146-.1-.272s.125,0,.146,0,0-.167-.188-.209,0,.167-.125.334h.167a.146.146,0,0,0,0,.188c-.146,0-.188-.125-.314,0s0-.146-.146-.167a1.045,1.045,0,0,1,.1.272c.146-.125.23,0,.439.125s0,0,0-.167S99.807,149.183,99.765,149.287Z"
          transform="translate(-2.691 -22.498)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M99.225,148.7c.251,0,0-.23-.167-.293s.146,0,.167,0C98.786,148.264,99.225,148.557,99.225,148.7Z"
          transform="translate(-2.131 -22.625)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M101.072,151.987c-.711-.146-.355-.418-.5-.627C100.048,151.381,100.8,152.468,101.072,151.987Z"
          transform="translate(-6.758 -19.365)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M103.676,148.8c-.167,0-.334.188-.376.46.23,0,.293.167.439.272a.272.272,0,0,0,0-.439h.314a.146.146,0,0,1,.125-.251C104.011,148.779,103.885,149.03,103.676,148.8Z"
          transform="translate(-15.947 -22.157)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M102.57,151.029a2.09,2.09,0,0,0,1.338-1.045h.251c.1,0,0-.188-.355-.23,0-.188.188-.167,0-.334a.251.251,0,0,1,.272,0,5.393,5.393,0,0,1,1.171-2.216c-.293,0,0-.209-.439-.272h.146c.021,0,0,.188.251.251,0-.272,0-.335-.314-.564s.272.376-.251.146c-.5,1.338-.732,2.09-1.3,3.638,0,.146-.188,0-.272,0s0,.209,0,.272a.376.376,0,0,1-.376,0C102.716,150.778,102.758,151.05,102.57,151.029Z"
          transform="translate(-15.489 -24.594)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M99.14,145.824c.335-.125.376-.251.774-.439,0,0-.209-.167-.188-.251-.314-.146,0,.355-.481,0C99.328,145.49,99.182,145.469,99.14,145.824Z"
          transform="translate(-2.986 -26.184)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M98.844,151.7c.334,0,.564-.293-.1-.46C98.468,151.428,99.032,151.512,98.844,151.7Z"
          transform="translate(-1.248 -19.496)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M99.9,145.51c-.376.355-.355.606-.711.983h.355a1.046,1.046,0,0,0,.355.209c-.251-.564.125-.773.564-1.024C100.319,145.615,99.671,145.97,99.9,145.51Z"
          transform="translate(-3.642 -25.744)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M99.527,145.3c.272.251,0-.355-.439-.418a.376.376,0,0,1,0,.334C99.276,145.319,99.527,145.131,99.527,145.3Z"
          transform="translate(-2.6 -26.431)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M101.364,146.03c0-.125-.125-.209,0-.335s-.439,0-.376.272-.146-.125-.293-.167,0,.209-.125.293-.146-.1,0-.188,0,.125-.418,0c0,.1.355.125.418.1s.188.188.146.314.125,0,.272,0C101.05,146.176,101.008,146.03,101.364,146.03Z"
          transform="translate(-6.569 -25.595)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M104.208,150.882c.1,0,.167,0,.188-.146s.146,0,.293.146.251-.23.4-.314c-.376,0,0-.753-.418-1a.125.125,0,0,0,.125-.1.376.376,0,0,1-.188-.418c-.355-.314-.314-.732-.648-.983,0,.146-.167.272-.146.209-.418.523,0,1.84-.941,2.09,0-.146.376,0,.355-.355-.251.1-.523,0-.794.188s.167-.188,0-.23,0,.146,0,.23c.439,0,.209.46,0,.564s.125,0,.272,0,0-.272.125-.314c.355.314,0,.46,0,.732.167-.23.857-.23,1.15-.439s0-.335.376-.251-.167-.125-.1-.23.314.146.334-.146a.481.481,0,0,1,.272,0c.23.188-.272.4,0,.46h-.146c-.021,0,0-.146-.146-.167s-.1.146.125.209C104.563,150.757,104.271,150.673,104.208,150.882Z"
          transform="translate(-14.848 -22.964)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M103.742,148.236c-.544-.167-.146.4-.125.606C103.7,148.633,103.825,148.424,103.742,148.236Z"
          transform="translate(-15.866 -22.805)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M102.769,146.588c-.167,0,0,.125-.439,0,.251.146,0,.376.418.523-.209.167-.544.23-1.15,0-.711.251-.5,1.024,0,1.484.92.5,1.693.585,1.965.293s-.46-1.422.125-1.652V146.3c0-.293-.188,0-.481-.188s0,.481-.481.314a.355.355,0,0,0,.042.167Z"
          transform="translate(-10.942 -25.129)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M91.46,122.4a.335.335,0,0,0,.376.125C91.711,122.153,91.585,122.341,91.46,122.4Z"
          transform="translate(21.146 -51.054)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.18,121.39h0c-.125,0,.376,0,.481-.23a3.218,3.218,0,0,0-.481.23Z"
          transform="translate(28.13 -52.298)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.34,120.935a.432.432,0,0,1,.418.209C89.57,120.852,89.486,120.894,89.34,120.935Z"
          transform="translate(27.656 -52.576)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.178,121.087h0a.457.457,0,0,1,.355.167c.1.167,0-.125,0,0s0-.125-.146-.334a.606.606,0,0,1-.335,0,.439.439,0,0,0,0,.293C89.157,121.3,89.053,120.962,89.178,121.087Z"
          transform="translate(28.487 -52.56)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M88.82,121.253c.146.188.167,0,.251,0s.125.314.125,0S88.945,121.253,88.82,121.253Z"
          transform="translate(29.305 -52.349)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M88.4,121.028h.376A.355.355,0,0,0,88.4,121.028Z"
          transform="translate(30.603 -52.501)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M88.314,120.688c0-.146,0,0,.167,0S88.189,120.479,88.314,120.688Z"
          transform="translate(31.086 -52.914)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M88.342,120.145h.209C88.76,120.145,88.342,120.082,88.342,120.145Z"
          transform="translate(30.891 -53.436)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M90.671,121.153v.125h.209C90.963,121.279,90.754,121.112,90.671,121.153Z"
          transform="translate(23.733 -52.313)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.9,121.65h.146S89.9,121.608,89.9,121.65Z"
          transform="translate(26.136 -51.785)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.817,121.72h0Z"
          transform="translate(26.427 -51.688)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.94,126.7a1.28,1.28,0,0,1,.627.125v-.23c0-.042-.188.167-.293-.188C76.191,126.6,76.128,126.514,75.94,126.7Z"
          transform="translate(68.86 -46.573)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.87,120.083c0,.23.146,0,.293,0s0,.167-.125.167.146-.272.334,0a.356.356,0,0,1,.167-.125c0-.167-.125,0-.188,0s-.146,0-.146-.334a.4.4,0,0,1-.334.293Z"
          transform="translate(25.768 -53.792)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M88.565,120.354c.125,0,0,0,.125.23s0,0,.125,0S88.649,120.229,88.565,120.354Z"
          transform="translate(30.187 -53.207)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M91.993,123.273c.188.146.439.125.167-.293C91.972,123.022,92.265,123.273,91.993,123.273Z"
          transform="translate(19.589 -50.314)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M76.9,139.836c.334.146.4-.4.314-.481s.4,0,.606,0a3.092,3.092,0,0,0-.732-.585c-.188,0-.293.564,0,.523C77,139.523,76.856,139.627,76.9,139.836Z"
          transform="translate(65.624 -33.095)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M77.929,128.775c0,.1.167,0,.146-.125S77.929,128.754,77.929,128.775Z"
          transform="translate(63.192 -44.172)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M77.79,128h0Z"
          transform="translate(63.456 -44.839)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.361,128.848c.167.251.355.46.523.4s-.251-.523-.376-.585A.23.23,0,0,1,75.361,128.848Z"
          transform="translate(70.714 -44.12)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M95.46,120.147c.314.481.125-.46.4,0a.7.7,0,0,0-.125-.4,1.4,1.4,0,0,1-.272.4Z"
          transform="translate(8.763 -53.836)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.433,126c.125.167.272.251.439.125S81.579,126,81.433,126Z"
          transform="translate(52.037 -47.02)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.84,125.671c.251.293.418-.251.648-.314,0-.251-.23.23-.334-.167C80.154,125.566,80.028,125.462,79.84,125.671Z"
          transform="translate(56.786 -47.904)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M95.3,120.051c0,.209.146.23.23.335s-.23-.648,0-.5-.167,0-.23-.209S95.444,120.009,95.3,120.051Z"
          transform="translate(9.385 -53.97)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M95.059,120.218c0-.167,0-.272.251-.23s0-.251,0-.314S94.913,119.925,95.059,120.218Z"
          transform="translate(10.105 -53.928)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M91.75,119.506a.272.272,0,0,0,.293,0,.482.482,0,0,0-.125-.376H91.75C91.917,119.381,91.917,119.444,91.75,119.506Z"
          transform="translate(20.334 -54.512)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.155,127.039c.146.355.293,0,.439,0S78.323,126.872,78.155,127.039Z"
          transform="translate(62.171 -45.969)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M77.029,124.112a1.515,1.515,0,0,1-.188-.23c-.063-.1-.23.836-.69.69,0-.209.251-.146.188-.334s-.146.188-.439,0c.125.334,0,.23.23.564.46,0,.335-.23.9-.439-.314-.23.209-.272,0-.4.167.355.335.209.293-.146.188,0,0,.272.251.335,0-.293.627-.146.355-.523v-.125c0-.125,0,.439.334.272-.314-.209,0-.293,0-.46-.544-.376-.983.334-1.422.272A2.8,2.8,0,0,0,77.029,124.112Z"
          transform="translate(67.249 -50.066)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.39,122.707c.376,0,.585-.564.627-.962C89.683,121.64,89.683,122.435,89.39,122.707Z"
          transform="translate(27.293 -51.671)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.092,138.379c.564.167-.209-.418.314-.23a2.582,2.582,0,0,0-.355-.209A1.881,1.881,0,0,1,75.092,138.379Z"
          transform="translate(71.883 -34)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.16,122.879c0,.209.209,0,.314,0a.9.9,0,0,1-.23-.439C89.139,122.524,89.244,122.774,89.16,122.879Z"
          transform="translate(28.317 -50.903)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M88.823,122.914c.167.188.439.335.355,0a.272.272,0,0,0-.355,0Z"
          transform="translate(29.303 -50.457)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M88.59,122.678c.23.125.711.23.69-.125s-.209,0-.293-.293C89.008,122.511,88.8,122.594,88.59,122.678Z"
          transform="translate(29.703 -51.099)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.926,122.344c.167.5,1.129,0,.648-.564v.293C88.365,121.989,88.261,122.491,87.926,122.344Z"
          transform="translate(31.662 -51.622)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M86.639,121.779c.125.188.272,0,.4,0S86.723,121.612,86.639,121.779Z"
          transform="translate(36.001 -51.705)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.779,121.06c.146.167.293.251.439.125S84.779,121.081,84.779,121.06Z"
          transform="translate(41.709 -52.408)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M83.21,120.732c.23.272.4-.272.648-.334-.1-.23-.251.23-.335-.167C83.565,120.627,83.4,120.5,83.21,120.732Z"
          transform="translate(46.371 -53.313)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.113,138.207a.8.8,0,0,0,.4,0c0-.125-.606-.334-.251-.439,0-.209-.188,0-.314,0S75.2,138.061,75.113,138.207Z"
          transform="translate(72.049 -34.288)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.306,123.167c.251.334.314-.167.4-.293a.856.856,0,0,1-.188-.251c.314,0,0-.272.167-.418s.125.167.188.125.167-.188,0-.439c-.167.272-.146.23-.481.251l.125.251c-.125,0-.188,0-.188.209,0-.23,0-.334-.293-.418s.251,0,0-.335a.443.443,0,0,1-.167.418c.272,0,.251.251.376.585,0-.209.125,0,.251-.125C89.682,123.083,89.452,123.083,89.306,123.167Z"
          transform="translate(28.443 -51.546)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.129,138.149c0-.188-.146-.23,0-.355a.252.252,0,0,1-.251-.209C74.857,137.5,74.857,138.024,75.129,138.149Z"
          transform="translate(72.535 -34.397)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M88.746,122.4c.314.251.167-.314,0-.5s.167.188.251.1C88.683,121.458,88.893,122.21,88.746,122.4Z"
          transform="translate(29.755 -51.593)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.619,127.905c.188,0,.293-.293.314-.5S81.766,127.78,81.619,127.905Z"
          transform="translate(51.62 -45.539)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M91.486,119.539c.167.251,0-.23.209,0s0-.125,0-.209Z"
          transform="translate(21.142 -54.294)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.5,127.98h.167a.627.627,0,0,1-.125-.23S81.563,127.917,81.5,127.98Z"
          transform="translate(52.137 -45.112)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.337,128.017c0,.1.209.188.167,0A.125.125,0,0,0,81.337,128.017Z"
          transform="translate(52.634 -44.856)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.209,127.874h.355c-.021,0-.1,0-.146-.167S81.335,127.832,81.209,127.874Z"
          transform="translate(52.846 -45.215)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M80.864,127.742c0,.272.585,0,.355-.272s0,0,0,.146S80.99,127.826,80.864,127.742Z"
          transform="translate(53.86 -45.543)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M91.476,119.42a.731.731,0,0,0,.125.188s-.125-.355,0-.272,0,0-.125,0S91.56,119.4,91.476,119.42Z"
          transform="translate(21.381 -54.301)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.736,128.217c.125.167.167,0,.209-.167s0,0,0-.125v-.209h0v-.23c0-.23,0,.125-.251.125v.146h-.1c-.1,0,0-.188-.146-.209s.146,0,0-.188a1.438,1.438,0,0,0,0,.209c.146,0,.125.146.188.314s0,0,.125,0S81.82,128.175,81.736,128.217Z"
          transform="translate(52.047 -45.537)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M91.333,119.586c0-.1,0-.146.125-.125s0-.125,0-.167S91.249,119.419,91.333,119.586Z"
          transform="translate(21.775 -54.341)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.24,127.736c.146.125,0-.167,0-.272s0,0,.125,0S81.3,127.652,81.24,127.736Z"
          transform="translate(52.941 -45.474)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M94.669,118.824c.125.167.272,0,.4,0S94.753,118.635,94.669,118.824Z"
          transform="translate(11.185 -54.938)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M82.426,127.386c.146.167.293.251.439,0S82.572,127.407,82.426,127.386Z"
          transform="translate(48.974 -45.626)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M80.82,126.981c.23.293.4-.251.648-.314-.1-.251-.251.23-.335-.167C81.092,126.876,81.092,126.772,80.82,126.981Z"
          transform="translate(53.758 -46.475)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M74.583,126.94c.1,0,.209.23.334.167s-.188-.355-.314-.627C74.332,126.584,74.729,126.773,74.583,126.94Z"
          transform="translate(73.479 -46.497)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M72.97,125.977c.125.188.272,0,.4,0C73.158,125.58,73.137,125.935,72.97,125.977Z"
          transform="translate(78.269 -47.248)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M71.762,128.237c.125.334.439,0,.251-.355S71.929,128.237,71.762,128.237Z"
          transform="translate(82.09 -45.077)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M82.85,123.292a.355.355,0,0,0,.544,0C83.205,123.145,83.08,123.313,82.85,123.292Z"
          transform="translate(47.588 -50.04)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M82.4,123.2c.209.272.439-.23.753-.146C82.923,122.84,82.484,123.132,82.4,123.2Z"
          transform="translate(48.77 -50.32)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M82.006,122.646c.209.293.418,0,.648,0s-.418,0-.334-.293S82.153,122.626,82.006,122.646Z"
          transform="translate(50.042 -51.088)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M82.233,122.589c0-.209-.125-.146-.23-.314s-.272.125-.125.376v-.146C81.877,122.485,82.149,122.673,82.233,122.589Z"
          transform="translate(50.902 -51.135)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.6,122.683c.272.188.146-.439.481-.251C81.808,122.035,81.536,122.39,81.6,122.683Z"
          transform="translate(51.536 -51.104)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.373,126.7c.167.376.314,0,.251-.125S79.561,126.764,79.373,126.7Z"
          transform="translate(58.612 -46.425)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M76.122,127.065c.146.293.481.251.251-.125Z"
          transform="translate(68.594 -45.995)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.59,126.875a.376.376,0,0,0,.439,0C75.883,126.541,75.736,126.9,75.59,126.875Z"
          transform="translate(70.13 -46.223)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.1,127.27c-.146.272.523.188.251-.125S75.267,127.5,75.1,127.27Z"
          transform="translate(71.812 -45.865)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M78.42,127.8c0,.23.293-.314.5-.355C78.629,127.239,78.566,127.7,78.42,127.8Z"
          transform="translate(61.321 -45.5)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M77.94,125.317c.355,0,.564-.585.606-.983C78.212,124.23,78.233,125.046,77.94,125.317Z"
          transform="translate(62.7 -48.846)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M76.96,127.006c.314.5.125-.46.4,0a.559.559,0,0,0-.146-.376,1.715,1.715,0,0,1-.251.376Z"
          transform="translate(65.938 -46.333)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M77.71,125.469c0,.209.188,0,.293,0a1.108,1.108,0,0,1-.23-.439A.941.941,0,0,1,77.71,125.469Z"
          transform="translate(63.724 -48.078)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M77.382,125.508c.167.188.418.334.334,0a.23.23,0,0,0-.334,0Z"
          transform="translate(64.68 -47.636)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M77.14,125.289c.251.125.711.209.69-.125s-.188,0-.293-.314C77.579,125.164,77.349,125.164,77.14,125.289Z"
          transform="translate(65.089 -48.274)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M76.471,124.924c.167.5,1.129,0,.669-.544v.293C76.931,124.589,76.826,125.091,76.471,124.924Z"
          transform="translate(67.054 -48.787)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M76.787,126.942c0,.188.167.209.251.314s-.23-.648,0-.5-.188,0-.23-.209S76.954,126.879,76.787,126.942Z"
          transform="translate(66.55 -46.478)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M77.851,125.767c.272.335.314-.188.4-.314a.857.857,0,0,1-.188-.251c.314.125,0-.251.167-.4s.125.146.188.125.188-.188,0-.46c-.188.272-.167.23-.5.251l.146.272a.251.251,0,0,0-.209.209c0-.251,0-.355-.272-.418s.251,0,0-.334a1.08,1.08,0,0,1-.167.4c.293,0,.251.251.376.606,0-.209.125,0,.251-.125C78.29,125.6,78.019,125.6,77.851,125.767Z"
          transform="translate(63.834 -48.711)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M76.566,127.088c0-.167,0-.251.251-.23s0-.251,0-.314S76.4,126.8,76.566,127.088Z"
          transform="translate(67.272 -46.436)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M77.286,125.01c.314.251.167-.293,0-.5s.167.188.251,0C77.2,124.069,77.412,124.864,77.286,125.01Z"
          transform="translate(65.173 -48.79)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M85.21,121.306c.355,0,.564-.585.606-.983C85.482,120.24,85.5,121.034,85.21,121.306Z"
          transform="translate(40.232 -53.218)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.23,123.006c.314.481.125-.46.4,0a.591.591,0,0,0-.146-.376A3.262,3.262,0,0,1,84.23,123.006Z"
          transform="translate(43.47 -50.695)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.98,121.469c0,.188.188,0,.293,0a1.108,1.108,0,0,1-.23-.439C84.959,121.114,85.064,121.344,84.98,121.469Z"
          transform="translate(41.256 -52.44)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.652,121.49c.167.209.418.334.334,0a.272.272,0,0,0-.335,0Z"
          transform="translate(42.212 -52.001)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.41,121.268c.251.125.711.209.69-.125s-.188,0-.293-.293C84.849,121.164,84.619,121.164,84.41,121.268Z"
          transform="translate(42.621 -52.637)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M83.746,120.914c.167.523,1.129,0,.648-.544v.293C84.206,120.579,84,121.081,83.746,120.914Z"
          transform="translate(44.581 -53.16)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M82.459,120.369c.125.188.272,0,.4,0S82.563,120.2,82.459,120.369Z"
          transform="translate(48.92 -53.242)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.079,122.918c0,.188.167.209.251.334s-.23-.669,0-.5c.1-.167-.188-.1-.23-.23S84.247,122.876,84.079,122.918Z"
          transform="translate(44.06 -50.837)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M85.121,121.757c.272.335.314-.188.4-.314a.544.544,0,0,1-.188-.23c.314,0,0-.272.167-.418s.125.167.188.125.188-.188,0-.46c-.188.293-.167.23-.5.251l.146.272a.23.23,0,0,0-.209.209.417.417,0,0,0-.272-.418c-.167-.084.251,0,0-.335a.478.478,0,0,1-.167.418c.293,0,.251.251.376.585,0-.209.125,0,.251-.125C85.56,121.611,85.268,121.611,85.121,121.757Z"
          transform="translate(41.366 -53.084)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M83.846,123.1c0-.188,0-.272.251-.23s0-.251,0-.335S83.741,122.808,83.846,123.1Z"
          transform="translate(44.732 -50.811)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.556,121.01c.314.23.167-.314,0-.5s.167.167.251,0C84.473,120.069,84.682,120.864,84.556,121.01Z"
          transform="translate(42.705 -53.152)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M86.7,123.041c.167,0,.272-.272.293-.46S86.845,122.916,86.7,123.041Z"
          transform="translate(35.942 -50.793)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.14,124.95c0,.209.188,0,.293,0a1.066,1.066,0,0,1-.23-.46C84.119,124.595,84.224,124.845,84.14,124.95Z"
          transform="translate(43.852 -48.667)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M83.823,124.978c.167.188.439.334.355,0a.251.251,0,0,0-.355,0Z"
          transform="translate(44.755 -48.215)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M83.57,124.684c.23.125.711.23.69,0s-.209,0-.293-.314C83.988,124.516,83.779,124.6,83.57,124.684Z"
          transform="translate(45.217 -48.798)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.026,125.774c.167.5,1.129,0,.648-.544v.293C84.486,125.439,84.382,125.92,84.026,125.774Z"
          transform="translate(43.715 -47.86)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M81.619,123.854c.125.188.272,0,.4,0S81.723,123.665,81.619,123.854Z"
          transform="translate(51.516 -49.452)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M79.779,123.12c.146.167.293.251.439.125S79.779,123.141,79.779,123.12Z"
          transform="translate(57.161 -50.161)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M86.733,123.251c0,.146.125,0,.167-.146h0c.146,0,0-.125,0-.209h0v-.209c0-.209,0,0-.23.125v.125a.2.2,0,0,0-.125-.188c-.125-.042,0,0,0-.146a.754.754,0,0,1,0,.188c.146,0,.125.1.188.272h0S86.8,123.209,86.733,123.251Z"
          transform="translate(36.41 -50.731)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M86.409,122.885c.146,0,0-.146,0-.251h0S86.472,122.8,86.409,122.885Z"
          transform="translate(37.068 -50.741)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M91.161,122.09s-.355,0-.146.293c.23-.23.544.23.523.523s.146,0,.23.23C91.809,122.738,91.412,122.487,91.161,122.09Z"
          transform="translate(22.282 -51.284)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M90.479,122.678c-.188-.23-.125-.606-.272-.627s.188.376.167.69a.223.223,0,0,1,.188.125c.209-.355.481,0,.794-.4-.125-.125-.293-.146-.293,0,0-.418-.314-.167-.627-.355a4.426,4.426,0,0,1,.23.439Z"
          transform="translate(24.322 -51.328)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.889,138.059c-.5,0-.251-.272-.355-.439S75.7,138.435,75.889,138.059Z"
          transform="translate(70.438 -34.37)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.366,120.74s-.418.23,0,.146a.293.293,0,0,1,0-.146Z"
          transform="translate(28.383 -52.757)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.338,120.784a.5.5,0,0,0-.418,0c.125,0,0,.23.1.355a.188.188,0,0,0,.146-.272l.146.188a.125.125,0,0,1,.188,0A.255.255,0,0,1,89.338,120.784Z"
          transform="translate(28.787 -52.758)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.364,121.3a1.443,1.443,0,0,0,1.213,0l.125.146c.125.146,0-.146,0-.293s.167,0,.188-.146,0,0,.125.125a3.868,3.868,0,0,1,1.672-.627c-.167-.125,0-.146,0-.355h.125v.272c.1-.167.188-.146.1-.46s0,.334-.209,0c-.9.481-1.38.794-2.4,1.338h-.167c-.063,0-.146,0,0,.167a.23.23,0,0,1-.188-.167S87.531,121.409,87.364,121.3Z"
          transform="translate(30.594 -53.697)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.591,122.292c0,.125.146,0,.209,0,.272.209.4-.125.648-.167-.188-.209.293-.544.46-.335s0-.125,0-.167.167.125.251.125l-.1-.146c.146,0,0-.272,0-.4s.146,0,.188,0,0-.167,0-.188-.293-.146-.439,0h0a.293.293,0,0,1-.23.272.293.293,0,0,0,0,.209c-.146,0-.251.167-.439,0s0,.146-.1.167,0,0,.125,0h.125a8.128,8.128,0,0,1-.69.627Z"
          transform="translate(25.628 -52.531)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M90.6,120.82c0,.125-.146.146,0,.293s.146-.23.23-.209S90.619,120.841,90.6,120.82Z"
          transform="translate(24.078 -52.669)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M76.986,126.6c-.355,0-.46.167-.836.188,0,0,.167.167.251,0a.627.627,0,0,0,0,.293c.146-.418.439-.355.794-.272C77.174,126.872,76.673,126.746,76.986,126.6Z"
          transform="translate(67.793 -46.366)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.981,126.738c0,.272.167-.188,0-.418a.23.23,0,0,1-.146.188C75.834,126.634,76.106,126.613,75.981,126.738Z"
          transform="translate(69.593 -46.671)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.93,121.023a4.184,4.184,0,0,0,.627.125v-.209c0-.042-.209.146-.293-.209C85.2,120.939,85.118,120.855,84.93,121.023Z"
          transform="translate(41.076 -52.767)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M74.343,137.844c.23,0,.4-.188,0-.314C74.134,137.655,74.49,137.718,74.343,137.844Z"
          transform="translate(74.324 -34.447)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M94.109,141.86c-.251.209.125.5.272.481S94.109,142.153,94.109,141.86Z"
          transform="translate(13.208 -29.725)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.764,140.34c-.481.209,0,.418,0,.606C90.14,140.737,89.848,140.549,89.764,140.34Z"
          transform="translate(27.023 -31.382)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M86.49,138.191c-.167.251.23.5-.209.753C86.678,138.985,86.825,138.149,86.49,138.191Z"
          transform="translate(37.133 -33.728)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M95.315,139.34c0,.523.878.773,1.422.815C96.862,139.674,95.754,139.737,95.315,139.34Z"
          transform="translate(8.176 -32.473)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M96.411,139.91c-.251.125,0,.272,0,.4a1.631,1.631,0,0,1,.585-.335C96.913,139.847,96.6,140.1,96.411,139.91Z"
          transform="translate(5.868 -31.851)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M97.089,140.34c-.272.251-.439.627,0,.481A.376.376,0,0,0,97.089,140.34Z"
          transform="translate(4.605 -31.382)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M96.1,140.43c-.146.355-.23,1.024.23.962s0-.272.4-.418C96.388,141.036,96.242,140.723,96.1,140.43Z"
          transform="translate(6.811 -31.284)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M95.514,141.28c-.711.272.167,1.589.815.9a.633.633,0,0,0-.4.146C96.016,141.907,95.326,141.782,95.514,141.28Z"
          transform="translate(8.73 -30.357)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M95.051,143.33c-.251.188.125.355.146.544C95.448,143.623,95.239,143.455,95.051,143.33Z"
          transform="translate(10.343 -28.122)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M95.591,140.386c-.439.376.272.418.46.544a1.171,1.171,0,0,1,.334-.272c-.146.439.355,0,.585.188s-.209.188-.167.293.272.23.627,0c-.4-.23-.334-.209-.376-.69l-.355.23a.4.4,0,0,0-.314-.293c.314-.125.46-.146.544-.418s.125.355.481,0l-.585-.188c0,.4-.334.355-.794.544.293,0,.1.167.188.355C95.758,140.972,95.737,140.6,95.591,140.386Z"
          transform="translate(7.211 -31.993)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M95.427,140.56c-.314.46.439.209.711.125,0,.125-.23.23-.125.355C96.744,140.393,95.636,140.748,95.427,140.56Z"
          transform="translate(8.587 -31.142)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M91.176,144.26c-.418.209-.334.69.188.355Z"
          transform="translate(22.768 -27.108)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.522,147.23a.564.564,0,0,0,.439.439C85.191,147.188,84.669,147.376,84.522,147.23Z"
          transform="translate(42.467 -23.869)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.486,148.14c-.418.146.334.711.376.125C84.737,148.035,84.423,148.558,84.486,148.14Z"
          transform="translate(42.963 -22.876)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.021,148.23c-.146.314.5.69.606.209C84.335,148.544,84.377,148.272,84.021,148.23Z"
          transform="translate(43.95 -22.778)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M83.45,148.41c0,.335.376.5.585.5S83.617,148.6,83.45,148.41Z"
          transform="translate(45.651 -22.582)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M93.777,142.13c0,.523.857.774,1.422.815C95.3,142.464,94.195,142.527,93.777,142.13Z"
          transform="translate(12.934 -29.43)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M94.891,142.69c-.272.146,0,.293,0,.418a1.526,1.526,0,0,1,.585-.355C95.393,142.648,95,142.815,94.891,142.69Z"
          transform="translate(10.587 -28.82)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M95.546,143.13c-.251.23-.418.606,0,.481a.334.334,0,0,0,0-.481Z"
          transform="translate(9.325 -28.34)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M94.581,143.22c-.167.355-.251,1.024.23.962.167-.272,0-.272.4-.418C94.853,143.826,94.728,143.513,94.581,143.22Z"
          transform="translate(11.524 -28.242)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M93.975,144.07c-.69.272.167,1.589.836.9a.633.633,0,0,0-.4.146C94.5,144.7,93.787,144.572,93.975,144.07Z"
          transform="translate(13.447 -27.315)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M96.95,142c-.251.188.146.376.167.564C97.347,142.334,97.18,142.146,96.95,142Z"
          transform="translate(4.451 -29.572)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M94.069,143.2c-.46.376.272.418.46.544a1.066,1.066,0,0,1,.314-.293c-.125.46.376,0,.606.209s-.23.188-.167.272.272.251.627,0c-.4-.23-.335-.209-.4-.69l-.355.209a.355.355,0,0,0-.314-.272c.334-.146.481-.146.564-.418s0,.355.481,0l-.606-.209c0,.4-.314.376-.794.564.314,0,.125.167.209.355C94.237,143.783,94.195,143.491,94.069,143.2Z"
          transform="translate(11.931 -28.972)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M93.9,143.35c-.314.46.439.209.711.125,0,.1-.251.23-.125.334C95.2,143.183,94.11,143.538,93.9,143.35Z"
          transform="translate(13.312 -28.1)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M96.7,146.25c0,.46.669.188.9.585C97.578,146.417,96.846,146.25,96.7,146.25Z"
          transform="translate(4.429 -24.937)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M95.765,146.1c0,.481.334.523.606.669.167-.335-.418-.4,0-.606C96.33,145.785,95.912,146.224,95.765,146.1Z"
          transform="translate(7.58 -25.204)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M95.856,146.837c.23-.23,0-.272.1-.564-.188,0-.4-.146-.5.272h.251C95.793,146.545,95.689,146.816,95.856,146.837Z"
          transform="translate(8.66 -24.94)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M95.2,146.712c0,.439.606-.272.753.251C96.061,146.294,95.455,146.378,95.2,146.712Z"
          transform="translate(9.186 -24.71)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M90.1,141.429c0-.272-.523,0-.606-.209-.23.23.125.5.439.209,0,.125,0,.23-.272.355C89.953,141.973,90.058,141.617,90.1,141.429Z"
          transform="translate(27.148 -30.423)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M91.615,144.83c0,.293,0,.523.314.544C91.991,145.039,91.761,144.976,91.615,144.83Z"
          transform="translate(20.72 -26.486)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M96.845,140.21a5.164,5.164,0,0,0-.125.9h.314c.084,0-.251-.272.23-.418C96.992,140.586,97.1,140.482,96.845,140.21Z"
          transform="translate(4.723 -31.524)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M97.857,140.821c0-.5-.272-.648-.334-1.171-.146.125-.209.23-.125.355a.942.942,0,0,0-.4.146c.585.167.544.585.46,1.087C97.523,141.092,97.627,140.382,97.857,140.821Z"
          transform="translate(3.544 -32.135)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M96.684,140.849c-.376,0,.272.209.585,0A.355.355,0,0,1,97,140.64C96.81,140.64,96.851,141.016,96.684,140.849Z"
          transform="translate(5.031 -31.055)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M90.74,146.09a.5.5,0,0,0,.544.188C91.1,145.714,90.907,145.944,90.74,146.09Z"
          transform="translate(23.204 -25.3)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.47,144.569s.188,0,.125.125a.544.544,0,0,0,.669-.335C87.972,144.4,87.825,144.485,87.47,144.569Z"
          transform="translate(33.059 -26.999)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.78,144.047c.188.167.376.146.564.293C88.094,143.859,87.885,143.921,87.78,144.047Z"
          transform="translate(32.331 -27.438)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.64,144.18v.146c0,.042.334,0,.5.23s0-.188-.125-.125a4.565,4.565,0,0,1-.188-.481,1.023,1.023,0,0,1-.5,0,.861.861,0,0,0,.1.4C87.535,144.514,87.452,144.055,87.64,144.18Z"
          transform="translate(33.412 -27.446)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.661,144.336v.167c.146.1.188,0,.272-.146S89.807,144.273,89.661,144.336Z"
          transform="translate(26.792 -27.1)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M88.59,145.084c0,.1.125.1.188,0a.209.209,0,0,1,0-.125C88.674,144.833,88.59,145.084,88.59,145.084Z"
          transform="translate(30.204 -26.384)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M88.445,145.129H88.7c.251,0-.188-.125-.293,0a.255.255,0,0,1,.042,0Z"
          transform="translate(30.6 -26.221)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M88.516,142.744c.146.314.209-.1.418,0s0,.23-.167.209c.251.418.209-.376.46.125,0,0,0-.146.23-.167s-.167,0-.251,0S89,142.764,89,142.43A.564.564,0,0,1,88.516,142.744Z"
          transform="translate(29.609 -29.103)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M91.495,147.3c.251.188.606.146.209-.439C91.453,146.881,91.746,147.278,91.495,147.3Z"
          transform="translate(21.028 -24.272)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.35,148.272c.209.46.773,0,.773-.209s.4.376.585.627a4.348,4.348,0,0,0-.23-1.275c-.23-.146-.836.314-.5.544C89.684,148.063,89.35,148.021,89.35,148.272Z"
          transform="translate(26.685 -23.698)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M96.37,142.883c.439.69.167-.648.544,0a.918.918,0,0,0-.188-.544,2.237,2.237,0,0,1-.355.544Z"
          transform="translate(5.805 -29.201)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M96.075,142.734c0,.272.23.314.334.46s-.334-.92.167-.711c.146-.23-.272-.125-.334-.293C96.2,142.378,96.305,142.671,96.075,142.734Z"
          transform="translate(6.727 -29.365)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M95.862,143c0-.251,0-.376.355-.334q-.188-.314,0-.439C96.343,142.142,95.653,142.581,95.862,143Z"
          transform="translate(7.629 -29.338)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M91.16,141.993a.334.334,0,0,0,.4,0c-.125-.23,0-.314-.188-.544l-.209.1C91.39,141.847,91.369,141.91,91.16,141.993Z"
          transform="translate(22.053 -30.172)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.85,146.5c.523,0,.815-.815.857-1.38C88.247,145,88.268,146.128,87.85,146.5Z"
          transform="translate(31.822 -26.175)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.52,146.726c.125.272.272,0,.418,0a1.442,1.442,0,0,1-.335-.606C87.478,146.2,87.625,146.559,87.52,146.726Z"
          transform="translate(33.281 -25.079)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M91.558,140.824c.272,0,.418-.418.46-.711S91.788,140.615,91.558,140.824Z"
          transform="translate(20.756 -31.699)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M90.86,142.022c.23.355,0-.334.293,0a.428.428,0,0,0-.1-.272,1.547,1.547,0,0,1-.188.272Z"
          transform="translate(23.084 -29.845)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M91.4,140.9c0,.146.146,0,.209,0a.92.92,0,0,1-.167-.314A.633.633,0,0,1,91.4,140.9Z"
          transform="translate(21.499 -31.11)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M91.154,140.935c.125.125.314.23.251,0a.188.188,0,0,0-.251,0Z"
          transform="translate(22.205 -30.786)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M90.98,140.709c.167.1.523.167.5,0s-.146,0-.209-.209C91.293,140.584,91.126,140.646,90.98,140.709Z"
          transform="translate(22.505 -31.208)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M90.5,140.507c.125.376.815,0,.481-.4v.209A.369.369,0,0,1,90.5,140.507Z"
          transform="translate(23.928 -31.633)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M90.754,141.959c0,.146,0,.167.167.251s-.167-.481,0-.376-.146,0-.188-.146S90.859,141.938,90.754,141.959Z"
          transform="translate(23.545 -29.949)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M91.516,141.165c.188.251.23-.125.293-.23l-.146-.167c.23,0,0-.188.125-.293s0,.1.146,0,.125-.146,0-.334c-.125.209-.125.167-.355.188v.188a.143.143,0,0,0-.146.146c0,.146,0-.23-.209-.293s.188,0,0-.251v.314c.209,0,.167.167.272.418,0-.146,0,0,.167,0S91.621,141.1,91.516,141.165Z"
          transform="translate(21.571 -31.622)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M90.563,142.1c0-.146,0-.209.188-.188s0-.167,0-.23S90.458,141.873,90.563,142.1Z"
          transform="translate(24.072 -29.926)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M91.095,140.569c.23.188.125-.209,0-.355s.125.125.188,0C91.053,139.879,91.2,140.464,91.095,140.569Z"
          transform="translate(22.536 -31.632)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M95.27,141.17c.188.272.376-.125.564-.125C95.584,140.689,95.4,140.856,95.27,141.17Z"
          transform="translate(9.183 -30.832)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M92.7,140c.209.251.418.355.627.167A.951.951,0,0,0,92.7,140Z"
          transform="translate(17.063 -31.753)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M90.328,145.62c0,.125-.5,0-.209.418.334-.334.774.314.732.732s.23,0,.335.293C91.248,146.519,90.663,146.164,90.328,145.62Z"
          transform="translate(24.786 -25.625)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.361,146.469c-.251-.334-.167-.878-.376-.9s.251.544.251.962c0-.1.167,0,.251.188.293-.5.69-.125,1.129-.564-.188-.188-.418-.188-.418,0-.1-.564-.439-.23-.878-.5,0,.209.209.418.334.627Z"
          transform="translate(27.656 -25.679)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.81,143.72s-.585.335,0,.209A.628.628,0,0,1,87.81,143.72Z"
          transform="translate(33.346 -27.696)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M88.067,145.85c0,.167.188,0,.272,0a.23.23,0,0,1,0,.167c.4.293.564-.188.92-.251-.272-.272.4-.753.648-.46s0-.188,0-.23.251.188.376.167a.606.606,0,0,1-.167-.209c.188,0,0-.376.125-.564.125.146.188,0,.251,0s0-.23,0-.251c-.251,0-.418-.23-.627-.125s0,0,.167.146a.418.418,0,0,1-.335.376.355.355,0,0,0,.146.272c-.188-.125-.355.272-.606,0,0,0,0,.188-.146.23s0,0,.188,0a.4.4,0,0,1,.146.125,11.574,11.574,0,0,1-1.359.606Z"
          transform="translate(29.557 -27.318)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.655,143.83c0,.188-.188.23-.125.439s.209-.334.314-.314S89.718,143.872,89.655,143.83Z"
          transform="translate(27.174 -27.577)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M90.832,143.593c.23.711,1.589,0,.92-.773a.7.7,0,0,0,.125.4C91.459,143.2,91.313,143.8,90.832,143.593Z"
          transform="translate(22.359 -28.678)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.04,142.88c.188.272.376,0,.564,0C89.354,142.5,89.186,142.65,89.04,142.88Z"
          transform="translate(28.437 -28.864)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M88.877,142.416h0v-.146C88.877,142.165,88.939,142.562,88.877,142.416Z"
          transform="translate(29.479 -29.298)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.335,143.727s.209.209.314.125c-.272-.23.23,0,.272-.188s.209.209.146.209.585-.4.648-.753c.272.314.167-.251.418,0v-.272a.641.641,0,0,1,.418-.272c.146,0-.272-.439,0-.293,0-.251-.23,0-.125.23s-.209.125-.293.272-.125,0-.188-.188,0,.251-.355.167c.376.585-.439.23-.376.773C87.857,143.476,87.669,143.727,87.335,143.727Z"
          transform="translate(32.024 -29.376)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M88.3,142.768c0,.125,0-.125.167,0s0-.146-.188-.272S88.451,142.663,88.3,142.768Z"
          transform="translate(31.201 -29.065)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.5,142.5a1.191,1.191,0,0,0,.355-.314S87.417,142.295,87.5,142.5Z"
          transform="translate(33.426 -29.365)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M92.57,144.426c.314.167.4-.376.293-.481s.4,0,.627,0a4.473,4.473,0,0,0-.732-.585c-.209,0-.314.585,0,.544C92.758,144.133,92.57,144.238,92.57,144.426Z"
          transform="translate(17.193 -28.089)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M90.712,142.979c.544.146-.209-.439.314-.251,0,0-.23-.125-.355-.188A4.3,4.3,0,0,1,90.712,142.979Z"
          transform="translate(23.609 -28.983)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M90.756,142.8h.376c.125,0-.606-.355-.23-.46,0-.188-.209,0-.334,0S90.839,142.678,90.756,142.8Z"
          transform="translate(23.753 -29.289)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M90.736,142.728c0-.167-.125-.23,0-.334a.214.214,0,0,1-.23-.209C90.506,142.1,90.443,142.624,90.736,142.728Z"
          transform="translate(24.254 -29.381)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M91.529,142.657c-.5,0-.272-.293-.376-.439S91.32,143.013,91.529,142.657Z"
          transform="translate(22.143 -29.351)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M90,142.454c.209,0,.4-.209,0-.335C89.749,142.245,90,142.329,90,142.454Z"
          transform="translate(26.034 -29.441)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M88.625,163.945c.648,0,.606.523.857.962C89.691,164.447,89.608,163.527,88.625,163.945Z"
          transform="translate(29.333 -5.756)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M90.785,166a.481.481,0,0,0,0,.9C91.2,166.523,90.493,166.5,90.785,166Z"
          transform="translate(24.121 -3.4)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.165,165.356c0,.1,0,.293-.209.209-.272.251.272.251.523,0a.439.439,0,0,0,0-.355C89.27,165.377,89.27,165.4,89.165,165.356Z"
          transform="translate(28.855 -4.261)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M90.814,167.086c-.293-.1,0,.5.23.418s0-.1,0-.146v-.125C91.044,167.107,90.814,167.379,90.814,167.086Z"
          transform="translate(23.444 -2.228)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.735,163.67c-.669.481.648.146,0,.564,0,0,.334-.125.523-.209a2.237,2.237,0,0,1-.523-.355Z"
          transform="translate(26.738 -5.941)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.8,164c-.251.125-.272.23-.439.355.167.1.9-.376.711.125.251.146.125-.272.293-.355C90.175,164,89.883,164.146,89.8,164Z"
          transform="translate(27.009 -5.581)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.365,164.324c.251,0,.376,0,.355.355.188-.125.335-.146.418,0S89.762,164.094,89.365,164.324Z"
          transform="translate(27.213 -5.277)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M76.94,124.946c.355,0,.564-.585.606-.983C77.212,123.88,77.233,124.674,76.94,124.946Z"
          transform="translate(65.791 -49.248)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.96,126.646c.314.481.125-.46.4,0a.559.559,0,0,0-.146-.376A3.267,3.267,0,0,1,75.96,126.646Z"
          transform="translate(69.028 -46.726)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M76.71,125.109c0,.188.209,0,.293,0a1.108,1.108,0,0,1-.23-.439A.968.968,0,0,1,76.71,125.109Z"
          transform="translate(66.815 -48.471)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M76.382,125.133c.167.209.418.334.334,0a.251.251,0,0,0-.335,0Z"
          transform="translate(67.77 -48.035)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M76.139,124.919c.251.125.732.209.69-.125s-.188,0-.272-.314C76.578,124.731,76.348,124.835,76.139,124.919Z"
          transform="translate(68.181 -48.678)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M91.521,120.644c.146.523,1.108,0,.648-.544v.293C91.96,120.309,91.855,120.811,91.521,120.644Z"
          transform="translate(20.563 -53.454)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M90.24,120.145c.125.188.251,0,.4,0C90.449,119.852,90.324,120.019,90.24,120.145Z"
          transform="translate(24.896 -53.583)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.819,126.558c0,.188.146.209.23.334s-.23-.669,0-.5c0-.167-.188-.1-.23-.23S75.966,126.5,75.819,126.558Z"
          transform="translate(69.629 -46.868)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M76.831,125.4c.272.334.314-.188.418-.314a.753.753,0,0,1-.209-.23c.314,0,0-.272.167-.418s.125.146.188.125.188-.188,0-.46c-.167.272-.167.23-.5.251l.146.272a.251.251,0,0,0-.209.209c0-.251,0-.334-.272-.418s.251,0,0-.334a2.467,2.467,0,0,0-.146.418c.272,0,.251.23.355.585,0-.209.125,0,.251-.125C77.27,125.292,77,125.313,76.831,125.4Z"
          transform="translate(66.945 -49.114)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.566,126.741c0-.188,0-.272.251-.251s0-.23,0-.314S75.4,126.428,75.566,126.741Z"
          transform="translate(70.363 -46.842)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M76.286,124.654c.314.23.167-.314,0-.5s.167.167.251,0C76.2,123.693,76.433,124.508,76.286,124.654Z"
          transform="translate(68.263 -49.187)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M85.986,120.93c-.355,0-.481.146-.836.188,0,0,.146.146.23,0a.711.711,0,0,0,0,.293c.125-.418.439-.376.773-.293C86.153,121.181,85.652,121.076,85.986,120.93Z"
          transform="translate(40.02 -52.549)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M84.966,121.058c0,.293.146-.188,0-.418a.272.272,0,0,1-.146.188C84.8,120.974,85.071,120.954,84.966,121.058Z"
          transform="translate(41.835 -52.866)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M75.1,127.8h0v.188c-.146-.439.585-.314.23-.9-.125,0,0,.167,0,.314S75.186,127.7,75.1,127.8Z"
          transform="translate(71.788 -45.832)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M86.412,121.155s0-.167.125-.209-.251-.167-.314,0,0-.125,0-.209,0,.125-.209,0,0-.146,0-.167,0,0-.251-.125.125.23.167.251,0,.188,0,.23,0,0,.146.125S86.223,120.987,86.412,121.155Z"
          transform="translate(38.508 -53.108)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M90.126,119.812h0v-.1C90.126,119.624,90.146,119.917,90.126,119.812Z"
          transform="translate(25.637 -53.899)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.063,120.76s.146.146.209,0,.167,0,.188-.125.167.146,0,.146.439-.293.481-.544c.188.23,0-.167.293,0s0-.146,0-.188a.557.557,0,0,1,.293-.209c.1-.021-.188-.293,0-.188s-.167,0,0,.146-.167,0-.209.209,0,0-.125-.146,0,.188-.272.125c.272.418-.293.167-.251.544C89.418,120.593,89.293,120.76,89.063,120.76Z"
          transform="translate(27.411 -53.968)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.82,122.322a.125.125,0,0,0,.188,0c0,.1,0,.125,0,.209s.23,0,.355,0c-.188-.23.376-.4.251-.753v-.314c0-.063.167-.544,0-.815h-.167c-.46,0-.857.983-1.442.606,0,0,.251.125.355,0s-.293-.23-.5-.272.167,0,0-.167,0,0-.146,0c.23.209,0,.335-.209.355s0,0,.146.125,0-.167.209-.1-.209.251-.355.376c.188,0,.564.272.815.314s.188-.146.314,0,0-.146,0-.167.1.23.23,0,0,0,.146.146-.334,0-.167.272,0,0-.125,0,.125,0,0-.146-.125,0,0,.167S89.945,122.239,89.82,122.322Z"
          transform="translate(28.661 -52.855)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.887,120.137h0l-.125-.188C89.637,119.761,89.971,119.97,89.887,120.137Z"
          transform="translate(26.691 -53.7)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89.148,119.88a.648.648,0,0,0,.251-.23S89.086,119.713,89.148,119.88Z"
          transform="translate(28.433 -53.945)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M89,120.541c-.209-.334-.251.146-.335.272S88.955,120.687,89,120.541Z"
          transform="translate(29.86 -53.101)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M87.99,120.593c0-.125-.125,0-.23-.209,0,.188-.167.209,0,.46-.188,0-.4-.125-.585-.564s-.753.314-.732.773.606,1.108.9,1.087.439-.983.857-.836c0-.125.355-.293.4-.523s0-.125-.167-.335-.272.209-.418,0A.356.356,0,0,0,87.99,120.593Z"
          transform="translate(34.859 -53.404)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M85.33,116.772c0,.418,0,.815.167.92a2.634,2.634,0,0,0,.167-.962.272.272,0,0,1-.334.042Z"
          transform="translate(40.133 -57.129)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M69.663,126.49a.46.46,0,0,0,.314.481C70.165,126.469,69.872,126.532,69.663,126.49Z"
          transform="translate(88.516 -46.486)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M66.22,116.77c0,.188.146.1.167.23l-.125.1c.125.46.564.209.878.376,0-.376.815-.334.815,0s0-.146.167-.146,0,.293.188.355a1.294,1.294,0,0,1,0-.251c.125.146.293-.251.439-.355s.125.146.23,0,0-.209.23-.146c-.188-.188-.209-.418-.439-.481s0,.125,0,.209a.4.4,0,0,1-.5,0,.439.439,0,0,0,0,.314c0-.23-.439,0-.439-.418h-.042a.523.523,0,0,1,0,.188A9.822,9.822,0,0,1,66.22,116.77Z"
          transform="translate(96.538 -57.441)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M72.542,132.666a.481.481,0,0,0,.46-.606C72.73,132.144,72.73,132.478,72.542,132.666Z"
          transform="translate(79.512 -40.412)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M72.36,132.29c.46,0,.146-.669.544-.92C72.465,131.412,72.36,132.143,72.36,132.29Z"
          transform="translate(80.008 -41.164)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M72.746,131.131c.481,0,.481-.355.606-.648-.334-.146-.376.439-.606,0C72.37,130.587,72.851,130.942,72.746,131.131Z"
          transform="translate(79.057 -42.158)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M73.024,130.238c-.23-.209-.272,0-.564,0,0,.146,0,.4.314.46a.272.272,0,0,1,0-.251C72.815,130.343,73.024,130.406,73.024,130.238Z"
          transform="translate(79.678 -42.5)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M72.588,130.647c.439-.125-.314-.585.209-.753C72.107,129.81,72.233,130.417,72.588,130.647Z"
          transform="translate(80.24 -42.782)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M74.457,133.207c0-.251-.23-.314,0-.481a.384.384,0,0,1-.376-.251C74.039,132.371,74.018,133.082,74.457,133.207Z"
          transform="translate(74.901 -39.97)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M72.659,135.192c.293,0,.146-.251.251-.334a1.86,1.86,0,0,1-.69-.167C72.262,134.878,72.617,135,72.659,135.192Z"
          transform="translate(80.294 -37.544)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M71.571,134.994c.376,0,.773-.146.376-.314A.376.376,0,0,0,71.571,134.994Z"
          transform="translate(82.448 -37.555)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M71.932,134.786c.355-.125.9-.544.523-.836-.314,0-.125.272-.585,0C72.141,134.18,72.036,134.494,71.932,134.786Z"
          transform="translate(81.356 -38.351)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M71.328,136.4c.69.314.983-1.233,0-1.192a2.147,2.147,0,0,0,.4.167C71.39,135.608,71.809,136.193,71.328,136.4Z"
          transform="translate(83.11 -36.977)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M70.86,131.762c.314,0,.167-.355.293-.5C70.86,131.26,70.86,131.511,70.86,131.762Z"
          transform="translate(84.894 -41.284)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
        <path
          d="M69.74,129.187c.314,0,.544,0,.544-.334C69.886,128.748,69.74,129,69.74,129.187Z"
          transform="translate(88.105 -43.936)"
          fill="#d6fcdb"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);
export default HeroImage;
