const TwitterIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={39} height={39} viewBox="0 0 39 39">
    <path
      d="M30.875,0H8.125A8.126,8.126,0,0,0,0,8.125v22.75A8.126,8.126,0,0,0,8.125,39h22.75A8.125,8.125,0,0,0,39,30.875V8.125A8.125,8.125,0,0,0,30.875,0Zm-.226,15.01A15.17,15.17,0,0,1,7.311,28.481a10.732,10.732,0,0,0,7.9-2.208,5.338,5.338,0,0,1-4.982-3.7,5.364,5.364,0,0,0,2.408-.091,5.34,5.34,0,0,1-4.279-5.3,5.307,5.307,0,0,0,2.415.668A5.344,5.344,0,0,1,9.12,10.725,15.145,15.145,0,0,0,20.114,16.3a5.337,5.337,0,0,1,9.09-4.864,10.7,10.7,0,0,0,3.39-1.293,5.356,5.356,0,0,1-2.345,2.951,10.69,10.69,0,0,0,3.063-.84,10.822,10.822,0,0,1-2.663,2.758Z"
      fill="#ff884a"
    />
  </svg>
);

export default TwitterIcon;
