const FacebookIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={39} height={39} viewBox="0 0 39 39">
    <path
      d="M30.875,0H8.125A8.126,8.126,0,0,0,0,8.125v22.75A8.126,8.126,0,0,0,8.125,39h22.75A8.125,8.125,0,0,0,39,30.875V8.125A8.125,8.125,0,0,0,30.875,0ZM26,11.375H22.873c-1,0-1.748.409-1.748,1.445v1.805H26L25.613,19.5H21.125v13H16.25v-13H13V14.625h3.25V11.5c0-3.286,1.729-5,5.624-5H26Z"
      fill="#ff884a"
    />
  </svg>
);

export default FacebookIcon;
